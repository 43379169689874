import { stableColorGenerator } from "$root/utils/chart/colorGenerator";
import type { TableWithGroupsProps } from "@mdotm/mdotui/components";
import Color from "color";

export function groupedColorProps(): Pick<
	TableWithGroupsProps<{ assetClass?: string } & { rows: Array<{ assetClass?: string }> }>,
	"groupColor" | "rowColor"
>;

export function groupedColorProps<
	GroupAssetClassKey extends string = "assetClass",
	RowAssetClassKey extends string = "assetClass",
>(
	groupAssetClassKey?: GroupAssetClassKey,
	rowAssetClassKey?: RowAssetClassKey,
): Pick<
	TableWithGroupsProps<{ [K in GroupAssetClassKey]?: string } & { rows: Array<{ [K in RowAssetClassKey]?: string }> }>,
	"groupColor" | "rowColor"
>;

export function groupedColorProps<
	GroupAssetClassKey extends string = "assetClass",
	RowAssetClassKey extends string = "assetClass",
>(
	groupAssetClassKey?: GroupAssetClassKey,
	rowAssetClassKey?: RowAssetClassKey,
): Pick<
	TableWithGroupsProps<{ [K in GroupAssetClassKey]?: string } & { rows: Array<{ [K in RowAssetClassKey]?: string }> }>,
	"groupColor" | "rowColor"
> {
	return {
		groupColor: (group) => stableColorGenerator(group[groupAssetClassKey ?? "assetClass"] ?? ""),
		rowColor: (row) =>
			Color(stableColorGenerator(row[rowAssetClassKey ?? "assetClass"] ?? ""))
				.alpha(0.4)
				.toString(),
	};
}
