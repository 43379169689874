import type {
	HmmRegion,
	InvestmentListEntry,
	InvestmentReferenceListEntry,
	MarketViewListEntry,
	MarketViewType,
	ReferenceUniverseListEntry,
} from "$root/api/api-gen";
import { PortfolioStudioTab } from "$root/pages/PortfoliosStudio";
import { CircularProgressBar, Option, Select } from "@mdotm/mdotui/components";
import { builtInSortFnFor } from "@mdotm/mdotui/utils";
import type { UseQueryResult } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import type { Crumb } from "../Breadcrumbs";
import { typedUrlForRoute } from "../PlatformRouter/RoutesDef";
import { ReactQueryWrapperBase } from "../ReactQueryWrapper";
import { ellipsis } from "$root/utils/strings";
import { useSidebarEntries } from "../PlatformRouter/sidebar";
import { useMemo } from "react";
import { PortfolioStudioSettingTabEnum } from "$root/functional-areas/portfolio-studio-settings";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const useCrumbs = () => {
	const { t } = useTranslation();
	const sidebarEntries = useSidebarEntries();

	return useMemo(
		() =>
			({
				portfolioStudio: {
					children: "Portfolio studio",
				},
				selectEntities(params: { selectedEntity: PortfolioStudioTab; onChange(entity: PortfolioStudioTab): void }) {
					return {
						children: (
							<Select
								unstyled
								classList="flex items-center w-40 truncate"
								strategy="fixed"
								options={[
									{
										label: "Portfolios",
										value: PortfolioStudioTab.Portfolios,
									},
									{
										label: "References",
										value: PortfolioStudioTab.References,
									},
									{
										label: "Universes",
										value: PortfolioStudioTab.Universes,
									},
									{
										label: "Market Views",
										value: PortfolioStudioTab.MarketViews,
									},
								]}
								value={params.selectedEntity}
								i18n={{ triggerPlaceholder: () => "Select a portfolio studio area" }}
								onChange={params.onChange}
							/>
						),
					};
				},
				//#region Portfolio
				portfolios: {
					children: "Portfolios",
					href: typedUrlForRoute("PortfoliosStudio", { tab: PortfolioStudioTab.Portfolios }),
				},
				portfolioName: (params: { name?: string; uuid?: string }) => ({
					children: params?.name ?? "...",
					href: params?.uuid
						? typedUrlForRoute("PortfolioDetails", { portfolioUid: params?.uuid })
						: typedUrlForRoute("PortfoliosStudio", { tab: PortfolioStudioTab.Portfolios }),
				}),
				asyncSelectPortfolios: (params: {
					queryPortfolioList: UseQueryResult<InvestmentListEntry[], unknown>;
					selectedPortfolio: string;
					onChange(uuid: string): void;
				}) => ({
					children: (
						<ReactQueryWrapperBase
							query={params.queryPortfolioList}
							loadingFallback={<CircularProgressBar value="indeterminate" outerDiameter={12} innerDiameter={8} />}
						>
							{(portfolios) => (
								<Select
									unstyled
									classList="flex items-center w-40 truncate"
									strategy="fixed"
									options={portfolios
										.sort(builtInSortFnFor("creationTime"))
										.reverse()
										.map(({ name, uuid, status }) => ({
											label: `${name}${status === "DRAFT" ? "(draft)" : ""}`,
											value: uuid!,
											disabled: status === "DRAFT",
										}))}
									value={params.selectedPortfolio}
									i18n={{ triggerPlaceholder: () => t("PORTFOLIO_DETAILS.PORTFOLIO_SELECT_PLACEHOLDER") }}
									onChange={params.onChange}
								/>
							)}
						</ReactQueryWrapperBase>
					),
				}),
				newPortfolio: {
					children: "New portfolio",
				},
				editPortfolio: {
					children: "Edit portfolio composition",
				},
				editPortfolioSettings: {
					children: "Portfolio settings",
				},
				enhancePortfolio: {
					children: "Proposal",
				},
				//#endregion Portfolio

				//#region Reference
				references: {
					children: "References",
					href: typedUrlForRoute("PortfoliosStudio", { tab: PortfolioStudioTab.References }),
				},
				benchmarkName: (params: { name?: string; uuid?: string }) => ({
					children: params?.name ?? "...",
					href: params?.uuid
						? typedUrlForRoute("CustomBenchmark", { benchmarkId: params.uuid })
						: typedUrlForRoute("PortfoliosStudio", { tab: PortfolioStudioTab.References }),
				}),
				newBenchmark: {
					children: "New benchmark",
				},
				editBenchmark: {
					children: "Edit benchmark composition",
				},
				targetPortfolioName: (params: { name?: string; uuid?: string }) => ({
					children: params.name ?? "...",
					href: params?.uuid
						? typedUrlForRoute("PortfolioReferenceDetails", { portfolioUid: params.uuid })
						: typedUrlForRoute("PortfoliosStudio", { tab: PortfolioStudioTab.References }),
				}),
				asyncSelectTargetPortfolios: (params: {
					queryTargetPortfolioList: UseQueryResult<InvestmentReferenceListEntry[], unknown>;
					selectedTargetPortfolio: string;
					onChange(uuid: string): void;
				}) => ({
					children: (
						<ReactQueryWrapperBase
							query={params.queryTargetPortfolioList}
							loadingFallback={<CircularProgressBar value="indeterminate" outerDiameter={12} innerDiameter={8} />}
						>
							{(targetPortfolios) => (
								<Select
									unstyled
									classList="flex items-center w-40 truncate"
									strategy="fixed"
									options={targetPortfolios
										.sort(builtInSortFnFor("creationTime"))
										.reverse()
										.map(({ name, uuid }) => ({
											label: name!,
											value: uuid!,
										}))}
									value={params.selectedTargetPortfolio}
									i18n={{ triggerPlaceholder: () => t("PORTFOLIO_DETAILS.TARGET_PORTFOLIO_SELECT_PLACEHOLDER") }}
									onChange={params.onChange}
								/>
							)}
						</ReactQueryWrapperBase>
					),
				}),
				newTargetPortfolio: {
					children: "New target portfolio",
				},
				editTargetPortfolio: {
					children: "Edit target portfolio composition",
				},
				//#endregion Reference

				//#region Universe
				universes: {
					children: "Universes",
					href: typedUrlForRoute("PortfoliosStudio", { tab: PortfolioStudioTab.Universes }),
				},
				universeName: (params: { name?: string; uuid?: string }) => ({
					children: params?.name ?? "...",
					href: params?.uuid
						? typedUrlForRoute("UniverseDetails", { universeUuid: params?.uuid })
						: typedUrlForRoute("PortfoliosStudio", { tab: PortfolioStudioTab.Universes }),
				}),
				asyncSelectUniverses: (params: {
					queryUniverseList: UseQueryResult<ReferenceUniverseListEntry[], unknown>;
					selectedUniverse: string;
					onChange(uuid: string): void;
				}) => ({
					children: (
						<ReactQueryWrapperBase
							query={params.queryUniverseList}
							loadingFallback={<CircularProgressBar value="indeterminate" outerDiameter={12} innerDiameter={8} />}
						>
							{(universes) => (
								<Select
									unstyled
									classList="flex items-center w-40 truncate"
									strategy="fixed"
									options={universes
										.sort(builtInSortFnFor("creationTime"))
										.reverse()
										.map(({ name, uuid }) => ({ label: name!, value: uuid! }))}
									value={params.selectedUniverse}
									i18n={{ triggerPlaceholder: () => t("UNIVERSE.SELECT_PLACEHOLDER") }}
									onChange={params.onChange}
								/>
							)}
						</ReactQueryWrapperBase>
					),
				}),
				newUniverse: {
					children: "New universe",
				},
				editUniverse: {
					children: "Edit universe",
				},
				//#endregion Universe

				//#region Market view
				marketView: {
					children: "Market Views",
					href: typedUrlForRoute("PortfoliosStudio", { tab: PortfolioStudioTab.MarketViews }),
				},
				marketViewType(params: { marketViewType?: MarketViewType }) {
					return {
						children:
							params.marketViewType === "EXPECTED_RETURNS_VOLATILITY"
								? t("EXPECTED_RETURNS_VOLATILITY")
								: t("POSITIONING_INDICATORS"),
					};
				},
				marketViewName(params: { name?: string; uuid?: string; marketViewType?: MarketViewType }) {
					return {
						children: ellipsis(params?.name ?? "...", 20),
						href:
							params?.uuid && params?.marketViewType
								? typedUrlForRoute("MarketViewWorkSpace", {
										action: "view",
										uuid: params.uuid,
										type: params.marketViewType,
								  })
								: typedUrlForRoute("PortfoliosStudio", { tab: PortfolioStudioTab.MarketViews }),
					};
				},
				asyncSelectMarketViews: (params: {
					queryMarketViewList: UseQueryResult<MarketViewListEntry[], unknown>;
					selectedMarketViews: string;
					onChange(uuid: string): void;
				}) => ({
					children: (
						<ReactQueryWrapperBase
							query={params.queryMarketViewList}
							loadingFallback={<CircularProgressBar value="indeterminate" outerDiameter={12} innerDiameter={8} />}
						>
							{(marketViews) => (
								<Select
									unstyled
									classList="flex items-center w-40 truncate"
									strategy="fixed"
									options={marketViews
										.sort(builtInSortFnFor("creationDate"))
										.reverse()
										.map(({ name, uuid }) => ({ label: name!, value: uuid! }))}
									value={params.selectedMarketViews}
									i18n={{ triggerPlaceholder: () => "Select a market view" }} //TODO: translate
									onChange={params.onChange}
								/>
							)}
						</ReactQueryWrapperBase>
					),
				}),
				duplicateMarketView: {
					children: "Duplicate",
				},
				//#endregion Market view

				//#region Market insights
				marketInsights: {
					children: "Market insights",
				},
				selectMarketAreas(params: { selectedArea: string; onChange(url: string): void; mode?: "default" | "label" }) {
					const { mode = "default" } = params;
					return {
						children: (
							<Select
								unstyled
								classList={{
									"flex items-center truncate": true,
									"[&>span:nth-child(2)]:hidden [&>span:nth-child(1)]:!mr-0": mode === "label",
								}}
								strategy="fixed"
								options={
									sidebarEntries
										.find((x) => x.name === "MarketInsight")
										?.submenuEntries?.map(({ label, url, matchingUrls }) => ({
											label,
											value: url,
										})) ?? []
								}
								value={params.selectedArea}
								i18n={{ triggerPlaceholder: () => "Select a market insights area" }}
								onChange={params.onChange}
							/>
						),
					};
				},
				asyncSelectHMMRegion(params: {
					selectedRegion: HmmRegion;
					onChange(newRegion: HmmRegion): void;
					queryRegionOptions: UseQueryResult<
						{
							regionsOptions: Option<HmmRegion>[];
							updatedAt: Date;
						},
						unknown
					>;
				}) {
					return {
						children: (
							<ReactQueryWrapperBase
								query={params.queryRegionOptions}
								loadingFallback={<CircularProgressBar value="indeterminate" outerDiameter={12} innerDiameter={8} />}
							>
								{({ regionsOptions }) => (
									<Select
										unstyled
										classList="flex items-center w-40 truncate"
										strategy="fixed"
										options={regionsOptions}
										value={params.selectedRegion}
										i18n={{ triggerPlaceholder: () => "Select a region" }}
										onChange={params.onChange}
									/>
								)}
							</ReactQueryWrapperBase>
						),
					};
				},
				//#endregion Market insights

				//#region Storyfolio
				storyfolio: {
					children: "Storyfolio",
				},
				reportBuilder: {
					children: "Report builder",
					href: typedUrlForRoute("PortfolioStudioSettings", { tab: PortfolioStudioSettingTabEnum.ReportCustomisation })
				},
				commentaryBuilder: {
					children: "Commentary builder",
					href: typedUrlForRoute("Storyfolio/Studio", { })
				},
				selectStoryfolioBuilders(params: { currentPath: string; onChange(url: string): void }) {
					return {
						children: (
							<Select
								unstyled
								classList="flex items-center w-40 truncate"
								strategy="fixed"
								options={
									sidebarEntries
										.find((x) => x.name === "Storyfolio")
										?.submenuEntries?.map(({ label, url }) => ({ label, value: url })) ?? []
								}
								value={params.currentPath}
								i18n={{ triggerPlaceholder: () => "Select a builder area" }}
								onChange={params.onChange}
							/>
						),
					};
				},
				//#endregion Market insights

				//#region My settings
				mySettings: {
					children: "My settings",
				},
				selectmySettingsArea(params: { currentPath: string; onChange(url: string): void }) {
					return {
						children: (
							<Select
								unstyled
								classList="flex items-center w-40 truncate"
								strategy="fixed"
								options={
									sidebarEntries
										.find((x) => x.name === "My Settings")
										?.submenuEntries?.flatMap(({ label, url }) => (url ? [{ label, value: url }] : [])) ?? []
								}
								value={params.currentPath}
								i18n={{ triggerPlaceholder: () => "Select a setting area" }}
								onChange={params.onChange}
							/>
						),
					};
				},
				//#endregion My settings
			}) satisfies Record<string, Crumb | ((...args: any[]) => Crumb)>,
		[sidebarEntries, t],
	);
};

export default useCrumbs;
