import { AbortError } from "$root/utils/promise";
import { useUnsafeUpdatedRef } from "@mdotm/mdotui/react-extensions";
import { sleep, noop } from "@mdotm/mdotui/utils";
import { useState, useCallback, useRef, useEffect, useMemo } from "react";
import { fakeProgressProvider } from "$root/hooks/fake-progress";

export const stepNames = ["recovering", "analysing", "preparing"] as const;

export type FakeAiLoaderStepName = (typeof stepNames)[number];
export type FakeAiLoaderStep = { stepName: FakeAiLoaderStepName; progress: number };

export type FakeAiLoaderActions = {
	start(initialState?: FakeAiLoaderStep): void;
	stop(): Promise<void>;
};

export type UseFakeAiLoaderParams = {
	/** used only when autoStart is true */
	initialState?: FakeAiLoaderStep;
	/** @default true */
	autoStart?: boolean;
	onStateChange?(params: FakeAiLoaderStep): void;
};

export function useFakeAiLoader(params: UseFakeAiLoaderParams): {
	state: FakeAiLoaderStep;
} & FakeAiLoaderActions {
	const [state, _setState] = useState<FakeAiLoaderStep>(
		() =>
			params.initialState ?? {
				stepName: stepNames[0],
				progress: 0,
			},
	);

	const onStateChangeRef = useUnsafeUpdatedRef(params.onStateChange);
	const setState = useCallback(
		(v: FakeAiLoaderStep) => {
			onStateChangeRef.current?.(v);
			_setState(v);
		},
		[onStateChangeRef],
	);

	const [handles] = useState(() => {
		let latestAbortController: AbortController | undefined = undefined;
		let stopPromise = Promise.resolve();
		return {
			start(initialState?: FakeAiLoaderStep) {
				const abortController = new AbortController();
				latestAbortController = abortController;
				const initialStep = initialState?.stepName ?? stepNames[0];
				let currentState = initialState ?? { stepName: initialStep, progress: 0 };
				setState(currentState);
				stopPromise = (async () => {
					for (let i = stepNames.indexOf(currentState.stepName); i < stepNames.length; i++) {
						const stepName = stepNames[i];
						currentState = {
							stepName,
							progress: stepName === initialStep ? initialState?.progress ?? 0 : 0,
						};
						setState(currentState);

						for await (const p of fakeProgressProvider({
							signal:
								i !== stepNames.length - 1
									? AbortSignal.any([abortController.signal, AbortSignal.timeout(3000)])
									: abortController.signal,
							deltaX: i !== stepNames.length - 1 ? 0.3 : 0.02,
							expCoeff: i !== stepNames.length - 1 ? 1 : 0.4,
							sleepMs: 400,
							initialProgress: currentState.progress,
						})) {
							currentState = {
								stepName,
								progress: p,
							};
							setState(currentState);
						}
						await sleep(400);
					}
				})();
			},
			async stop() {
				latestAbortController?.abort(new AbortError());
				await stopPromise;
			},
		};
	});

	const handlesRef = useRef(handles);
	const initialPropsRef = useRef(params);
	useEffect(() => {
		if (initialPropsRef.current.autoStart ?? true) {
			handlesRef.current.start(initialPropsRef.current.initialState);
		}
		return () => {
			// always stop on unmount
			// eslint-disable-next-line react-hooks/exhaustive-deps
			handlesRef.current.stop().catch(noop);
		};
	}, []);

	return useMemo(() => ({ state, ...handles }), [handles, state]);
}
