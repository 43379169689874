import type {
	CommentaryLanguages,
	CommentaryTemplateDto,
	CustomReportDataDTO,
	InvestmentCommentaryResponse,
	InvestmentSummary,
	PortfolioExAnteMetricTypeEnum,
	PortfolioMetricPreference,
} from "$root/api/api-gen";
import {
	CommentaryTemplateControllerApiFactory,
	InvestmentEnhancementReportsControllerApiFactory,
	InvestmentReportsControllerApiFactory,
	PortfolioStudioPreferencesApiFactory,
	UserControllerApiFactory,
} from "$root/api/api-gen";
import { getApiGen } from "$root/api/factory";
import type { ComponentAndPropsPair } from "$root/components/EvolvedPrint/configuration";
import type {
	ReportTemplate,
	ReportTemplateItemFor,
	ReportTemplateItemMap,
	ReportTemplateStatus,
	ReportTemplateVariant,
} from "$root/pages/PortfolioStudioSettings/ReportEditor/report-latest";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { customObjectEntriesFn } from "$root/utils/experimental";
import { typedObjectEntries } from "$root/utils/objects";
import { parallelize, structuredParallelize } from "$root/utils/promise";
import type { MaybePromise } from "@mdotm/mdotui/headless";
import { builtInSortFnFor } from "@mdotm/mdotui/utils";
import { Map } from "immutable";
import type { IUser } from "../user";
import Commentary, { getCommentaryProps } from "./components/Commentary";
import Composition from "./components/Composition";
import { Cover } from "./components/Cover";
import { Disclaimer } from "./components/Disclaimer";
import ExAnteMetrics from "./components/ExAnteMetrics";
import type { ExAnteVolatilityContributionSelectionProps } from "./components/ExAnteVolatilityContribution";
import ExAnteVolatilityContribution, {
	getExAnteVolatilityContributionSplittableProps,
} from "./components/ExAnteVolatilityContribution";
import {
	Exposure,
	chunkExposureListItems,
	exposureChartHeight,
	exposureEntriesToSplittableProps,
} from "./components/Exposure";
import { ExposureCompare, exposureCompareEntriesToSplittableProps } from "./components/ExposureCompare";
import ExposureFactors, { getExposureFactorsProps } from "./components/ExposureFactors";
import { FreeText } from "./components/FreeText";
import { PortfolioPerformance } from "./components/Performance";
import PerformanceAttribution from "./components/PerformanceAttribution";
import type { PerformanceContributionVolatilitySelectionProps } from "./components/PerformanceVolatilityContribution";
import PerformanceVolatilityContribution, {
	getPerformanceVolatilityContributionProps,
} from "./components/PerformanceVolatilityContribution";
import { PortfolioMetrics } from "./components/PortfolioMetrics";
import { Summary } from "./components/Summary";
import { jsxHelper } from "./utils";
import type { Languages } from "$root/localization/i18n";
import i18next from "i18next";

const i18nLangToCommentaryLang: Record<Languages, CommentaryLanguages> = {
	it: "ITALIAN",
	en: "ENGLISH",
	de: "GERMAN",
	fr: "FRENCH",
	es: "SPANISH"
};

const commentaryLanguageToI18nLang: Record<CommentaryLanguages, Languages> = {
	ITALIAN: "it",
	ENGLISH: "en",
	FRENCH: "fr", // TODO: replace with "fr" once supported
	GERMAN: "de", // TODO: replace with "de" once supported
	SPANISH: "es", // TODO: replace with "es" once supported
};

const volatilityList = ["VOLATILITY_1Y", "VOLATILITY_2Y", "VOLATILITY_3Y"] as const;
const varList = [
	"PARAMETRIC_VAR_95_1Y",
	"PARAMETRIC_VAR_975_1Y",
	"PARAMETRIC_VAR_99_1Y",
	"PARAMETRIC_VAR_95_2Y",
	"PARAMETRIC_VAR_975_2Y",
	"PARAMETRIC_VAR_99_2Y",
	"PARAMETRIC_VAR_95_3Y",
	"PARAMETRIC_VAR_975_3Y",
	"PARAMETRIC_VAR_99_3Y",
	"HISTORICAL_VAR_95_1Y",
	"HISTORICAL_VAR_975_1Y",
	"HISTORICAL_VAR_99_1Y",
	"HISTORICAL_VAR_95_2Y",
	"HISTORICAL_VAR_975_2Y",
	"HISTORICAL_VAR_99_2Y",
	"HISTORICAL_VAR_95_3Y",
	"HISTORICAL_VAR_975_3Y",
	"HISTORICAL_VAR_99_3Y",
] as const;
const trackingErrorList = ["TRACKING_ERROR_3Y"] as const;

export type CommonPortfolioData = {
	portfolio: InvestmentSummary;
	variant: ReportTemplateVariant;
	report: CustomReportDataDTO;
	language: Languages;
	commentaryTemplates: CommentaryTemplateDto[];
};

export async function getDataForTemplate(
	portfolioUid: string,
	reportTemplate: ReportTemplate,
	variant: ReportTemplateVariant,
	status: ReportTemplateStatus,
	user: IUser,
): Promise<{
	componentAndPropsList: Array<Promise<Array<ComponentAndPropsPair<any, any>>>>;
	common: CommonPortfolioData;
}> {
	const portfolioApi = getApiGen(InvestmentReportsControllerApiFactory);
	const portfolioEnhancedApi = getApiGen(InvestmentEnhancementReportsControllerApiFactory);
	const userApi = getApiGen(UserControllerApiFactory);
	const portfolioStudioPreferenceApi = getApiGen(PortfolioStudioPreferencesApiFactory);

	const { commentaryLanguage } = await axiosExtract(portfolioStudioPreferenceApi.getExplainabilitySettings());
	const common: CommonPortfolioData = {
		portfolio:
			variant === "current"
				? await axiosExtract(portfolioApi.getInvestmentSummary(portfolioUid))
				: await axiosExtract(portfolioEnhancedApi.getInvestmentEnhancementSummary(portfolioUid)),
		report: await axiosExtract(userApi.customReportData()),
		variant,
		commentaryTemplates: await axiosExtract(getApiGen(CommentaryTemplateControllerApiFactory).getTemplateList()),
		language: commentaryLanguageToI18nLang[commentaryLanguage ?? "ENGLISH"],
	};

	const all = structuredParallelize(
		(status === "draft" ? reportTemplate.data.templateDraftItemsByVariant : reportTemplate.data.templateItemsByVariant)[
			variant
		].map((x, index) => () => getDataForItemTemplate(x, common, index, user)),
		{ concurrency: 10 },
	);
	return { common, componentAndPropsList: all };
}

function getDataForItemTemplate<K extends keyof ReportTemplateItemMap, T extends ReportTemplateItemFor<K>>(
	item: T,
	common: CommonPortfolioData,
	index: number,
	user: IUser,
): ReturnType<(typeof itemTemplateDataProviders)[K]> {
	// Cheating a bit here but it should be fine
	return (itemTemplateDataProviders[item.kind] as any)(item, common, index, user) as ReturnType<
		(typeof itemTemplateDataProviders)[K]
	>;
}

const itemTemplateDataProviders = {
	cover(config, common) {
		const { portfolio, variant, report } = common;
		return [
			jsxHelper(Cover, {
				commonProps: {
					title: config.title ? config.title : variant === "current" ? "PORTFOLIO DETAILS" : "PORTFOLIO DETAILS",
					subtitle: `${portfolio.name?.toUpperCase()}`,
					footerDescription: report.footer?.description,
					backgroundImage: report.backgroundImage?.realData ? report.backgroundImage : undefined,
					clientLogo: report.clientLogo?.realData ? report.clientLogo : undefined,
				},
				splittableProps: [],
				hideFooter: true,
				hideHeader: true,
			}),
		];
	},
	summary(config, common) {
		return [
			jsxHelper(Summary, {
				commonProps: {
					portfolio: common.portfolio,
					config,
				},
				splittableProps: [],
			}),
		];
	},
	async portfolioMetrics(config, common) {
		const { id, kind, benchmark, ...params } = config;
		const portfolioMetricPreferences: Array<PortfolioMetricPreference> = customObjectEntriesFn(params).flatMap(
			([_, preferences]) =>
				customObjectEntriesFn(preferences).map(
					([metricType, enabled]): PortfolioMetricPreference => ({ enabled, metricType }),
				),
		);
		const metrics = await axiosExtract(
			getApiGen(InvestmentReportsControllerApiFactory).getPortfolioMetrics(
				common.portfolio.uuid!,
				common.portfolio.primaryBenchmarkIdentifier!,
				{ portfolioMetricPreferences },
			),
		);
		return [
			jsxHelper(PortfolioMetrics, {
				commonProps: {
					benchmark,
				},
				splittableProps: metrics.portfolioMetrics?.filter((x) => x.type) ?? [],
			}),
		];
	},
	async exAntePortfolioMetrics(config, common) {
		const metrics = await axiosExtract(
			common.variant === "current"
				? getApiGen(InvestmentReportsControllerApiFactory).getPortfolioExAnteMetrics(
						common.portfolio.uuid!,
						common.portfolio.primaryBenchmarkIdentifier!,
				  )
				: getApiGen(InvestmentEnhancementReportsControllerApiFactory).getPortfolioExAnteMetrics1(
						common.portfolio.uuid!,
						common.portfolio.primaryBenchmarkIdentifier!,
				  ),
		);
		const { id, kind, benchmark, riskConstraint, ...params } = config;

		const userVolatilityMap = Map<PortfolioExAnteMetricTypeEnum, boolean>(
			volatilityList.map((metricType) => [metricType, riskConstraint.VOLATILITY]),
		);

		const userVarMap = Map<PortfolioExAnteMetricTypeEnum, boolean>(
			varList.map((metricType) => [metricType, riskConstraint.VAR]),
		);

		const userTrackingErrorMap = Map<PortfolioExAnteMetricTypeEnum, boolean>(
			trackingErrorList.map((metricType) => [metricType, riskConstraint.TRACKING_ERROR]),
		);

		const portfolioExtanteMetricsPreferences: Array<{ enabled: boolean; metricType: PortfolioExAnteMetricTypeEnum }> =
			customObjectEntriesFn(params).flatMap(([_, preferences]) =>
				customObjectEntriesFn(preferences).map(
					([metricType, enabled]): { enabled: boolean; metricType: PortfolioExAnteMetricTypeEnum } => ({
						enabled,
						metricType,
					}),
				),
			);

		const preferenceMap = Map<PortfolioExAnteMetricTypeEnum, boolean>(
			portfolioExtanteMetricsPreferences.map((x) => [x.metricType, x.enabled]),
		);

		const filteredExAnteMetrics = metrics.portfolioExAnteMetrics?.filter((x) => {
			if (x.type) {
				return x.fromUserConstraint
					? userVolatilityMap.get(x.type) || userVarMap.get(x.type) || userTrackingErrorMap.get(x.type)
					: preferenceMap.get(x.type);
			}
			return false;
		});

		return [
			jsxHelper(ExAnteMetrics, {
				commonProps: {
					benchmark: config.benchmark,
					variant: common.variant,
				},
				splittableProps: filteredExAnteMetrics ?? [],
			}),
		];
	},
	async commentary(config, common) {
		const { commentaryTemplates } = common;
		const { useTemplateLanguage } = config;
		let reportLanguageToCommentaryLang = i18nLangToCommentaryLang[common.language];
		const commentaryResponse = await (async () => {
			try {
				let commentary: InvestmentCommentaryResponse | undefined = undefined;

				let sanitizedTemplateUuid = config.templateUuid;

				if (
					!sanitizedTemplateUuid ||
					(commentaryTemplates && !commentaryTemplates.find((x) => x.uuid === config.templateUuid))
				) {
					sanitizedTemplateUuid =
						commentaryTemplates.find((x) => x.name === "Standard Template")?.uuid ?? config.templateUuid;
				}

				commentary = await axiosExtract(
					common.variant === "current"
						? getApiGen(InvestmentReportsControllerApiFactory).getCommentaries(common.portfolio.uuid!)
						: getApiGen(InvestmentEnhancementReportsControllerApiFactory).getCommentaries1(common.portfolio.uuid!),
				);

				if (useTemplateLanguage) {
					reportLanguageToCommentaryLang =
						commentaryTemplates.find((x) => x.uuid === sanitizedTemplateUuid)?.language ??
						reportLanguageToCommentaryLang;
				}

				if (
					sanitizedTemplateUuid &&
					(commentary.template?.uuid !== sanitizedTemplateUuid ||
						commentary.status !== "COMPLETED" ||
						commentary.template.language !== reportLanguageToCommentaryLang)
				) {
					const detailedCommentary = await axiosExtract(
						getApiGen(CommentaryTemplateControllerApiFactory).preview1(
							common.portfolio.uuid!,
							sanitizedTemplateUuid,
							reportLanguageToCommentaryLang,
							common.variant === "proposal",
						),
					);
					commentary = {
						commentary: detailedCommentary,
						status: "COMPLETED",
						template: commentaryTemplates.find((x) => x.uuid === sanitizedTemplateUuid),
					};
				}

				return commentary;
			} catch (e) {
				console.error(e);
				throw e;
			}
		})().catch(() => undefined);

		return !commentaryResponse || !commentaryResponse?.commentary
			? [
					jsxHelper(Commentary, {
						commonProps: {
							commentary: { status: "COMPLETED" },
							config,
						},
						splittableProps: getCommentaryProps({
							...commentaryResponse,
							commentary: i18next.getFixedT(common.language)("REPORT.COMMENTARY_SOON"),
						}),
					}),
			  ]
			: [
					jsxHelper(Commentary, {
						commonProps: {
							commentary: commentaryResponse,
							config,
						},
						splittableProps: getCommentaryProps({
							...commentaryResponse,
							commentary: commentaryResponse.commentary,
						}),
					}),
			  ];
	},
	async composition(config, common) {
		const { composition } = await axiosExtract(
			common.variant === "current"
				? getApiGen(InvestmentReportsControllerApiFactory).getInvestmentComposition(common.portfolio.uuid!)
				: getApiGen(InvestmentEnhancementReportsControllerApiFactory).getInvestmentComposition1(common.portfolio.uuid!),
		);
		return [
			jsxHelper(Composition, {
				commonProps: {
					variant: common.variant,
					portfolio: common.portfolio,
					config,
				},
				splittableProps: composition?.sort(builtInSortFnFor("weight", "desc")) ?? [],
			}),
		];
	},
	async exposure(config, common) {
		switch (common.variant) {
			case "current": {
				const reportEnhanceApi = getApiGen(InvestmentReportsControllerApiFactory);
				const all = await parallelize(
					typedObjectEntries(config.versus)
						.filter(([, enabled]) => enabled)
						.map(([exposureType]) => exposureType)
						.map((exposureType) => async () => {
							const { investmentComposition: composition } = await axiosExtract(
								reportEnhanceApi.getTwoLevelsInvestmentExposure(
									common.portfolio.uuid!,
									common.portfolio.primaryBenchmarkIdentifier!,
									exposureType,
								),
							);
							return !composition?.length
								? null
								: jsxHelper(Exposure, {
										commonProps: {
											comparison: exposureType,
											composition: composition ?? [],
										},
										splittableProps: chunkExposureListItems(
											exposureEntriesToSplittableProps(composition ?? []),
											exposureChartHeight,
										),
										startsFromBlankPage: true,
								  });
						}),
					{ concurrency: 4 },
				);
				return all.filter(Boolean) as ComponentAndPropsPair<any, unknown>[];
			}
			case "proposal": {
				const reportEnhanceApi = getApiGen(InvestmentEnhancementReportsControllerApiFactory);
				const all = await parallelize(
					typedObjectEntries(config.versus)
						.filter(([, enabled]) => enabled)
						.map(([exposureType]) => exposureType)
						.map((exposureType) => async () => {
							const { investmentComposition, enhancementComposition } = await axiosExtract(
								reportEnhanceApi.getTwoLevelsInvestmentExposure1(
									common.portfolio.uuid!,
									common.portfolio.primaryBenchmarkIdentifier!,
									exposureType,
								),
							);
							return !investmentComposition?.length || !enhancementComposition?.length
								? null
								: jsxHelper(ExposureCompare, {
										commonProps: {
											comparison: exposureType,
											composition: investmentComposition ?? [],
										},
										splittableProps: exposureCompareEntriesToSplittableProps({
											currentComposition: investmentComposition ?? [],
											enhancedComposition: enhancementComposition ?? [],
										}),
										startsFromBlankPage: true,
								  });
						}),
					{ concurrency: 4 },
				);
				return all.filter(Boolean) as ComponentAndPropsPair<any, unknown>[];
			}
		}
	},
	freeText(config, _common) {
		return [
			jsxHelper(FreeText, {
				commonProps: {
					config,
				},
				splittableProps: [],
			}),
		];
	},
	async performance(config, common) {
		const { benchmark, current } = await axiosExtract(
			getApiGen(InvestmentReportsControllerApiFactory).getInvestmentPerformance(
				common.portfolio.uuid!,
				common.portfolio.primaryBenchmarkIdentifier!,
			),
		);
		return !current
			? []
			: [
					jsxHelper(PortfolioPerformance, {
						commonProps: {
							current: current as [number, number][],
							benchmark: benchmark as [number, number][],
							showBenchmark: config.benchmark,
						},
						splittableProps: [],
					}),
			  ];
	},
	async performanceAttribution(config, common) {
		const { id, kind, ...params } = config;

		const preferences = customObjectEntriesFn(params).flatMap(([k, v]) => (v ? [k] : []));
		const attributionResponses = await parallelize(
			preferences.map((horizon) => async () => ({
				data: await axiosExtract(
					getApiGen(InvestmentReportsControllerApiFactory).getRealizedPerformanceAttribution(
						common.portfolio.uuid!,
						common.portfolio.primaryBenchmarkIdentifier!,
						horizon,
					),
				),
				horizon,
			})),
		);

		return attributionResponses.flatMap(({ data, horizon }) => {
			if (!data.current?.length) {
				return [];
			}

			return [
				jsxHelper(PerformanceAttribution, {
					commonProps: { performanceAttribution: { current: data.current }, horizon },
					splittableProps: [],
					startsFromBlankPage: true,
				}),
			];
		});
	},
	async exAnteContributionVolatility(config, common) {
		const data = await axiosExtract(
			common.variant === "current"
				? getApiGen(InvestmentReportsControllerApiFactory).getCompositionVolatilityContribution(
						common.portfolio.uuid!,
						common.portfolio.primaryBenchmarkIdentifier!,
						"ONE_YEAR",
				  )
				: getApiGen(InvestmentEnhancementReportsControllerApiFactory).getCompositionVolatilityContribution1(
						common.portfolio.uuid!,
						"ONE_YEAR",
				  ),
		);

		const map: Record<keyof (typeof config)["versus"], ExAnteVolatilityContributionSelectionProps> = {
			MACRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY: { selector: "assetClass", vsSelector: "geography" },
			MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS: { selector: "assetClass", vsSelector: "microAssetClass" },
			MICRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY: { selector: "microAssetClass", vsSelector: "geography" },
			MACRO_GEOGRAPHY_VS_MACRO_ASSET_CLASS: { selector: "geography", vsSelector: "assetClass" },
			MACRO_GEOGRAPHY_VS_MICRO_ASSET_CLASS: { selector: "geography", vsSelector: "microAssetClass" },
		};
		return typedObjectEntries(config.versus)
			.filter(([, enabled]) => enabled)
			.flatMap(([selectorStr]) => {
				const selector = map[selectorStr];
				const dataVSdata = getExAnteVolatilityContributionSplittableProps(
					data,
					selector,
					common.variant,
					config.filterSignificant,
				);
				return !dataVSdata.list.length
					? []
					: [
							jsxHelper(ExAnteVolatilityContribution, {
								commonProps: {
									variant: common.variant,
									data,
									comparison: dataVSdata.opts,
									graphLimit: dataVSdata.graphLimit,
									graphColumnMarkers: dataVSdata.graphColumnMarkers,
								},
								splittableProps: dataVSdata.list,
								startsFromBlankPage: true,
							}),
					  ];
			});
	},
	async performanceAndVolatilityContribution(config, common) {
		const [contributionVolatility, performanceVolatility] = await Promise.all([
			axiosExtract(
				getApiGen(InvestmentReportsControllerApiFactory).getRealizedVolatilityContribution(
					common.portfolio.uuid!,
					common.portfolio.primaryBenchmarkIdentifier!,
					"ONE_YEAR",
				),
			),
			axiosExtract(
				getApiGen(InvestmentReportsControllerApiFactory).getRealizedPerformanceContribution(
					common.portfolio.uuid!,
					common.portfolio.primaryBenchmarkIdentifier!,
					"ONE_YEAR",
				),
			),
		]);

		const map: Record<keyof (typeof config)["versus"], PerformanceContributionVolatilitySelectionProps> = {
			MACRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY: { selector: "assetClass", vsSelector: "geography" },
			MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS: { selector: "assetClass", vsSelector: "microAssetClass" },
			MICRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY: { selector: "microAssetClass", vsSelector: "geography" },
			MACRO_GEOGRAPHY_VS_MACRO_ASSET_CLASS: { selector: "geography", vsSelector: "assetClass" },
			MACRO_GEOGRAPHY_VS_MICRO_ASSET_CLASS: { selector: "geography", vsSelector: "microAssetClass" },
		};
		return typedObjectEntries(config.versus)
			.filter(([, enabled]) => enabled)
			.flatMap(([selectorStr]) => {
				const selector = map[selectorStr];
				const data = { contributionVolatility, performanceVolatility };
				const dataVSdata = getPerformanceVolatilityContributionProps(data, selector, config.filterSignificant);
				return !dataVSdata.list.length
					? []
					: [
							jsxHelper(PerformanceVolatilityContribution, {
								commonProps: {
									data,
									comparison: dataVSdata.opts,
									graphLimit: dataVSdata.graphLimit,
									graphColumnMarkers: dataVSdata.graphColumnMarkers,
								},
								splittableProps: dataVSdata.list,
								startsFromBlankPage: true,
							}),
					  ];
			});
	},
	async factorExposure(config, common) {
		const investmentFactors = await axiosExtract(
			common.variant === "current"
				? getApiGen(InvestmentReportsControllerApiFactory).getInvestmentFactors(
						common.portfolio.uuid!,
						common.portfolio.primaryBenchmarkIdentifier!,
				  )
				: getApiGen(InvestmentEnhancementReportsControllerApiFactory).getInvestmentFactors1(
						common.portfolio.uuid!,
						common.portfolio.primaryBenchmarkIdentifier!,
				  ),
		);
		const data = { investmentFactors, variant: common.variant };
		const exposureFactorsData = getExposureFactorsProps(data, config.filterSignificant);

		return !exposureFactorsData.list.length
			? []
			: [
					jsxHelper(ExposureFactors, {
						commonProps: {
							variant: common.variant,
							data,
							graphLimits: exposureFactorsData.graphLimits,
							graphColumnMarkers: exposureFactorsData.graphColumnMarkers,
							config,
						},
						splittableProps: exposureFactorsData.list,
						startsFromBlankPage: true,
					}),
			  ];
	},
	disclaimer(config, common) {
		return [
			jsxHelper(Disclaimer, {
				commonProps: {
					disclaimer: config.content.enabled ? config.content : undefined,
					// TODO: add to add contact field?
					address: undefined,
					mail: undefined,
					website: undefined,
					contacts: config.contact.enabled ? config.contact.description : undefined,
				},
				splittableProps: [],
				startsFromBlankPage: true,
				hideFooter: true,
				hideHeader: true,
			}),
		];
	},
} as const satisfies {
	[K in keyof ReportTemplateItemMap]: (
		config: ReportTemplateItemFor<K>,
		common: CommonPortfolioData,
		index: number,
		user: IUser,
	) => MaybePromise<Array<ComponentAndPropsPair<any, unknown>>>;
};
