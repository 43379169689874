import type {
	AvailableFieldsResponse,
	FieldKey,
	Filter,
	FilterResponse,
	FilterValues,
	MetricEntityType,
	MetricResponse,
	MultiPresetResponse,
	PresetResponse,
	ReferenceEntityValues,
} from "$root/api/api-gen";
import { MetricOrdering, MetricReferenceType, MetricThresholdType } from "$root/api/api-gen";
import { DebouncedSearchInput } from "$root/components/DebouncedSearchInput";
import { ReactQueryWrapperBase } from "$root/components/ReactQueryWrapper";
import Separator from "$root/components/Separator";
import { SideDrawer } from "$root/components/SideDrawer";
import { spawnCreateDialog } from "$root/components/spawnable/entity-management/create-dialog";
import { platformToast } from "$root/notification-system/toast";
import type { ImperativeHandlesRefProps } from "$root/utils/react-extra";
import { useImperativeHandlesRef } from "$root/utils/react-extra";
import { useQueryNoRefetch } from "$root/utils/react-query";
import type { Option, OptionWithOptionalGroup } from "@mdotm/mdotui/components";
import {
	AsyncButton,
	Button,
	ButtonGroupRadio,
	CircularProgressBar,
	Controller,
	DatePickerInput,
	FormField,
	Icon,
	NullableNumberInput,
	Select,
	TinyIconButton,
	Transition,
} from "@mdotm/mdotui/components";
import type { MaybePromise } from "@mdotm/mdotui/headless";
import {
	ForEach,
	generateUniqueDOMId,
	overrideClassName,
	toClassName,
	useRefProxyWithState,
} from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { groupBy, noop, unpromisify } from "@mdotm/mdotui/utils";
import { Map } from "immutable";
import { useCallback, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";

export const extendedTabWidth = 464;
export const smallTabWidth = 24;

function handleMapTruthy<K, T>(map?: Map<K, T>): Map<K, T> {
	return map ?? Map();
}

type keyMetricThresholdMapType =
	| {
			thresholdType?: Exclude<MetricThresholdType, "MANUAL">;
			thresholdEntity?: MetricResponse["thresholdEntity"];
			thresholdValue?: MetricResponse["thresholdValue"];
	  }
	| {
			thresholdType: Extract<MetricThresholdType, "MANUAL">;
			thresholdEntity?: undefined;
			thresholdValue: MetricResponse["thresholdValue"];
	  };

export type PortfolioInsightPanelPreset = Omit<PresetResponse, "filters" | "metric"> & {
	search: string;
	filters?: Map<string, FilterResponse>;
	metric?: Omit<MetricResponse, "thresholdType" | "thresholdEntity" | "thresholdValue"> & {
		keyMetricThreshold?: Map<string, keyMetricThresholdMapType>;
	};
};

export type PortfolioInsightPanelProps = {
	expand: boolean;
	onExpandChange(): void;
	onCheckIfNameIsAvailable(name: string): Promise<boolean>;
	presetsProvider(): Promise<MultiPresetResponse>;
	selectableProvider(): Promise<AvailableFieldsResponse>;
	thresholdForEntityProvider(preset: NonNullable<PortfolioInsightPanelPreset["metric"]>): Promise<number | undefined>;
	onChangeFavorite(uuid: string): Promise<void>;
	onChangePreset(preset?: PortfolioInsightPanelPreset): void;
	onSaveAsNew(preset: PortfolioInsightPanelPreset, name: string): MaybePromise<void>;
	onSave(preset: PortfolioInsightPanelPreset): MaybePromise<void>;
};

const emptyInsightPanelPreset = { search: "" };
function parsePresetResponse(preset?: PresetResponse): PortfolioInsightPanelPreset {
	return preset
		? {
				...preset,
				...emptyInsightPanelPreset,
				filters: Map<string, Filter>(preset.filters?.map((x) => [generateUniqueDOMId(), x])),
				metric: {
					...preset.metric,
					keyMetricThreshold: Map<string, keyMetricThresholdMapType>([
						[
							generateUniqueDOMId(),
							preset.metric?.thresholdType === "MANUAL"
								? {
										thresholdType: preset.metric?.thresholdType,
										thresholdValue: preset.metric?.thresholdValue ?? 0,
								  }
								: {
										thresholdType: preset.metric?.thresholdType,
										thresholdEntity: preset.metric?.thresholdEntity,
								  },
						],
					]),
				},
		  }
		: emptyInsightPanelPreset;
}

export type PortfolioInsightPanelHandles = {
	setThreshold(thresholdValue?: number): void;
	setManualSelection(manualSelection?: Array<string>): void;
	setOrder(newOrder: MetricResponse["ordering"]): void;
};

function SelectPreset(props: {
	value?: string;
	presets?: PresetResponse[];
	onChangeSelection?(newPreset: PortfolioInsightPanelPreset, uuid?: string | null): void;
}) {
	const { presets, value, onChangeSelection } = props;
	const options = useMemo(() => {
		if (!presets) {
			return [];
		}
		return presets.flatMap((x): Array<Option<string>> => (x.uuid && x.name ? [{ label: x.name, value: x.uuid }] : []));
	}, [presets]);

	const presetMap = useMemo(() => {
		if (!presets) {
			return Map<string, PortfolioInsightPanelPreset | undefined>();
		}
		return Map<string, PortfolioInsightPanelPreset | undefined>(
			presets.flatMap((x) => (x.uuid ? [[x.uuid, parsePresetResponse(x)]] : [])),
		);
	}, [presets]);

	const onSelectPreset = useCallback(
		(uuid?: string | null) => {
			if (!uuid) {
				return onChangeSelection?.(emptyInsightPanelPreset, uuid);
			}
			onChangeSelection?.(presetMap.get(uuid) ?? emptyInsightPanelPreset, uuid);
		},
		[onChangeSelection, presetMap],
	);
	return (
		<Select
			data-qualifier="InsightPanel/SelectPreset/Dropdown"
			innerRef={(e) => e?.setAttribute("data-qualifier", "InsightPanel/SelectPreset/Trigger")}
			options={options}
			value={value}
			onChange={onSelectPreset}
			classList="w-[150px]"
		/>
	);
}

function PortfolioInsightPanel(
	props: PortfolioInsightPanelProps & ImperativeHandlesRefProps<PortfolioInsightPanelHandles>,
): JSX.Element {
	const {
		thresholdForEntityProvider,
		presetsProvider,
		onChangePreset,
		selectableProvider,
		onExpandChange,
		onSave,
		onSaveAsNew,
		onCheckIfNameIsAvailable,
		onChangeFavorite,
		handlesRef,
		expand,
	} = props;

	const latestInsightPanelPresetRef = useRef<PortfolioInsightPanelPreset>(emptyInsightPanelPreset);
	const [insightPanelPreset, setInsightPanelPreset] = useRefProxyWithState<PortfolioInsightPanelPreset>(
		emptyInsightPanelPreset,
		latestInsightPanelPresetRef,
	);

	const { t } = useTranslation();
	useImperativeHandlesRef(handlesRef, {
		setThreshold: (threshold) => {
			setInsightPanelPreset((preset) => {
				const key = Array.from(preset.metric?.keyMetricThreshold?.keys() ?? []).at(0);
				return {
					...preset,
					metric: {
						...preset.metric,
						keyMetricThreshold: handleMapTruthy(preset.metric?.keyMetricThreshold).set(key ?? generateUniqueDOMId(), {
							thresholdType: "MANUAL",
							thresholdValue: threshold ?? 0,
						}),
					},
				};
			});
			onChangePreset(latestInsightPanelPresetRef.current);
		},
		setManualSelection: (manualSelection) => {
			setInsightPanelPreset((preset) => {
				const presetMap = handleMapTruthy(preset.filters);
				const key = presetMap.findKey((v) => v.key === "NAME");
				return {
					...preset,
					filters: presetMap.set(key ?? generateUniqueDOMId(), {
						key: "NAME",
						type: "FINITE_SET_PORTFOLIOS",
						relation: "EQUAL",
						value: manualSelection,
					}),
				};
			});

			onChangePreset(latestInsightPanelPresetRef.current);
		},
		setOrder: (newOrder) => {
			setInsightPanelPreset((preset) => {
				if (preset.metric) {
					preset.metric.ordering = newOrder;
				}
				return preset;
			});
		},
	});

	const queryPresets = useQueryNoRefetch(["queryPresets"], {
		queryFn: presetsProvider,
		onSuccess: ({ currentPreset }) => {
			const parsedPreset = parsePresetResponse(currentPreset);
			setInsightPanelPreset(parsedPreset);
			onChangePreset(parsedPreset);
		},
	});

	const querySelectableInsightFilters = useQueryNoRefetch(["querySelectableInsightFilters"], {
		queryFn: selectableProvider,
	});

	return (
		<SideDrawer
			toggleIcon="Filter"
			toggleOffset={64}
			expand={expand}
			onExpandChange={onExpandChange}
			widths={[smallTabWidth, extendedTabWidth]}
			title="Analysis panel"
			actionHeader={
				<ReactQueryWrapperBase
					query={queryPresets}
					errorFallback={<Select value="" options={[]} disabled classList="w-[150px]" />}
					loadingFallback={<CircularProgressBar value="indeterminate" outerDiameter={16} innerDiameter={12} />}
				>
					{({ presets }) => (
						<SelectPreset
							value={insightPanelPreset.uuid}
							presets={presets}
							onChangeSelection={(newPreset) => {
								setInsightPanelPreset(newPreset);
								onChangePreset(newPreset);
								const { uuid } = newPreset;
								if (uuid) {
									unpromisify(() => onChangeFavorite(uuid))();
								}
							}}
						/>
					)}
				</ReactQueryWrapperBase>
			}
			footer={
				<div className="flex justify-between">
					<Button
						palette="tertiary"
						size="small"
						onClick={() => {
							onChangePreset(emptyInsightPanelPreset);
							setInsightPanelPreset(emptyInsightPanelPreset);
						}}
					>
						{t("BUTTON.CLEAR_ALL")}
					</Button>
					{insightPanelPreset.standard || !insightPanelPreset.uuid ? (
						<div className="flex gap-2">
							<AsyncButton
								onClickAsync={() =>
									spawnCreateDialog({
										entityType: "preset",
										onSubmitAsync: (name) => onSaveAsNew(latestInsightPanelPresetRef.current, name),
										checkIfNameIsAvailable: onCheckIfNameIsAvailable,
										placeholder: "insert a name",
									}).then(() => queryPresets.refetch())
								}
								palette="primary"
								size="small"
							>
								{t("BUTTON.SAVE_AS_NEW")}
							</AsyncButton>
						</div>
					) : (
						<div className="flex gap-2">
							<AsyncButton
								onClickAsync={() =>
									spawnCreateDialog({
										entityType: "preset",
										onSubmitAsync: (name) => onSaveAsNew(latestInsightPanelPresetRef.current, name),
										checkIfNameIsAvailable: onCheckIfNameIsAvailable,
										placeholder: "insert a name",
									}).then(() => queryPresets.refetch())
								}
								palette="secondary"
								size="small"
							>
								{t("BUTTON.SAVE_AS_NEW")}
							</AsyncButton>
							<AsyncButton
								onClickAsync={async () => {
									await onSave(latestInsightPanelPresetRef.current);
									await queryPresets.refetch();
								}}
								palette="primary"
								size="small"
							>
								{t("BUTTON.SAVE")}
							</AsyncButton>
						</div>
					)}
				</div>
			}
		>
			<div className="relative z-0">
				<Separator text="Filters" align="start" classList="mb-2" />
				<DebouncedSearchInput
					query={insightPanelPreset?.search ?? ""}
					onChange={(search) => {
						setInsightPanelPreset((preset) => ({ ...preset, search }));
						onChangePreset(latestInsightPanelPresetRef.current);
					}}
					placeholder="search portfolio by name"
					data-qualifier="InsightPanel/SearchByKeyWord"
					classList="mb-2"
				/>
				<ReactQueryWrapperBase query={querySelectableInsightFilters}>
					{(selectable) => (
						<PortfolioInsightPanelInner
							selectable={selectable}
							preset={insightPanelPreset}
							onAddFilter={() =>
								setInsightPanelPreset((preset) => ({
									...preset,
									filters: handleMapTruthy(preset?.filters).set(generateUniqueDOMId(), {}),
								}))
							}
							onChangeFilter={(id, payload) =>
								setInsightPanelPreset((preset) => ({
									...preset,
									filters: handleMapTruthy(preset?.filters).set(id, payload),
								}))
							}
							onRemoveFilter={(id) =>
								setInsightPanelPreset((preset) => ({
									...preset,
									filters: handleMapTruthy(preset?.filters).remove(id),
								}))
							}
							onChangeKeyMetric={(newKeyMetric) =>
								setInsightPanelPreset((preset) => ({
									...preset,
									metric: {
										...preset?.metric,
										key: newKeyMetric,
									},
								}))
							}
							onChangeReferenceType={(newReferenceType) =>
								setInsightPanelPreset((preset) => ({
									...preset,
									metric: {
										...preset?.metric,
										referenceType: newReferenceType,
									},
								}))
							}
							onChangeReferenceEntity={(newReferenceEntity) =>
								setInsightPanelPreset((preset) => ({
									...preset,
									metric: {
										...preset?.metric,
										referenceEntity: newReferenceEntity,
									},
								}))
							}
							onAddKeyMetricThreshold={() =>
								setInsightPanelPreset((preset) => ({
									...preset,
									metric: {
										...preset?.metric,
										keyMetricThreshold: handleMapTruthy(preset?.metric?.keyMetricThreshold).set(
											generateUniqueDOMId(),
											{},
										),
									},
								}))
							}
							onChangeKeyMetricThreshold={(id, payload) =>
								setInsightPanelPreset((preset) => ({
									...preset,
									metric: {
										...preset?.metric,
										keyMetricThreshold: handleMapTruthy(preset?.metric?.keyMetricThreshold).set(id, payload),
									},
								}))
							}
							onRemoveKeyMetricThreshold={(id) =>
								setInsightPanelPreset((preset) => ({
									...preset,
									metric: {
										...preset?.metric,
										keyMetricThreshold: handleMapTruthy(preset?.metric?.keyMetricThreshold).remove(id),
									},
								}))
							}
							onChangeOrder={(newOrdering) => {
								setInsightPanelPreset((preset) => ({
									...preset,
									metric: {
										...preset?.metric,
										ordering: newOrdering,
									},
								}));
							}}
							onCommitKeyParameter={() => onChangePreset(latestInsightPanelPresetRef.current)}
							onCommitKeyThreshold={() => onChangePreset(latestInsightPanelPresetRef.current)}
							onCommitFilters={() => onChangePreset(latestInsightPanelPresetRef.current)}
							thresholdForEntityProvider={thresholdForEntityProvider}
						/>
					)}
				</ReactQueryWrapperBase>
			</div>
		</SideDrawer>
	);
}

type PortfolioInsightPanelInnerProps = {
	selectable: AvailableFieldsResponse;
	onChangeFilter(id: string, payload: FilterResponse): void;
	onRemoveFilter(id: string): void;
	onAddFilter(): void;

	onCommitFilters(): void;
	onCommitKeyParameter(): void;
	onCommitKeyThreshold(): void;
	onSubmit?(): MaybePromise<void>;

	onChangeKeyMetric(newKeyMetric: FieldKey): void;
	onChangeReferenceType(newReferenceType: MetricReferenceType): void;
	onChangeReferenceEntity(referenceEntity?: ReferenceEntityValues): void;

	onChangeOrder(newOrdering?: MetricOrdering): void;

	onChangeKeyMetricThreshold(id: string, payload: keyMetricThresholdMapType): void;
	onRemoveKeyMetricThreshold(id: string): void;
	onAddKeyMetricThreshold(): void;
	preset?: PortfolioInsightPanelPreset;
	thresholdForEntityProvider(metric: NonNullable<PortfolioInsightPanelPreset["metric"]>): Promise<number | undefined>;
};

const keyMetricSelectionEntities: Array<Option<MetricThresholdType>> = [
	{
		label: "Manual",
		value: MetricThresholdType.Manual,
	},
	{
		label: "Portfolio",
		value: MetricThresholdType.Investment,
	},
	{
		label: "Target portfolio",
		value: MetricThresholdType.TargetInvestment,
	},
	{
		label: "Standard benchmark",
		value: MetricThresholdType.StandardBenchmark,
	},
	{
		label: "Custom benchmark",
		value: MetricThresholdType.CustomBenchmark,
	},
];

const allowedSelectionByKey = {
	CURRENT_ACTION: 0,
	BENCHMARK_NAME: 0,
	COMMENTARY_TEMPLATE: 0,
	CURRENCY_NAME: 0,
	REFERENCE_NAME: 0,
	UNIVERSE_NAME: 0,
	AVERAGE_SCORE: 0,
	STATUS: 0,
	NAME: 0,
	EFFICIENCY_RATIO_1M: Infinity,
	MAX_DRAWDOWN_1M: Infinity,
	SORTINO_1M: Infinity,
	VOLATILITY_1M: Infinity,
	INCEPTION_DATE: Infinity,
	LAST_STATUS_UPDATE: Infinity,
	PERFORMANCE_1M: Infinity,
	AUTO_SYNC: Infinity,
	MARKET_VIEW_NAME: Infinity,
};

function PortfolioInsightPanelInner({
	selectable,
	preset,
	onAddFilter,
	onChangeFilter,
	onCommitFilters,
	onRemoveFilter,
	onChangeKeyMetric,
	onCommitKeyParameter,
	onChangeReferenceType,
	onChangeReferenceEntity,
	onChangeOrder,
	onChangeKeyMetricThreshold,
	onRemoveKeyMetricThreshold,
	onAddKeyMetricThreshold,
	onCommitKeyThreshold,
	thresholdForEntityProvider,
}: PortfolioInsightPanelInnerProps): JSX.Element {
	const { metric, filters } = preset ?? {};
	const { t } = useTranslation();

	const availableFiltersMap = useMemo<Map<string, FilterValues>>(() => {
		if (!selectable.availableFilters) {
			return Map();
		}

		return Map(selectable.availableFilters.flatMap((x) => (x.key ? [[x.key, x]] : [])));
	}, [selectable.availableFilters]);

	const aggregateFilters = useMemo(
		() =>
			groupBy(
				Array.from(filters?.values() ?? []).filter(({ key }) => key),
				(x) => x.key!,
			),
		[filters],
	);

	const availableFilterKeys = useMemo<Array<Option<FieldKey>>>(() => {
		if (!selectable.availableFilters) {
			return [];
		}
		return selectable.availableFilters.flatMap((x) =>
			x.key
				? [
						{
							label: t(`METRIC_COMPARISON_CHART.${x.key}`),
							value: x.key,
							disabled:
								allowedSelectionByKey[x.key as keyof typeof allowedSelectionByKey] <
								(aggregateFilters[x.key as keyof typeof aggregateFilters]?.length ?? 0),
						},
				  ]
				: [],
		);
	}, [selectable.availableFilters, t, aggregateFilters]);

	const availableMetricKeys = useMemo<Array<Option<FieldKey>>>(() => {
		if (!selectable.availableMetrics) {
			return [];
		}

		return selectable.availableMetrics.flatMap((x) =>
			x.key
				? [
						{
							label: t(`METRIC_COMPARISON_CHART.${x.key}`),
							value: x.key,
						},
				  ]
				: [],
		);
	}, [selectable.availableMetrics, t]);

	const availableEntitiesMap = useMemo<Map<string, ReferenceEntityValues>>(() => {
		if (!selectable.availableEntities) {
			return Map();
		}
		return Map(selectable.availableEntities.flatMap((x) => (x.identifier ? [[x.identifier, x]] : [])));
	}, [selectable.availableEntities]);

	const availableEntities = useMemo(() => {
		if (!selectable.availableEntities) {
			return [];
		}

		return selectable.availableEntities.flatMap((x) =>
			x.label && x.identifier && x.type
				? [
						{
							label: x.label,
							value: x.identifier,
							group: {
								id: x.type,
								label: t(`METRIC_SELECTION.${x.type}`),
							},
						},
				  ]
				: [],
		) satisfies Array<OptionWithOptionalGroup<string, MetricEntityType>>;
	}, [selectable.availableEntities, t]);

	const groupedAvailableEntities = useMemo(() => groupBy(availableEntities, (x) => x.group.id), [availableEntities]);

	const availableEntitiesByGroup = useMemo(() => {
		const selectedThresholdType = metric?.keyMetricThreshold?.first()?.thresholdType;

		return (
			match(selectedThresholdType)
				.with("MANUAL", undefined, () => [])
				.with("INVESTMENT", () => groupedAvailableEntities.INVESTMENT)
				.with("TARGET_INVESTMENT", () => groupedAvailableEntities.TARGET_PORTFOLIO)
				.with("CUSTOM_BENCHMARK", () => groupedAvailableEntities.USER_BENCHMARK)
				.with("STANDARD_BENCHMARK", () => groupedAvailableEntities.STANDARD_BENCHMARK)
				.exhaustive() ?? []
		);
	}, [groupedAvailableEntities, metric?.keyMetricThreshold]);

	return (
		<>
			<div className="mb-2">
				<ForEach collection={preset?.filters?.toArray() ?? []} keyProvider={(item) => item[0]}>
					{({ item: [id, opt], index }) => (
						<Transition
							in
							initialIn={false}
							classList="transition-[opacity,transform] origin-top"
							enterFromClassList="opacity-0 scale-95"
							enterToClassList="opacity-100 scale-100"
						>
							{({ classList }) => (
								<div className={overrideClassName(classList, "flex items-center")}>
									<div className="flex gap-2 flex-1 max-w-[calc(100%_-_38px)]">
										<Select
											innerRef={(e) => e?.setAttribute("data-qualifier", `InsightPanel/Filter(${index})/Trigger`)}
											data-qualifier={`InsightPanel/Filter(${index})/Dropdown`}
											options={availableFilterKeys}
											value={opt.key}
											onChange={(newKey) => {
												if (newKey) {
													onChangeFilter(id, {
														key: newKey,
														type: availableFiltersMap.get(newKey)!.type!,
														relation: undefined,
														value: undefined,
													});
												}
												onCommitFilters();
											}}
											classList="w-[139px]"
										/>

										{opt.key &&
											opt.type &&
											match(opt.type)
												.with(
													"FINITE_SET_PORTFOLIOS",
													"FINITE_SET_STATUSES",
													"FINITE_SET_ACTIONS",
													"FINITE_SET_BASE_CURRENCIES",
													"FINITE_SET_UNIVERSES",
													"FINITE_SET_BENCHMARKS",
													"FINITE_SET_REFERENCES",
													"FINITE_SET_MARKET_VIEWS",
													"FINITE_SET_COMMENTARY_TEMPLATES",
													() => {
														const options =
															availableFiltersMap.get(opt.key!)?.valuesSet?.flatMap((x) =>
																x.label && x.identifier
																	? [
																			{
																				label: x.label,
																				value: x.identifier,
																			},
																	  ]
																	: [],
															) ?? [];

														return (
															<Select
																innerRef={(e) =>
																	e?.setAttribute("data-qualifier", `InsightPanel/Filter(${index})/${opt.key}/Trigger`)
																}
																multi
																enableSearch
																data-qualifier={`InsightPanel/Filter(${index})/${opt.key}/Dropdown`}
																options={options}
																value={opt.value ?? []}
																onChange={(identifiers) => {
																	if (identifiers) {
																		onChangeFilter(id, { ...opt, value: identifiers as string[], relation: "EQUAL" });
																		onCommitFilters();
																	}
																}}
																classList="w-[139px]"
															/>
														);
													},
												)
												.with("BOOLEAN", () => (
													<Select
														innerRef={(e) =>
															e?.setAttribute("data-qualifier", `InsightPanel/Filter(${index})/${opt.key}/Trigger`)
														}
														data-qualifier={`InsightPanel/Filter(${index})/${opt.key}/Dropdown`}
														options={[
															{ label: "Yes", value: true },
															{ label: "No", value: false },
														]}
														value={opt.value ? opt.value[0] === "true" : undefined}
														onChange={(flag) => {
															onChangeFilter(id, { ...opt, value: [String(flag)], relation: "EQUAL" });
															onCommitFilters();
														}}
														classList="w-[139px]"
													/>
												))
												.with("DATE", "NUMERIC", "PERCENTAGE", (filterType) => {
													const options =
														availableFiltersMap.get(opt.key!)?.availableRelations?.flatMap((relation) =>
															relation
																? [
																		{
																			label:
																				filterType === "DATE"
																					? t(`RELATION.CALENDAR.${relation}`)
																					: t(`RELATION.${relation}`),
																			value: relation,
																		},
																  ]
																: [],
														) ?? [];
													return (
														<Select
															innerRef={(e) =>
																e?.setAttribute("data-qualifier", `InsightPanel/Filter(${index})/${opt.key}/Trigger`)
															}
															data-qualifier={`InsightPanel/Filter(${index})/${opt.key}/Dropdown`}
															options={options}
															value={opt.relation}
															onChange={(relation) => {
																if (relation) {
																	onChangeFilter(id, { ...opt, relation });
																}

																if (relation && opt.value) {
																	onCommitFilters();
																}
															}}
															classList="w-[139px]"
														/>
													);
												})
												.exhaustive()}

										{opt.key &&
											opt.relation &&
											opt.type &&
											match(opt.type)
												.with("PERCENTAGE", () => (
													<NullableNumberInput
														data-qualifier={`InsightPanel/Filter(${index})/${opt.key}/Percentage`}
														value={opt.value?.[0] ? Number(opt.value?.[0]) : null}
														rightContent={<Icon icon="Percentile" />}
														placeholder="0"
														onChange={(value) =>
															onChangeFilter(id, { ...opt, value: value != null ? [String(value)] : undefined })
														}
														classList="w-24"
														onBlur={() => onCommitFilters()}
													/>
												))
												.with("NUMERIC", () => (
													<NullableNumberInput
														data-qualifier={`InsightPanel/Filter(${index})/${opt.key}/NumericValue`}
														value={opt.value?.[0] ? Number(opt.value?.[0]) : null}
														placeholder="0"
														onChange={(value) =>
															onChangeFilter(id, { ...opt, value: value != null ? [String(value)] : undefined })
														}
														classList="w-24"
														onBlur={() => onCommitFilters()}
													/>
												))
												.with("DATE", () => (
													<DatePickerInput
														data-qualifier={`InsightPanel/Filter(${index})/${opt.key}/DatePicker`}
														value={opt.value?.[0] ?? ""}
														classList="w-24"
														onChange={(newDate) => {
															onChangeFilter(id, {
																...opt,
																value: newDate ? [newDate] : undefined,
															});
															onCommitFilters();
														}}
													/>
												))
												.otherwise(() => <></>)}
									</div>
									<TinyIconButton
										data-qualifier={`InsightPanel/Filter(${index})/${opt.key}/Delete`}
										icon="Delete"
										onClick={() => {
											onRemoveFilter(id);
											onCommitFilters();
										}}
										size={18}
										color={themeCSSVars.palette_P400}
										classList="ml-auto "
									/>
								</div>
							)}
						</Transition>
					)}
				</ForEach>
			</div>
			<Button palette="secondary" onClick={() => onAddFilter()} size="small" data-qualifier="InsightPanel/AddFilter">
				<Icon icon="Outline1" />
			</Button>
			<Separator text="Sorting parameters" align="start" classList="mb-2" />
			<Controller value={false}>
				{({ value: isLoading, onChange: setIsLoading }) => (
					<FormField label="Key Metric" classList="mb-2">
						{(filedProps) => (
							<Select
								{...filedProps}
								enableSearch
								options={availableMetricKeys}
								value={metric?.key}
								innerRef={(e) => e?.setAttribute("data-qualifier", "InsightPanel/SortingParameters/KeyMetric/Trigger")}
								data-qualifier="InsightPanel/SortingParameters/KeyMetric/Dropdown"
								disabled={isLoading}
								onChange={(newKeyMetric) => {
									if (newKeyMetric) {
										onChangeKeyMetric(newKeyMetric);
									}

									if (
										newKeyMetric &&
										((metric?.referenceType === "ABSOLUTE" && metric.ordering) ||
											(metric?.referenceType === "DELTA_RELATIVE" && metric?.referenceEntity?.identifier))
									) {
										unpromisify(() => onCommitKeyParameter())();
									}

									const keyMetricThreshold = metric?.keyMetricThreshold?.first();
									const keyMetricThresholdKey = metric?.keyMetricThreshold?.keySeq().first();
									if (
										keyMetricThreshold?.thresholdType &&
										keyMetricThreshold?.thresholdType !== "MANUAL" &&
										keyMetricThreshold.thresholdEntity?.identifier &&
										keyMetricThresholdKey &&
										newKeyMetric
									) {
										(async () => {
											setIsLoading(true);
											try {
												const thresholdValue = await thresholdForEntityProvider({
													key: newKeyMetric,
													keyMetricThreshold: metric?.keyMetricThreshold,
													ordering: metric?.ordering,
													referenceEntity: metric?.referenceEntity,
													referenceType: metric?.referenceType,
												});

												if (thresholdValue) {
													onChangeKeyMetricThreshold(keyMetricThresholdKey, {
														...keyMetricThreshold,
														thresholdValue,
													});
												}

												if (metric?.key) {
													onCommitKeyThreshold();
												}
											} catch (err) {
												platformToast({
													children: "Unable to compute threshold value, please re-try",
													icon: "Icon-full-error",
													severity: "error",
												});
											} finally {
												setIsLoading(false);
											}
										})().catch(noop);
									}
								}}
							/>
						)}
					</FormField>
				)}
			</Controller>
			<div className="flex items-end gap-4 mb-2">
				<Controller value={false}>
					{({ value: isLoading, onChange: setIsLoading }) => (
						<FormField label="Value type" classList="w-[115px]">
							{(filedProps) => (
								<Select
									{...filedProps}
									innerRef={(e) =>
										e?.setAttribute("data-qualifier", "InsightPanel/SortingParameters/ValueType/Trigger")
									}
									data-qualifier="InsightPanel/SortingParameters/ValueType/Dropdown"
									options={[
										{ label: "Absolute", value: MetricReferenceType.Absolute },
										{ label: "Relative", value: MetricReferenceType.DeltaRelative },
									]}
									disabled={isLoading}
									value={metric?.referenceType}
									onChange={(newReferenceType) => {
										if (newReferenceType) {
											onChangeReferenceType(newReferenceType);
										}

										if (
											metric?.key &&
											((newReferenceType === "ABSOLUTE" && metric.ordering) ||
												(newReferenceType === "DELTA_RELATIVE" && metric?.referenceEntity?.identifier))
										) {
											unpromisify(() => onCommitKeyParameter())();
										}

										const keyMetricThreshold = metric?.keyMetricThreshold?.first();
										const keyMetricThresholdKey = metric?.keyMetricThreshold?.keySeq().first();

										if (
											keyMetricThreshold?.thresholdType &&
											keyMetricThreshold?.thresholdType !== "MANUAL" &&
											keyMetricThreshold.thresholdEntity?.identifier &&
											keyMetricThresholdKey &&
											metric?.key
										) {
											(async () => {
												setIsLoading(true);
												try {
													let thresholdValue: number | undefined = undefined;
													if (newReferenceType === "ABSOLUTE") {
														thresholdValue = await thresholdForEntityProvider({
															key: metric?.key,
															keyMetricThreshold: metric?.keyMetricThreshold,
															ordering: metric?.ordering,
															referenceType: newReferenceType,
														});
													}

													if (newReferenceType === "DELTA_RELATIVE" && metric?.referenceEntity?.identifier) {
														thresholdValue = await thresholdForEntityProvider({
															key: metric?.key,
															keyMetricThreshold: metric?.keyMetricThreshold,
															ordering: metric?.ordering,
															referenceType: newReferenceType,
															referenceEntity: metric?.referenceEntity,
														});
													}

													if (thresholdValue != null) {
														onChangeKeyMetricThreshold(keyMetricThresholdKey, {
															...keyMetricThreshold,
															thresholdValue,
														});
													}

													if (metric?.key) {
														onCommitKeyThreshold();
													}
												} catch (err) {
													platformToast({
														children: "Unable to compute threshold value, please re-try",
														icon: "Icon-full-error",
														severity: "error",
													});
												} finally {
													setIsLoading(false);
												}
											})().catch(noop);
										}
									}}
								/>
							)}
						</FormField>
					)}
				</Controller>
				<Controller value={false}>
					{({ value: isLoading, onChange: setIsLoading }) => (
						<FormField label="" classList="grow">
							{(filedProps) => (
								<Select
									{...filedProps}
									innerRef={(e) =>
										e?.setAttribute("data-qualifier", "InsightPanel/SortingParameters/Reference/Trigger")
									}
									data-qualifier="InsightPanel/SortingParameters/Reference/Dropdown"
									options={availableEntities}
									enableSearch
									value={metric?.referenceEntity?.identifier}
									disabled={metric?.referenceType === "ABSOLUTE" || metric?.referenceType === undefined}
									classList="w-[calc(100%_-115px_-1rem)]"
									onChange={(entityIdentifier) => {
										if (entityIdentifier) {
											onChangeReferenceEntity(availableEntitiesMap.get(entityIdentifier));
										}

										if (
											metric?.key &&
											((metric?.referenceType === "ABSOLUTE" && metric.ordering) ||
												(metric?.referenceType === "DELTA_RELATIVE" && entityIdentifier))
										) {
											unpromisify(() => onCommitKeyParameter())();
										}

										const keyMetricThreshold = metric?.keyMetricThreshold?.first();
										const keyMetricThresholdKey = metric?.keyMetricThreshold?.keySeq().first();

										if (
											keyMetricThreshold?.thresholdType &&
											keyMetricThreshold?.thresholdType !== "MANUAL" &&
											keyMetricThreshold.thresholdEntity?.identifier &&
											keyMetricThresholdKey &&
											metric?.key
										) {
											(async () => {
												setIsLoading(true);
												try {
													let thresholdValue: number | undefined = undefined;
													if (metric?.referenceType === "ABSOLUTE") {
														thresholdValue = await thresholdForEntityProvider({
															key: metric?.key,
															keyMetricThreshold: metric?.keyMetricThreshold,
															ordering: metric?.ordering,
															referenceType: metric?.referenceType,
														});
													}

													if (metric?.referenceType === "DELTA_RELATIVE" && entityIdentifier) {
														thresholdValue = await thresholdForEntityProvider({
															key: metric?.key,
															keyMetricThreshold: metric?.keyMetricThreshold,
															ordering: metric?.ordering,
															referenceType: metric?.referenceType,
															referenceEntity: availableEntitiesMap.get(entityIdentifier),
														});
													}

													if (thresholdValue != null) {
														onChangeKeyMetricThreshold(keyMetricThresholdKey, {
															...keyMetricThreshold,
															thresholdValue,
														});
													}

													if (metric?.key) {
														onCommitKeyThreshold();
													}
												} catch (err) {
													platformToast({
														children: "Unable to compute threshold value, please re-try",
														icon: "Icon-full-error",
														severity: "error",
													});
												} finally {
													setIsLoading(false);
												}
											})().catch(noop);
										}
									}}
								/>
							)}
						</FormField>
					)}
				</Controller>
			</div>
			<FormField label="Order" classList="mb-2">
				{() => {
					function changeOrder(newOrdering: MetricOrdering | undefined) {
						onChangeOrder(newOrdering);
						if (
							metric?.key &&
							((metric?.referenceType === "ABSOLUTE" && newOrdering) ||
								(metric?.referenceType === "DELTA_RELATIVE" && metric.referenceEntity?.identifier))
						) {
							unpromisify(() => onCommitKeyParameter())();
						}
					}
					return (
						<ButtonGroupRadio
							options={[
								{
									"data-qualifier": `InsightPanel/SortingParameters/Order/${MetricOrdering.Asc}`,
									value: MetricOrdering.Asc,
									children: (
										<>
											<Icon icon="sort-ascending" />
											&nbsp;Asc
										</>
									),
								},
								{
									"data-qualifier": `InsightPanel/SortingParameters/Order/${MetricOrdering.Desc}`,
									value: MetricOrdering.Desc,
									children: (
										<>
											<Icon icon="sort-decending" />
											&nbsp;Desc
										</>
									),
								},
								{
									"data-qualifier": `InsightPanel/SortingParameters/Order/${MetricOrdering.Unsorted}`,
									disabled: true,
									value: MetricOrdering.Unsorted,
									children: (
										<>
											<Icon icon="table" />
											&nbsp;Table order
										</>
									),
								},
							]}
							onChange={changeOrder}
							value={metric?.ordering}
						/>
					);
				}}
			</FormField>
			<ForEach collection={Array.from(metric?.keyMetricThreshold?.toArray() ?? [])} keyProvider={(x) => x[0]}>
				{({ item: [id, keyMetricThreshold] }) => {
					return (
						<div className="flex items-end gap-4 mb-4">
							<FormField label="Key Metric Threshold" classList="w-[160px]">
								{(filedProps) => (
									<Select
										{...filedProps}
										innerRef={(e) =>
											e?.setAttribute("data-qualifier", "InsightPanel/SortingParameters/KeyMetricThreshold/Trigger")
										}
										data-qualifier="InsightPanel/SortingParameters/KeyMetricThreshold/Dropdown"
										options={keyMetricSelectionEntities}
										value={keyMetricThreshold.thresholdType}
										onChange={(newThresholdType) => {
											if (newThresholdType) {
												onChangeKeyMetricThreshold(
													id,
													newThresholdType === "MANUAL"
														? {
																thresholdType: newThresholdType,
																thresholdValue: 0,
														  }
														: {
																thresholdType: newThresholdType,
														  },
												);
											}

											if (newThresholdType === "MANUAL") {
												onCommitKeyThreshold();
											}
										}}
									/>
								)}
							</FormField>

							{keyMetricThreshold.thresholdType === "MANUAL" ? (
								<NullableNumberInput
									data-qualifier="InsightPanel/SortingParameters/KeyMetricThreshold/Manual/Value"
									value={keyMetricThreshold.thresholdValue ?? null}
									rightContent={<Icon icon="Percentile" />}
									placeholder="0"
									onChange={(newThresholdValue) => {
										onChangeKeyMetricThreshold(id, {
											...keyMetricThreshold,
											thresholdType: "MANUAL",
											thresholdValue: newThresholdValue ?? undefined,
										});

										onCommitKeyThreshold();
									}}
									classList="flex-1"
								/>
							) : (
								<Controller
									value={keyMetricThreshold}
									onChange={(newThreshold) => {
										if (newThreshold) {
											onChangeKeyMetricThreshold(id, newThreshold);
										}

										if (metric?.key) {
											onCommitKeyThreshold();
										}
									}}
								>
									{({ value, onChange, onCommit }) => (
										<Controller value={false}>
											{({ value: isLoading, onChange: setIsLoading }) => (
												<Select
													innerRef={(e) =>
														e?.setAttribute(
															"data-qualifier",
															`InsightPanel/SortingParameters/KeyMetricThreshold/${value.thresholdType}/Trigger`,
														)
													}
													data-qualifier={`InsightPanel/SortingParameters/KeyMetricThreshold/${value.thresholdType}/Dropdown`}
													options={availableEntitiesByGroup}
													enableSearch
													value={value.thresholdEntity?.identifier ?? null}
													classList="flex-1"
													disabled={isLoading}
													onChange={(val) => {
														if (!val) {
															return;
														}
														const newVal = {
															thresholdType: value.thresholdType,
															thresholdEntity: availableEntitiesMap.get(val),
															thresholdValue: undefined,
														};
														onChange(newVal);
														(async () => {
															if (!metric) {
																// TODO: is this possible?
																return;
															}
															setIsLoading(true);
															try {
																const key = Array.from(metric.keyMetricThreshold?.keys() ?? []).at(0);
																const thresholdValue = await thresholdForEntityProvider({
																	...metric,
																	keyMetricThreshold: handleMapTruthy(metric.keyMetricThreshold).set(
																		key ?? generateUniqueDOMId(),
																		newVal,
																	),
																});
																onCommit({ ...newVal, thresholdValue });
															} catch (err) {
																platformToast({
																	children: "Unable to compute threshold value, please re-try",
																	icon: "Icon-full-error",
																	severity: "error",
																});
															} finally {
																setIsLoading(false);
															}
														})().catch(noop);
													}}
												/>
											)}
										</Controller>
									)}
								</Controller>
							)}
							<TinyIconButton
								data-qualifier="InsightPanel/SortingParameters/KeyMetricThreshold/Delete"
								icon="Delete"
								onClick={() => {
									onRemoveKeyMetricThreshold(id);
									onCommitKeyThreshold();
								}}
								size={18}
								color={themeCSSVars.palette_P400}
								classList="ml-auto"
							/>
						</div>
					);
				}}
			</ForEach>
			{(!metric?.keyMetricThreshold?.size || metric?.keyMetricThreshold?.size === 0) && (
				<FormField label="Key Metric Threshold" classList="w-[160px]">
					<Button palette="secondary" classList="w-fit mt-1" size="small" onClick={onAddKeyMetricThreshold}>
						<Icon icon="Outline1" />
					</Button>
				</FormField>
			)}
		</>
	);
}

export default PortfolioInsightPanel;
