import type { EditorSelectableBasketsRequestEntityEnum, ReviewTicker, SelectableBasket } from "$root/api/api-gen";
import { EntityEditorControllerApiFactory } from "$root/api/api-gen";
import { DebouncedSearchInput } from "$root/components/DebouncedSearchInput";
import { IconWallBase } from "$root/components/IconWall";
import { ReactQueryWrapperBase } from "$root/components/ReactQueryWrapper";
import { useInstrumentColumnsTableV2 } from "$root/functional-areas/instruments/hooks";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { customObjectEntriesFn } from "$root/utils/experimental";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { objectTextSearchMatchFns } from "$root/utils/strings";
import type { Option, OptionWithOptionalGroup, TableColumn } from "@mdotm/mdotui/components";
import {
	AutoSortTable,
	Button,
	Dialog,
	DialogFooter,
	Select,
	SubmitButton,
	Text,
	useSelectableTableColumn,
} from "@mdotm/mdotui/components";
import { useSearchable } from "@mdotm/mdotui/headless";
import type { SpawnResult } from "@mdotm/mdotui/react-extensions";
import { adaptAnimatedNodeProvider, spawn, toClassListRecord } from "@mdotm/mdotui/react-extensions";
import { noop } from "@mdotm/mdotui/utils";
import { Map, Set } from "immutable";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import type { InstrumentEditorEntity, InstrumentEditorEntry, MinimunDialogProps } from "../../const";
import { instrumentFilters } from "./instrument-adder-const";
import type { AddMissingInstrumentDialogProps } from "./missing-instrument-adder";
import { spawnInstrumentDialog } from "./missing-instrument-adder";
import { typedObjectValues } from "$root/utils/objects";
import { getApiGen } from "$root/api/factory";
import { withCache } from "$root/caching/with-cache";
import { minutes } from "$root/utils/time";
import { reportPlatformError } from "$root/api/error-reporting";

type InstrumentAdderDialogProps = {
	entity: InstrumentEditorEntity;
	uuid?: string;
	onSubmit(instruments: ReviewTicker[]): void;
	onSubmitMissingInstrument: AddMissingInstrumentDialogProps["onSubmit"];
	instrumentsInComposition: InstrumentEditorEntry[];
} & MinimunDialogProps;

type Instrument = ReviewTicker;

function eraseDuplicate(instruments: Instrument[]) {
	let duplicatedAlias = (instruments ?? []).reduce(
		(acc, el) => {
			const alias = el.alias;

			if (!alias) {
				return acc;
			}

			if (acc.comp.includes(alias)) {
				return { ...acc, included: [...acc.included, alias] };
			}

			return { ...acc, comp: [...acc.comp, alias] };
		},
		{ comp: [] as string[], included: [] as string[] },
	);

	return instruments?.filter((x) => {
		const someDuplicate = duplicatedAlias?.included;
		if (someDuplicate === undefined || someDuplicate.length === 0) {
			return true;
		}
		const indexOfDuplicate = someDuplicate.indexOf(x.alias ?? "") ?? -1;
		if (indexOfDuplicate >= 0) {
			duplicatedAlias = { ...duplicatedAlias, included: someDuplicate.splice(indexOfDuplicate, 1) };
			return false;
		}

		return true;
	});
}

function filterAlreadyExist(
	category: "assetClass" | "instrumentType" | "granularity" | "geography" | "microAssetClass",
	filter: string,
	instrument: ReviewTicker,
): boolean | undefined {
	if (category === "assetClass") {
		return instrument.assetClass?.toLowerCase().includes(filter.toLowerCase());
	}

	if (category === "instrumentType") {
		return instrument.type?.toLowerCase().includes(filter.toLowerCase());
	}

	if (category === "granularity") {
		return instrument.granularity?.toLowerCase().includes(filter.toLowerCase());
	}

	return instrument.microAssetClass?.toLowerCase()?.includes(filter.toLowerCase());
}

// TODO: pass an update fn and move update logic inside component
function InstrumentAddersFilters(props: {
	selectedOptions: typeof defaultFilters;
	onSelectBaskets(baskets: SelectableBasket[]): void;
	onSelectGranularity(granularity: string[]): void;
	onSelectInstrumentType(instrumentsType: string[]): void;
	onSelectAssetClass(assetClasses: string[]): void;
	onSelectGeographies(geographies: string[]): void;
	onSelectMicroAssetClasses(microAssetClasses: string[]): void;
	selectableInstruments?: ReviewTicker[];
	selectableBaskets?: SelectableBasket[];
}) {
	const { selectedOptions, selectableBaskets, selectableInstruments } = props;
	const { t } = useTranslation();
	const basketOptions = useMemo(() => {
		return (
			selectableBaskets
				?.filter((el) => el.basketName && el.basketIdentifier)
				.map(
					(el): OptionWithOptionalGroup<SelectableBasket, string> => ({
						label: el.basketName!,
						value: el,
						disabled: false,
						group: el.basketType ? t(`BASKET_ENTITY.${el.basketType}`) : undefined,
					}),
				) ?? []
		);
	}, [selectableBaskets, t]);

	const options = useMemo(() => {
		let granularity: Option<string>[] = [];

		if (selectableInstruments && selectableInstruments.length > 0) {
			const granularitySet = Set(selectableInstruments?.filter((ac) => ac.granularity).map((ac) => ac.granularity));
			granularity = granularitySet.toArray().map((x) => ({ value: x!, label: x! })) ?? [];
		}

		const currentAvailableFilters = { ...instrumentFilters, granularity };
		return customObjectEntriesFn(currentAvailableFilters).reduce<{
			[K in keyof typeof currentAvailableFilters]: Option<string>[];
		}>(
			(acc, [category, values]) => {
				return {
					...acc,
					[category]: values
						.filter(({ value: filter }) => {
							if (!selectableInstruments) {
								return false;
							}
							return selectableInstruments?.some((instrument) => filterAlreadyExist(category, filter, instrument));
						})
						.sort()
						.map((el) => ({ label: el.label, value: el.value, disabled: false })),
				};
			},
			{ assetClass: [], geography: [], microAssetClass: [], instrumentType: [], granularity: [] },
		);
	}, [selectableInstruments]);
	return (
		<div className="flex items-center gap-4 mb-2">
			<p className="font-semibold">Filter by: </p>
			<Select
				classList="flex-1"
				options={basketOptions}
				value={selectedOptions.baskets}
				multi
				i18n={{ triggerPlaceholder: () => "Basket" }}
				onChange={props.onSelectBaskets}
				disabled={basketOptions.length === 0}
				enableSearch
				listboxAppearance={{ classList: "!max-h-[220px]" }}
				data-qualifier="InstrumentModal/Filter(Basket)/Options"
				innerRef={(e) => e?.setAttribute("data-qualifier", "InstrumentModal/Filter(Basket)")}
			/>
			<Select
				classList="flex-1"
				options={options.granularity}
				value={selectedOptions.granularities}
				multi
				i18n={{ triggerPlaceholder: () => "Granularity" }}
				onChange={props.onSelectGranularity}
				disabled={options.granularity.length === 0}
				enableSearch
				listboxAppearance={{ classList: "!max-h-[220px]" }}
				data-qualifier="InstrumentModal/Filter(Granularity)/Options"
				innerRef={(e) => e?.setAttribute("data-qualifier", "InstrumentModal/Filter(Granularity)")}
			/>
			<Select
				classList="flex-1"
				options={options.instrumentType}
				value={selectedOptions.instrumentsType}
				multi
				i18n={{ triggerPlaceholder: () => "Asset type" }}
				onChange={props.onSelectInstrumentType}
				disabled={options.instrumentType.length === 0}
				enableSearch
				listboxAppearance={{ classList: "!max-h-[220px]" }}
				data-qualifier="InstrumentModal/Filter(Asset Type)/Options"
				innerRef={(e) => e?.setAttribute("data-qualifier", "InstrumentModal/Filter(Asset Type)")}
			/>
			<Select
				classList="flex-1"
				options={options.assetClass}
				value={selectedOptions.assetClasses}
				multi
				i18n={{ triggerPlaceholder: () => "Macro asset class" }}
				onChange={props.onSelectAssetClass}
				disabled={options.assetClass.length === 0}
				enableSearch
				listboxAppearance={{ classList: "!max-h-[220px]" }}
				data-qualifier="InstrumentModal/Filter(Marco Asset Class)/Options"
				innerRef={(e) => e?.setAttribute("data-qualifier", "InstrumentModal/Filter(Marco Asset Class)")}
			/>
			<Select
				classList="flex-1"
				options={options.geography}
				value={selectedOptions.geographies}
				multi
				i18n={{ triggerPlaceholder: () => "Geography" }}
				onChange={props.onSelectGeographies}
				disabled={options.geography.length === 0}
				enableSearch
				listboxAppearance={{ classList: "!max-h-[220px]" }}
				data-qualifier="InstrumentModal/Filter(Geography)/Options"
				innerRef={(e) => e?.setAttribute("data-qualifier", "InstrumentModal/Filter(Geography)")}
			/>
			<Select
				classList="flex-1"
				options={options.microAssetClass}
				value={selectedOptions.microAssetClasses}
				multi
				i18n={{ triggerPlaceholder: () => "Micro asset class" }}
				onChange={props.onSelectMicroAssetClasses}
				disabled={options.microAssetClass.length === 0}
				enableSearch
				listboxAppearance={{ classList: "!max-h-[220px]" }}
				data-qualifier="InstrumentModal/Filter(Micro asset class)/Options"
				innerRef={(e) => e?.setAttribute("data-qualifier", "InstrumentModal/Filter(Micro asset class)")}
			/>
		</div>
	);
}

function includesGuardFn(filter: string[], field: string) {
	if (filter.length === 0) {
		return true;
	}
	return filter.filter((k) => field.toLowerCase().search(k.toLowerCase()) >= 0).length > 0;
}

const InstrumentAddersDialog = ({
	show,
	onClose,
	onAnimationStateChange,
	uuid,
	entity,
	onSubmit,
	onSubmitMissingInstrument,
	instrumentsInComposition,
}: InstrumentAdderDialogProps): JSX.Element => {
	const [query, setQuery] = useState("");
	const [filters, setFilters] = useState(defaultFilters);

	const { t } = useTranslation();

	const preSelectedInstruments = useMemo(
		() =>
			Set(
				instrumentsInComposition.flatMap((x) =>
					x.ticker && x.proxyOverwriteType !== "PORTFOLIO_MIXED" ? [x.ticker] : [],
				),
			),
		[instrumentsInComposition],
	);

	const querySelectableInstruments = useQueryNoRefetch(["querySelectableInstruments", uuid, filters.baskets], {
		queryFn: async () => {
			if (uuid) {
				const { availableTickers, baskets } = await getInstrumentEditorTickers({
					uuid,
					entity,
					baskets: filters.baskets.length > 0 ? filters.baskets : undefined,
				});

				return {
					baskets,
					tickers: eraseDuplicate(availableTickers)?.sort((a, b) =>
						preSelectedInstruments.has(a.ticker ?? "") > preSelectedInstruments.has(b.ticker ?? "") ? -1 : 1,
					),
				};
			}

			const { availableTickers, baskets } = await getInstrumentEditorTickers({
				entity,
				baskets: filters.baskets.length > 0 ? filters.baskets : undefined,
			});

			return {
				baskets,
				tickers: eraseDuplicate(availableTickers)?.sort((a, b) =>
					preSelectedInstruments.has(a.ticker ?? "") > preSelectedInstruments.has(b.ticker ?? "") ? -1 : 1,
				),
			};
		},
	});

	const baskets = querySelectableInstruments.data?.baskets;
	const selectableInstruments = useMemo(() => {
		if (!querySelectableInstruments.data?.tickers) {
			return [];
		}

		const { baskets: _baskets, ...others } = filters;
		const currentSelection = typedObjectValues(others).flatMap((x) => x);

		if (currentSelection.length === 0) {
			return querySelectableInstruments.data.tickers;
		}

		return querySelectableInstruments.data.tickers.filter(
			(row) =>
				includesGuardFn(filters.assetClasses, row.assetClass ?? "") &&
				includesGuardFn(filters.geographies, row.microAssetClass ?? "") &&
				includesGuardFn(filters.microAssetClasses, row.microAssetClass ?? "") &&
				includesGuardFn(filters.instrumentsType, row.type ?? "") &&
				includesGuardFn(filters.granularities, row.granularity ?? ""),
		);
	}, [filters, querySelectableInstruments.data?.tickers]);

	const instrumentColumns = useInstrumentColumnsTableV2();
	const { filtered } = useSearchable({
		query,
		collection: selectableInstruments,
		matchFn: (row, q) => objectTextSearchMatchFns.keyword(row, q),
	});

	const selectableRowIds = useMemo(() => {
		if (selectableInstruments.length === 0) {
			return undefined;
		}

		return selectableInstruments.flatMap((instrument) => {
			if (!instrument.ticker) {
				return [];
			}

			const isAlreadyInComposition = preSelectedInstruments.has(instrument.ticker);
			return isAlreadyInComposition ? [] : [instrument.ticker];
		});
	}, [preSelectedInstruments, selectableInstruments]);

	const selectableTableColumn = useSelectableTableColumn({
		rows: selectableInstruments,
		selectBy: (r) => r.ticker ?? "",
		alwaysSelected: preSelectedInstruments,
		filteredRows: filtered,
		selectableRowIds,
	});

	const columns = useMemo<Array<TableColumn<Instrument>>>(
		() => [
			selectableTableColumn.column,
			instrumentColumns.instrument(),
			instrumentColumns.alias,
			instrumentColumns.assetClass,
			instrumentColumns.microAssetClass,
		],
		[instrumentColumns, selectableTableColumn.column],
	);

	const onSubmitSelectedInstruments = useCallback(
		(list: ReviewTicker[], ctx: Immutable.Set<string>) => {
			const selectedInstruments = ctx.toArray();
			const selectableInstrumentMap = Map(list.flatMap((x) => (x.ticker ? [[x.ticker, x]] : [])));
			const instruments = selectedInstruments.flatMap((tickerId) => {
				const selectedInstrument = selectableInstrumentMap.get(tickerId);
				return selectedInstrument ? [selectedInstrument] : [];
			});
			onSubmit?.(instruments);
		},
		[onSubmit],
	);

	return (
		<Dialog
			size="xxlarge"
			noValidate
			show={show}
			onClose={onClose}
			header="Select"
			onAnimationStateChange={onAnimationStateChange}
			footer={
				<DialogFooter
					neutralAction={
						<Button palette="tertiary" onClick={onClose}>
							{t("BUTTON.CANCEL")}
						</Button>
					}
					primaryAction={
						<SubmitButton disabled={querySelectableInstruments.isFetching}>{t("BUTTON.CONFIRM")}</SubmitButton>
					}
				/>
			}
			onSubmitAsync={() =>
				onSubmitSelectedInstruments(selectableInstruments, selectableTableColumn.multiSelectCtx.selection)
			}
		>
			<ReactQueryWrapperBase query={querySelectableInstruments}>
				{() => (
					<>
						<div className="mb-4">
							<DebouncedSearchInput
								query={query}
								onChange={setQuery}
								placeholder={placholderSearch}
								classList="max-w-[280px]"
								data-qualifier="InstrumentModal/Input/Search"
							/>
						</div>
						<InstrumentAddersFilters
							selectedOptions={filters}
							onSelectAssetClass={(ac) => setFilters((f) => ({ ...f, assetClasses: ac }))}
							onSelectBaskets={(bs) => setFilters((f) => ({ ...f, baskets: bs }))}
							onSelectGeographies={(geo) => setFilters((f) => ({ ...f, geographies: geo }))}
							onSelectGranularity={(grn) => setFilters((f) => ({ ...f, granularities: grn }))}
							onSelectInstrumentType={(instr) => setFilters((f) => ({ ...f, instrumentsType: instr }))}
							onSelectMicroAssetClasses={(mcr) => setFilters((f) => ({ ...f, microAssetClasses: mcr }))}
							selectableBaskets={baskets}
							selectableInstruments={selectableInstruments}
						/>
						<AutoSortTable
							rows={filtered}
							columns={columns}
							onRowClick={({ ticker }) => (ticker ? selectableTableColumn.toggle(ticker) : noop)}
							rowClassList={(row, rowIndex) => ({
								...toClassListRecord(selectableTableColumn.rowClassList(row, rowIndex)),
								InstrumentListTableRow: true,
							})}
							classList={{
								"max-h-[410px]": true,
								"[&>div:nth-child(2)]:!flex-col [&>div:nth-child(2)]:grow [&>div:nth-child(2)>div]:grow":
									filtered.length === 0,
							}}
							noDataText={
								<IconWallBase>
									<div className="flex flex-col justify-center space-y-2">
										<Text as="p" type="Body/L/Bold">
											No results matching the selected criteria
										</Text>
										<Button
											size="small"
											palette="secondary"
											classList="mx-auto"
											onClick={() => {
												const currentInstrumentSelection =
													selectableInstruments?.flatMap((x) =>
														x.ticker && selectableTableColumn.multiSelectCtx.selection.has(x.ticker) && x.alias
															? [x.alias]
															: [],
													) ?? [];

												const aliasInCompoisition = instrumentsInComposition.flatMap((x) => (x.alias ? [x.alias] : []));
												const instrumentInComposition = Set<string>(
													aliasInCompoisition.concat(currentInstrumentSelection),
												);

												spawnInstrumentDialog({
													instrumentInComposition,
													onSubmit: onSubmitMissingInstrument,
													entity,
												});
											}}
										>
											Add instrument manually
										</Button>
									</div>
								</IconWallBase>
							}
						/>
					</>
				)}
			</ReactQueryWrapperBase>
		</Dialog>
	);
};

const placholderSearch = "Filter by name";
const defaultFilters = {
	assetClasses: [] as string[],
	geographies: [] as string[],
	microAssetClasses: [] as string[],
	baskets: [] as SelectableBasket[],
	instrumentsType: [] as string[],
	granularities: [] as string[],
};

export type SpawnInstrumentAdderDialogProps = Omit<InstrumentAdderDialogProps, "show" | "onClose">;
const getInstrumentEditorTickers = withCache(
	async (params: {
		uuid?: string;
		entity: EditorSelectableBasketsRequestEntityEnum;
		baskets?: SelectableBasket[];
	}): Promise<{
		availableTickers: ReviewTicker[];
		baskets: SelectableBasket[];
	}> => {
		const editorApi = getApiGen(EntityEditorControllerApiFactory);
		const [{ availableTickers = [] }, { selectableBaskets = [] }] = await Promise.all([
			axiosExtract(
				params.uuid
					? editorApi.getEditorEditSelectableInstruments(params.uuid, {
							entity: params.entity,
							selectableBaskets: params.baskets,
					  })
					: editorApi.getEditorNewSelectableInstruments({
							entity: params.entity,
							selectableBaskets: params.baskets,
					  }),
			),
			axiosExtract(
				params.uuid
					? editorApi.getEditorEditSelectableBaskets(params.uuid, params.entity)
					: editorApi.getEditorNewSelectableBaskets2(params.entity),
			),
		]);
		return { availableTickers, baskets: selectableBaskets };
	},
	{
		cacheTime: minutes(5),
		cacheKey: (params) => `getInstrumentEditorTickers(${JSON.stringify(params)})`,
	},
);

export function prefetchAvailableInstruments(params: Parameters<typeof getInstrumentEditorTickers>[0]): void {
	getInstrumentEditorTickers(params).catch((err) =>
		reportPlatformError(err, "ERROR", "portfolio", {
			message: params.uuid ? "laod instruments modal on edit composition" : "laod instruments modal on new composition",
			payload: JSON.stringify(params),
		}),
	);
}

export function spawnInstrumentAddersDialog(props: SpawnInstrumentAdderDialogProps): SpawnResult<void> {
	return spawn<void>(
		adaptAnimatedNodeProvider(({ show, resolve, onHidden }) => (
			<InstrumentAddersDialog
				{...props}
				show={show}
				onAnimationStateChange={(state) => state === "hidden" && onHidden()}
				onClose={() => resolve()}
				onSubmit={(instruments) => {
					props.onSubmit(instruments);
					resolve();
				}}
			/>
		)),
	);
}
