import type { InnerRefProps } from "@mdotm/mdotui/components";
import { Icon, ProgressBar, Text } from "@mdotm/mdotui/components";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { SphereAIAvatar } from "../icons/SphereAIAvatar";
import { For, Switch } from "@mdotm/mdotui/react-extensions";
import { useLocaleFormatters } from "$root/localization/hooks";
import { Range } from "immutable";
import type { ImperativeHandlesRefProps } from "$root/utils/react-extra";
import { useImperativeHandlesRef } from "$root/utils/react-extra";
import type { FakeAiLoaderActions, UseFakeAiLoaderParams } from "$root/components/FakeAiLoader/common";
import { useFakeAiLoader, stepNames } from "$root/components/FakeAiLoader/common";

// eslint-disable-next-line react/no-unused-prop-types
export function ThinkingBoxV2(
	props: ImperativeHandlesRefProps<FakeAiLoaderActions> & InnerRefProps<HTMLDivElement> & UseFakeAiLoaderParams,
): JSX.Element {
	const { state, ...handles } = useFakeAiLoader(props);
	useImperativeHandlesRef(props.handlesRef, handles);

	const { formatNumber } = useLocaleFormatters();
	const stateIndex = stepNames.indexOf(state.stepName);
	return (
		<div
			ref={props.innerRef}
			className={`bg-[${themeCSSVars.palette_N20}] px-8 flex flex-col min-w-0 items-center py-4 border-y border-y-[${themeCSSVars.palette_N100}]`}
		>
			<For times={stateIndex + 1}>
				{({ index }) => (
					<div className="w-full py-0.5">
						<div className="flex justify-between">
							<div className="flex items-start min-w-0 w-full">
								<SphereAIAvatar
									classList={{
										invisible: index !== 0,
									}}
									animated={index === 0}
									style={{ width: 24 }}
								/>
								<div className="ml-3 pt-0.5 flex-1">
									<Text
										type="Body/L/Book"
										color={index !== stateIndex ? themeCSSVars.palette_N700 : themeCSSVars.palette_N400}
									>
										<Switch
											case={stepNames[index]}
											match={{
												recovering: () => "Retrieving data",
												analysing: () => "Analysing data",
												preparing: () => "Preparing and formatting your response",
											}}
										/>
									</Text>
									{index === stateIndex && (
										<div className="min-w-0 w-full mt-2">
											<ProgressBar
												accentColor={themeCSSVars.palette_graph_B400}
												classList="w-full"
												value={state.progress}
											/>
										</div>
									)}
								</div>
							</div>
							<Text
								as="div"
								type="Body/L/Book"
								color={index !== stateIndex ? themeCSSVars.palette_N700 : themeCSSVars.palette_N400}
							>
								{index !== stateIndex ? (
									<Icon
										icon="Outline"
										size={16}
										color={index !== stateIndex ? themeCSSVars.palette_N700 : themeCSSVars.palette_N400}
									/>
								) : (
									`${formatNumber(state.progress * 100, 0)}%`
								)}
							</Text>
						</div>
					</div>
				)}
			</For>
		</div>
	);
}

export function ThinkingBox(): JSX.Element {
	return (
		<div
			className={`bg-[${themeCSSVars.palette_N20}] px-8 flex items-center py-4 border-y border-y-[${themeCSSVars.palette_N100}]`}
		>
			<SphereAIAvatar animated style={{ width: 24 }} />
			<Text type="Body/L/Book" color={themeCSSVars.palette_N700} classList="ml-3">
				Sphere AI is thinking
				{Range(0, 3).map((i) => (
					<span
						key={i}
						className="animate-ping"
						style={{
							animationDelay: `${i * 100}ms`,
						}}
					>
						.
					</span>
				))}
			</Text>
		</div>
	);
}
