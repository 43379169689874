import { useUserStore } from "$root/functional-areas/user";
import type { AxiosInstance } from "axios";
import { Configuration } from "./api-gen";
import { preconfiguredAxiosInstance } from "./config";
import env from "$root/env";
import { AttemptedOperation, PlatformErrorAreas, reportPlatformError } from "./error-reporting";
import { ToastableError } from "$root/utils/errors";
import { platformToast } from "$root/notification-system/toast";

export type ControllerFactory<T> = (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) => T;

/**
 * Example:
 * ```tsx
 * const portfolioApi = makeAuthController({ controllerFactory: InvestmentsControllerApiFactory });
 * ```
 */
export function makeAuthController<T>({
	controllerFactory,
	axiosInstance,
	token,
}: {
	controllerFactory: ControllerFactory<T>;
	axiosInstance?: AxiosInstance;
	token?: string;
}): T {
	return controllerFactory(
		new Configuration({
			// For some reason this doesn't work after login (until page reload),
			// but explicitly setting the header (see below) does the trick.
			// accessToken: token,
			baseOptions: !token
				? undefined
				: {
						headers: {
							Authorization: "Bearer " + token,
						},
				  },
			basePath: env.apiOrigin,
		}),
		undefined,
		axiosInstance ?? preconfiguredAxiosInstance,
	);
}

/**
 * Like {@link useApiGen}, but it's not a hook, so it can be called anywhere in the code.
 *
 * Example:
 * ```tsx
 * const portfolioApi = getApiGen(InvestmentsControllerApiFactory);
 * ```
 */
export function getApiGen<T>(controllerFactory: ControllerFactory<T>, axiosInstance?: AxiosInstance): T {
	const user = useUserStore.getState().value;
	const api = makeAuthController({ controllerFactory, axiosInstance, token: user.token });
	return api;
}

/**
 * Executes a given asynchronous function with error reporting and user notifications.
 *
 * @template T The return type of the asynchronous function.
 * @param {() => Promise<T>} fn - The asynchronous function to execute.
 * @param {Object} opts - Options for error reporting and user notifications.
 * @param {PlatformErrorAreas} opts.area - The platform area where the error occurred.
 * @param {AttemptedOperation} opts.attemptedOperation - A description of the operation attempted when the error occurred.
 * @returns {Promise<T>} A promise that resolves to the result of the asynchronous function, or rejects with the encountered error.
 *
 * @throws Will rethrow any error encountered during execution of the `fn` function after reporting it.
 *
 * @example
 * Example:
 * ```
 * <AsyncButton
 * 	onClickAsync={async () => {
 * 		const x = await runWithErrorReporting(
 * 			async () => {
 * 				try {
 * 					const response = await api();
 * 					return response;
 * 				} catch (err) {
 * 					if (err.statusCode == 502) {
 * 						throw new ToastableError("Oh nooooo", {
 * 							cause: err,
 * 							icon: "Portfolio",
 * 						});
 * 					}
 * 					throw err;
 * 				}
 * 			},
 * 			{
 * 				area: "portfolio",
 * 				attemptedOperation: "save proposal",
 * 			},
 * 		);
 * 	}}
 * >
 * 	Test
 * </AsyncButton>;
 * ```
 */
export async function runWithErrorReporting<T>(
	fn: () => Promise<T>,
	opts: {
		area: PlatformErrorAreas;
		attemptedOperation: AttemptedOperation;
	},
): Promise<T> {
	try {
		return await fn();
	} catch (err) {
		if (err instanceof ToastableError) {
			platformToast({
				children: err.endUserMsg,
				icon: err.icon ?? "Icon-full-alert",
				severity: "error",
			});
		} else {
			platformToast({
				children: "something went wrong",
				icon: "Icon-full-alert",
				severity: "error",
			});
		}

		reportPlatformError(err, "ERROR", opts.area, opts.attemptedOperation);
		throw err;
	}
}

export function createDefaultApiErrorHandler<T>(...params: Parameters<typeof runWithErrorReporting<T>>) {
	return (): Promise<T> => runWithErrorReporting<T>(...params);
}
