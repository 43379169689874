import type {
	InvestmentSummary,
	ReviewTicker,
	UserCompositionColumnOrdering,
	UserCompositionColumnPreference,
	UserCompositionColumnPreferencePreferenceTypeEnum,
	UserEnhancementCompositionColumnOrdering,
	UserEnhancementCompositionColumnPreference,
	UserEnhancementCompositionColumnPreferencePreferenceTypeEnum,
} from "$root/api/api-gen";
import {
	InvestmentEnhancementExportControllerApiFactory,
	InvestmentEnhancementReportsControllerApiFactory,
	InvestmentExportControllerApiFactory,
	InvestmentReportsControllerApiFactory,
	PortfolioStudioPreferencesApiFactory,
} from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import type { ColumnMetadata } from "$root/components/tables-extra/CustomizeColumns";
import type { PortfolioAlert } from "$root/functional-areas/portfolio/alerts";
import {
	PortfolioCompositionInnerFlat,
	PortfolioCompositionInnerGrouped,
} from "$root/pages/PortfolioDetails/PortfolioComposition";
import {
	PortfolioQueryWidgetBase,
	WidgetStatus,
	portfolioWidgetMissingDataReason,
} from "$root/pages/PortfolioDetails/PortfolioWidgetStatus";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { trackMixPanelEvent } from "$root/third-party-integrations/initMixPanel";
import { downloadContentDisposition } from "$root/utils/files";
import type { ContextContent } from "$root/utils/react-extra";
import { withContext } from "$root/utils/react-extra";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { PortfolioContext } from "$root/widgets-architecture/contexts/portfolio";
import { useWidgetOptions } from "$root/widgets-architecture/layout/WidgetsMapper/context";
import { Checkbox, DropdownMenu, Icon } from "@mdotm/mdotui/components";
import { useUnsafeUpdatedRef } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const Composition = (props: ContextContent<typeof PortfolioContext>) => {
	const { enhanced, portfolio, reportExcutionCounter } = props;
	const uuid = props.portfolio?.uuid;
	const { t } = useTranslation();

	useWidgetOptions(
		() => ({
			title: t("COMPOSITION.TITLE"),
		}),
		[t],
	);

	const investmentReportApi = useApiGen(InvestmentReportsControllerApiFactory);
	const investmentEnhancementReportApi = useApiGen(InvestmentEnhancementReportsControllerApiFactory);
	const portfolioStudioPreferencesApi = useApiGen(PortfolioStudioPreferencesApiFactory);

	const query = useQueryNoRefetch(["compositionProvider", uuid, enhanced, portfolio?.status, reportExcutionCounter], {
		queryFn: async () => {
			if (!uuid) {
				return {
					data: undefined,
					widgetStatus: portfolioWidgetMissingDataReason(props.portfolio!, "Composition"),
				};
			}

			const data = await axiosExtract(
				props.enhanced
					? investmentEnhancementReportApi.getInvestmentComposition1(uuid)
					: investmentReportApi.getInvestmentComposition(uuid),
			);
			// PROPOSAL: we can either remap the response here or ask the BE to give us the
			// appropriate data status (letting us delete portfolioWidgetMissingDataReason)
			if (data.composition) {
				return {
					data: {
						composition: data.composition,
						userCompositionColumnOrderingPreferences: !enhanced
							? (await axiosExtract(portfolioStudioPreferencesApi.getUserCompositionColumnOrderingPreferences()))
									.userCompositionColumnPreferences
							: [],
						userEnhancementCompositionColumnOrderingPreferences: enhanced
							? (
									await axiosExtract(
										portfolioStudioPreferencesApi.getUserEnhancementCompositionColumnOrderingPreferences(),
									)
							  ).userEnhancementCompositionColumnPreferences
							: [],
					},
					widgetStatus: WidgetStatus.READY,
				};
			}

			return {
				data: undefined,
				widgetStatus: portfolioWidgetMissingDataReason(props.portfolio!, "Composition"),
			};
		},
	});

	return (
		<PortfolioQueryWidgetBase query={query}>
			{(
				{ composition, userCompositionColumnOrderingPreferences, userEnhancementCompositionColumnOrderingPreferences },
				{ refetch },
			) => (
				<CompositionInner
					ctx={props}
					composition={composition}
					columnPreferences={{
						current: userCompositionColumnOrderingPreferences ?? [],
						enhance: userEnhancementCompositionColumnOrderingPreferences ?? [],
					}}
					onAsyncChangeColumnPreferences={async (columns) => {
						if (enhanced) {
							const payload = {
								userEnhancementCompositionColumnPreferences: columns.map((preference) => ({
									enabled: preference.visible,
									preferenceType: preference.id as UserEnhancementCompositionColumnPreferencePreferenceTypeEnum,
								})),
							} satisfies UserEnhancementCompositionColumnOrdering;
							await portfolioStudioPreferencesApi.setUserEnhancementCompositionColumnOrderingPreferences(payload);
						} else {
							const payload = {
								userCompositionColumnPreferences: columns.map((preference) => ({
									enabled: preference.visible,
									preferenceType: preference.id as UserCompositionColumnPreferencePreferenceTypeEnum,
								})),
							} satisfies UserCompositionColumnOrdering;
							await portfolioStudioPreferencesApi.setUserCompositionColumnOrderingPreferences(payload);
						}

						await refetch();
					}}
				/>
			)}
		</PortfolioQueryWidgetBase>
	);
};

const CompositionInner = (props: {
	ctx: ContextContent<typeof PortfolioContext>;
	composition: ReviewTicker[];
	columnPreferences: {
		current: UserCompositionColumnPreference[];
		enhance: UserEnhancementCompositionColumnPreference[];
	};
	onAsyncChangeColumnPreferences: (
		columns: ColumnMetadata<
			UserEnhancementCompositionColumnPreferencePreferenceTypeEnum | UserCompositionColumnPreferencePreferenceTypeEnum
		>[],
	) => Promise<void>;
}) => {
	const { t } = useTranslation();
	const { composition, ctx } = props;
	const { enhanced, portfolio } = ctx;

	const exportApi = useApiGen(InvestmentExportControllerApiFactory);
	const exportEnhancedApi = useApiGen(InvestmentEnhancementExportControllerApiFactory);

	const exportPortfolioComposition = async (downloadTarget: "composition" | "trades") => {
		const response = enhanced
			? downloadTarget === "composition"
				? await exportEnhancedApi.exportEnhancedComposition(portfolio!.uuid!, "FULL_COMPOSITION", {
						responseType: "blob",
				  })
				: await exportEnhancedApi.exportEnhancedComposition(portfolio!.uuid!, "TRADES_ONLY", { responseType: "blob" })
			: await exportApi.exportComposition(portfolio!.uuid!, { responseType: "blob" });

		downloadContentDisposition(response);
		trackMixPanelEvent("Portfolio", {
			Type: "Export",
			Area: downloadTarget,
			ID: portfolio!.uuid!,
		});
	};

	const exportPortfolioCompositionRef = useUnsafeUpdatedRef(exportPortfolioComposition);
	const [groupByAssetClass, setGroupByAssetClass] = useState(true);

	useWidgetOptions(
		() => ({
			actionHeader: function Download() {
				return (
					<div style={{ display: "flex", flexDirection: "row" }} className="space-x-4">
						<Checkbox switchType="switch" checked={groupByAssetClass} onChange={setGroupByAssetClass}>
							Group by Asset Class
						</Checkbox>
						<DropdownMenu
							trigger={({ innerRef, open, ...forward }) => (
								<button ref={innerRef} aria-expanded={open} type="button" {...forward}>
									<Icon icon="Dowload" color={themeCSSVars.MessageSeverity_success} size={20} />
								</button>
							)}
							actions={[
								{
									icon: "xls",
									onClickAsync: async () => {
										await exportPortfolioCompositionRef.current("composition");
									},
									label: portfolio?.reference ? "Target portfolio composition" : "Portfolio composition",
								},
								portfolio?.status === "PROPOSAL_READY" &&
									enhanced && {
										icon: "xls",
										onClickAsync: async () => {
											await exportPortfolioCompositionRef.current("trades");
										},
										label: t("COMPOSITION.DOWNLOAD_TRADES_TITLE"),
									},
							]}
						/>
						{/* <InfoTooltip>{t("COMPOSITION.TOOLTIP")}</InfoTooltip> */}
					</div>
				);
			},
			title: t("COMPOSITION.TITLE"),
		}),
		[enhanced, exportPortfolioCompositionRef, groupByAssetClass, portfolio?.reference, portfolio?.status, t],
	);

	return (
		<>
			{props.ctx.portfolio && (
				<CompositionTable
					ctx={{ ...ctx, portfolio: props.ctx.portfolio!, reportExcutionCounter: 0 }}
					groupByAssetClass={groupByAssetClass}
					filtered={composition}
					columnPreferences={props.columnPreferences}
					onAsyncChangeColumnPreferences={props.onAsyncChangeColumnPreferences}
				/>
			)}
		</>
	);
};

const CompositionTable = (props: {
	ctx: {
		portfolio: InvestmentSummary;
		enhanced: boolean;
		alerts: PortfolioAlert[];
		reportExcutionCounter: number;
	};
	filtered: ReviewTicker[];
	groupByAssetClass: boolean;
	columnPreferences: {
		current: UserCompositionColumnPreference[];
		enhance: UserEnhancementCompositionColumnPreference[];
	};
	onAsyncChangeColumnPreferences: (
		columns: ColumnMetadata<
			UserEnhancementCompositionColumnPreferencePreferenceTypeEnum | UserCompositionColumnPreferencePreferenceTypeEnum
		>[],
	) => Promise<void>;
}) => {
	return (
		<>
			{props.groupByAssetClass ? (
				<PortfolioCompositionInnerGrouped {...props} />
			) : (
				<PortfolioCompositionInnerFlat {...props} />
			)}
		</>
	);
};

export default withContext(PortfolioContext)(Composition);
