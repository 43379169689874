import type { PageDto } from "$root/api/api-gen";
import { PdfControllerApiFactory } from "$root/api/api-gen";
import { makeAuthController } from "$root/api/factory";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { downloadBlob } from "$root/utils/files";
import { format } from "date-fns";
import type { TemplateChooserSubmitParams } from "./portfolio-studio/dialogs/TemplateChooserDialog";
import { useUserStore } from "./user";

export function generateReportBuilderTemplateName(
	pageMetadata: TemplateChooserSubmitParams,
	templateName: string,
): string {
	const { name, choice } = pageMetadata;
	return `${name ? `${name}_` : ""}${templateName}_${format(new Date(), "MMddyyyy")}${
		choice === "current" ? "" : "proposal_ready"
	}`;
}

export async function downloadSinglePdf(name: string, conf: PageDto): Promise<void> {
	const pdfV3Api = makeAuthController({
		controllerFactory: PdfControllerApiFactory,
		token: useUserStore.getState().value.token,
	});

	const pdf = await axiosExtract(pdfV3Api.generateCustomReportPdf(name, conf, { responseType: "blob" }));
	downloadBlob(new Blob([pdf]), { fileName: `${name}.pdf` });
}
