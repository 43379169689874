import type { EditorVerifyExcelRequestPolicyEnum, ReviewTicker, UploadError } from "$root/api/api-gen";
import { EntityEditorControllerApiFactory } from "$root/api/api-gen";
import { reportPlatformError } from "$root/api/error-reporting";
import { useApiGen } from "$root/api/hooks";
import { DropzoneArea } from "$root/components/DropzoneArea";
import { uploadErrorMap } from "$root/components/Portfolio/UploadPortfolio/uploadErrorMap";
import UploadButton from "$root/components/UploadButton";
import EmptyImportBox from "$root/functional-areas/instruments-editor/spawn/box/EmptyImportBox";
import ErrorImportBox from "$root/functional-areas/instruments-editor/spawn/box/ErrorImportBox";
import SuccessImportBox from "$root/functional-areas/instruments-editor/spawn/box/SuccessImportBox";
import { useLocaleFormatters } from "$root/localization/hooks";
import { platformToast } from "$root/notification-system/toast";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { FormController } from "$root/third-party-integrations/react-hook-form";
import { LocalizedError, ToastableError } from "$root/utils/errors";
import { customObjectEntriesFn } from "$root/utils/experimental";
import { downloadContentDisposition } from "$root/utils/files";
import type { OrderBy, TableColumn } from "@mdotm/mdotui/components";
import {
	ActionText,
	AutoSortTable,
	Banner,
	Button,
	Dialog,
	DialogFooter,
	Icon,
	Radio,
	RadioGroup,
	SubmitButton,
} from "@mdotm/mdotui/components";
import type { MaybePromise } from "@mdotm/mdotui/headless";
import type { SpawnResult } from "@mdotm/mdotui/react-extensions";
import { adaptAnimatedNodeProvider, spawn } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { builtInSortFnFor, unpromisify } from "@mdotm/mdotui/utils";
import type { ReactNode } from "react";
import { useCallback, useMemo } from "react";
import { useForm, type Control } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import * as XLSX from "xlsx";
import type { InstrumentEditorEntity, MinimunDialogProps } from "../const";
import { runWithErrorReporting } from "$root/api/factory";

const ACCEPTED_FILE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

const downloadTitle = {
	INVESTMENT: "Portfolio",
	BENCHMARK: "Benchmark",
	TARGET_INVESTMENT: "Target portfolio",
	UNIVERSE: "universe",
	INVESTMENT_ENHANCEMENT: "Portfolio",
	INVESTMENT_DRAFT: "Portfolio",
} satisfies Record<InstrumentEditorEntity, string>;

const defaultCompositionBoxOrderBy: OrderBy[] = [
	{
		columnName: "alias",
		direction: "desc",
	},
];

const Description = ({ onDownload, entity }: { onDownload: () => Promise<void>; entity: InstrumentEditorEntity }) => {
	const { t } = useTranslation();
	const portfolio = t("PORTFOLIOS", { returnObjects: true });

	const portfolio18Key = {
		INVESTMENT: "PORTFOLIOS_UPLOAD_STEP",
		BENCHMARK: "PORTFOLIOS_UPLOAD_STEP",
		TARGET_INVESTMENT: "PORTFOLIOS_UPLOAD_STEP",
		UNIVERSE: "UNIVERSE_UPLOAD_STEP",
		INVESTMENT_ENHANCEMENT: "PORTFOLIOS_UPLOAD_STEP",
		INVESTMENT_DRAFT: "PORTFOLIOS_UPLOAD_STEP",
	} satisfies Record<InstrumentEditorEntity, keyof typeof portfolio>;

	return (
		<ol className="list-decimal ml-4 mb-5">
			{customObjectEntriesFn(t(`PORTFOLIOS.${portfolio18Key[entity]}`, { returnObjects: true })).map(([step], i) => (
				<li key={i}>
					<Trans
						t={t}
						i18nKey={`PORTFOLIOS.${portfolio18Key[entity]}.${step}`}
						components={{
							u: <u className="cursor-pointer font-semibold" />,
							download: (
								<ActionText aria-label="download" onClickAsync={onDownload}>
									{" "}
								</ActionText>
							),
						}}
						values={{ type: downloadTitle[entity].toLowerCase() }}
					/>
				</li>
			))}
		</ol>
	);
};

const MainInfoBlockErrors = (props: {
	errors?: UploadError[];
	entity: InstrumentEditorEntity;
	uploadPlaceholders: Record<InstrumentEditorEntity, { type: string; confirmText: string }>;
}) => {
	const { errors, entity, uploadPlaceholders } = props;
	const { t } = useTranslation();
	const { formatNumber } = useLocaleFormatters();

	const localUploadStateErrors = useMemo(
		() => errors?.filter((el) => uploadErrorMap[el.code!].isAliasReferred === true),
		[errors],
	);

	const globalUploadStateErrors = useMemo(
		() =>
			errors
				?.filter((el) => uploadErrorMap[el.code!].isAliasReferred === false)
				.map((el) => ({
					mappedError: uploadErrorMap[el.code!],
					errorData: el,
				})),
		[errors],
	);

	const isBlockError = useMemo(
		() => (localUploadStateErrors ?? []).filter((el) => uploadErrorMap[el.code!].type === "error").length > 0,
		[localUploadStateErrors],
	);

	const PTF_ERRORS = t("PORTFOLIO_UPLOAD_ALERTS", { returnObjects: true });

	const errorColumns = useMemo<TableColumn<UploadError>[]>(
		() =>
			entity === "UNIVERSE"
				? [
						{
							name: "alias",
							header: t("PORTFOLIOS.TB_HEADER_ALIAS"),
							cellClassList: "underline",
							content: ({ alias }) => alias,
							sortFn: builtInSortFnFor("alias"),
							minWidth: 200,
						},
						{
							name: "error",
							header: t("PORTFOLIOS.TB_HEADER_ERROR"),
							content: ({ code }) => t(code!),
							minWidth: 300,
						},
				  ]
				: [
						{
							name: "alias",
							header: t("PORTFOLIOS.TB_HEADER_ALIAS"),
							cellClassList: "underline",
							content: ({ alias }) => alias,
							sortFn: builtInSortFnFor("alias"),
							minWidth: 200,
						},
						{
							name: "weight",
							header: t("PORTFOLIOS.TB_HEADER_WEIGHT"),
							content: ({ weight }) => `${formatNumber(weight)}%`,
							width: 64,
						},
						{
							name: "error",
							header: t("PORTFOLIOS.TB_HEADER_ERROR"),
							content: ({ code }) => t(code!),
							minWidth: 300,
						},
				  ],
		[entity, t, formatNumber],
	);

	const handleDownloadErrorReport = useCallback(
		(report: Array<UploadError>) => {
			const mapReport = report.map(({ code, weight, alias }) =>
				entity === "UNIVERSE" ? { identifier: alias, code: t(code!) } : { identifier: alias, weight, code: t(code!) },
			);
			const reportWS = XLSX.utils.json_to_sheet(mapReport);
			const wb = XLSX.utils.book_new();
			XLSX.utils.book_append_sheet(wb, reportWS, "Report Errors");
			XLSX.writeFile(wb, "Errors file.xlsx");
		},
		[entity, t],
	);

	return (
		<>
			{globalUploadStateErrors &&
				globalUploadStateErrors.length > 0 &&
				globalUploadStateErrors.map((el, i) => (
					<div className="my-4" key={i}>
						<Banner
							severity={el.mappedError.type === "error" ? "error" : "info"}
							title={
								el.mappedError.translationKey === "WEIGHT_UNDER_100" ||
								el.mappedError.translationKey === "WEIGHT_OVER_100" ? (
									<Trans
										i18nKey={`PORTFOLIO_UPLOAD_ALERTS.${el.mappedError.translationKey}.title`}
										values={{
											weight: formatNumber(el.errorData.weight),
											remaining: formatNumber(100 - (el.errorData.weight ?? 0), 2),
										}}
									/>
								) : (
									(
										PTF_ERRORS[el.mappedError.translationKey as keyof typeof PTF_ERRORS] as {
											title: string;
											description: string;
										}
									).title
								)
							}
						>
							{el.mappedError.translationKey === "WEIGHT_UNDER_100" ||
							el.mappedError.translationKey === "WEIGHT_OVER_100" ? (
								<Trans i18nKey={`PORTFOLIO_UPLOAD_ALERTS.${el.mappedError.translationKey}.description`} />
							) : (
								(
									PTF_ERRORS[el.mappedError.translationKey as keyof typeof PTF_ERRORS] as {
										title: string;
										description: string;
									}
								).description
							)}
						</Banner>
					</div>
				))}

			{localUploadStateErrors && localUploadStateErrors.length > 0 && (
				<div className="my-4">
					<Banner
						severity={isBlockError ? "error" : "info"}
						title={
							isBlockError ? (
								t("PORTFOLIO_UPLOAD_ALERTS.UPLOAD_ERROR.title")
							) : (
								<Trans
									i18nKey="PORTFOLIO_UPLOAD_ALERTS.UPLOAD_WARNING.title"
									values={{
										type: uploadPlaceholders[entity].type,
									}}
								/>
							)
						}
					>
						{isBlockError ? (
							t("PORTFOLIO_UPLOAD_ALERTS.UPLOAD_ERROR.description")
						) : (
							<Trans
								i18nKey="PORTFOLIO_UPLOAD_ALERTS.UPLOAD_WARNING.description"
								values={{
									type: uploadPlaceholders[entity].type,
								}}
								className="whitespace-pre-wrap"
							/>
						)}
						<div className="mt-1">
							<button
								type="button"
								className="underline cursor-pointer font-bold"
								onClick={() => handleDownloadErrorReport(localUploadStateErrors)}
							>
								{isBlockError ? t("EXPORT_ERROR") : t("EXPORT_WARNING")}
							</button>
						</div>
					</Banner>

					<AutoSortTable
						columns={errorColumns}
						rows={localUploadStateErrors}
						orderBy={defaultCompositionBoxOrderBy}
						classList="max-h-[200px]"
					/>
				</div>
			)}
		</>
	);
};

const MainBlock = ({
	entity,
	onChangeFile,
	onReset,
	file,
	control,
}: {
	file: UploadInstrumentFormProps;
	control: Control<UploadInstrumentFormProps, any>;
	entity: InstrumentEditorEntity;
	onChangeFile?(file: File | null): MaybePromise<void>;
	onReset?(): void;
}) => {
	const { t } = useTranslation();
	const status = file.status;

	const hasBlockError = useMemo(
		() => (file.fileErrors ?? []).filter((el) => uploadErrorMap[el.code!].type === "error").length > 0,
		[file.fileErrors],
	);

	const uploadState = useMemo(() => {
		if (hasBlockError) {
			return "error";
		}
		if (file.fileContent) {
			return "success";
		}

		return "empty";
	}, [file.fileContent, hasBlockError]);

	const BoxMap = {
		empty: <EmptyImportBox />,
		error: <ErrorImportBox />,
		success: <SuccessImportBox />,
	} satisfies Record<typeof uploadState, ReactNode>;

	return (
		<>
			<div className="mb-5">
				<p className="font-semibold">
					{entity === "UNIVERSE"
						? "In case the template has existing instruments, which information should be considered?"
						: "In case the template has existing instruments, which weights should be considered?"}
				</p>
				<FormController
					control={control}
					name="uploadStrategy"
					render={({ field: { value, onChange } }) => (
						<RadioGroup value={value} onChange={onChange} disabled={status === "calculating"}>
							<div className="flex flex-row flex-wrap gap-4">
								<Radio
									value="PRIORITY_TO_EDITOR_WEIGHTS"
									classList={`text-[${themeCSSVars.palette_N400}]`}
									disabled={status === "calculating"}
								>
									{entity === "UNIVERSE" ? "Keep information from the editor" : "Keep the weights from the editor"}
								</Radio>
								<Radio
									value="PRIORITY_TO_EXCEL_WEIGHTS"
									classList={`text-[${themeCSSVars.palette_N400}]`}
									disabled={status === "calculating"}
								>
									{entity === "UNIVERSE"
										? "Keep information from the template file"
										: "Keep the weights from the template file"}
								</Radio>
							</div>
						</RadioGroup>
					)}
				/>
			</div>
			{/* )} */}
			<DropzoneArea
				onChange={
					file.uploadStrategy === undefined && entity !== "UNIVERSE"
						? console.log
						: onChangeFile
						  ? unpromisify(onChangeFile)
						  : console.log
				}
				accept={ACCEPTED_FILE}
				disabled={(file.uploadStrategy === undefined && entity !== "UNIVERSE") || status === "calculating"}
				childrenWrapperAppearance={{
					classList: {
						[`relative rounded flex flex-1 gap-4 p-4 justify-between items-center border-2 `]: true,
						[`bg-[color:${themeCSSVars.palette_N50}] border-dashed border-[color:${themeCSSVars.palette_N500}]`]:
							file.fileContent === null && hasBlockError === false,
						[`bg-[color:${themeCSSVars.palette_N0}] border-[color:${themeCSSVars.palette_P600}]`]: Boolean(
							file.fileContent && hasBlockError === false,
						),
						[`bg-[color:${themeCSSVars.palette_N0}] border-[color:${themeCSSVars.palette_D500}]`]: hasBlockError,
					},
				}}
			>
				<div className="flex gap-2 items-center">
					{BoxMap[uploadState]}
					<p className="font-semibold">{file.filename || "Select a file or drag and drop here"}</p>
				</div>

				{file.fileContent ? (
					<Button unstyled size="small" onClick={onReset} disabled={status === "calculating"}>
						<Icon
							icon="Delete"
							size={20}
							color={status === "calculating" ? themeCSSVars.palette_N500 : themeCSSVars.palette_P500}
						/>
					</Button>
				) : (
					<UploadButton
						size="small"
						onChange={
							file.uploadStrategy === undefined && entity !== "UNIVERSE"
								? console.log
								: onChangeFile
								  ? unpromisify(onChangeFile)
								  : console.log
						}
						accept={ACCEPTED_FILE}
						disabled={(file.uploadStrategy === undefined && entity !== "UNIVERSE") || status === "calculating"}
						loading={status === "calculating"}
						label={file.fileContent ? t("SELECT_NEW_FILE") : t("BUTTON.SELECT")}
					/>
				)}
			</DropzoneArea>

			{status === "calculating" && (
				<div className="my-4">
					<Banner severity="info">{t("PORTFOLIOS.UPLOAD_DURATION_LIMIT")}</Banner>
				</div>
			)}

			<MainInfoBlockErrors errors={file.fileErrors} uploadPlaceholders={uploadPlaceholders} entity={entity} />
		</>
	);
};

const uploadPlaceholders: Record<InstrumentEditorEntity, { type: string; confirmText: string }> = {
	BENCHMARK: { type: "Benchmark", confirmText: "Upload benchmark" },
	INVESTMENT: { type: "Portfolio", confirmText: "Upload portfolio" },
	INVESTMENT_ENHANCEMENT: { type: "Portfolio", confirmText: "Upload portfolio" },
	TARGET_INVESTMENT: { type: "Portfolio", confirmText: "Upload portfolio" },
	UNIVERSE: { type: "Universe", confirmText: "Upload universe" },
	INVESTMENT_DRAFT: { type: "Portfolio", confirmText: "Upload portfolio" },
};

type InstrumentUploadDialogProps = {
	entity: InstrumentEditorEntity;
	composition?: ReviewTicker[];
	onSubmit(data: ReviewTicker[]): void;
} & MinimunDialogProps;

const InstrumentUploadDialog = ({
	entity,
	show,
	composition,
	onAnimationStateChange,
	onSubmit,
	onClose,
}: InstrumentUploadDialogProps) => {
	const { t } = useTranslation();
	const editorApi = useApiGen(EntityEditorControllerApiFactory);

	const { control, handleSubmit, watch, reset, resetField, getValues, setValue } = useForm({
		defaultValues: defaultUploadFormValue,
	});

	const observedFileContent = watch("fileContent");

	const handleDownloadTemplate = useCallback(
		() =>
			editorApi
				.getEditorUploadTemplate(entity, {
					responseType: "blob",
				})
				.then(downloadContentDisposition)
				.catch((e) => {
					reportPlatformError(e, "ERROR", "portfolio", { message: "unable to download editor template" });
					throw e;
				}),
		[editorApi, entity],
	);

	const onReset = useCallback(() => {
		resetField("status");
		resetField("filename");
		resetField("fileContent");
		resetField("tickerComposition");
	}, [resetField]);

	const onChangeFile = useCallback(
		async (file: File | null) => {
			await runWithErrorReporting(
				async () => {
					try {
						onReset();
						if (!file) {
							setValue("filename", "");
							setValue("fileContent", undefined);
							return;
						}

						if (file.type !== ACCEPTED_FILE) {
							throw new LocalizedError(t("PORTFOLIOS.WRONG_FILE_TYPE"), { msg: "wrong file type" });
						}

						const fileReader = new FileReader();
						await new Promise((resolve, reject) => {
							fileReader.onload = resolve;
							fileReader.onerror = reject;
							fileReader.readAsArrayBuffer(file);
						});

						setValue("fileContent", fileReader.result ?? undefined);
						setValue("filename", file.name);
					} catch (err) {
						if (err instanceof LocalizedError) {
							throw new ToastableError(err.endUserMsg, { cause: err, icon: "Portfolio" });
						} else {
							throw new ToastableError(t("PORTFOLIOS.PORTFOLIO_GENERIC_UPLOAD_ERROR"), {
								cause: err,
								icon: "Portfolio",
							});
						}
					}
				},
				{ area: "portfolio", attemptedOperation: { message: "upload portfolio" } },
			);
		},
		[onReset, setValue, t],
	);

	const verifyFile = useCallback(
		async (body: { composition: Blob }) => {
			const verifiedFile = await axiosExtract(
				editorApi.verifyEditorExcel(body.composition, {
					entity,
					policy: getValues("uploadStrategy"),
					composition,
				}),
			);

			if (verifiedFile.errors && verifiedFile.errors.length > 0) {
				setValue("fileErrors", verifiedFile.errors);
				if (verifiedFile.errors.filter((error) => uploadErrorMap[error.code!].type === "error").length > 0) {
					throw new LocalizedError(t("FILE_KNOWN_ERROR"), { msg: "file is not uploadable" });
				}
			}

			return verifiedFile.composition;
		},
		[editorApi, entity, getValues, composition, setValue, t],
	);

	const onSubmitUploadedInstruments = useCallback(async () => {
		await handleSubmit(async (formData) => {
			try {
				setValue("status", "calculating");

				const fileReaderResult = formData.fileContent;
				if (!fileReaderResult) {
					throw new Error("missing file");
				}

				const body = {
					composition: new Blob([fileReaderResult as ArrayBuffer]),
				};
				const verifiedComposition = await verifyFile(body);
				setValue("status", "ready");
				onSubmit?.(verifiedComposition ?? []);

				reset();
			} catch (error) {
				if (error instanceof LocalizedError) {
					platformToast({
						children: error.endUserMsg,
						severity: "error",
						icon: "Icon-full-error",
					});
				} else {
					platformToast({
						children: "Unable to generate the composition",
						severity: "error",
						icon: "Icon-full-error",
					});
				}

				setValue("status", "error");
				throw error;
			}
		})();
	}, [handleSubmit, onSubmit, reset, setValue, verifyFile]);
	return (
		<Dialog
			size="xlarge"
			show={show}
			onClose={onClose}
			onSubmitAsync={onSubmitUploadedInstruments}
			onAnimationStateChange={onAnimationStateChange}
			header="Import file"
			footer={
				<DialogFooter
					primaryAction={
						<SubmitButton disabled={observedFileContent === null || observedFileContent === undefined}>
							{" "}
							{t("BUTTON.SAVE")}
						</SubmitButton>
					}
					neutralAction={
						<Button palette="tertiary" onClick={onClose}>
							{t("BUTTON.CANCEL")}
						</Button>
					}
				/>
			}
		>
			<Description onDownload={handleDownloadTemplate} entity={entity} />
			<MainBlock
				control={control}
				entity={entity}
				onChangeFile={onChangeFile}
				onReset={() => reset({ uploadStrategy: getValues("uploadStrategy") })}
				file={watch()}
			/>
		</Dialog>
	);
};

type UploadInstrumentFormProps = {
	filename?: string;
	fileContent?: ArrayBuffer | string;
	tickerComposition?: Array<ReviewTicker>;
	uploadStrategy?: EditorVerifyExcelRequestPolicyEnum;
	fileErrors?: Array<UploadError>;
	status?: "calculating" | "ready" | "empty" | "error";
};

const defaultUploadFormValue: UploadInstrumentFormProps = {
	uploadStrategy: "PRIORITY_TO_EDITOR_WEIGHTS" as EditorVerifyExcelRequestPolicyEnum | undefined,
} as const;

export type SpawnInstrumentUploadDialogProps = Omit<InstrumentUploadDialogProps, "show" | "onClose">;
export function spawnInstrumentUploadDialogProps(props: SpawnInstrumentUploadDialogProps): SpawnResult<void> {
	return spawn<void>(
		adaptAnimatedNodeProvider(({ show, resolve, onHidden }) => (
			<InstrumentUploadDialog
				{...props}
				show={show}
				onAnimationStateChange={(state) => state === "hidden" && onHidden()}
				onClose={() => resolve()}
				onSubmit={(composition) => {
					props.onSubmit(composition);
					resolve();
				}}
			/>
		)),
	);
}
