import { distance } from "fastest-levenshtein";

export const replaceSpaceWith_ = (toReplace: string): string => {
	if (!toReplace) {
		return "";
	}
	return toReplace.split(" ").join("_");
};

export const toSnakeCase = (toConvert: string): string => {
	return toConvert.replace(/[A-Z]/g, (word, index) => {
		return index === 0 ? word.toLowerCase() : "_" + word.toLowerCase();
	});
};

export const capitalizeString = (toCapitalize: string): string => {
	const words = toCapitalize.split(" ");
	return words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
};

/**
 * Return the Levenshtein Distance between two strings.
 * @param a A string.
 * @param b Another string.
 * @returns The (case sensitive) Levenshtein Distance between the input strings.
 */
export function levenshteinDistance(a: string, b: string): number {
	return distance(a, b);
}

/**
 * Return a modified version of the Levenshtein Distance that takes into account the
 * length of the search and input strings.
 * @param str A string.
 * @param search The search string.
 * Reference: https://github.com/cdellacqua/custom-uikit-svelte/blob/2df7f4a5d858fc81a0cdc86a2c78e6b17ffcf61d/src/helpers/filter-sort.js
 */
function lengthAdjustedLevenshteinDistance(str: string, search: string): number {
	const levenDst = levenshteinDistance(str, search);
	const compensation = -Math.max(0, str.length - search.length);
	return levenDst + compensation;
}

/**
 * Filter and sort an array based on the Levenshtein Distance between its items and a search string.
 * @param search A search string.
 * @param objects An array to filter and sort.
 * @param stringExtractor A function that, given an item of the input array, returns a stringified form to be used for the comparison.
 * Reference: https://github.com/cdellacqua/custom-uikit-svelte/blob/2df7f4a5d858fc81a0cdc86a2c78e6b17ffcf61d/src/helpers/filter-sort.js
 */
export function lengthAdjustedLevenshteinFilterSort<T>(
	search: string,
	objects: T[],
	stringExtractor: (item: T) => string,
): T[] {
	const searchLower = search;
	const distances = objects
		.map((o, index) => ({
			distance: lengthAdjustedLevenshteinDistance(stringExtractor(o), searchLower),
			index,
			value: stringExtractor(o),
		}))
		.filter((d) => d.distance < d.value.length)
		.sort((a, b) => {
			if (a.distance - b.distance === 0) {
				const aHasSubstring = a.value.indexOf(search) !== -1;
				const bHasSubstring = b.value.indexOf(search) !== -1;
				if (aHasSubstring && !bHasSubstring) {
					return -1;
				}
				if (!aHasSubstring && bHasSubstring) {
					return 1;
				}
				return a.value.localeCompare(b.value);
			}
			return a.distance - b.distance;
		});
	const filteredAndSorted = new Array(distances.length);
	for (let i = 0; i < distances.length; i++) {
		filteredAndSorted[i] = objects[distances[i].index];
	}

	return filteredAndSorted;
}

export function ellipsis(str: string, maxLength: number): string {
	const ellipsisStr = "...";
	if (str.length <= maxLength) {
		return str;
	}
	return str.slice(0, maxLength - ellipsisStr.length) + ellipsisStr;
}

/**
 * function that return a composite string given an object
 * example:
 *
 * ```ts
 * const obj = { name: "mario", surname: "rossi" };
 * const id  = generateIdsFromObjectFields(obj)(["name", "surname"])
 * console.log(id)
 *
 * //logs mario-rossi
 * ```
 * @param obj
 * @returns
 */
export function generateIdsFromObjectFields<T>(obj: T) {
	return (keys: Array<keyof T>): string => {
		return keys.map((k) => obj[k]).join("-");
	};
}

export function maybeUppercase<T extends string | undefined | null>(
	str: T,
): T extends string ? Uppercase<T> : undefined {
	return str?.toUpperCase() as T extends string ? Uppercase<T> : undefined;
}

export const objectTextSearchMatchFns = {
	keyword<T extends object>(instrument: T, query: string): boolean {
		const stringifiedRow = String(Object.values(instrument));
		return textSearchMatchFns.keyword(stringifiedRow, query);
	},
	substring<T extends object>(instrument: T, query: string): boolean {
		const stringifiedRow = String(Object.values(instrument)).toLowerCase();
		return textSearchMatchFns.substring(stringifiedRow, query);
	},
};

export const textSearchMatchFns = {
	keyword(text: string, query: string): boolean {
		const textLower = text.toLowerCase();
		const words = query.toLowerCase().split(" ");
		return words.every((word) => textLower.includes(word));
	},
	substring(text: string, query: string): boolean {
		return text.toLowerCase().includes(query.toLowerCase());
	},
};
