import { createAtom } from "$root/third-party-integrations/zustand";

export type PlatformLayoutOptions = {
	sideBar: boolean;
	backgroundImage?: string;
	mode: "default" | "print";
};

export const usePlatformLayoutOptions = createAtom<PlatformLayoutOptions>({
	sideBar: true,
	mode: "default",
});
export const usePlatformLayoutOptionsValue = (): PlatformLayoutOptions => usePlatformLayoutOptions((x) => x.value);
export const usePlatformLayoutOptionsState = (): {
	platformLayoutOptions: PlatformLayoutOptions;
	setPlatformLayoutOptions: (newValue: PlatformLayoutOptions) => void;
} => usePlatformLayoutOptions((x) => ({ platformLayoutOptions: x.value, setPlatformLayoutOptions: x.set }));
