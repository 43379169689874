import type { CustomMetricsCustomPortfolioExAnteMetricsEnum, PortfolioExAnteMetric } from "$root/api/api-gen";
import {
	InvestmentEnhancementReportsControllerApiFactory,
	InvestmentReportsControllerApiFactory,
} from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { WidgetStatus, portfolioWidgetMissingDataReason } from "$root/pages/PortfolioDetails/PortfolioWidgetStatus";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { qualifier } from "$root/utils/qualifiers";
import type { ContextContent } from "$root/utils/react-extra";
import { withContext } from "$root/utils/react-extra";
import { PortfolioContext } from "$root/widgets-architecture/contexts/portfolio";
import { Row, Text } from "@mdotm/mdotui/components";
import { Map } from "immutable";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { PortfolioMetricsBase, ReapplySphereMetricsIconTooltip } from "../PortfolioMetrics/PortfolioMetricsBase";

export const exanteMetricsBlockBaseQueryKey = "Exante H Metrics";
const ExanteMetricsBlock = (props: ContextContent<typeof PortfolioContext>) => {
	const uuid = props.portfolio?.uuid;
	const enhanced = props.enhanced;
	const benchmarkId = props.selectedBenchmark ?? props.portfolio?.primaryBenchmarkIdentifier;
	const { t } = useTranslation();
	const reportInvestmentApi = useApiGen(InvestmentReportsControllerApiFactory);
	const reportEnhanceApi = useApiGen(InvestmentEnhancementReportsControllerApiFactory);

	const isCalculating = useMemo(
		() =>
			(props.portfolio?.action === "UPLOAD" || props.portfolio?.action === "CREATION") &&
			props.portfolio?.status === "CALCULATING",
		[props.portfolio?.action, props.portfolio?.status],
	);

	return (
		<PortfolioMetricsBase
			hasBenchmark={Boolean(benchmarkId)}
			title={({ lastImportDate, hasCustomMetrics }) => (
				<Row alignItems="center" gap={8}>
					<Text
						type="Body/XL/Bold"
						title={t("EXANTE_METRICS.TITLE")}
						classList="truncate"
						data-qualifier={qualifier.widgets.portfolioExAnteMetric.name}
					>
						{t("EXANTE_METRICS.TITLE")}
					</Text>
					{lastImportDate && hasCustomMetrics && (
						<ReapplySphereMetricsIconTooltip lastUpdate={lastImportDate} uuid={uuid} />
					)}
				</Row>
			)}
			widgetTooltip={t("EXANTE_METRICS.TOOLTIP")}
			noDataText={t("EXANTE_METRICS.NO_DATA")}
			queryKey={[
				exanteMetricsBlockBaseQueryKey,
				uuid,
				benchmarkId,
				props.portfolio?.status,
				props.reportExcutionCounter,
			]}
			/* saveHandler={async () => {
				await sleep(1000); // TODO: actual api call
			}} */
			metricsProvider={async () => {
				const { portfolioExAnteMetrics, customPortfolioExAnteMetrics, metadataLastUpdate } = enhanced
					? await axiosExtract(reportEnhanceApi.getPortfolioExAnteMetrics1(uuid ?? "", benchmarkId ?? ""))
					: await axiosExtract(reportInvestmentApi.getPortfolioExAnteMetrics(uuid ?? "", benchmarkId ?? ""));

				if (!portfolioExAnteMetrics || isCalculating) {
					return {
						data: undefined,
						widgetStatus: portfolioWidgetMissingDataReason(props.portfolio!, "ExanteContributionVolatility"),
					};
				}

				const customMetricsMap = Map<CustomMetricsCustomPortfolioExAnteMetricsEnum, PortfolioExAnteMetric>(
					customPortfolioExAnteMetrics?.flatMap((metric) => (metric.type ? [[metric.type, metric]] : [])),
				);

				const metrics = portfolioExAnteMetrics?.flatMap((metric) =>
					metric.type
						? [
								{
									label: t(`EXANTE_METRICS.METRICS.${metric.type}`),
									name: metric.type,
									visible: true,
									current: customMetricsMap.get(metric.type)?.current ?? metric.current,
									benchmark: customMetricsMap.get(metric.type)?.benchmark ?? metric.benchmark,
									proposal: metric.proposal,
									isCurrentCustom: customMetricsMap.get(metric.type)?.current != null,
									isBenchmarkCustom: customMetricsMap.get(metric.type)?.benchmark != null,
								},
						  ]
						: [],
				);

				return {
					data: { metrics, lastImportDate: metadataLastUpdate, hasCustomMetrics: customMetricsMap.size > 0 },
					widgetStatus: WidgetStatus.READY,
				};
			}}
		/>
	);
};

export default withContext(PortfolioContext)(ExanteMetricsBlock);
