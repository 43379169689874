import { ReportHorizon, type CommentaryTemplateDto, type RealizedMetricsDto } from "$root/api/api-gen";
import { createPersistentAtom } from "$root/third-party-integrations/zustand";
import type { Updater } from "$root/utils/functions";
import { typedObjectValues } from "$root/utils/objects";
import type { Option } from "@mdotm/mdotui/components";

export type AllowedModules = Extract<
	keyof CommentaryTemplateDto,
	| "instrumentInsightsBlock"
	| "metricsBlock"
	| "marketOutlook"
	| "portfolioCompositionBlock"
	| "dynamicsBlock"
	| "performanceAnalysisBlock"
>;

export const defaultSelectableHorizonRealisedMetrics = [
	{ metricHorizon: "ONE_MONTH", enabled: false },
	{ metricHorizon: "THREE_MONTHS", enabled: false },
	{ metricHorizon: "SIX_MONTHS", enabled: false },
	{ metricHorizon: "ONE_YEAR", enabled: false },
	{ metricHorizon: "YEAR_TO_DATE", enabled: false },
	{ metricHorizon: "FROM_INCEPTION", enabled: false },
] satisfies Array<Required<RealizedMetricsDto>>;

export const selectableHorizonRealisedMetricsOptions = defaultSelectableHorizonRealisedMetrics.map((horizion) => ({
	label: horizion.metricHorizon,
	value: horizion.metricHorizon,
})) satisfies Array<Option<ReportHorizon>>;

export const selectablePerformanceAnalysisHorizon = typedObjectValues(ReportHorizon).map((horizon) => ({
	label: horizon,
	value: horizon,
})) satisfies Array<Option<ReportHorizon>>;

export const stableDefaultConfiguration: {
	[K in AllowedModules]: (
		current?: CommentaryTemplateDto[K],
	) => Omit<Required<NonNullable<CommentaryTemplateDto[K]>>, "moduleStyling"> & { moduleStyling?: string };
} = {
	dynamicsBlock: (module) => ({
		enabled: module?.enabled ?? true,
		moduleStyling: module?.moduleStyling,
		mainInstrument: module?.enabled ?? false,
		relevanceThreshold: module?.relevanceThreshold ?? 0,
	}),
	instrumentInsightsBlock: (module) => ({
		enabled: module?.enabled ?? true,
		moduleStyling: module?.moduleStyling,
		commentedInstrument: module?.commentedInstrument ?? { enabled: true, threshold: 3 },
		deletedInstrument: module?.deletedInstrument ?? { enabled: true, threshold: 3 },
		instrumentWithNegativeDeltaWeight: module?.instrumentWithNegativeDeltaWeight ?? { enabled: true, threshold: 3 },
		instrumentWithPositiveDeltaWeight: module?.instrumentWithPositiveDeltaWeight ?? { enabled: true, threshold: 3 },
		newInstrument: module?.newInstrument ?? { enabled: true, threshold: 3 },
		returnsOnSingleInstrument: module?.returnsOnSingleInstrument ?? false,
	}),
	marketOutlook: (module) => ({
		enabled: module?.enabled ?? true,
		moduleStyling: module?.moduleStyling,
	}),
	metricsBlock: (module) => ({
		enabled: module?.enabled ?? true,
		moduleStyling: module?.moduleStyling,
		varVolatilityRiskConstraint: module?.varVolatilityRiskConstraint ?? false,
		riskBudgetOnVarVolatility: module?.riskBudgetOnVarVolatility ?? false,
		trackingError: module?.trackingError ?? false,
		riskBudgetTrackingError: module?.riskBudgetTrackingError ?? false,
		exAnteVar: module?.exAnteVar ?? false,
		exAnteDiversificationRatio: module?.exAnteDiversificationRatio ?? false,
		exAnteAnnualizedVolatility: module?.exAnteAnnualizedVolatility ?? false,
		exAnteVolatilityContribution: module?.exAnteVolatilityContribution ?? false,
		riskFactors: module?.riskFactors ?? false,
		exAnteMaxDrawDown: module?.exAnteMaxDrawDown ?? false,
		exAnteEfficiencyRatio: module?.exAnteEfficiencyRatio ?? false,
		exAnteAnnualizedReturn: module?.exAnteAnnualizedReturn ?? false,
		compareWithBenchmark: module?.compareWithBenchmark ?? false,
		efficiencyRatio: module?.efficiencyRatio ?? {
			realisedMetrics: defaultSelectableHorizonRealisedMetrics,
			enabled: false,
		},
		maxDrawDown: module?.maxDrawDown ?? { realisedMetrics: defaultSelectableHorizonRealisedMetrics, enabled: false },
		performances: module?.performances ?? { realisedMetrics: defaultSelectableHorizonRealisedMetrics, enabled: false },
		sortino: module?.sortino ?? { realisedMetrics: defaultSelectableHorizonRealisedMetrics, enabled: false },
		volatility: module?.volatility ?? { realisedMetrics: defaultSelectableHorizonRealisedMetrics, enabled: false },
	}),
	performanceAnalysisBlock: (module) => ({
		enabled: module?.enabled ?? true,
		showAttribution: module?.showAttribution ?? false,
		showContribution: module?.showContribution ?? false,
		positiveContributionContributors: module?.positiveContributionContributors ?? { enabled: false, number: 3 },
		negativeContributionContributors: module?.negativeContributionContributors ?? { enabled: false, number: 3 },
		qualitativeOnly: module?.qualitativeOnly ?? false,
		performanceHorizon: module?.performanceHorizon ?? "FROM_INCEPTION",
		moduleStyling: module?.moduleStyling,
	}),
	portfolioCompositionBlock: (module) => ({
		enabled: module?.enabled ?? true,
		moduleStyling: module?.moduleStyling,
		commentConstraint: module?.commentConstraint ?? false,
		commentOnBenchmark: module?.commentOnBenchmark ?? false,
		relevanceThresholdForMicroAcCommented: module?.relevanceThresholdForMicroAcCommented ?? 0.3,
		showMainInstrumentForMacroAc: module?.showMainInstrumentForMacroAc ?? true,
	}),
};

export const stableEmptyCommentaryTemplate: CommentaryTemplateDto = {
	context: undefined,
	default: false,
	dynamicsBlock: stableDefaultConfiguration.dynamicsBlock({ enabled: false }),
	instrumentInsightsBlock: stableDefaultConfiguration.instrumentInsightsBlock({ enabled: false }),
	marketOutlook: stableDefaultConfiguration.marketOutlook({ enabled: false }),
	metricsBlock: stableDefaultConfiguration.metricsBlock({ enabled: false }),
	performanceAnalysisBlock: stableDefaultConfiguration.performanceAnalysisBlock({ enabled: false }),
	portfolioCompositionBlock: stableDefaultConfiguration.portfolioCompositionBlock({ enabled: false }),
	name: "Untitled template",
	structure: undefined,
	toneOfVoice: undefined, //TODO: ritornare un enum
	language: "ENGLISH", //TODO: ritornare un enum
};

export type StableConfiguration = { [K in AllowedModules]: ReturnType<(typeof stableDefaultConfiguration)[K]> };

type CommentaryAnimation = {
	objectId: string;
	uuid?: string;
};

const initProgress: Array<CommentaryAnimation> = [];
export const commentaryAnimationStore = createPersistentAtom<Array<CommentaryAnimation>>(
	initProgress,
	"commentary-animations",
);

export function commentaryAnimationState(): {
	setCommentaryAnimation: (newValue: CommentaryAnimation[] | Updater<CommentaryAnimation[]>) => void;
	commentaryAnimation: Array<CommentaryAnimation>;
} {
	const { set, value } = commentaryAnimationStore();
	return { setCommentaryAnimation: set, commentaryAnimation: value };
}

export function getCommentaryAnimationByObjectId(objectId: string): CommentaryAnimation | undefined {
	const animations = commentaryAnimationStore((animation) => animation.value);
	return animations.find((animation) => animation.objectId === objectId);
}
