import type {
	InvestmentImportResponse,
	MarketViewImport,
	TemplateImportType,
	MarketViewConversionNamedItems,
} from "$root/api/api-gen";
import { IntegrationsControllerApiFactory, IntegrationMarketViewControllerApiFactory } from "$root/api/api-gen";
import { reportPlatformError } from "$root/api/error-reporting";
import { useApiGen } from "$root/api/hooks";
import type { Converted, DropzoneState } from "$root/components/Upload/BaseDropzoneBlock";
import BaseDropzoneBlock from "$root/components/Upload/BaseDropzoneBlock";
import { platformToast } from "$root/notification-system/toast";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { downloadContentDisposition } from "$root/utils/files";
import { parallelize, parallelizeWithAllSettled } from "$root/utils/promise";
import { capitalizeString } from "$root/utils/strings";
import type { DialogProps, FormProps, Option } from "@mdotm/mdotui/components";
import {
	ActionText,
	Banner,
	Button,
	Collapsible,
	DefaultCollapsibleHeader,
	Dialog,
	DialogFooter,
	FormField,
	Select,
	SubmitButton,
} from "@mdotm/mdotui/components";
import type { SpawnResult } from "@mdotm/mdotui/react-extensions";
import { adaptAnimatedNodeProvider, ForEach, spawn } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { unpromisify } from "@mdotm/mdotui/utils";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import XLSX from "xlsx";

type MarketViewImportConverterType = "SPHERE_TEMPLATE_CONVERTER"; // TODO: replace with appropriate import once API is ready
// type MarketViewImportItems = any; // TODO: replace with appropriate import once API is ready

const concurrency = 32;
function ConversionBlock({
	converter,
	status,
	converted,
	onSubmit,
	onChangeStatus,
}: {
	status: DropzoneState;
	onChangeStatus(status: DropzoneState): void;
	converter: MarketViewImportConverterType;
	onSubmit(conversions: Array<Converted<MarketViewConversionNamedItems>>): void;
	converted: Array<Converted<MarketViewConversionNamedItems>>;
}) {
	const { t } = useTranslation();
	const integrationsApi = useApiGen(IntegrationMarketViewControllerApiFactory);

	const reset = useCallback(() => {
		onChangeStatus("empty");
		onSubmit([]);
	}, [onChangeStatus, onSubmit]);

	const onChange = useCallback(
		async (files: File[], type: MarketViewImportConverterType) => {
			try {
				reset();
				onChangeStatus("loading");
				const filesToConverter = files.map(
					(file) => () => axiosExtract(integrationsApi.convertMarketViewCheckFrom(type, file)),
				);
				const responseStack = await parallelizeWithAllSettled(filesToConverter, { concurrency });
				const assocFileWithConversion = files.map((file, i) => ({
					filename: file.name,
					conversion: responseStack[i].status === "fulfilled" ? responseStack[i].value : undefined,
				}));

				const succededResponses = assocFileWithConversion.filter(
					({ conversion }) => conversion !== undefined,
				) as Converted<MarketViewConversionNamedItems>[];

				const failedResponses = assocFileWithConversion.filter(
					({ conversion }) => conversion === undefined,
				) as Converted<undefined>[];

				if (failedResponses.length > 0) {
					failedResponses.map((x) =>
						platformToast({
							children: `Failed to convert ${x.filename ?? "-"}`,
							severity: "error",
							icon: "Portfolio",
						}),
					);
				}

				if (succededResponses.length > 0) {
					onSubmit(succededResponses);
				}

				if (failedResponses.length === succededResponses.length) {
					onChangeStatus("error");
				} else {
					onChangeStatus("success");
				}
			} catch (error) {
				onChangeStatus("error");

				throw error;
			}
		},
		[integrationsApi, onChangeStatus, onSubmit, reset],
	);

	const failedConversion = useMemo(() => converted.filter(({ conversion }) => conversion === undefined), [converted]);

	return (
		<>
			<BaseDropzoneBlock
				converted={converted}
				disabled={status === "loading"}
				onDelete={reset}
				status={status}
				onChange={(x) => {
					if (x === null) {
						return reset();
					}

					unpromisify(() => onChange(x, converter))();
				}}
			/>
			{status === "loading" && (
				<div className="my-4">
					<Banner severity="info">{t("PORTFOLIOS.UPLOAD_DURATION_LIMIT")}</Banner>
				</div>
			)}
			{failedConversion.length > 0 && (
				<div className="mb-4">
					<Banner severity="warning" title="Failed to convert these files">
						{failedConversion.map(({ filename }) => filename).join(", ")}
					</Banner>
				</div>
			)}
		</>
	);
}

const converterToTemplateType = {
	SPHERE_TEMPLATE_CONVERTER: "SPHERE_MARKET_VIEW_PTF_IMPORT",
} satisfies { [key in MarketViewImportConverterType]: TemplateImportType };

type MarketViewImportDialogProps = {
	converters: Array<MarketViewImportConverterType>;
	onImportFinished(params: {
		imported: Array<InvestmentImportResponse>;
		failed: Array<{ investment: MarketViewImport; err: unknown }>;
	}): void;
	qualifier?: string;
	show: boolean;
	onClose(): void;
	onAnimationStateChange?: DialogProps["onAnimationStateChange"];
};

function MarketViewImport({
	converters,
	onImportFinished,
	show,
	onClose,
	onAnimationStateChange,
}: MarketViewImportDialogProps): JSX.Element {
	const { t } = useTranslation();
	const options = useMemo(
		() =>
			converters.map(
				(converter): Option<MarketViewImportConverterType> => ({
					label: capitalizeString(t(`IMPORT.${converter}`).toLowerCase()),
					value: converter,
				}),
			),
		[converters, t],
	);

	const [selectedConverter, setSelectedConverter] = useState(converters[0]);
	const [converted, setConverted] = useState<Array<Converted<MarketViewConversionNamedItems>>>([]);
	const [dropzoneStatus, setDropzoneStatus] = useState<DropzoneState>("empty");

	const flatConversion = useMemo(() => converted.flatMap((x) => x.conversion.conversions ?? []), [converted]);

	const conversionWithErrors = useMemo(
		() => converted.filter((x) => x.conversion.errors && x.conversion.errors.length > 0),
		[converted],
	);

	const existingMarketViews = useMemo(() => {
		return flatConversion.filter(({ exist }) => exist);
	}, [flatConversion]);

	const disabled = useMemo(
		() => conversionWithErrors.length > 0 || converted.length === 0 || existingMarketViews.length === converted.length,
		[conversionWithErrors.length, converted.length, existingMarketViews.length],
	);

	const integrationsApiV2 = useApiGen(IntegrationMarketViewControllerApiFactory);

	const onSubmitAsync = useCallback<FormProps<void>["onSubmitAsync"]>(
		async (_, { progressCb }) => {
			const fileToSubmit = flatConversion.flatMap((investments) =>
				investments.marketViewImport && !investments.exist ? [investments.marketViewImport] : [],
			);
			if (fileToSubmit.length === 0) {
				platformToast({
					children: "No market view imported",
					severity: "info",
					icon: "Dowload",
				});
				onImportFinished({ failed: [], imported: [] });
				return;
			}

			let progress = 0;
			const failed: { investment: MarketViewImport; err: unknown }[] = [];
			const imported: InvestmentImportResponse[] = [];
			const callStack = fileToSubmit.map(
				(investment) => () =>
					integrationsApiV2
						.importMarketViewAsync(investment)
						.then((resp) => imported.push(resp.data))
						.catch((err) => failed.push({ investment, err }))
						.finally(() => {
							progress++;
							progressCb(progress / callStack.length);
						}),
			);
			await parallelize(callStack, { concurrency });

			if (failed.length === 0) {
				platformToast({
					children: "Sphere has taken over your request",
					severity: "info",
					icon: "Dowload",
				});
			} else if (failed.length === callStack.length) {
				platformToast({
					children: "No market view imported",
					severity: "error",
					icon: "Dowload",
				});
			} else {
				platformToast({
					children: `Imported ${callStack.length - failed.length} out of ${callStack.length} market views`,
					severity: "warning",
					icon: "Dowload",
				});
			}

			onImportFinished({ imported, failed });
		},
		[flatConversion, integrationsApiV2, onImportFinished],
	);

	const handleDownloadErrorReport = useCallback((report: Array<Converted<MarketViewConversionNamedItems>>) => {
		const xlsSheet = report.flatMap(
			({ conversion, filename }) =>
				conversion.errors?.map(({ errorMessage, investmentName }: any /* TODO: remove `any` */) => ({
					File: filename,
					Portfolio: investmentName,
					Message: errorMessage,
				})) ?? [],
		);

		const reportWS = XLSX.utils.json_to_sheet(xlsSheet);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, reportWS, "Report Errors");
		XLSX.writeFile(wb, "Errors file.xlsx");
	}, []);

	const integrationsApi = useApiGen(IntegrationsControllerApiFactory);
	const handleDownloadTemplate = useCallback(
		(convertTo: MarketViewImportConverterType) =>
			integrationsApi
				.getUploadTemplate(converterToTemplateType[convertTo], {
					responseType: "blob",
				})
				.then(downloadContentDisposition)
				.catch((e) => {
					platformToast({
						children: "Oops, Something went wrong, please re-try",
						severity: "error",
						icon: "Portfolio",
					});
					reportPlatformError(e, "ERROR", "market-views", { message: "unable to download template" });
					throw e;
				}),
		[integrationsApi],
	);

	return (
		<Dialog
			size="large"
			show={show}
			onClose={onClose}
			onSubmitAsync={onSubmitAsync}
			onAnimationStateChange={onAnimationStateChange}
			header="Import file"
			classList="max-h-[830px]"
			footer={
				<DialogFooter
					primaryAction={
						<SubmitButton palette="primary" disabled={disabled}>
							{t("BUTTON.SAVE")}
						</SubmitButton>
					}
					neutralAction={
						<Button palette="tertiary" onClick={onClose}>
							{t("BUTTON.CANCEL")}
						</Button>
					}
				/>
			}
		>
			<div className="mb-4">
				<FormField label="File format">
					{(forward) => (
						<Select
							{...forward}
							options={options}
							value={selectedConverter}
							onChange={(converter) => {
								setConverted([]);
								setDropzoneStatus("empty");
								if (converter) {
									setSelectedConverter(converter);
								}
							}}
							classList="w-[300px]"
						/>
					)}
				</FormField>
			</div>
			<ol className="list-decimal ml-4 mb-4">
				<li>
					Download the <ActionText onClickAsync={() => handleDownloadTemplate(selectedConverter)}>template</ActionText>
				</li>
				<li>Insert your market view preferences</li>
				<li>Upload the edited template. Only files formatted like the template will be accepted.</li>
			</ol>
			<ConversionBlock
				converted={converted}
				onSubmit={setConverted}
				status={dropzoneStatus}
				onChangeStatus={setDropzoneStatus}
				converter={selectedConverter}
			/>

			{existingMarketViews.length > 0 && (
				<div className="mb-4">
					<Banner severity="warning" title="Existing market view">
						{/* <p className="mb-2">Some market view already exists.</p> */}
						<ForEach collection={existingMarketViews.slice(0, 3)}>
							{({ item }) => <span>{item.marketViewImport?.marketViewName}</span>}
						</ForEach>
						{existingMarketViews.length > 3 && <span className="font-semibold">...more</span>}
					</Banner>
				</div>
			)}

			{!disabled && dropzoneStatus === "success" && (
				<div className="mb-4">
					<Banner severity="success" title="File uploaded correctly">
						Your market view has been converted by Sphere. Click &quot;
						<strong>Save</strong>&quot; to start analyzing and processing your instruments.
					</Banner>
				</div>
			)}

			{conversionWithErrors.length > 0 && (
				<Banner severity="error" title="Please review the template format and upload the file again" classList="mb-4">
					<p>The uploaded files have some formatting errors</p>
					{"\n"}
					<ActionText as="button" onClick={() => handleDownloadErrorReport(conversionWithErrors)}>
						Download errors file
					</ActionText>
				</Banner>
			)}

			<ForEach collection={conversionWithErrors}>
				{({ item }) => (
					<Collapsible
						header={(params) => (
							<DefaultCollapsibleHeader
								{...params}
								classList={{
									[`!bg-transparent h-[40px] flex items-center border-b border-[${themeCSSVars.palette_N200}]`]: true,
								}}
							>
								{item.filename}
							</DefaultCollapsibleHeader>
						)}
					>
						<ForEach collection={item.conversion.errors ?? []}>
							{({ item: e }) => (
								<div className="h-[40px] border-b border-[#eeeef1] transition-[background] data-[forceHover]:bg-[#EFF0F3] hover:bg-[#EFF0F3] flex space-x-2 items-center px-2">
									<div className="w-14">
										<p className="pr-1 truncate">{e.investmentName}</p>
									</div>
									<p className="pr-1 truncate">{e.errorMessage}</p>
								</div>
							)}
						</ForEach>
					</Collapsible>
				)}
			</ForEach>
		</Dialog>
	);
}

export type SpawnMarketViewImportDialogParams = Omit<MarketViewImportDialogProps, "onClose" | "show">;
export function spawnMarketViewImportDialog(params: SpawnMarketViewImportDialogParams): SpawnResult<void> {
	return spawn<void>(
		adaptAnimatedNodeProvider(({ resolve, show, onHidden }) => (
			<MarketViewImport
				{...params}
				show={show}
				onAnimationStateChange={(state) => state === "hidden" && onHidden()}
				onClose={() => resolve()}
				onImportFinished={(...forward) => {
					params.onImportFinished(...forward);
					resolve();
				}}
			/>
		)),
	);
}
export default MarketViewImport;
