import { useDebouncedNameUniquenessChecker } from "$root/functional-areas/named-entities/uniqueness";
import { UserCancelledError } from "$root/utils/errors";
import type { Abortable } from "$root/utils/promise";
import { useQueryNoRefetch } from "$root/utils/react-query";
import type { DialogBoxSize, DialogProps } from "@mdotm/mdotui/components";
import {
	Button,
	CircularProgressBar,
	Dialog,
	FormField,
	StackingContext,
	SubmitButton,
	TextInput,
} from "@mdotm/mdotui/components";
import type { MaybePromise, TrackableAsyncFn } from "@mdotm/mdotui/headless";
import type { NodeOrFn } from "@mdotm/mdotui/react-extensions";
import {
	WrapIfStrOrNumOrEmpty,
	adaptAnimatedNodeProvider,
	renderNodeOrFn,
	spawn,
	useUniqueDOMId,
} from "@mdotm/mdotui/react-extensions";
import type { MaybeFn } from "@mdotm/mdotui/utils";
import { maybeCall } from "@mdotm/mdotui/utils";
import { useState, type ReactNode } from "react";

export type EntityManagementBaseDialogCommon<T> = {
	children?: ReactNode;
	header?: string | ReactNode;
	confirmButton?: string | NodeOrFn;
	inputLabel?: string;
	placeholder?: string;
	onSubmitAsync: TrackableAsyncFn<T, [string]>;
	cancelButton?: string | NodeOrFn<{ onClick(): void }>;
	size?: DialogBoxSize;
	currentName?: string;
	/** Human readable name */
	entityType?: string;
	suggestedName?: string;
	checkIfNameIsAvailable?: MaybeFn<MaybePromise<boolean>, [string, Abortable | undefined]>;
};

export type SpawnEntityManagementBaseDialogParams<T> = EntityManagementBaseDialogCommon<T> & {
	zIndex?: number;
};

export function spawnEntityManagementBaseDialog<T>(params: SpawnEntityManagementBaseDialogParams<T>): Promise<T> {
	const { zIndex: overrideZindex, onSubmitAsync, ...forward } = params ?? {};

	return spawn<T>(
		adaptAnimatedNodeProvider(({ resolve, reject, show, onHidden }) => (
			<StackingContext.Consumer>
				{({ zIndex }) => (
					<StackingContext.Provider value={{ zIndex: (overrideZindex ?? zIndex) + 1 }}>
						<EntityManagementBaseDialog
							show={show}
							onAnimationStateChange={(state) => state === "hidden" && onHidden()}
							onClose={() => reject(new UserCancelledError())}
							onSubmitAsync={async (...p) => {
								resolve(await onSubmitAsync(...p));
							}}
							{...forward}
						/>
					</StackingContext.Provider>
				)}
			</StackingContext.Consumer>
		)),
	).promise;
}

export type EntityManagementBaseDialogProps<T> = EntityManagementBaseDialogCommon<T> & {
	show: boolean;
	onClose?(): void;
	onAnimationStateChange?: DialogProps["onAnimationStateChange"];
};

export function EntityManagementBaseDialog<T>(props: EntityManagementBaseDialogProps<T>): JSX.Element {
	const [name, setName] = useState(props.suggestedName ?? props.currentName ?? "");
	const allNamesAreValid = !props.checkIfNameIsAvailable;
	const uniquenessQueryId = useUniqueDOMId();
	const { checkIfNameIsAvailable } = useDebouncedNameUniquenessChecker({
		currentName: props.currentName,
		isNameAvailableApi: (n, opts) => maybeCall(props.checkIfNameIsAvailable ?? true, n, opts),
	});
	const isQueryEnabled = Boolean(name && !allNamesAreValid);
	const isUniqueQuery = useQueryNoRefetch({
		enabled: isQueryEnabled,
		queryKey: ["EntityManagementBase", uniquenessQueryId, name],
		queryFn: () => checkIfNameIsAvailable(name),
	});
	const isNameAvailable = allNamesAreValid || (isUniqueQuery.data ?? true);

	return (
		<Dialog
			size={props.size ?? "small"}
			show={props.show}
			onAnimationStateChange={props.onAnimationStateChange}
			onClose={props.onClose}
			onSubmitAsync={(_, opts) => props.onSubmitAsync?.(name, opts)}
			header={props.header ?? ""}
			footer={
				<div className="flex justify-between">
					<WrapIfStrOrNumOrEmpty
						wrapper={(content) => (
							<Button
								palette="tertiary"
								size="small"
								type="button"
								onClick={() => props.onClose?.()}
								data-qualifier="EntityManagementDialog/CancelButton"
							>
								{content}
							</Button>
						)}
					>
						{renderNodeOrFn(props.cancelButton ?? "Cancel", { onClick: () => props.onClose?.() })}
					</WrapIfStrOrNumOrEmpty>
					<WrapIfStrOrNumOrEmpty
						wrapper={(content) => (
							<SubmitButton
								data-qualifier="EntityManagementDialog/ConfirmButton"
								palette="primary"
								size="small"
								disabled={(isQueryEnabled && isUniqueQuery.isLoading) || !name || !isNameAvailable}
							>
								{content}
							</SubmitButton>
						)}
					>
						{renderNodeOrFn(props.confirmButton ?? "Proceed")}
					</WrapIfStrOrNumOrEmpty>
				</div>
			}
		>
			<FormField label={props.inputLabel} error={!name || isNameAvailable ? undefined : "Name not available"}>
				{(forward) => (
					<TextInput
						{...forward}
						innerRef={(e) => e?.setAttribute("data-qualifier", "EntityManagementDialog/NameField")}
						placeholder={props.placeholder}
						onChangeText={setName}
						value={name}
						rightContent={
							isQueryEnabled && isUniqueQuery.isLoading ? (
								<CircularProgressBar classList="w-3" value="indeterminate" />
							) : undefined
						}
					/>
				)}
			</FormField>
		</Dialog>
	);
}
