import { customObjectEntriesFn } from "$root/utils/experimental";
import { qualifier } from "$root/utils/qualifiers";
import type { TableColumn } from "@mdotm/mdotui/components";
import {
	AsyncButton,
	AutoSortTable,
	Button,
	Dialog,
	Searchable,
	Select,
	TextInput,
	useSelectableTableColumn,
} from "@mdotm/mdotui/components";
import { useMultiSelect } from "@mdotm/mdotui/headless";
import { toClassListRecord, useUnsafeUpdatedRef } from "@mdotm/mdotui/react-extensions";
import { memo, useCallback, useEffect, useMemo, useReducer } from "react";
import { useTranslation } from "react-i18next";

export function getTickerCompositeId<T>(ticker: T) {
	return (keys: Array<keyof T>): string => {
		return keys.map((k) => ticker[k]).join("-");
	};
}

type ModalRowsProps<
	T extends {
		granularity?: string;
		assetClass?: string;
		geography?: string;
		microAssetClass?: string;
		microGeography?: string;
		id: string;
	},
> = T;

type ActionUtility<T, K extends keyof T> = {
	field: K;
	data: T[K];
};

type State<T> = {
	composition: T[];
};

// customBenchmarkModal Props
export type ModalWithGroupFiltersProps<
	T extends {
		granularity?: string;
		assetClass?: string;
		geography?: string;
		microAssetClass?: string;
		microGeography?: string;
		id: string;
	},
> = {
	showModal: boolean;
	setShowModal: (prev: boolean) => void;
	matchFn(item: T, query: string): boolean;
	onLoad?: (list: T[]) => void;
	rows: T[];
	state: State<ModalRowsProps<T>>;
	columns: TableColumn<T>[];
	singleCheck?: boolean;
	customSelectableRowIds?: string[];
	filtersMode?: "multi" | "single";
	rowClassList?: string;
};

type SelectProps = { label: string; value: string; disabled?: boolean };

type CategoriesProps = {
	granularity: Array<SelectProps>;
	assetClass: Array<SelectProps>;
	geography: Array<SelectProps>;
	microAssetClass: Array<SelectProps>;
	microGeography: Array<SelectProps>;
};
// reducer customBenchmarkModal
type FiltersState = {
	granularity: Array<string>;
	assetClass: Array<string>;
	geography: Array<string>;
	microAssetClass: Array<string>;
	microGeography: Array<string>;
};

type FiltersAction =
	| ActionUtility<FiltersState, "granularity">
	| ActionUtility<FiltersState, "assetClass">
	| ActionUtility<FiltersState, "geography">
	| ActionUtility<FiltersState, "microAssetClass">
	| ActionUtility<FiltersState, "microGeography">
	| { field: "reset"; data: FiltersState };

function filterReducer(state: FiltersState, action: FiltersAction): FiltersState {
	if (action.field === "reset") {
		return action.data;
	}
	return { ...state, [action.field]: action.data };
}

const defaultFilterState: FiltersState = {
	granularity: [],
	assetClass: [],
	geography: [],
	microAssetClass: [],
	microGeography: [],
};

function _modalWithGroupFilters<
	T extends {
		granularity?: string;
		assetClass?: string;
		geography?: string;
		microAssetClass?: string;
		microGeography?: string;
		id: string;
	},
>(props: ModalWithGroupFiltersProps<T>) {
	const { state, setShowModal, matchFn, onLoad, showModal, rows, columns, customSelectableRowIds } = props;
	const [filtersState, dispatchFilterState] = useReducer(filterReducer, defaultFilterState);
	const multiSelectCtx = useMultiSelect<string>();
	const { t } = useTranslation();
	const filtersCategory = [
		"assetClass",
		"geography",
		"granularity",
		"microAssetClass",
		"microGeography",
	] satisfies Array<keyof CategoriesProps>;

	const multiSelectCtxRef = useUnsafeUpdatedRef(multiSelectCtx);
	useEffect(() => {
		if (showModal === true) {
			multiSelectCtxRef.current.setSelection(state.composition.map((d) => String(d.id)));
		}
	}, [multiSelectCtxRef, showModal, state.composition]);

	const modalRowsMemo = useMemo(() => {
		function includesGuardFn(filteredKeys: string[], field?: string) {
			if (filteredKeys.length === 0) {
				return true;
			}

			return filteredKeys.includes(field ?? "");
		}

		const filterKeyList = Object.values(filtersState).flat();

		if (filterKeyList.length === 0) {
			return rows;
		}

		return rows.filter(
			(m) =>
				includesGuardFn(filtersState.assetClass, m.assetClass) &&
				includesGuardFn(filtersState.microAssetClass, m.microAssetClass) &&
				includesGuardFn(filtersState.granularity, m.granularity) &&
				includesGuardFn(filtersState.geography, m.geography) &&
				includesGuardFn(filtersState.microGeography, m.microGeography),
		);
	}, [filtersState, rows]);

	const rawFilters = useMemo(() => {
		return filtersCategory.reduce<{
			[key in keyof CategoriesProps]: CategoriesProps[key];
		}>(
			(acc, filterCategory) => {
				acc = {
					...acc,
					[filterCategory]: Array.from(new Set(rows.filter((el) => el[filterCategory]).map((el) => el[filterCategory])))
						.sort()
						.map((el) => ({
							label: el,
							value: el,
							disabled: false,
						})),
				};
				return acc;
			},
			{ granularity: [], assetClass: [], geography: [], microAssetClass: [], microGeography: [] },
		);
	}, [filtersCategory, rows]);

	const filterCategoriesOptions = useMemo(() => {
		const currentFilters = Object.values(filtersState).flat();
		if (currentFilters.length === 0) {
			return rawFilters;
		}

		if (props.filtersMode === "multi") {
			return rawFilters;
		}

		const clickableFilters = filtersCategory.reduce<{
			[key in keyof CategoriesProps]: Array<{ value: string; counter: number }>;
		}>(
			(acc, filterCategory) => {
				acc = {
					...acc,
					[filterCategory]: Array.from(new Set(modalRowsMemo.filter((el) => el[filterCategory]))).map((el) => ({
						value: el[filterCategory],
						counter: modalRowsMemo.filter((y) => el[filterCategory] === y[filterCategory]).length,
					})),
				};
				return acc;
			},
			{ granularity: [], assetClass: [], geography: [], microAssetClass: [], microGeography: [] },
		);

		return customObjectEntriesFn(clickableFilters).reduce((acc, [filterCategoryKey, filterCategoryValue]) => {
			if (filterCategoryValue.length === 0) {
				acc[filterCategoryKey] = acc[filterCategoryKey].map((el) => ({ ...el, disabled: true }));
				return acc;
			}

			acc[filterCategoryKey] = acc[filterCategoryKey].map((filter) => {
				const exactFilter = filterCategoryValue.find((x) => filter.value === x.value);
				return {
					...filter,
					disabled: exactFilter === undefined,
					label: `${filter.value} ${exactFilter ? `(${exactFilter.counter})` : ""}`,
				};
			});

			return acc;
		}, rawFilters);
	}, [filtersCategory, filtersState, modalRowsMemo, props.filtersMode, rawFilters]);

	const handleLoad = useCallback(async () => {
		const filteredComposition = rows.filter(({ id }) => multiSelectCtx.selection.get(id));
		const filteredByExistingComposition = filteredComposition.filter(
			({ id }) => state.composition.some((el) => el.id === id) === false,
		);
		onLoad?.(filteredByExistingComposition);
		dispatchFilterState({ field: "reset", data: defaultFilterState });
	}, [multiSelectCtx.selection, onLoad, rows, state.composition]);

	const onCancel = useCallback(() => {
		dispatchFilterState({ field: "reset", data: defaultFilterState });
		setShowModal(false);
	}, [setShowModal]);

	const functionReferences = useUnsafeUpdatedRef({ dispatchFilterState });
	return (
		<Dialog
			show={showModal}
			onClose={onCancel}
			header={t("MODAL.SELECT_INDEX")}
			footer={
				<div className="flex justify-between">
					<Button
						palette="tertiary"
						size="small"
						onClick={onCancel}
						data-qualifier={qualifier.component.modalWithFiler.cancel}
					>
						{t("BUTTON.CANCEL")}
					</Button>
					<AsyncButton
						palette="primary"
						size="small"
						onClickAsync={handleLoad}
						disabled={multiSelectCtx.selection.count() === 0}
						data-qualifier={qualifier.component.modalWithFiler.submit}
					>
						{t("BUTTON.ADD")}
					</AsyncButton>
				</div>
			}
			size="xxlarge"
		>
			<Searchable matchFn={matchFn} collection={modalRowsMemo ?? []}>
				{function IndexesTable({ filtered, query, setQuery }) {
					const selectableRowIds = useMemo(() => {
						if (state.composition.length === 0) {
							return undefined;
						}
						return filtered.filter(({ id }) => !state.composition.some((r) => r.id === id)).map((d) => d.id ?? "");
					}, [filtered]);

					const {
						column: checkBoxColumn,
						rowClassList,
						toggle,
					} = useSelectableTableColumn({
						rows: filtered,
						multiSelectCtx,
						selectableRowIds: customSelectableRowIds ?? selectableRowIds,
						selectBy: ({ id }) => id ?? "",
						mode: props.singleCheck ? "radio" : "checkbox",
					});

					return (
						<>
							<div className="mb-4">
								<TextInput
									data-qualifier={qualifier.component.modalWithFiler.search}
									placeholder="Filter by name"
									value={query}
									onChangeText={setQuery}
								/>
							</div>
							<div className="flex items-center gap-4">
								<p className="font-semibold">Filter by: </p>
								<Select
									options={filterCategoriesOptions.granularity}
									value={filtersState.granularity}
									multi
									i18n={{ triggerPlaceholder: () => "Granularity" }}
									onChange={(n) => functionReferences.current.dispatchFilterState({ field: "granularity", data: n })}
									disabled={filterCategoriesOptions.granularity.length === 0}
								/>
								<Select
									options={filterCategoriesOptions.assetClass}
									value={filtersState.assetClass}
									multi
									i18n={{ triggerPlaceholder: () => "Macro asset class" }}
									onChange={(n) => functionReferences.current.dispatchFilterState({ field: "assetClass", data: n })}
									disabled={filterCategoriesOptions.assetClass.length === 0}
								/>
								<Select
									options={filterCategoriesOptions.geography}
									value={filtersState.geography}
									multi
									i18n={{ triggerPlaceholder: () => "Geography" }}
									onChange={(n) => functionReferences.current.dispatchFilterState({ field: "geography", data: n })}
									disabled={filterCategoriesOptions.geography.length === 0}
								/>
								<Select
									options={filterCategoriesOptions.microAssetClass}
									value={filtersState.microAssetClass}
									multi
									i18n={{ triggerPlaceholder: () => "Micro asset class" }}
									onChange={(n) =>
										functionReferences.current.dispatchFilterState({ field: "microAssetClass", data: n })
									}
									disabled={filterCategoriesOptions.microAssetClass.length === 0}
								/>
								<Select
									options={filterCategoriesOptions.microGeography}
									value={filtersState.microGeography}
									multi
									i18n={{ triggerPlaceholder: () => "Micro geo" }}
									onChange={(n) => functionReferences.current.dispatchFilterState({ field: "microGeography", data: n })}
									disabled={filterCategoriesOptions.microGeography.length === 0}
								/>
							</div>
							<AutoSortTable
								rows={filtered}
								rowClassList={(row, rowIndex) => ({
									...toClassListRecord(rowClassList(row, rowIndex)),
									[props.rowClassList ?? ""]: Boolean(props.rowClassList),
								})}
								columns={[checkBoxColumn, ...columns]}
								classList="max-h-[410px]"
								onRowClick={({ id }) => toggle(id)}
							/>
						</>
					);
				}}
			</Searchable>
		</Dialog>
	);
}

export const ModalWithGroupFilters = memo(_modalWithGroupFilters) as typeof _modalWithGroupFilters;
