import type { InvestmentStatuses, RichAccessControl } from "$root/api/api-gen";
import { BenchmarksControllerApiFactory } from "$root/api/api-gen";
import { reportPlatformError } from "$root/api/error-reporting";
import { createDefaultApiErrorHandler, getApiGen, runWithErrorReporting } from "$root/api/factory";
import type { EntityManagementActions } from "$root/components/spawnable/entity-management/actions";
import { spawnDeleteDialog } from "$root/components/spawnable/entity-management/delete-dialog";
import { spawnDuplicateDialog } from "$root/components/spawnable/entity-management/duplicate-dialog";
import { spawnRenameDialog } from "$root/components/spawnable/entity-management/rename-dialog";
import { platformToast } from "$root/notification-system/toast";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { t } from "i18next";
import { aclByArea } from "../acl/checkers/all";
import { useUserValue } from "../user";
import { useMemo } from "react";
import { trackMixPanelEvent } from "$root/third-party-integrations/initMixPanel";
import { ToastableError } from "$root/utils/errors";

export function useBenchmarkEntityManagementActions(
	benchmark:
		| {
				name?: string;
				uuid?: string;
				richAcl?: { acl?: RichAccessControl[] };
				status?: InvestmentStatuses;
		  }
		| null
		| undefined,
	callbacks?: {
		onRename?(uuid: string): void;
		onDelete?(uuid: string): void;
		onDuplicate?(uuid: string): void;
	},
): Partial<Omit<EntityManagementActions, "createAsync">> {
	const user = useUserValue();
	return useMemo(
		() => (!benchmark ? {} : benchmarkEntityManagementActions(user, benchmark, callbacks)),
		[callbacks, benchmark, user],
	);
}

export function benchmarkEntityManagementActions(
	user: { id: string },
	benchmark: {
		name?: string;
		uuid?: string;
		richAcl?: { acl?: RichAccessControl[] };
		status?: InvestmentStatuses;
	},
	callbacks?: {
		onRename?(uuid: string): void;
		onDelete?(uuid: string): void;
		onDuplicate?(uuid: string): void;
	},
): Partial<Omit<EntityManagementActions, "createAsync">> {
	const api = () => getApiGen(BenchmarksControllerApiFactory); // accessor for lazy evaluation
	return {
		deleteAsync:
			!aclByArea.benchmark.canDelete(user.id, benchmark?.richAcl?.acl ?? []) || benchmark?.status === "ERROR"
				? undefined
				: () =>
						spawnDeleteDialog({
							entityName: benchmark?.name ?? "",
							entityType: "benchmark",
							onDeleteAsync: createDefaultApiErrorHandler(
								async () => {
									try {
										await api().deleteBenchmark(benchmark.uuid ?? "");

										trackMixPanelEvent("Portfolio", {
											Type: "Delete",
											ID: benchmark.uuid,
											Name: benchmark.name,
										});

										platformToast({
											children: t("PUSH_NOTIFICATION.SUCCESS_DELETE_CUSTOM_BENCHMARK", { name: benchmark.name }),
											severity: "success",
											icon: "Portfolio",
										});
										callbacks?.onDelete?.(benchmark.uuid ?? "");
									} catch (error) {
										throw new ToastableError(t("SOMETHING_WENT_WRONG"), {
											cause: error,
											icon: "Portfolio",
										});
									}
								},
								{
									area: "benchmark",
									attemptedOperation: {
										message: `delete benchmark "${benchmark.uuid}"`,
										payload: JSON.stringify({ benchmark }),
									},
								},
							),
						}),
		duplicateAsync:
			!benchmark.status || invalidStatus.includes(benchmark.status)
				? undefined
				: () =>
						spawnDuplicateDialog({
							entityType: "benchmark",
							originalName: benchmark?.name ?? "",
							checkIfNameIsAvailable: (name, opts) => axiosExtract(api().isBenchmarkNameAvailable(name, opts)),
							onSubmitAsync(name) {
								return runWithErrorReporting(
									async () => {
										try {
											const uuid =
												(await axiosExtract(api().duplicateBenchmark(benchmark.uuid ?? "", name))).identifier ?? "";

											platformToast({
												children: t("PUSH_NOTIFICATION.SUCCESS_DUPLICATED_CUSTOM_BENCHMARK", { name }),
												severity: "success",
												icon: "Portfolio",
											});
											callbacks?.onDuplicate?.(uuid);

											return uuid;
										} catch (error) {
											throw new ToastableError(t("SOMETHING_WENT_WRONG"), {
												cause: error,
												icon: "Portfolio",
											});
										}
									},
									{
										area: "benchmark",
										attemptedOperation: {
											message: `duplicate benchmark "${benchmark.uuid}"`,
											payload: JSON.stringify({ benchmark }),
										},
									},
								);
							},
						}),
		renameAsync:
			!aclByArea.benchmark.canRename(user.id, benchmark?.richAcl?.acl ?? []) || benchmark?.status === "ERROR"
				? undefined
				: () =>
						spawnRenameDialog({
							entityType: "benchmark",
							currentName: benchmark?.name ?? "",
							checkIfNameIsAvailable: (name, opts) => axiosExtract(api().isBenchmarkNameAvailable(name, opts)),
							onSubmitAsync(name) {
								return runWithErrorReporting(
									async () => {
										try {
											await api().renameBenchmark(benchmark.uuid ?? "", name);

											platformToast({
												children: t("PUSH_NOTIFICATION.SUCCESS_RENAMED_CUSTOM_BENCHMARK", { name }),
												severity: "success",
												icon: "Portfolio",
											});
											callbacks?.onRename?.(name);

											return name;
										} catch (error) {
											throw new ToastableError(t("SOMETHING_WENT_WRONG"), {
												cause: error,
												icon: "Portfolio",
											});
										}
									},
									{
										area: "benchmark",
										attemptedOperation: {
											message: `rename benchmark "${benchmark.uuid}"`,
											payload: JSON.stringify({ benchmark }),
										},
									},
								);
							},
						}),
	};
}

const invalidStatus: Array<InvestmentStatuses> = [
	"ERROR",
	"REVIEW",
	"CALCULATING",
	"RETRIEVING_DATA",
	"PROPOSAL_READY",
];
