import type { InvestmentSummary } from "$root/api/api-gen";
import AuthorizationGuard from "$root/components/AuthorizationGuard";
import { ButtonWithSelect } from "$root/components/buttons/ButtonWithSelect";
import { FakeAiLoaderBase } from "$root/components/FakeAiLoader";
import type { MarkdownRendererProps } from "$root/components/MarkdownRenderer/MarkdownRenderer";
import { MarkdownRenderer } from "$root/components/MarkdownRenderer/MarkdownRenderer";
import { typedUrlForRoute, useTypedNavigation } from "$root/components/PlatformRouter/RoutesDef";
import { aclByArea } from "$root/functional-areas/acl/checkers/all";
import { PortfolioStudioSettingTabEnum } from "$root/functional-areas/portfolio-studio-settings";
import { useLocaleFormatters } from "$root/localization/hooks";
import { TransientNotification } from "$root/notification-system/transient";
import { Card } from "$root/widgets-architecture/layout/Card";
import { ActionText, Button, CircularProgressBar, Icon, Text } from "@mdotm/mdotui/components";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { useMemo } from "react";
import { PortfolioWidgetBase, WidgetStatus } from "./PortfolioWidgetStatus";
import { IconWalls } from "$root/components/IconWall";
import { platformToast } from "$root/notification-system/toast";
import {
	ReapplySphereMetricsBanner,
	usePortfolioCommentary,
} from "$root/widgets-architecture/widgets/PortfolioCommentaryMDBlock";
import { diffrenceISODate } from "$root/utils/dates";
import { START_DATE_CREATE_PROPOSAL_COMMENTARY } from "$root/utils/const";
import { ReactQueryWrapperBase } from "$root/components/ReactQueryWrapper";

export type PortfolioCommentaryProps = {
	portfolio: InvestmentSummary;
	enhanced: boolean;
	reportExcutionCounter: number;
};

export const portfolioCommentaryPageBaseQueryKey = "portfolioCommentaryPage";
// import { useTranslation } from "react-i18next";

export function PortfolioCommentary({
	portfolio,
	enhanced,
	reportExcutionCounter,
}: PortfolioCommentaryProps): JSX.Element {
	const { formatDateTime } = useLocaleFormatters();
	//animation atom progress
	const {
		cachedCommentaryStatus,
		commentaryQuery,
		commentaryTemplatesQuery,
		fakeLoaderState,
		generateCommentaryNonBlocking,
		templateOptions,
	} = usePortfolioCommentary({ portfolio: portfolio ?? {}, enhanced, reportExcutionCounter });

	const canUserGenerateNewCommentary = useMemo(() => {
		if (enhanced) {
			return diffrenceISODate(portfolio?.lastActionDate, START_DATE_CREATE_PROPOSAL_COMMENTARY) > 0;
		}

		return true;
	}, [enhanced, portfolio?.lastActionDate]);

	const generateCommentaryAction = useMemo(() => {
		if (commentaryQuery.data && portfolio) {
			return (
				<AuthorizationGuard
					permissionChecker={aclByArea.portfolio.canEditComposition}
					acl={portfolio?.richAcl?.acl ?? []}
				>
					{canUserGenerateNewCommentary && (
						<ButtonWithSelect
							options={templateOptions}
							value={commentaryQuery.data?.template?.uuid}
							disabled={
								portfolio?.status === "CALCULATING" ||
								portfolio?.status === "ERROR" ||
								commentaryQuery.data?.status === "CALCULATING" ||
								cachedCommentaryStatus === "CALCULATING"
							}
							palette="secondary"
							size="small"
							onClick={generateCommentaryNonBlocking}
							enableSearch
						>
							Generate
						</ButtonWithSelect>
					)}
				</AuthorizationGuard>
			);
		} else {
			return <></>;
		}
	}, [
		cachedCommentaryStatus,
		canUserGenerateNewCommentary,
		commentaryQuery.data,
		generateCommentaryNonBlocking,
		portfolio,
		templateOptions,
	]);

	// const reportUrl = useMemo(
	// 	() =>
	// 		commentaryQuery.data?.data?.template?.uuid && portfolio.uuid
	// 			? typedUrlForRoute("Report", {
	// 					templateId: "storyfolio",
	// 					objectId: portfolio.uuid,
	// 					enhanced: enhanced ? "true" : undefined,
	// 			  })
	// 			: undefined,
	// 	[portfolio.uuid, commentaryQuery.data?.data?.template?.uuid, enhanced],
	// );

	const { push } = useTypedNavigation();

	return (
		<>
			<TransientNotification
				variant="warning"
				dismissible={false}
				location="in-page"
				autoHide={null}
				// TODO: based on API response
				trigger={false}
			>
				Some of the descriptions for the instruments contained in this portfolio are expired.{" "}
				<Text color="inherit" type="Body/M/Bold">
					Customize your
				</Text>{" "}
				<ActionText
					color="inherit"
					type="Body/M/Bold"
					href={typedUrlForRoute("PortfolioStudioSettings", {
						tab: PortfolioStudioSettingTabEnum.InstrumentsCustomisation,
					})}
				>
					instrument descriptions
				</ActionText>
			</TransientNotification>
			<Card removeDefaultPaddings classList="min-h-[calc(100dvh_-_340px)]">
				{cachedCommentaryStatus === "CALCULATING" && <FakeAiLoaderBase state={fakeLoaderState} />}
				<ReactQueryWrapperBase
					query={commentaryQuery}
					loadingFallback={<IconWalls.CalculatingApi />}
					errorFallback="Generative AI commentary is not available at the moment. It will be generated soon!"
				>
					{(commentaryData, { isFetching }) => {
						const widgetData = (() => {
							if (
								(commentaryData.status === "CALCULATING" || portfolio?.status === "CALCULATING") &&
								!commentaryData.commentary
							) {
								return {
									data: undefined,
									widgetStatus: WidgetStatus.CALCULATING as const,
								};
							}
							if (!commentaryData.commentary) {
								return {
									data: { ...commentaryData, templates: commentaryTemplatesQuery.data ?? [] },
									widgetStatus: WidgetStatus.EMPTY as const,
								};
							}
							return {
								data: { ...commentaryData, templates: commentaryTemplatesQuery.data ?? [] },
								widgetStatus: WidgetStatus.READY as const,
							};
						})();

						return (
							<PortfolioWidgetBase
								{...widgetData}
								iconWalls={{
									empty: IconWalls.PortfolioCommentaryEmpty({
										onGenerate: async (templateId) => {
											if (templateId) {
												await generateCommentaryNonBlocking(templateId);
												return;
											}

											platformToast({
												children: "Unable to generate portfolio commentary",
												severity: "error",
												icon: "Ask-ai",
											});
										},
										generateOption: templateOptions,
										defaultValue: commentaryQuery.data?.template?.uuid,
									}),
								}}
							>
								{({
									commentary,
									commentaryDate,
									usedCustomMetrics,
									metadataLastUpdate,
									prevUsedCustomMetrics,
									template,
								}) => (
									<>
										<div
											className="flex py-2 px-4 border-b justify-between"
											style={{ borderColor: themeCSSVars.palette_N200 }}
										>
											<div className="flex items-center">
												<Text type="Body/M/Bold" color={themeCSSVars.palette_N500}>
													Template: {template?.name}
												</Text>
											</div>
											<div className="flex gap-2 items-center">
												<AuthorizationGuard requiredServices={["COMMENTARY_BUILDER"]}>
													<Button
														size="small"
														palette="tertiary"
														onClick={() =>
															push("Storyfolio/Details", {
																uuid:
																	templateOptions.find((x) => x.value === template?.uuid)?.value ??
																	templateOptions.find((x) => x.label === "Standard Template")?.value ??
																	template?.uuid ??
																	"",
															})
														}
													>
														Edit template
													</Button>
												</AuthorizationGuard>
												{generateCommentaryAction}
											</div>
										</div>

										<div className="flex flex-col items-center mt-7 pb-4">
											<div className="flex flex-col max-w-[946px]">
												<div className="flex justify-end">
													<div className="flex gap-4">
														{commentaryDate && (
															<div className="flex space-x-1 items-center">
																<Icon icon="Clock" color={themeCSSVars.palette_N300} size={16} />
																<Text type="Body/S/Book" as="p">
																	Generated on&nbsp;
																	<span>{formatDateTime(commentaryDate)}</span>
																</Text>
															</div>
														)}
													</div>
												</div>

												<div className="px-4">
													{isFetching ? (
														<div className="h-80 flex">
															<CircularProgressBar value="indeterminate" classList="m-auto" />
														</div>
													) : (
														<>
															{(usedCustomMetrics || prevUsedCustomMetrics) && (
																<ReapplySphereMetricsBanner
																	classList="my-4"
																	uuid={portfolio?.uuid}
																	metadataLastUpdate={metadataLastUpdate}
																/>
															)}
															<MarkdownRenderer componentOverrides={markdownOverrides}>
																{commentary ?? ""}
															</MarkdownRenderer>
														</>
													)}
												</div>
											</div>
										</div>
									</>
								)}
							</PortfolioWidgetBase>
						);
					}}
				</ReactQueryWrapperBase>
			</Card>
		</>
	);
}

const markdownOverrides: MarkdownRendererProps["componentOverrides"] = {
	h1: ({ node: _node, ...props }) => (
		<h1 className="text-[18px] font-bold pb-2 pt-2 " {...props}>
			{props.children}
		</h1>
	),
	h2: ({ node: _node, ...props }) => (
		<h2 className="text-[18px] font-bold pb-2 pt-2" {...props}>
			{props.children}
		</h2>
	),
	h3: ({ node: _node, ...props }) => (
		<h3 className="text-[16px] font-bold pb-2 pt-2" {...props}>
			{props.children}
		</h3>
	),
	ul: ({ node: _node, ...props }) => <ul className="list-disc pl-4 text-[16px]" {...props} />,
	ol: ({ node: _node, ...props }) => <ul className="list-decimal pl-4 text-[16px]" {...props} />,
	li: ({ node: _node, ...props }) => <li className="text-[16px]" {...props} />,
	p: ({ node: _node, ...props }) => <div className="text text-[16px] pb-2" {...props} />,
	blockquote: ({ node: _node, ...props }) => (
		<blockquote className="bg-sky-100 px-2 py-1 rounded text-[16px]" {...props} />
	),
	table: ({ node: _node, ...props }) => <table className="w-full border-collapse" {...props} />,
	thead: ({ node: _node, ...props }) => <thead {...props} />,
	tr: ({ node: _node, ...props }) => (
		<tr className={`even:bg-[#F7F8F9] border-b border-b-[color:${themeCSSVars.palette_N100}]`} {...props} />
	),
	td: ({ node: _node, ...props }) => <td className="text-left p-4 first:w-2/4" {...props} />,
	th: ({ node: _node, ...props }) => (
		<th className="text-left px-4 py-2 !font-bold !text-[12px] !uppercase text-[#667085]" {...props} />
	),
	tbody: ({ node: _node, ...props }) => <tbody {...props} />,
	code: ({ node: _node, ...props }) => <span className="text-[16px]" {...props} />,
	pre: ({ node: _node, ...props }) => <i className="text-[16px]" {...props} />,
};
