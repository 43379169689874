import { useDebouncedMemo } from "$root/utils/react-extra";
import type { DataAttributesProps } from "@mdotm/mdotui/components";
import { Button, Dialog, DialogFooter, FloatingContent, Icon, ProgressBar, TextInput } from "@mdotm/mdotui/components";
import { forwardRef, useCallback, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

export type EditTagOptions = { value: string; color: string; deletable?: boolean };
type TagButtonProps = {
	options: EditTagOptions[];
	enableDebounce?: boolean;
	onDelete?(value: string): void;
	onAdd?(value: string): void;
} & DataAttributesProps;

const EditButton = forwardRef<HTMLButtonElement, { onClick?(): void }>(function _ReadyOnlyButton({ onClick }, ref) {
	return (
		<Button palette="secondary" size="small" classList="flex gap-2" innerRef={ref} onClick={onClick}>
			<Icon icon="Edit" />
			Edit tags
		</Button>
	);
});

const tagRegex = new RegExp("^[A-Za-z0-9 ]{2,30}$");
export function EditTagButton(props: TagButtonProps): JSX.Element {
	const { options, enableDebounce, onAdd, onDelete, ...dataAttributes } = props;

	const [tagToDelete, setTagToDelete] = useState<string | undefined>(undefined);
	const [isOpen, setIsOpen] = useState(false);
	const [searchedTag, setSearchedTag] = useState("");
	const [isTagValid, setIsTagValid] = useState(true);

	const { t } = useTranslation();

	const { value: debouncedSearch, state } = useDebouncedMemo(() => searchedTag, [searchedTag], {
		debounceInterval: enableDebounce ? 800 : 0,
	});

	const filteredTags = useMemo(() => {
		return options.filter((option) => option.value.toLowerCase().includes(debouncedSearch.toLowerCase()));
	}, [options, debouncedSearch]);

	const searchedTagIsInOption = useMemo(() => {
		return options.some(({ value }) => value === debouncedSearch);
	}, [options, debouncedSearch]);

	const handleFloatingStatus = () => setIsOpen(!isOpen);

	const onChangeText = useCallback((value: string) => {
		setSearchedTag(value);
		setIsTagValid((prev) => {
			if (prev === false) {
				return true;
			}
			return prev;
		});
	}, []);

	const handleOnAdd = useCallback(
		(tagName: string) => {
			const isValidTag = tagRegex.test(tagName);
			if (isValidTag) {
				onAdd?.(tagName);
				return;
			}

			setIsTagValid(false);
		},
		[onAdd],
	);

	const handleOnDelete = useCallback(
		(tagName: string) => {
			onDelete?.(tagName);
			setTagToDelete(undefined);
		},
		[onDelete],
	);

	return (
		<>
			<FloatingContent
				open={isOpen}
				position="bottom"
				onClickAway={handleFloatingStatus}
				trigger={({ innerRef }) => <EditButton {...dataAttributes} onClick={handleFloatingStatus} ref={innerRef} />}
			>
				<div className="mt-2 pb-2 bg-white rounded-lg shadow-xl border-2 w-60">
					<div className="sticky top-0">
						<div className="flex items-center">
							<TextInput
								leftContent={<Icon icon="Search" size={16} />}
								onChangeText={onChangeText}
								placeholder="Search for a tag.."
								size="small"
								value={searchedTag}
								classList="[&>input]:border-none [&>input]:h-12 flex-1 w-[calc(100%_-_50px)]"
							/>
							<div className="flex-1">
								<Button
									size="small"
									palette="primary"
									onClick={() => handleOnAdd(searchedTag)}
									disabled={searchedTagIsInOption || state === "debouncing"}
								>
									Add
								</Button>
							</div>
						</div>
						<hr />
						{state === "debouncing" && <ProgressBar value="indeterminate" />}
					</div>
					<div className="overflow-y-auto custom-scrollbar-y max-h-48">
						{filteredTags.length === 0 && isTagValid && (
							<div className="h-10 flex items-center hover:bg-slate-100 px-4">
								<i>No tag found</i>
							</div>
						)}

						{isTagValid === false && (
							<div className="px-4 pt-2">
								<p className="mb-2 text-red-600">
									<strong>Tags can only include</strong>
								</p>
								<ul>
									<li>
										digits <span className="rounded bg-slate-200 px-1">0-9</span>
									</li>
									<li>
										letters <span className="rounded bg-slate-200 px-1">a-z</span>
									</li>
									<li>
										<span className="rounded bg-slate-200 px-1">2 - 30</span> total characters
									</li>
								</ul>
							</div>
						)}

						{isTagValid &&
							filteredTags.map((tags, i) => {
								return (
									<div className="h-10 flex items-center hover:bg-slate-100 px-4" key={i}>
										<div
											className="rounded-full px-2 py-0.5 flex gap-2 text-white"
											style={{ backgroundColor: tags.color }}
										>
											<Button unstyled classList="font-semibold">
												<p title={tags.value} className="truncate max-w-40">
													{tags.value}
												</p>
											</Button>
											{tags.deletable && (
												<Button unstyled onClick={() => setTagToDelete(tags.value)} classList="active:scale-90">
													<Icon icon="Close" size={18} />
												</Button>
											)}
										</div>
									</div>
								);
							})}
					</div>
				</div>
			</FloatingContent>

			<Dialog
				show={Boolean(tagToDelete)}
				onClose={() => setTagToDelete(undefined)}
				header={t("TAGS.DELETE_MODAL_TITLE")}
				footer={
					<DialogFooter
						primaryAction={
							<Button onClick={() => handleOnDelete(tagToDelete ?? "")} palette="primary">
								{t("BUTTON.DELETE")}
							</Button>
						}
						neutralAction={
							<Button palette="tertiary" onClick={() => setTagToDelete(undefined)}>
								{t("BUTTON.CANCEL")}
							</Button>
						}
					/>
				}
			>
				<Trans
					i18nKey="INSTRUMENT_TAGS.DELETE_MESSAGE"
					values={{ tag: tagToDelete }}
					components={{ strong: <strong /> }}
				/>
			</Dialog>
		</>
	);
}
