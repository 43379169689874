import type {
	OrderBy,
	PaginationRequest,
	PaginationResponse,
	StylableProps,
	TableColumn,
} from "@mdotm/mdotui/components";
import {
	BaseTable,
	BatchActions,
	CircularProgressBar,
	LocalOverlay,
	Pagination,
	ProgressBar,
	Select,
	Text,
	useSelectableTableColumn,
} from "@mdotm/mdotui/components";
import { instrumentColumnsMetadataByKey, useInstrumentColumnsTableV2 } from "./hooks";
import type { MaybePromise } from "@mdotm/mdotui/headless";
import { useMultiSelect } from "@mdotm/mdotui/headless";
import { useCallback, useMemo, useState } from "react";
import type { Map } from "immutable";
import { useTranslation } from "react-i18next";
import { noop, stableEmptyArray } from "@mdotm/mdotui/utils";
import { CommonItemActions } from "$root/ui-lib/interactive-collections/common-item-actions";
import { overrideClassName } from "@mdotm/mdotui/react-extensions";
import { spawnYesNoDialog } from "$root/components/spawnable/yes-no-dialog";
import {
	type IndexTicker,
	type ReviewTicker,
	type SelectableBasket,
	type SelectableBasketBasketTypeEnum,
} from "$root/api/api-gen";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { spawnInstrumentClassificationDialog } from "./instrument-classification/InstrumentClassificationDialog";
import { ReactQueryWrapperBase } from "$root/components/ReactQueryWrapper";
import { parallelize } from "$root/utils/promise";
import { DebouncedSearchInput } from "../../components/DebouncedSearchInput";
import { platformToast } from "$root/notification-system/toast";
import { useHistory } from "react-router";
import { UploadInstrumentDetailsButton } from "./UploadInstrumentDetailsButton";
import { flushSync } from "react-dom";

type AvailableFilters = {
	baskets: Array<SelectableBasket>;
	assetClasses: Array<string>;
	microAssetClasses: Array<string>;
};

export type InstrumentCustomizationTableProps = {
	rowOverrides?: Map</* ticker */ string, ReviewTicker>;
	invalidateCache?(): void;
	rowsProvider: (
		request: PaginationRequest<
			{
				//Made for pagination query but is not requested
				query?: string;
				baskets?: Array<SelectableBasket>;
				assetClasses?: Array<string>;
				microAssetClasses?: Array<string>;
				customProxy: boolean | null;
			},
			string
		>,
	) => MaybePromise<PaginationResponse<ReviewTicker>>;
	defaultBaskets?: Array<SelectableBasket>;
	availableFilters: AvailableFilters;
	onGenerateCommentary: (ticker: string, isin: string) => MaybePromise<void>;
	onSubmitCustomProxy: (tickerData: ReviewTicker) => MaybePromise<void>;
	onChangeCommentary: (ticker: string, newCommentaryText: string) => MaybePromise<void>;
	onChangeInstrumentName: (ticker: string, newName: string) => MaybePromise<void>;
} & StylableProps;

function instrumentProxy(params: { onSubmitAsync(data: ReviewTicker): MaybePromise<void> }): TableColumn<ReviewTicker> {
	return {
		header: "Custom proxy",
		align: "end",
		content: function CustomProxy(row, props) {
			return (
				<div
					className={overrideClassName("flex items-center px-2 justify-center", props.classList)}
					style={props.style}
				>
					{row.needsCustomProxy && row.delisted === false && (
						<CommonItemActions.Edit
							disabled={row.descriptionStatus === "CALCULATING"}
							onClick={() =>
								spawnInstrumentClassificationDialog({
									askForName: true,
									onSubmitAsync: async ({ categorization, indices, weights }) => {
										const proxyIndexes = indices.map<IndexTicker>((indexTicker) => ({
											...indexTicker,
											weight: indexTicker.ticker ? weights.get(indexTicker.ticker)?.toNumber() : undefined,
										}));
										const instrument = {
											...row,
											instrument: categorization.instrumentName ?? row.instrument ?? "-",
											assetClass: categorization.macroAssetClass,
											microAssetClass: categorization.microAssetClass,
											currency: categorization.instrumentCurrencyExposure,
											proxies: proxyIndexes,
										};
										await params.onSubmitAsync(instrument);
									},
									defaultInstrumentProxy: row,
								})
							}
							data-qualifier="InstrumentCustomizationTable/Table/Column(Action)/Edit"
						/>
					)}
				</div>
			);
		},
		...instrumentColumnsMetadataByKey.needsCustomProxy,
		minWidth: 90,
		width: 90,
		maxWidth: 90,
	};
}

const basketGroups: Record<SelectableBasketBasketTypeEnum | "Default", string> = {
	INVESTMENT: "Portfolios",
	UNIVERSE: "Universes",
	TEMPLATE: "Universe templates",
	TARGET_INVESTMENT: "Target potfolios",
	BENCHMARK: "Custom benchmarks",
	Default: "Others",
};

export function InstrumentCustomizationTable(props: InstrumentCustomizationTableProps): JSX.Element {
	const multiSelectCtx = useMultiSelect<string>();
	const history = useHistory();

	const [orderBy, setOrderBy] = useState<OrderBy[]>([]);
	const [skip, _setSkip] = useState(0);
	const setSkip = useCallback<typeof _setSkip>(
		(x) => {
			multiSelectCtx.reset();
			return _setSkip(x);
		},
		[multiSelectCtx],
	);
	const take = 100;

	const [filters, _setFilters] = useState<
		AvailableFilters & {
			customProxy: boolean | null;
		}
	>(() => ({
		assetClasses: [],
		baskets: props.defaultBaskets ? props.defaultBaskets : [],
		microAssetClasses: [],
		customProxy: null,
	}));

	const setFilters = useCallback<typeof _setFilters>(
		(x) => {
			setSkip(0);
			return _setFilters(x);
		},
		[setSkip],
	);

	// TODO: should we support this feature?
	// const [softDeleted, setSoftDeleted] = useState<Set<string>>(Set);

	const basketOptions = props.availableFilters.baskets
		?.filter((x) => x.basketIdentifier && x.basketName && x.basketType && x.basketType !== "TEMPLATE")
		.map((x) => ({ label: x.basketName!, value: x, group: basketGroups[x.basketType!] }));

	const macroAssetClassOptions = props.availableFilters.assetClasses
		?.filter((x) => x)
		.map((x) => ({ label: x!, value: x! }));

	const microAssetClassOptions = props.availableFilters.microAssetClasses
		?.filter((x) => x)
		.map((x) => ({ label: x!, value: x! }));

	const [query, _setQuery] = useState("");
	const setQuery = useCallback<typeof _setQuery>(
		(x) => {
			setSkip(0);
			return _setQuery(x);
		},
		[setSkip],
	);

	const rowsQuery = useQueryNoRefetch({
		queryKey: ["instrument-commentary", filters, skip, take, query, orderBy],
		queryFn: () => props.rowsProvider({ skip, take, filters: { ...filters, query }, orderBy }),
		keepPreviousData: true,
	});

	const { t } = useTranslation();
	const rows = useMemo(
		() => (rowsQuery.data?.items ?? []).map((ticker) => ({ ...ticker, ...props.rowOverrides?.get(ticker.ticker!) })),
		[props.rowOverrides, rowsQuery.data],
	);
	const {
		column: checkBoxColumn,
		rowClassList,
		toggle,
	} = useSelectableTableColumn({
		rows,
		multiSelectCtx,
		selectBy: (row) => row.ticker ?? "",
		mode: "checkbox",
	});

	const commonCols = useInstrumentColumnsTableV2("InstrumentCustomizationTable");

	const columns = useMemo<TableColumn<ReviewTicker>[]>(
		() => [
			checkBoxColumn,
			commonCols.instrument({ onSubmitAsync: props.onChangeInstrumentName }),
			commonCols.identifier,
			{ ...commonCols.assetClass, minWidth: 90, maxWidth: 120 },
			commonCols.microAssetClass,
			{
				...commonCols.commentary({
					tooltip:
						"Instrument descriptions provide detailed information about each asset, which will be used to enrich the portfolio commentary.",
					onGenerate: props.onGenerateCommentary,
					onSubmitAsync: props.onChangeCommentary,
				}),
				minWidth: 400,
				maxWidth: 600,
				width: "max-width",
			},
			{
				header: t("TABLE.HEADERS.LINKED_PORTFOLIOS"),
				content: (x) => x.linkedPortfolios ?? 0,
				minWidth: 110,
				width: 110,
				maxWidth: 110,
				align: "end",
				cellClassList: "tabular-nums",
				...instrumentColumnsMetadataByKey.linkedPortfolios,
			},
			instrumentProxy({ onSubmitAsync: props.onSubmitCustomProxy }),
			/* customProxy, */
			// {
			// 	header: (
			// 		<CommonItemActions.Settings
			// 			onClick={(e) => {
			// 				e.stopPropagation();
			// 				// TODO
			// 			}}
			// 		/>
			// 	),
			// 	content: () => <></>,
			// 	maxWidth: 32,
			// }, // TODO: this column will be released in the next sprint
		],
		[
			checkBoxColumn,
			commonCols,
			props.onChangeCommentary,
			props.onChangeInstrumentName,
			props.onGenerateCommentary,
			props.onSubmitCustomProxy,
			t,
		],
	);

	return (
		<div className={overrideClassName("flex flex-col min-h-0", props.classList)} style={props.style}>
			<div>
				<div className="flex flex-row justify-between items-center">
					<div className="max-w-[500px] flex-1">
						<DebouncedSearchInput query={query} timeout={200} onChange={setQuery} placeholder="Search by keyword" />
					</div>
					<div>
						<UploadInstrumentDetailsButton
							onUpload={() => {
								flushSync(() => props.invalidateCache?.());
								rowsQuery.refetch().catch(noop);
							}}
						>
							Upload data
						</UploadInstrumentDetailsButton>
					</div>
				</div>
				<div className="flex gap-2 pt-4">
					{basketOptions && basketOptions?.length > 0 && (
						<Select
							multi
							enableSearch
							i18n={{
								triggerPlaceholder: () => "Universe",
							}}
							options={basketOptions}
							value={filters.baskets ?? stableEmptyArray}
							onChange={(baskets) => {
								const newSearchParams = new URLSearchParams(location.search);
								newSearchParams.set("uuid", baskets.map((x) => x.basketIdentifier).join(","));
								history.replace({ pathname: location.pathname, search: newSearchParams.toString() });
								setFilters((f) => ({ ...f, baskets }));
							}}
						/>
					)}
					{macroAssetClassOptions && macroAssetClassOptions?.length > 0 && (
						<Select
							multi
							enableSearch
							i18n={{
								triggerPlaceholder: () => "Macro Asset Class",
							}}
							options={macroAssetClassOptions}
							value={filters.assetClasses ?? stableEmptyArray}
							onChange={(assetClasses) => setFilters((f) => ({ ...f, assetClasses }))}
						/>
					)}
					{microAssetClassOptions && microAssetClassOptions?.length > 0 && (
						<Select
							multi
							enableSearch
							i18n={{
								triggerPlaceholder: () => "Micro Asset Class",
							}}
							options={microAssetClassOptions}
							value={filters.microAssetClasses ?? stableEmptyArray}
							onChange={(microAssetClasses) => setFilters((f) => ({ ...f, microAssetClasses }))}
						/>
					)}
					{/* <Select
						enableSearch
						i18n={{
							triggerPlaceholder: () => "Custom proxy",
						}}
						options={[
							{ label: "show", value: true },
							{ label: "hide", value: false },
						]}
						value={filters.customProxy ?? stableEmptyArray}
						onChange={(customProxy: boolean | null) => {
							if (customProxy === filters.customProxy) {
								return setFilters((f) => ({ ...f, customProxy: null }));
							}
							setFilters((f) => ({ ...f, customProxy }));
						}}
					/> */}
				</div>
			</div>
			<ReactQueryWrapperBase
				keepChildren
				query={rowsQuery}
				loadingFallback={<ProgressBar value="indeterminate" classList="mt-4" />}
			>
				{() => (
					<>
						<div className="flex items-center justify-between">
							<BatchActions
								selected={multiSelectCtx.selection.size}
								classList="pt-4 pb-4"
								total={rows.length}
								actions={[
									// TODO: should we support this feature?
									// commonBatchActions.deleteRestore({
									// 	t,
									// 	deleted: softDeleted,
									// 	onDeletedChange: setSoftDeleted,
									// 	selection: multiSelectCtx.data.selection,
									// }),
									{
										label: "Generate description",
										icon: "sphere-ai",
										onClick: () => {
											const selection = rows.filter((x) => multiSelectCtx.selection.includes(x.ticker!));
											const tickersWithValidIsin = selection.filter(
												({ isin, ticker, type }) => isin && ticker && type !== "index",
											);
											if (tickersWithValidIsin.length === 0) {
												return platformToast({
													children: "Unable to generate description, please select instruments with valid identifier",
													icon: "Portfolio",
													severity: "info",
												});
											}

											spawnYesNoDialog({
												size: "medium",
												header: "Generate description",
												yesButton: "Proceed",
												noButton: "Cancel",
												children: (
													<>
														<Text as="p" type="Body/M/Book" classList="mb-2">
															You are about to generate{" "}
															<Text as="span" type="Body/M/Bold">
																{tickersWithValidIsin.length} instrument descriptions
															</Text>
														</Text>
														<Text as="p" type="Body/M/Book">
															Please confirm to proceed.
														</Text>
													</>
												),
											})
												.then(async (proceed) => {
													if (proceed) {
														try {
															await parallelize(
																tickersWithValidIsin.map(({ ticker, isin }) =>
																	props.onGenerateCommentary(ticker!, isin!),
																),
																{ concurrency: 32 },
															);
														} catch (err) {
															console.error(err);
														}
													}
												})
												.catch(noop);
										},
									},
								]}
							/>
							<Pagination skip={skip} take={take} total={rowsQuery.data?.total ?? 0} onChange={setSkip} />
						</div>
						<div className="flex flex-col min-h-0 relative z-0">
							<LocalOverlay
								show={rowsQuery.isFetching}
								classList="z-10"
								style={{
									background: "transparent",
								}}
							>
								<CircularProgressBar value="indeterminate" />
							</LocalOverlay>
							<BaseTable
								canMultiSort={false}
								orderBy={orderBy}
								onOrderByChange={setOrderBy}
								classList="flex-1 z-0"
								palette="uniform"
								rows={rows}
								columns={columns}
								rowClassList={(row, rowIndex) => rowClassList(row, rowIndex)}
								// rowStyle={({ ticker }) => ({
								// 	backgroundColor: softDeleted.has(ticker ?? "-")
								// 		? themeCSSVars.Table_highlightedRowBackgroundColor
								// 		: undefined,
								// })}
								onRowClick={({ ticker }) => toggle(ticker!)}
							/>
						</div>
					</>
				)}
			</ReactQueryWrapperBase>
		</div>
	);
}
