import type {
	InvestmentCreationConfigurationControllerV4Api,
	InvestmentDraftConfigurationControllerV4Api,
	InvestmentEnhancementConfigurationControllerV4Api,
	SelectableStrategyConstraintsResponse,
} from "$root/api/api-gen";
import {
	ConstraintValidity,
	InvestmentCreationConfigurationControllerV4ApiFactory,
	InvestmentDraftConfigurationControllerV4ApiFactory,
	InvestmentEnhancementConfigurationControllerV4ApiFactory,
	InvestmentsStaticConfigurationControllerApiFactory,
} from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { DataDisplayOverlay } from "$root/components/DataDisplayOverlay";
import { IconWalls } from "$root/components/IconWall";
import { useLocaleFormatters } from "$root/localization/hooks";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { trackMixPanelEvent } from "$root/third-party-integrations/initMixPanel";
import { FormController } from "$root/third-party-integrations/react-hook-form";
import { multiRef } from "$root/utils/react-extra";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { zodResolver } from "@hookform/resolvers/zod";
import { AutoTooltip, Icon, NullableNumberInput, ProgressBar, Slider, TooltipContent } from "@mdotm/mdotui/components";
import { generateUniqueDOMId, toClassName } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { useEffect, useMemo } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { match } from "ts-pattern";
import { z } from "zod";
import type { EditPortfolioV4Props } from "../EditPortfolio";
import { StepBase } from "../StepBase";
import type { ConstraintAdder } from "../constraints/AddConstraintSection";
import { AddConstraintSection } from "../constraints/AddConstraintSection";
import { ConstraintListSection } from "../constraints/ConstraintListSection";
import type { ConstraintSpecificColumnsProps } from "../constraints/ConstraintSpecificColumns";
import { DefaultConstraintListItem } from "../constraints/DefaultConstraintListItem";
import { useStepSync } from "../hooks";
import { makeStepToRequestMapper, responseToStepMapper } from "../requests";
import {
	useHandleSubmitToCustomSubmitHandlerInContext,
	type EditPortfolioV4StepPayloadMap,
	type EditablePortfolioStrategyHelper,
	type PortfolioStrategyTypePayloadMap,
	type StepPropsFor,
} from "../shared";
import { debugValue } from "@mdotm/mdotui/utils";
import { reportPlatformError } from "$root/api/error-reporting";

export type PortfolioStrategyCategory =
	| "targetMaxTurnover"
	| "targetMinOperationWeight"
	| "targetTransactionCostsInBps"
	| "minNumberOfInstruments"
	| "maxNumberOfInstruments"
	| "instrumentMinWeight"
	| "instrumentRoundingWeight";

const titleClassName = `text-[color:${themeCSSVars.global_palette_neutral_700}] pb-2`;

const constraintTypeToCategory: Record<keyof PortfolioStrategyTypePayloadMap, PortfolioStrategyCategory> = {
	targetMaxTurnover: "targetMaxTurnover",
	targetMinOperationWeight: "targetMinOperationWeight",
	targetTransactionCostsInBps: "targetTransactionCostsInBps",
	maxNumberOfInstruments: "maxNumberOfInstruments",
	minNumberOfInstruments: "minNumberOfInstruments",
	instrumentMinWeight: "instrumentMinWeight",
	instrumentRoundingWeight: "instrumentRoundingWeight",
};

type ConstraintTemplateOmittedKey = "id" | "target" | "readonly" | "type";

function constraintTemplatesHelper<
	T extends {
		[K in keyof PortfolioStrategyTypePayloadMap]: Omit<
			EditablePortfolioStrategyHelper<K>,
			ConstraintTemplateOmittedKey
		>;
	},
>(
	a: T,
): {
	[K in keyof T & keyof PortfolioStrategyTypePayloadMap]: () => EditablePortfolioStrategyHelper<K>;
} {
	return Object.fromEntries(
		Object.entries(a).map(([key, value]) => [
			key,
			() =>
				({
					...value,
					type: key,
					id: generateUniqueDOMId(),
					target: false,
					readonly: false,
				}) satisfies Record<ConstraintTemplateOmittedKey, unknown>,
		]),
	) as unknown as {
		[K in keyof T & keyof PortfolioStrategyTypePayloadMap]: () => EditablePortfolioStrategyHelper<K>;
	};
}

export function getPortfolioStrategyStepData(
	createApi: Omit<InvestmentCreationConfigurationControllerV4Api, "basePath" | "axios">,
	enhanceApi: Omit<InvestmentEnhancementConfigurationControllerV4Api, "basePath" | "axios">,
	draftApi: Omit<InvestmentDraftConfigurationControllerV4Api, "basePath" | "axios">,
	area: EditPortfolioV4Props["area"],
): Promise<EditPortfolioV4StepPayloadMap["portfolioStrategy"]> {
	return (
		!area.portfolioUid
			? createApi.getCreationConfigurationStrategyConstraints()
			: area.name === "draft"
			  ? draftApi.getDraftConfigurationStrategyConstraints(area.portfolioUid)
			  : enhanceApi.getEnhancementConfigurationStrategyConstraints(area.portfolioUid)
	).then(({ data }) => responseToStepMapper.portfolioStrategy(data, area));
}

const validations = {
	targetMaxTurnover: z.object({
		selectedValue: z.number({ invalid_type_error: "Please insert a valid value" }),
		suggestedMaxValue: z.number({ invalid_type_error: "Please insert a valid value" }),
		suggestedMinValue: z.number({ invalid_type_error: "Please insert a valid value" }),
		suggestedValue: z.number({ invalid_type_error: "Please insert a valid value" }),
		validity: z.any(),
	} satisfies Record<keyof PortfolioStrategyTypePayloadMap["targetMaxTurnover"], unknown>),
	targetMinOperationWeight: z.object({
		selectedValue: z.number({ invalid_type_error: "Please insert a valid value" }),
		suggestedMaxValue: z.number({ invalid_type_error: "Please insert a valid value" }),
		suggestedMinValue: z.number({ invalid_type_error: "Please insert a valid value" }),
		suggestedValue: z.number({ invalid_type_error: "Please insert a valid value" }),
		validity: z.any(),
	} satisfies Record<keyof PortfolioStrategyTypePayloadMap["targetMinOperationWeight"], unknown>),
	targetTransactionCostsInBps: z.object({
		suggestedMinValue: z.number({ invalid_type_error: "Please insert a valid value" }),
		suggestedMaxValue: z.number({ invalid_type_error: "Please insert a valid value" }),
		suggestedValue: z.number({ invalid_type_error: "Please insert a valid value" }),
		selectedValue: z.number({ invalid_type_error: "Please insert a valid value" }),
		validity: z.any(),
	} satisfies Record<keyof PortfolioStrategyTypePayloadMap["targetTransactionCostsInBps"], unknown>),
	minNumberOfInstruments: z.object({
		suggestedMinValue: z.number({ invalid_type_error: "Please insert a valid value" }),
		suggestedMaxValue: z.number({ invalid_type_error: "Please insert a valid value" }),
		suggestedValue: z.number({ invalid_type_error: "Please insert a valid value" }),
		selectedValue: z.number({ invalid_type_error: "Please insert a valid value" }),
		validity: z.any(),
	} satisfies Record<keyof PortfolioStrategyTypePayloadMap["minNumberOfInstruments"], unknown>),
	maxNumberOfInstruments: z.object({
		suggestedMinValue: z.number({ invalid_type_error: "Please insert a valid value" }),
		suggestedMaxValue: z.number({ invalid_type_error: "Please insert a valid value" }),
		suggestedValue: z.number({ invalid_type_error: "Please insert a valid value" }),
		selectedValue: z.number({ invalid_type_error: "Please insert a valid value" }),
		validity: z.any(),
	} satisfies Record<keyof PortfolioStrategyTypePayloadMap["maxNumberOfInstruments"], unknown>),
	instrumentMinWeight: z.object({
		suggestedMinValue: z.number({ invalid_type_error: "Please insert a valid value" }),
		suggestedMaxValue: z.number({ invalid_type_error: "Please insert a valid value" }),
		suggestedValue: z.number({ invalid_type_error: "Please insert a valid value" }),
		selectedValue: z.number({ invalid_type_error: "Please insert a valid value" }),
		validity: z.any(),
	} satisfies Record<keyof PortfolioStrategyTypePayloadMap["instrumentMinWeight"], unknown>),
	instrumentRoundingWeight: z.object({
		suggestedMinValue: z.number({ invalid_type_error: "Please insert a valid value" }),
		suggestedMaxValue: z.number({ invalid_type_error: "Please insert a valid value" }),
		suggestedValue: z.number({ invalid_type_error: "Please insert a valid value" }),
		selectedValue: z.number({ invalid_type_error: "Please insert a valid value" }),
		validity: z.any(),
	} satisfies Record<keyof PortfolioStrategyTypePayloadMap["instrumentRoundingWeight"], unknown>),
};

export function Step05_PortfolioStrategy({
	context,
	stepMetadata,
	stepData,
	allStepsData,
	onStepDataChange,
	onStepError,
	toggleDirty,
}: StepPropsFor<"portfolioStrategy">): JSX.Element {
	const { area } = context;
	const edit = area.editable && area.edit;

	const createInvestmentApi = useApiGen(InvestmentCreationConfigurationControllerV4ApiFactory);
	const draftInvestmentApi = useApiGen(InvestmentDraftConfigurationControllerV4ApiFactory);
	const enhanceInvestmentApi = useApiGen(InvestmentEnhancementConfigurationControllerV4ApiFactory);
	const staticController = useApiGen(InvestmentsStaticConfigurationControllerApiFactory);

	const isCalculating = useMemo(() => stepMetadata.processing, [stepMetadata.processing]);

	const { handleSubmit, reset, watch, formState, trigger, control, getValues } = useForm({
		defaultValues: stepData,
		resolver: zodResolver(
			z.object({
				list: z
					.array(
						z
							.object({
								id: z.string(),
							})
							.and(
								z.discriminatedUnion("type", [
									z
										.object({
											type: z.literal("targetMaxTurnover"),
										})
										.merge(validations.targetMaxTurnover),
									z
										.object({
											type: z.literal("targetMinOperationWeight"),
										})
										.merge(validations.targetMinOperationWeight),
									z
										.object({
											type: z.literal("targetTransactionCostsInBps"),
										})
										.merge(validations.targetTransactionCostsInBps),
									z
										.object({
											type: z.literal("minNumberOfInstruments"),
										})
										.merge(validations.minNumberOfInstruments),
									z
										.object({
											type: z.literal("maxNumberOfInstruments"),
										})
										.merge(validations.maxNumberOfInstruments),
									z
										.object({
											type: z.literal("instrumentMinWeight"),
										})
										.merge(validations.instrumentMinWeight),
									z
										.object({
											type: z.literal("instrumentRoundingWeight"),
										})
										.merge(validations.instrumentRoundingWeight),
								]),
							)
							.refine(
								(item) => {
									switch (item.type) {
										case "targetMaxTurnover":
										case "targetMinOperationWeight":
										case "minNumberOfInstruments":
										case "maxNumberOfInstruments":
										case "instrumentMinWeight":
										case "instrumentRoundingWeight":
											return (
												item.selectedValue >= item.suggestedMinValue && item.selectedValue <= item.suggestedMaxValue
											);
										case "targetTransactionCostsInBps":
											return true;
									}
								},
								(item) => ({
									message: `Select a value between ${item.suggestedMinValue} and ${item.suggestedMaxValue}`,
									path: ["selectedValue"],
								}),
							),
					)
					.superRefine((list, ctx) => {
						const minInstrumentsIndex = list.findIndex((x) => x.type === "minNumberOfInstruments");
						const maxInstrumentsIndex = list.findIndex((x) => x.type === "maxNumberOfInstruments");
						if (
							minInstrumentsIndex !== -1 &&
							maxInstrumentsIndex !== -1 &&
							list[minInstrumentsIndex].selectedValue > list[maxInstrumentsIndex].selectedValue
						) {
							ctx.addIssue({
								code: z.ZodIssueCode.custom,
								path: [`${maxInstrumentsIndex}.selectedValue`],
								message: "Max must be greater than or equal to min",
							});
						}
					})
					// .superRefine((list, ctx) => {
					// 	const minInstrumentsIndex = list.findIndex((x) => x.type === "minNumberOfInstruments");
					// 	const minWeightIndex = list.findIndex((x) => x.type === "instrumentMinWeight");
					// 	if (
					// 		minInstrumentsIndex !== -1 &&
					// 		minWeightIndex !== -1 &&
					// 		list[minInstrumentsIndex].selectedValue * list[minWeightIndex].selectedValue > 100
					// 	) {
					// 		ctx.addIssue({
					// 			code: z.ZodIssueCode.custom,
					// 			path: [`${minWeightIndex}.selectedValue`],
					// 			message: "Min weight is not compatible with the selected min number of instruments",
					// 		});
					// 	}
					// })
					.superRefine((list, ctx) => {
						const minInstrumentsIndex = list.findIndex((x) => x.type === "minNumberOfInstruments");
						const minWeightIndex = list.findIndex((x) => x.type === "instrumentMinWeight");
						const roundingIndex = list.findIndex((x) => x.type === "instrumentRoundingWeight");
						const minTradeSizeIndex = list.findIndex((x) => x.type === "targetTransactionCostsInBps");
						if (
							list[minInstrumentsIndex]?.selectedValue &&
							(list[minWeightIndex]?.selectedValue ||
								list[roundingIndex]?.selectedValue ||
								list[minTradeSizeIndex]?.selectedValue) &&
							!(
								list[minInstrumentsIndex].selectedValue <=
								100 /
									debugValue(
										Math.max(
											list[minWeightIndex]?.selectedValue ?? 0,
											list[roundingIndex]?.selectedValue ?? 0,
											list[minTradeSizeIndex]?.selectedValue ?? 0,
										),
									)
							)
						) {
							ctx.addIssue({
								code: z.ZodIssueCode.custom,
								path: [`${minInstrumentsIndex}.selectedValue`],
								message: `Min number of instrument is not compatible with ${[
									list[minWeightIndex]?.type,
									list[roundingIndex]?.type,
									list[minTradeSizeIndex]?.type,
								]
									.flatMap((x) => (x ? [constraintCategoryToLabel[x].label] : []))
									.join(", ")}`,
							});
						}
					}),
			}),
		),
		mode: "onBlur",
	});
	const { replace, append, fields } = useFieldArray({ control, name: "list", keyName: "_id" });

	useEffect(() => {
		trigger().then(console.log).catch(console.log);
	}, [trigger]);

	const { data: selectableStrategyConstraints, isFetching } = useQueryNoRefetch(
		["getStepPortfolioStrategyData", context.area, stepMetadata.processing],
		{
			enabled: !stepMetadata.processing,
			queryFn: async () => {
				const data = await match(context)
					.with({ area: { name: "enhance" } }, (x) =>
						axiosExtract(
							enhanceInvestmentApi.getEnhancementConfigurationSelectableStrategyConstraints(x.area.portfolioUid),
						),
					)
					.with({ area: { name: "settings-enhanced" } }, (x) =>
						axiosExtract(
							staticController.getStaticConfigurationSelectableStrategyConstraints(
								x.area.portfolioUid,
								allStepsData.investableUniverse.universeIdentifier,
							),
						),
					)
					.with({ area: { name: "settings-current" } }, (x) =>
						axiosExtract(
							staticController.getStaticConfigurationSelectableStrategyConstraints(
								x.area.portfolioUid,
								allStepsData.investableUniverse.universeIdentifier,
							),
						),
					)
					.with({ area: { name: "create" } }, () =>
						axiosExtract(createInvestmentApi.getCreationConfigurationSelectableStrategyConstraints()),
					)
					.with({ area: { name: "draft" } }, (x) =>
						axiosExtract(draftInvestmentApi.getDraftConfigurationSelectableStrategyConstraints(x.area.portfolioUid)),
					)
					.exhaustive();

				const constraintTemplates = constraintTemplatesHelper({
					targetMaxTurnover: {
						selectedValue: data.targetMaxTurnover?.selectedValue ?? data.targetMaxTurnover?.suggestedValue ?? null,
						suggestedMaxValue: data.targetMaxTurnover?.suggestedMaxValue ?? null,
						suggestedMinValue: data.targetMaxTurnover?.suggestedMinValue ?? null,
						suggestedValue: data.targetMaxTurnover?.suggestedValue ?? null,
						validity: ConstraintValidity.Unchecked,
					},
					targetMinOperationWeight: {
						selectedValue:
							data.targetMinOperationWeight?.selectedValue ?? data.targetMinOperationWeight?.suggestedValue ?? null,
						suggestedMaxValue: data.targetMinOperationWeight?.suggestedMaxValue ?? null,
						suggestedMinValue: data.targetMinOperationWeight?.suggestedMinValue ?? null,
						suggestedValue: data.targetMinOperationWeight?.suggestedValue ?? null,
						validity: ConstraintValidity.Unchecked,
					},
					targetTransactionCostsInBps: {
						selectedValue:
							data.targetTransactionCostsInBps?.selectedValue ??
							data.targetTransactionCostsInBps?.suggestedValue ??
							null,
						suggestedMaxValue: data.targetTransactionCostsInBps?.suggestedMaxValue ?? null,
						suggestedMinValue: data.targetTransactionCostsInBps?.suggestedMinValue ?? null,
						suggestedValue: data.targetTransactionCostsInBps?.suggestedValue ?? null,
						validity: ConstraintValidity.Unchecked,
					},
					minNumberOfInstruments: {
						selectedValue:
							data.minNumberOfInstruments?.selectedValue ?? data.minNumberOfInstruments?.suggestedValue ?? null,
						suggestedMaxValue: data.minNumberOfInstruments?.suggestedMaxValue ?? 100, //TODO: change fallback to null to give evidence if bug occurs
						suggestedMinValue: data.minNumberOfInstruments?.suggestedMinValue ?? 0, //TODO: change fallback to null to give evidence if bug occurs
						suggestedValue: data.minNumberOfInstruments?.suggestedValue ?? null,
						validity: ConstraintValidity.Unchecked,
					},
					maxNumberOfInstruments: {
						selectedValue:
							data.maxNumberOfInstruments?.selectedValue ?? data.maxNumberOfInstruments?.suggestedValue ?? null,
						suggestedMaxValue: data.maxNumberOfInstruments?.suggestedMaxValue ?? 100, //TODO: change fallback to null to give evidence if bug occurs
						suggestedMinValue: data.maxNumberOfInstruments?.suggestedMinValue ?? 0, //TODO: change fallback to null to give evidence if bug occurs
						suggestedValue: data.maxNumberOfInstruments?.suggestedValue ?? null,
						validity: ConstraintValidity.Unchecked,
					},
					instrumentMinWeight: {
						selectedValue: data.instrumentMinWeight?.selectedValue ?? data.instrumentMinWeight?.suggestedValue ?? null,
						suggestedMaxValue: data.instrumentMinWeight?.suggestedMaxValue ?? null,
						suggestedMinValue: data.instrumentMinWeight?.suggestedMinValue ?? null,
						suggestedValue: data.instrumentMinWeight?.suggestedValue ?? null,
						validity: ConstraintValidity.Unchecked,
					},
					instrumentRoundingWeight: {
						selectedValue: data.rounding?.selectedValue ?? data.rounding?.suggestedValue ?? null,
						suggestedMaxValue: data.rounding?.suggestedMaxValue ?? null,
						suggestedMinValue: data.rounding?.suggestedMinValue ?? null,
						suggestedValue: data.rounding?.suggestedValue ?? null,
						validity: ConstraintValidity.Unchecked,
					},
				});

				return { constraintTemplates, selectableData: data };
			},
			onSuccess: ({ selectableData }) => {
				const selectedConstraints = getValues("list");
				const selectedConstraintsWithNewSuggestedValues = selectedConstraints.map((constraint) =>
					match(constraint)
						.with({ type: "minNumberOfInstruments" }, (x) => ({
							...x,
							suggestedMaxValue: selectableData.minNumberOfInstruments?.suggestedMaxValue ?? null,
							suggestedMinValue: selectableData.minNumberOfInstruments?.suggestedMinValue ?? null,
						}))
						.with({ type: "maxNumberOfInstruments" }, (x) => ({
							...x,
							suggestedMaxValue: selectableData.maxNumberOfInstruments?.suggestedMaxValue ?? null,
							suggestedMinValue: selectableData.maxNumberOfInstruments?.suggestedMinValue ?? null,
						}))
						.with({ type: "targetMaxTurnover" }, (x) => ({
							...x,
							suggestedMaxValue: selectableData.targetMaxTurnover?.suggestedMaxValue ?? null,
							suggestedMinValue: selectableData.targetMaxTurnover?.suggestedMinValue ?? null,
						}))
						.with({ type: "instrumentMinWeight" }, (x) => ({
							...x,
							suggestedMaxValue: selectableData.instrumentMinWeight?.suggestedMaxValue ?? null,
							suggestedMinValue: selectableData.instrumentMinWeight?.suggestedMinValue ?? null,
						}))
						.with({ type: "targetMinOperationWeight" }, (x) => ({
							...x,
							suggestedMaxValue: selectableData.targetMinOperationWeight?.suggestedMaxValue ?? null,
							suggestedMinValue: selectableData.targetMinOperationWeight?.suggestedMinValue ?? null,
						}))
						.with({ type: "targetTransactionCostsInBps" }, (x) => ({
							...x,
							suggestedMaxValue: selectableData.targetTransactionCostsInBps?.suggestedMaxValue ?? null,
							suggestedMinValue: selectableData.targetTransactionCostsInBps?.suggestedMinValue ?? null,
						}))
						.with({ type: "instrumentRoundingWeight" }, (x) => ({
							...x,
						}))
						.exhaustive(),
				);
				replace(selectedConstraintsWithNewSuggestedValues);
			},
		},
	);
	const observableList = watch("list");

	const isMinNumberOfInstrumentSelected = observableList.some((c) => c.type === "minNumberOfInstruments");
	const isMaxNumberOfInstrumentSelected = observableList.some((c) => c.type === "maxNumberOfInstruments");

	const constraintAdders = useMemo<Array<ConstraintAdder<keyof PortfolioStrategyTypePayloadMap>>>(
		() =>
			context.area.portfolioUid
				? [
						{
							maxOfType: 1,
							label: "Min trade size",
							type: "targetMinOperationWeight",
							disabled: isCalculating,
							limitTooltip: isCalculating
								? "Sphere is calculating the constraint limits"
								: "You have already set VaR Min trade size",
							status: isCalculating ? "calculating" : undefined,
						},
						{
							maxOfType: 1,
							label: "Transaction costs",
							type: "targetTransactionCostsInBps",
							disabled: isCalculating,
							limitTooltip: isCalculating
								? "Sphere is calculating the constraint limits"
								: "You have already set Transaction costs",
							status: isCalculating ? "calculating" : undefined,
						},
						{
							maxOfType: 1,
							label: "Max Turnover",
							type: "targetMaxTurnover",
							disabled: isCalculating,
							limitTooltip: isCalculating
								? "Sphere is calculating the constraint limits"
								: "You have already set Max Turnover",
							status: isCalculating ? "calculating" : undefined,
						},
						{
							maxOfType: 1,
							label: "Min number of instruments",
							type: "minNumberOfInstruments",
							disabled: isCalculating || isMaxNumberOfInstrumentSelected,
							limitTooltip: isCalculating
								? "Sphere is calculating the constraint limits"
								: isMaxNumberOfInstrumentSelected
								  ? "You can only set Min number of instruments or Max number of instruments, not both"
								  : "You have already set Min number of instrument",
							status: isCalculating ? "calculating" : undefined,
						},
						{
							maxOfType: 1,
							label: "Max number of instruments",
							type: "maxNumberOfInstruments",
							disabled: isCalculating || isMinNumberOfInstrumentSelected,
							limitTooltip: isCalculating
								? "Sphere is calculating the constraint limits"
								: isMinNumberOfInstrumentSelected
								  ? "You can only set Min number of instruments or Max number of instruments, not both"
								  : "You have already set Max number of instrument",
							status: isCalculating ? "calculating" : undefined,
						},
						{
							maxOfType: 1,
							label: "Min weight on single instrument",
							type: "instrumentMinWeight",
							disabled: isCalculating,
							limitTooltip: isCalculating
								? "Sphere is calculating the constraint limits"
								: "You already set min weight on single instrument",
							status: isCalculating ? "calculating" : undefined,
						},
						{
							maxOfType: 1,
							label: "Rounding on single instrument",
							type: "instrumentRoundingWeight",
							disabled: isCalculating,
							limitTooltip: isCalculating
								? "Sphere is calculating the constraint limits"
								: "You already set rounding on single instrument",
							status: isCalculating ? "calculating" : undefined,
						},
				  ]
				: [
						{
							maxOfType: 1,
							label: "Min number of instruments",
							type: "minNumberOfInstruments",
							disabled: isCalculating || isMaxNumberOfInstrumentSelected,
							limitTooltip: isCalculating
								? "Sphere is calculating the constraint limits"
								: isMaxNumberOfInstrumentSelected
								  ? "You can set only Min number of instruments or Max number of instruments, not either"
								  : "You already set min number of instruments",
							status: isCalculating ? "calculating" : undefined,
						},
						{
							maxOfType: 1,
							label: "Max number of instruments",
							type: "maxNumberOfInstruments",
							disabled: isCalculating || isMinNumberOfInstrumentSelected,
							limitTooltip: isCalculating
								? "Sphere is calculating the constraint limits"
								: isMinNumberOfInstrumentSelected
								  ? "You can set only Min number of instruments or Max number of instruments, not either"
								  : "You have already set Max number of instrument",
							status: isCalculating ? "calculating" : undefined,
						},
						{
							maxOfType: 1,
							label: "Min weight on single instrument",
							type: "instrumentMinWeight",
							disabled: isCalculating,
							limitTooltip: isCalculating
								? "Sphere is calculating the constraint limits"
								: "You already set min weight on single instrument",
							status: isCalculating ? "calculating" : undefined,
						},
						{
							maxOfType: 1,
							label: "Rounding on single instrument",
							type: "instrumentRoundingWeight",
							disabled: isCalculating,
							limitTooltip: isCalculating
								? "Sphere is calculating the constraint limits"
								: "You already set rounding on single instrument",
							status: isCalculating ? "calculating" : undefined,
						},
				  ],
		[context.area.portfolioUid, isCalculating, isMaxNumberOfInstrumentSelected, isMinNumberOfInstrumentSelected],
	);

	useStepSync({ reset, toggleDirty, isDirty: formState.isDirty, stepData });

	const stepToRequestMapper = useMemo(() => makeStepToRequestMapper(context.area.portfolioUid), [context.area]);

	const onSubmitAsync = useHandleSubmitToCustomSubmitHandlerInContext({
		context,
		stepName: "portfolioStrategy",
		handleSubmit,
		baseSubmitFn: async (values) => {
			try {
				onStepDataChange(await context.saveHandlers.portfolioStrategy(values), {
					skipMetadataUpdate: true,
					persist: true,
				});
				trackMixPanelEvent("Portfolio-Draft", {
					Type: context.area.portfolioUid ? "enhance" : "creation",
					UID: context.area.portfolioUid,
					Action: "save",
					Step: "portfolio-strategy",
					...values,
				});
			} catch (err) {
				onStepError();
				reportPlatformError(err, "ERROR", "portfolio-creation", {
					message: "Save portfolio constraints step",
					payload: JSON.stringify(values),
				});
				throw err;
			}
		},
		onInvalid: () => replace(observableList.map((x) => ({ ...x, readonly: false }))),
	});

	return (
		<StepBase title="Operative Constraints" optional={stepMetadata.optional} onSubmitAsync={() => onSubmitAsync()}>
			<DataDisplayOverlay
				dataProvider={() => ({
					formData: watch(),
					requestBody: stepToRequestMapper?.portfolioStrategy(watch()),
				})}
				dataSource="Operative constraints"
			/>

			<div className="mb-6" data-qualifier="portfolioWizard/portfolioStrategy/hint">
				Add here the constraints of your portfolio. Sphere will strive to optimize your portfolio with respect to all
				the set constraints, if not feasible integer constraints will be disregarded
			</div>

			{isFetching && <ProgressBar value="indeterminate" />}
			{edit && (
				<AddConstraintSection
					constraintAdders={constraintAdders}
					constraintList={observableList}
					onAdd={(type) => {
						if (selectableStrategyConstraints) {
							append(selectableStrategyConstraints.constraintTemplates[type]());
						}
					}}
				/>
			)}
			<div className="mb-6">
				<div className="font-bold text-[16px] mb-2">Selected targets</div>
			</div>
			{observableList.length === 0 ? (
				<div className="h-[50dvh]">
					<IconWalls.ConstraintNotSet constraintName="targets" />
				</div>
			) : (
				<ConstraintListSection
					reOrderable={edit}
					constraints={observableList}
					onChange={replace}
					// Force remounting components when the list order or size changes. Without this, we have
					// issues with how react-hook-form handles inputs in this page.
					key={observableList.map((x) => x.id).join(";")}
				>
					{(props) => (
						<DefaultConstraintListItem {...props} actions={!isCalculating && edit}>
							<PortfolioConstraintListItem
								item={props.item}
								control={control}
								index={props.index}
								selectableData={selectableStrategyConstraints?.selectableData}
								editable={!isCalculating}
							/>
						</DefaultConstraintListItem>
					)}
				</ConstraintListSection>
			)}
		</StepBase>
	);
}

//#region Target max turnover
function TargetMaxTurnover({
	index,
	item,
	control,
	editable,
}: ConstraintSpecificColumnsProps<EditablePortfolioStrategyHelper<"targetMaxTurnover">> & { editable: boolean }) {
	const { formatNumber } = useLocaleFormatters();
	return (
		<div className="flex flex-1 max-w-[320px]">
			<div className={titleClassName}>&nbsp;</div>
			<div className="flex flex-1">
				<FormController
					name={`list.${index}.selectedValue`}
					control={control}
					render={({ field: { ref: fieldRef, ...fieldProps }, fieldState }) => {
						const error = Boolean(fieldState.error?.message);
						const invalid = isFieldOnError(error, item.validity, item.readonly);

						return (
							<AutoTooltip
								severity="error"
								position="left"
								mode="hover"
								disabled={!invalid}
								trigger={({ innerRef }) => (
									<div ref={innerRef} className="flex flex-1">
										<Slider
											data-qualifier={`portfolioWizard/portfolioStrategy/constraint(${item.type},${index})/value`}
											{...fieldProps}
											classList="flex-1 relative -top-4"
											value={fieldProps.value ?? 0}
											stepTextProvider={(x) => `${x}%`}
											input={{
												icon: "Percentile",
												invalid,
												innerRef: fieldRef,
											}}
											disabled={!editable || item.readonly}
											min={item.suggestedMinValue ?? 0}
											max={item.suggestedMaxValue ?? 0}
										/>
									</div>
								)}
							>
								{fieldState.error?.message ?? (
									<TooltipContent>
										<div className="font-bold mb-2">Constraint invalid</div>
										{item.suggestedMinValue !== null && item.suggestedMaxValue !== null && (
											<div>
												The value you have chosen is no longer compatible with the new limits. Choose a value between{" "}
												{formatNumber(item.suggestedMinValue)}% and {formatNumber(item.suggestedMaxValue)}%
											</div>
										)}
									</TooltipContent>
								)}
							</AutoTooltip>
						);
					}}
				/>
			</div>
		</div>
	);
}
//#endregion

//#region Target min operative weight(min trade size)
function TargetMinOperationWeight({
	index,
	item,
	control,
	editable,
}: ConstraintSpecificColumnsProps<EditablePortfolioStrategyHelper<"targetMinOperationWeight">> & {
	editable: boolean;
}) {
	const { formatNumber } = useLocaleFormatters();

	return (
		<div className="flex-1 max-w-[320px]">
			<div className={titleClassName}>&nbsp;</div>
			<div className="flex flex-1">
				<FormController
					name={`list.${index}.selectedValue`}
					control={control}
					render={({ field: { ref: fieldRef, ...fieldProps }, fieldState }) => {
						const error = Boolean(fieldState.error?.message);
						const invalid = isFieldOnError(error, item.validity, item.readonly);
						return (
							<AutoTooltip
								severity="error"
								position="left"
								mode="hover"
								disabled={!invalid}
								trigger={({ innerRef }) => (
									<div ref={innerRef} className="flex flex-1">
										<Slider
											data-qualifier={`portfolioWizard/portfolioStrategy/constraint(${item.type},${index})/value`}
											{...fieldProps}
											classList="flex-1 relative -top-4"
											value={fieldProps.value ?? 0}
											stepTextProvider={(x) => `${x}%`}
											input={{
												icon: "Percentile",
												innerRef: fieldRef,
											}}
											disabled={!editable || item.readonly}
											min={item.suggestedMinValue ?? 0}
											max={item.suggestedMaxValue ?? 0}
											step={0.01}
										/>
									</div>
								)}
							>
								{fieldState.error?.message ?? (
									<TooltipContent>
										<div className="font-bold mb-2">Constraint invalid</div>
										{item.suggestedMinValue !== null && item.suggestedMaxValue !== null && (
											<div>
												The value you have chosen is no longer compatible with the new limits. Choose a value between{" "}
												{formatNumber(item.suggestedMinValue)}% and {formatNumber(item.suggestedMaxValue)}%
											</div>
										)}
									</TooltipContent>
								)}
							</AutoTooltip>
						);
					}}
				/>
			</div>
		</div>
	);
}
//#endregion

//#region Min number of instrument
function MinNumberOfInstruments({
	index,
	item,
	control,
	editable,
}: ConstraintSpecificColumnsProps<EditablePortfolioStrategyHelper<"minNumberOfInstruments">> & {
	editable: boolean;
}) {
	const { formatNumber } = useLocaleFormatters();
	return (
		<div className="flex-1 max-w-[320px]">
			<div className={titleClassName}>&nbsp;</div>
			<FormController
				name={`list.${index}.selectedValue`}
				control={control}
				render={({ field: { ref: fieldRef, ...fieldProps }, fieldState }) => {
					const error = Boolean(fieldState.error?.message);
					const invalid = isFieldOnError(error, item.validity, item.readonly);
					return (
						<AutoTooltip
							severity="error"
							position="left"
							mode="hover"
							disabled={!invalid}
							trigger={({ innerRef }) => (
								<div ref={innerRef} className="flex flex-1">
									<Slider
										data-qualifier={`portfolioWizard/portfolioStrategy/constraint(${item.type},${index})/value`}
										{...fieldProps}
										value={fieldProps.value ?? 0}
										classList={{
											"flex-1 relative -top-4": true,
											"[&>div:nth-child(2)>input]:border-red-600 [&>div:nth-child(2)>input]:border-2 [&>div:nth-child(2)>input]:border-solid":
												invalid,
										}}
										input={{
											invalid,
											innerRef: fieldRef,
										}}
										disabled={!editable || item.readonly}
										min={item.suggestedMinValue ?? 0}
										max={item.suggestedMaxValue ?? 0}
										step={1}
										fillColor={invalid ? themeCSSVars.global_palette_danger_600 : undefined}
									/>
								</div>
							)}
						>
							{fieldState.error?.message ?? (
								<TooltipContent>
									<div className="font-bold mb-2">Constraint invalid</div>
									{item.suggestedMinValue !== null && item.suggestedMaxValue !== null && (
										<div>
											The value you have chosen is no longer compatible with the new limits. Choose a value between{" "}
											{formatNumber(item.suggestedMinValue)} and {formatNumber(item.suggestedMaxValue)}
										</div>
									)}
								</TooltipContent>
							)}
						</AutoTooltip>
					);
				}}
			/>
		</div>
	);
}
//#endregion

//#region Max number of instrument
function MaxNumberOfInstruments({
	index,
	item,
	control,
	editable,
}: ConstraintSpecificColumnsProps<EditablePortfolioStrategyHelper<"maxNumberOfInstruments">> & {
	editable: boolean;
}) {
	const { formatNumber } = useLocaleFormatters();

	return (
		<div className="flex-1 max-w-[320px]">
			<div className={titleClassName}>&nbsp;</div>
			<FormController
				name={`list.${index}.selectedValue`}
				control={control}
				render={({ field: { ref: fieldRef, ...fieldProps }, fieldState }) => {
					const error = Boolean(fieldState.error?.message);
					const invalid = isFieldOnError(error, item.validity, item.readonly);
					return (
						<AutoTooltip
							severity="error"
							position="left"
							mode="hover"
							disabled={!invalid}
							trigger={({ innerRef }) => (
								<div ref={innerRef} className="flex flex-1">
									<Slider
										data-qualifier={`portfolioWizard/portfolioStrategy/constraint(${item.type},${index})/value`}
										{...fieldProps}
										value={fieldProps.value ?? 0}
										classList={{
											"flex-1 relative -top-4": true,
											"[&>div:nth-child(2)>input]:border-red-600 [&>div:nth-child(2)>input]:border-2 [&>div:nth-child(2)>input]:border-solid":
												invalid,
										}}
										input={{
											invalid,
											innerRef: fieldRef,
										}}
										disabled={!editable || item.readonly}
										min={item.suggestedMinValue ?? 0}
										max={item.suggestedMaxValue ?? 0}
										step={1}
										fillColor={invalid ? themeCSSVars.global_palette_danger_600 : undefined}
									/>
								</div>
							)}
						>
							{fieldState.error?.message ?? (
								<TooltipContent>
									<div className="font-bold mb-2">Constraint invalid</div>
									{item.suggestedMinValue !== null && item.suggestedMaxValue !== null && (
										<div>
											The value you have chosen is no longer compatible with the new limits. Choose a value between{" "}
											{formatNumber(item.suggestedMinValue)} and {formatNumber(item.suggestedMaxValue)}
										</div>
									)}
								</TooltipContent>
							)}
						</AutoTooltip>
					);
				}}
			/>
		</div>
	);
}
//#endregion

//#region Target transaction cost in bps
function TargetTransactionCostsInBps({
	index,
	item,
	control,
	editable,
}: ConstraintSpecificColumnsProps<EditablePortfolioStrategyHelper<"targetTransactionCostsInBps">> & {
	editable: boolean;
}) {
	return (
		<div className="flex-1 max-w-[320px]">
			<div className={titleClassName}>&nbsp;</div>
			<div className="flex flex-1">
				<FormController
					name={`list.${index}.selectedValue`}
					control={control}
					render={({ field: { ref: innerRef, ...fieldProps } }) => (
						<NullableNumberInput
							data-qualifier={`portfolioWizard/portfolioStrategy/constraint(${item.type},${index})/value`}
							{...fieldProps}
							innerRef={innerRef}
							disabled={!editable || item.readonly}
							size="x-small"
							rightContent={<span>BPS</span>}
							classList={{ "ml-auto w-20": true }}
						/>
					)}
				/>
			</div>
		</div>
	);
}
//#endregion

//#region Target min weight on single instrument
function InstrumentMinWeight({
	index,
	item,
	control,
	editable,
}: ConstraintSpecificColumnsProps<EditablePortfolioStrategyHelper<"instrumentMinWeight">> & {
	editable: boolean;
}) {
	const { formatNumber } = useLocaleFormatters();

	return (
		<div className="grow grid justify-end mr-4">
			<div
				className={toClassName({
					[titleClassName]: true,
					"text-right": true,
				})}
			>
				Weight
			</div>
			<div className="w-20 text-right">
				{item.readonly || !editable ? (
					`${formatNumber(item.selectedValue, 2)}%`
				) : (
					<FormController
						control={control}
						name={`list.${index}.selectedValue`}
						render={({ field: { ref: fieldRef, ...fieldProps }, fieldState }) => {
							const error = Boolean(fieldState.error?.message);
							const invalid = isFieldOnError(error, item.validity, item.readonly);
							return (
								<AutoTooltip
									severity="error"
									position="left"
									mode="click"
									disabled={!invalid}
									trigger={({ innerRef }) => (
										<NullableNumberInput
											data-qualifier={`portfolioWizard/portfolioStrategy/constraint(${item.type},${index})/weight`}
											{...fieldProps}
											innerRef={multiRef(innerRef, fieldRef)}
											rightContent={<Icon icon="Percentile" />}
											size="x-small"
											placeholder="100"
											invalid={invalid}
										/>
									)}
								>
									{fieldState.error?.message ?? (
										<TooltipContent>
											<div className="font-bold mb-2">Constraint not feasible</div>

											<div>The closest constraint compatible is {formatNumber(item.suggestedValue)}% </div>
										</TooltipContent>
									)}
								</AutoTooltip>
							);
						}}
					/>
				)}
			</div>
		</div>
	);
}
//#endregion

function findClosestRangeNumber(value: number, opt: number[]): number {
	let closestDelta: number | null = null;
	let minStepIndex: number = 0;

	opt.forEach((step, i) => {
		const deltaStep = Math.abs(step - value);
		if (!closestDelta || closestDelta > deltaStep) {
			closestDelta = deltaStep;
			minStepIndex = i;
		}
	});

	return opt[minStepIndex];
}
const roundingStep = [0.01, 0.05, 0.1, 0.25, 0.5, 1];
//#region rounding weight on single instrument
function RoundindWeightOnSingleInstrumetn({
	index,
	item,
	control,
	editable,
}: ConstraintSpecificColumnsProps<EditablePortfolioStrategyHelper<"instrumentRoundingWeight">> & {
	editable: boolean;
}) {
	const { formatNumber } = useLocaleFormatters();

	return (
		<div className="flex-1 max-w-[320px]">
			<div className={titleClassName}>&nbsp;</div>
			<FormController
				name={`list.${index}.selectedValue`}
				control={control}
				render={({ field: { ref: fieldRef, ...fieldProps }, fieldState }) => {
					const error = Boolean(fieldState.error?.message);
					const invalid = isFieldOnError(error, item.validity, item.readonly);
					return (
						<AutoTooltip
							severity="error"
							position="left"
							mode="hover"
							disabled={!invalid}
							trigger={({ innerRef }) => (
								<div ref={innerRef} className="flex flex-1">
									<Slider
										data-qualifier={`portfolioWizard/portfolioStrategy/constraint(${item.type},${index})/value`}
										{...fieldProps}
										value={fieldProps.value ?? 0}
										classList={{
											"flex-1 relative -top-4": true,
											"[&>div:nth-child(2)>input]:border-red-600 [&>div:nth-child(2)>input]:border-2 [&>div:nth-child(2)>input]:border-solid":
												invalid,
										}}
										input={{
											icon: "Percentile",
											invalid,
											innerRef: fieldRef,
											disabled: true,
										}}
										disabled={!editable || item.readonly}
										min={item.suggestedMinValue ?? 0}
										max={item.suggestedMaxValue ?? 0}
										step={0.01}
										fillColor={invalid ? themeCSSVars.global_palette_danger_600 : undefined}
										stepTextProvider={(x) => `${x}%`}
										onCommit={(x) => {
											const cappedStep = findClosestRangeNumber(x, roundingStep);
											fieldProps.onChange(cappedStep);
										}}
										showSteps={roundingStep}
									/>
								</div>
							)}
						>
							{fieldState.error?.message ?? (
								<TooltipContent>
									<div className="font-bold mb-2">Constraint invalid</div>
									{item.suggestedMinValue !== null && item.suggestedMaxValue !== null && (
										<div>
											The value you have chosen is no longer compatible with the new limits. Choose a value between{" "}
											{formatNumber(item.suggestedMinValue)} and {formatNumber(item.suggestedMaxValue)}
										</div>
									)}
								</TooltipContent>
							)}
						</AutoTooltip>
					);
				}}
			/>
		</div>
	);
}
//#endregion

//#region List of the Constraints
function PortfolioConstraintListItem({
	index,
	control,
	item,
	editable,
}: ConstraintSpecificColumnsProps<EditablePortfolioStrategyHelper<keyof PortfolioStrategyTypePayloadMap>> & {
	selectableData?: SelectableStrategyConstraintsResponse;
	editable: boolean;
}): JSX.Element {
	const constraintMetadata = constraintCategoryToLabel[constraintTypeToCategory[item.type]];
	return (
		<>
			<div className="w-[120px]">
				<div className={titleClassName}>{constraintMetadata.type}</div>
				<div>
					<AutoTooltip
						position="left"
						overrideColor={themeCSSVars.global_palette_neutral_300}
						trigger={({ innerRef }) => (
							<span className="hover:font-medium hover:underline" ref={innerRef}>
								{constraintMetadata.label}
							</span>
						)}
					>
						<TooltipContent>
							<div className="font-bold mb-2">{constraintMetadata.label}</div>
							<div>{constraintMetadata.tooltip}</div>
						</TooltipContent>
					</AutoTooltip>
				</div>
			</div>
			<div className="flex grow justify-end items-center">
				{(function () {
					switch (item.type) {
						case "targetMaxTurnover":
							return (
								<TargetMaxTurnover
									index={index}
									item={item}
									control={
										control as ConstraintSpecificColumnsProps<
											EditablePortfolioStrategyHelper<"targetMaxTurnover">
										>["control"]
									}
									editable={editable}
								/>
							);
						case "targetMinOperationWeight":
							return (
								<TargetMinOperationWeight
									index={index}
									item={item}
									control={
										control as ConstraintSpecificColumnsProps<
											EditablePortfolioStrategyHelper<"targetMinOperationWeight">
										>["control"]
									}
									editable={editable}
								/>
							);
						case "targetTransactionCostsInBps":
							return (
								<TargetTransactionCostsInBps
									index={index}
									item={item}
									control={
										control as ConstraintSpecificColumnsProps<
											EditablePortfolioStrategyHelper<"targetTransactionCostsInBps">
										>["control"]
									}
									editable={editable}
								/>
							);
						// case "targetVolatility":
						// 	return (
						// 		<TargetVolatility
						// 			index={index}
						// 			item={item}
						// 			control={
						// 				control as ConstraintSpecificColumnsProps<
						// 					EditablePortfolioStrategyHelper<"targetVolatility">
						// 				>["control"]
						// 			}
						// 			editable={editable}
						// 		/>
						// 	);
						case "minNumberOfInstruments":
							return (
								<MinNumberOfInstruments
									index={index}
									item={item}
									control={
										control as ConstraintSpecificColumnsProps<
											EditablePortfolioStrategyHelper<"minNumberOfInstruments">
										>["control"]
									}
									editable={editable}
								/>
							);
						case "maxNumberOfInstruments":
							return (
								<MaxNumberOfInstruments
									index={index}
									item={item}
									control={
										control as ConstraintSpecificColumnsProps<
											EditablePortfolioStrategyHelper<"maxNumberOfInstruments">
										>["control"]
									}
									editable={editable}
								/>
							);
						case "instrumentMinWeight":
							return (
								<InstrumentMinWeight
									index={index}
									item={item}
									control={
										control as ConstraintSpecificColumnsProps<
											EditablePortfolioStrategyHelper<"instrumentMinWeight">
										>["control"]
									}
									editable={editable}
								/>
							);
						case "instrumentRoundingWeight":
							return (
								<RoundindWeightOnSingleInstrumetn
									index={index}
									item={item}
									control={
										control as ConstraintSpecificColumnsProps<
											EditablePortfolioStrategyHelper<"instrumentRoundingWeight">
										>["control"]
									}
									editable={editable}
								/>
							);
					}
				})()}
			</div>
		</>
	);
}
//#endregion

const constraintCategoryToLabel = {
	targetMaxTurnover: {
		type: "Constraint",
		label: "Max Turnover",
		tooltip: "The maximum target portion of portfolio you are willing to change",
	},
	targetMinOperationWeight: {
		type: "Constraint",
		label: "Min trade size",
		tooltip: "The minimum single buy/sell operation weight to do during your optimization process",
	},
	targetTransactionCostsInBps: {
		type: "Constraint",
		label: "Transaction costs",
		tooltip:
			"Average transaction cost paid for each transaction made, expressed as basis points. By default, Sphere takes into account a fee of 10 Bps.",
	},
	minNumberOfInstruments: {
		type: "Constraint",
		label: "Min number of instrument",
		tooltip: "The minimum number of instruments that the portfolio should have",
	},
	maxNumberOfInstruments: {
		type: "Constraint",
		label: "Max number of instrument",
		tooltip: "The maximum number of instruments that the portfolio should have",
	},
	instrumentMinWeight: {
		type: "Constraint",
		label: "Min weight on single instrument",
		tooltip: "Set the minimum weights to be allocated to every single portfolio instrument",
	},
	instrumentRoundingWeight: {
		type: "Constraint",
		label: "Rounding on Single Instrument",
		tooltip: "Adjusts instrument weights to specified rounding steps for precise portfolio construction.",
	},
} satisfies Record<PortfolioStrategyCategory, { label: string; tooltip: string; type: "Target" | "Constraint" }>;

function isFieldOnError(error: boolean, validity: ConstraintValidity, readonly: boolean): boolean {
	if (readonly) {
		return false;
	}

	return error || validity === ConstraintValidity.Invalid;
}
