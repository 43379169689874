import type { TrackableAsyncFn } from "@mdotm/mdotui/headless";
import { spawnYesNoDialog } from "../yes-no-dialog";
import type { FormEvent, ReactNode } from "react";
import type { NodeOrFn } from "@mdotm/mdotui/react-extensions";
import { Text, type DialogBoxSize } from "@mdotm/mdotui/components";
import { UserCancelledError } from "$root/utils/errors";

export type DeleteDialogCommon = {
	children?: ReactNode;
	header?: string | ReactNode;
	yesButton?: string | NodeOrFn;
	onDeleteAsync: TrackableAsyncFn<void, [FormEvent]>;
	noButton?: string | NodeOrFn<{ onClick(): void }>;
	size?: DialogBoxSize;
	entityType?: string;
} & (
	| {
			entityName: string;
			entityNames?: undefined;
	  }
	| {
			entityName?: undefined;
			entityNames: string[];
	  }
);

export type SpawnDeleteDialogParams = DeleteDialogCommon & {
	zIndex?: number;
};

const maxNOfItemsToDisplay = 3;
export async function spawnDeleteDialog(params: SpawnDeleteDialogParams): Promise<void> {
	const items = params.entityName ? [params.entityName.trim()] : params.entityNames?.map((x) => x.trim()) ?? ["-"];
	const shouldProceed = await spawnYesNoDialog({
		header: params.header ?? (params.entityType ? `Delete ${params.entityType}` : "Delete"),
		size: "large",
		children: (
			<>
				<Text type="Body/M/Book">Proceed with the deletion of </Text>

				{items.length === 1 ? (
					<Text type="Body/M/Bold">{items[0]}</Text>
				) : items.length <= maxNOfItemsToDisplay ? (
					<>
						<Text type="Body/M/Bold">{items.slice(0, -1).join(", ")}</Text>
						<Text type="Body/M/Book"> and </Text>
						<Text type="Body/M/Bold">{items.at(-1)}</Text>
					</>
				) : (
					<>
						<Text type="Body/M/Bold">{items.slice(0, maxNOfItemsToDisplay).join(", ")}</Text>
						{items.length === maxNOfItemsToDisplay + 1 && <Text type="Body/M/Book"> and 1 more item</Text>}
						{items.length > maxNOfItemsToDisplay + 1 && (
							<Text type="Body/M/Book"> and {items.length - maxNOfItemsToDisplay} more items</Text>
						)}
					</>
				)}
				<Text type="Body/M/Book">?</Text>
			</>
		),
		onSubmitAsync: params.onDeleteAsync,
	});
	if (!shouldProceed) {
		throw new UserCancelledError();
	}
}
