import { EntityEditorControllerApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { hasAccess } from "$root/components/AuthorizationGuard";
import type { Crumb } from "$root/components/Breadcrumbs";
import MonitorWithHourglass2 from "$root/components/glyphs/MonitorWithHourglass2";
import { PageHeader } from "$root/components/PageHeader";
import { typedUrlForRoute, useTypedNavigation } from "$root/components/PlatformRouter/RoutesDef";
import { ReactQueryWrapperBase } from "$root/components/ReactQueryWrapper";
import { defaultTagLabels, labelToTag } from "$root/components/tags/shared";
import { useEventBus } from "$root/event-bus";
import type { Tag } from "$root/functional-areas/instruments-editor/builder";
import {
	covertInstrumentEditorToReviewTicker,
	instrumentEntryKey,
	useInstrumentEditorBuilder,
} from "$root/functional-areas/instruments-editor/builder";
import type { InstrumentEditorEntry } from "$root/functional-areas/instruments-editor/const";
import { InstrumentEditorEntity } from "$root/functional-areas/instruments-editor/const";
import InstrumentCompostionEditor from "$root/functional-areas/instruments-editor/instrumentEditor";
import { useUserValue } from "$root/functional-areas/user";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { trackMixPanelEvent } from "$root/third-party-integrations/initMixPanel";
import {
	BENCHMARK_INSTRUMENT_LIMIT,
	MIN_NUMBER_OF_BENCHMARK_INSTRUMENTS,
	MIN_NUMBER_OF_INSTRUMENTS,
	MIN_NUMBER_OF_TARGET_INVESTMENT_INSTRUMENTS,
	MIN_NUMBER_OF_UNIVERSE_INSTRUMENTS,
	TARGET_PORTFOLIO_INSTRUMENT_LIMIT,
	UNIVERSE_INSTRUMENT_LIMIT,
} from "$root/utils/const";
import { objMatchFn } from "$root/utils/objects";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { useSearchParams } from "$root/utils/react-router-extra";
import { ActionText, Button, Icon, Modal, ProgressBar } from "@mdotm/mdotui/components";
import { Map, Set } from "immutable";
import { useEffect, useMemo, useState } from "react";
import { flushSync } from "react-dom";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { match } from "ts-pattern";
import { PortfolioStudioTab } from "../PortfoliosStudio";
import { manualCreationSaveFactory } from "./handler";
import { spawnSubmitBaseCreationDialog } from "./spawn-create-base";
import { spawnSubmitTargetPortfolioCreationDialog } from "./spawn-create-targetPortfolio";
import { spawnSubmitUniverseCreationDialog } from "./spawn-create-universe";
import { spawnBenchmarkTemplateDialog } from "$root/functional-areas/instruments-editor/spawn/benchmark-template";

const crumbs = {
	portfolioStudio: { children: "Portfolio studio" },
	referenceList: {
		children: "References",
		href: typedUrlForRoute("PortfoliosStudio", { tab: PortfolioStudioTab.References }),
	},
	universeList: {
		children: "Universes",
		href: typedUrlForRoute("PortfoliosStudio", { tab: PortfolioStudioTab.Universes }),
	},
	newTargetPortfolio: {
		children: "New target portfolio",
	},
	newBenchmark: {
		children: "New benchmark",
	},
	newUniverse: {
		children: "New universe",
	},
} satisfies Record<string, Crumb | ((...params: any[]) => Crumb)>;

//todo: autoforward on reference creation
type AllowedEntity = Extract<InstrumentEditorEntity, "TARGET_INVESTMENT" | "BENCHMARK" | "UNIVERSE">;
function ManualCreation(): JSX.Element {
	const { entity } = useParams<{
		entity: AllowedEntity;
	}>();
	const { selectBenchmarkTemplate } = useSearchParams<"selectBenchmarkTemplate">();
	const [waitingModalState, setWaitingModalState] = useState<{ show: boolean; uuid?: string }>({ show: false });

	const { t } = useTranslation();
	const { push, replace } = useTypedNavigation();
	const user = useUserValue();

	const entityEditorApi = useApiGen(EntityEditorControllerApiFactory);

	const instrumentBuilder = useInstrumentEditorBuilder({ composition: Map() });
	const compositionMapState = instrumentBuilder.watchComposition();
	const compositionMapWithouthDeleteInstruments = compositionMapState.removeAll(instrumentBuilder.getDeleted());
	const totalCompositionWeight = instrumentBuilder.getTotal("weight");

	const instrumentsInComposition = useMemo(
		() =>
			//TBD: idk since in the universe u can't have an aggregate view of the protfolio but they are
			// directly saved as nest portfolio maybe we can consider to count as single instrument just for the universe
			compositionMapWithouthDeleteInstruments.reduce((sum, instument) => {
				if (instument.proxyOverwriteType === "PORTFOLIO_MIXED") {
					const instrumentsInInvestment = instument.investment?.nofInstruments ?? 0;
					const instrumentsExcluded = instument.nOfInstrumentExcluded ?? 0;
					return instrumentsInInvestment - instrumentsExcluded + sum;
				}

				return sum + 1;
			}, 0),
		[compositionMapWithouthDeleteInstruments],
	);

	useEffect(() => {
		if (selectBenchmarkTemplate) {
			spawnBenchmarkTemplateDialog({
				onSubmit(benchmarks) {
					replace("Portfolios/ManualCreation", { entity: InstrumentEditorEntity.Benchmark });
					const composition = benchmarks.map(
						(instrument): InstrumentEditorEntry => ({
							...instrument,
							weight: instrument.weight ?? 0,
							rowId: instrumentEntryKey(instrument),
						}),
					);
					instrumentBuilder.bulkAddInstruments(composition);
				},
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [replace, selectBenchmarkTemplate]);

	const maxInstrumentsInComposition = hasAccess(user, { requiredService: "NUMBER_OF_INSTRUMENTS_CHECK_BYPASS" })
		? +Infinity
		: match(entity)
				.returnType<number>()
				.with("BENCHMARK", () => BENCHMARK_INSTRUMENT_LIMIT)
				.with("TARGET_INVESTMENT", () => TARGET_PORTFOLIO_INSTRUMENT_LIMIT)
				.with("UNIVERSE", () => UNIVERSE_INSTRUMENT_LIMIT)
				.exhaustive();

	const minInstrumentsInComposition = hasAccess(user, { requiredService: "NUMBER_OF_INSTRUMENTS_CHECK_BYPASS" })
		? MIN_NUMBER_OF_INSTRUMENTS
		: match(entity)
				.returnType<number>()
				.with("BENCHMARK", () => MIN_NUMBER_OF_BENCHMARK_INSTRUMENTS)
				.with("TARGET_INVESTMENT", () => MIN_NUMBER_OF_TARGET_INVESTMENT_INSTRUMENTS)
				.with("UNIVERSE", () => MIN_NUMBER_OF_UNIVERSE_INSTRUMENTS)
				.exhaustive();

	const forwardToEntityPage = match(entity)
		.returnType<(uuid: string) => void>()
		.with("TARGET_INVESTMENT", () => (uuid) => push("PortfolioReferenceDetails", { portfolioUid: uuid }))
		.with("BENCHMARK", () => (uuid) => push("CustomBenchmark", { benchmarkId: uuid }))
		.with("UNIVERSE", () => (uuid) => push("UniverseDetails", { universeUuid: uuid }))
		.exhaustive();

	const isSubmitDisabled = useMemo(() => {
		if (entity === "UNIVERSE") {
			return (
				instrumentsInComposition < minInstrumentsInComposition || instrumentsInComposition > maxInstrumentsInComposition
			);
		}

		return (
			totalCompositionWeight !== 100 ||
			instrumentsInComposition < minInstrumentsInComposition ||
			instrumentsInComposition > maxInstrumentsInComposition
		);
	}, [
		entity,
		instrumentsInComposition,
		maxInstrumentsInComposition,
		minInstrumentsInComposition,
		totalCompositionWeight,
	]);

	const queryInitManualCreation = useQueryNoRefetch(["queryInitManualCreation"], {
		queryFn: async () => {
			const editorResponse = await axiosExtract(entityEditorApi.getEditorNewComposition("INVESTMENT"));

			const { composition } = editorResponse;
			const nonNullableComposition = composition ?? [];

			return {
				...editorResponse,
				composition: nonNullableComposition,
			};
		},
		onSuccess({ composition, cashTicker }) {
			const uniqueTags = Set(composition.flatMap((x) => (x.tagLabel ? [x.tagLabel] : [])).concat(defaultTagLabels));
			const tags = Map<string, Tag>(
				uniqueTags.map((tag): [string, Tag] => {
					const convertedTag = labelToTag({ label: tag }, Array.from(uniqueTags), true);
					return [convertedTag.name, { label: convertedTag.name, color: convertedTag.color, value: convertedTag.name }];
				}),
			);

			const compositionMap = Map(
				composition.map((instrument): [string, InstrumentEditorEntry] => {
					const rowId = instrumentEntryKey(instrument);
					return [rowId, { ...instrument, rowId }];
				}),
			);
			instrumentBuilder.reset(
				{
					composition: compositionMap,
					cash: cashTicker ? { ...cashTicker, rowId: instrumentEntryKey(cashTicker) } : undefined,
					tags,
				},
				{ disableToggleDirty: true },
			);
		},
	});

	useEventBus("investment-update", {
		filter: objMatchFn({ uuid: waitingModalState.uuid }),
		listener: () => {
			flushSync(() => setWaitingModalState({ show: false }));
			if (waitingModalState.uuid) {
				forwardToEntityPage(waitingModalState.uuid);
			}
		},
	});

	useEventBus("benchmark-update", {
		filter: objMatchFn({ uuid: waitingModalState.uuid }),
		listener: () => {
			flushSync(() => setWaitingModalState({ show: false }));
			if (waitingModalState.uuid) {
				push("CustomBenchmark", { benchmarkId: waitingModalState.uuid });
			}
		},
	});

	return (
		<>
			<PageHeader
				title={match(entity)
					.returnType<string>()
					.with("BENCHMARK", () => crumbs.newBenchmark.children)
					.with("TARGET_INVESTMENT", () => crumbs.newTargetPortfolio.children)
					.with("UNIVERSE", () => crumbs.newUniverse.children)
					.exhaustive()}
				crumbs={match(entity)
					.returnType<Crumb[]>()
					.with("BENCHMARK", () => [crumbs.portfolioStudio, crumbs.referenceList, crumbs.newBenchmark])
					.with("TARGET_INVESTMENT", () => [crumbs.portfolioStudio, crumbs.referenceList, crumbs.newTargetPortfolio])
					.with("UNIVERSE", () => [crumbs.portfolioStudio, crumbs.universeList, crumbs.newUniverse])
					.exhaustive()}
				titleAction={
					<div className="flex items-center space-x-2">
						<Button
							size="small"
							onClick={() =>
								push("PortfoliosStudio", {
									tab: match(entity)
										.returnType<PortfolioStudioTab>()
										.with("BENCHMARK", "TARGET_INVESTMENT", () => PortfolioStudioTab.References)
										.with("UNIVERSE", () => PortfolioStudioTab.Universes)
										.exhaustive(),
								})
							}
							palette="tertiary"
							data-qualifier="CompositionEditor/Cancel"
						>
							{t("BUTTON.CANCEL")}
						</Button>

						<Button
							size="small"
							disabled={isSubmitDisabled}
							onClick={match(entity)
								.returnType<() => void>()
								.with(
									"BENCHMARK",
									(matchedEntity) => () =>
										spawnSubmitBaseCreationDialog({
											entity: matchedEntity,
											async onAsyncSubmit({ name }) {
												const composition = covertInstrumentEditorToReviewTicker(
													Array.from(instrumentBuilder.getComposition().values()),
												);

												const { identifier } = await manualCreationSaveFactory.createBenchmark({
													form: { name },
													composition,
												});

												trackMixPanelEvent("Benchmark", {
													Type: "Creation",
													Action: "save",
													ID: identifier,
												});
												setWaitingModalState({ show: true, uuid: identifier });
											},
										}),
								)
								.with(
									"TARGET_INVESTMENT",
									() => () =>
										spawnSubmitTargetPortfolioCreationDialog({
											onAsyncSubmit: async (form) => {
												const composition = covertInstrumentEditorToReviewTicker(
													Array.from(instrumentBuilder.getComposition().values()),
												);
												const { identifier } = await manualCreationSaveFactory.createTargetInvestment({
													form,
													composition,
												});

												trackMixPanelEvent("Portfolio", {
													Type: "Upload",
													ID: identifier ?? "",
													Name: form.name,
													Benchmark: "",
													Currency: "",
												});
												setWaitingModalState({ show: true, uuid: identifier });
											},
										}),
								)
								.with(
									"UNIVERSE",
									() => () =>
										spawnSubmitUniverseCreationDialog({
											async onAsyncSubmit({ name }) {
												const hasPortfolioInComposition = instrumentBuilder
													.getComposition()
													.some((instrument) => instrument.proxyOverwriteType === "PORTFOLIO_MIXED");
												const composition = covertInstrumentEditorToReviewTicker(
													Array.from(instrumentBuilder.getComposition().values()),
												);

												const universe = await manualCreationSaveFactory.createUniverse({
													form: {
														name,
														portfolioSavingMode: hasPortfolioInComposition ? "NEST_PORTFOLIOS" : "MIX_INSTRUMENTS",
													},
													composition,
												});

												trackMixPanelEvent("Universe", {
													Type: "Async Upload",
													Action: "save",
													ID: universe.identifier,
													Name: name,
													estimatedTime: universe.estimatedRetrieveTimeInMs ?? 0,
												});
												setWaitingModalState({ show: true, uuid: universe.identifier });
											},
										}),
								)
								.exhaustive()}
							palette="primary"
							data-qualifier="CompositionEditor/Save"
						>
							{t("BUTTON.DONE")}
						</Button>
					</div>
				}
			/>
			<ReactQueryWrapperBase query={queryInitManualCreation}>
				{() => (
					<div className="flex h-[calc(100dvh_-_186px)]">
						<InstrumentCompostionEditor
							entity={entity}
							mode="new"
							instrumentBuilder={instrumentBuilder}
							minThreshold={minInstrumentsInComposition}
							limit={maxInstrumentsInComposition}
						/>
					</div>
				)}
			</ReactQueryWrapperBase>
			<WaitingDialog
				show={waitingModalState.show}
				isOnCloseDisabled={!waitingModalState.uuid}
				onClose={() => {
					if (waitingModalState.uuid) {
						forwardToEntityPage(waitingModalState.uuid);
					}
				}}
			/>
		</>
	);
}

type WaitingDialogProps = { show: boolean; onClose(): void; isOnCloseDisabled: boolean };
function WaitingDialog(props: WaitingDialogProps) {
	const { t } = useTranslation();

	return (
		<Modal show={props.show}>
			<div className="w-[560px] p-[21px] bg-white rounded shadow relative z-0">
				<div className="absolute top-0 right-0">
					<button
						type="button"
						aria-label="close"
						className="cursor-pointer p-5"
						disabled={props.isOnCloseDisabled}
						onClick={props.onClose}
					>
						<Icon icon="Close" classList="text-[16px]" />
					</button>
				</div>
				<div className="flex flex-col items-center text-center px-[43px] pb-[43px] pt-[22px] ">
					<div className="flex justify-center">
						<MonitorWithHourglass2 style={{ margin: "0 auto" }} />
					</div>
					<h2 className="font-bold text-[20px] leading-[24px] mb-[26px] whitespace-pre-line">
						{t("PORTFOLIO_UPLOAD_DIALOG.title")}
					</h2>
					<div className="flex justify-center mb-[26px]">
						<div className="w-[290px]">
							<ProgressBar value="indeterminate" classList="w-full" />
						</div>
					</div>
					<p className="text-[14px] leading-[20px] whitespace-pre-line">{t("PORTFOLIO_UPLOAD_DIALOG.description")}</p>
					<ActionText
						classList="block mt-1"
						disabled={props.isOnCloseDisabled}
						onClick={props.onClose}
						data-qualifier="CompositionEditor/WaitingDialog/SendToBackground"
					>
						{t("PORTFOLIO_UPLOAD_DIALOG.actionable")}
					</ActionText>
				</div>
			</div>
		</Modal>
	);
}

export default ManualCreation;
