import BgLogin from "$root/assets/images/bg_login.jpg";
import { hasAccess } from "$root/components/AuthorizationGuard";
import type { ComponentPropsWithoutRef, ReactNode } from "react";
import { lazy, useMemo } from "react";
import { include } from "$root/utils/objects";
import { useHistory } from "react-router";
import { useUserValue, type IUser } from "$root/functional-areas/user";
import type { PlatformLayoutOptions } from "./layout-options";
import type { MaybeFn } from "@mdotm/mdotui/utils";
import { maybeCall } from "@mdotm/mdotui/utils";
import type { Intersect, Replace } from "$root/utils/types";
import type { ServiceType } from "$root/api/api-gen";

export function extractPath(relativeUrl: string): string {
	return new URL(`http://www.example.com/${relativeUrl[0] === "/" ? relativeUrl.slice(1) : relativeUrl}`).pathname;
}
export function extractSearch(relativeUrl: string): string {
	return new URL(`http://www.example.com/${relativeUrl[0] === "/" ? relativeUrl.slice(1) : relativeUrl}`).search;
}
export function extractSearchParams(relativeUrl: string): URLSearchParams {
	return new URL(`http://www.example.com/${relativeUrl[0] === "/" ? relativeUrl.slice(1) : relativeUrl}`).searchParams;
}

export type RouteOptionsContext = { user: IUser };

export type GuardFn = (
	params: RouteOptionsContext,
) => { passed: true } | { passed: false; reason?: "requires-login" | "missing-permission" };

const alwaysPassGuard: GuardFn = () => ({ passed: true });

export type RouteSerializedProps = { query?: string; params?: Record<string, string> };

export type PropsSerde<T> = {
	serialize: (props: T) => RouteSerializedProps;
	deserialize: (url: string, baseUrl: string) => T;
};

const signInGuard: GuardFn = ({ user }) =>
	user.signedIn ? { passed: true } : { passed: false, reason: "requires-login" };

function requiresServiceGuard(requiredService: ServiceType): GuardFn {
	return ({ user }) =>
		hasAccess(user, { requiredService }) ? { passed: true } : { passed: false, reason: "missing-permission" };
}

function andGuards(...guards: GuardFn[]): GuardFn {
	return (params) => {
		for (let i = 0; i < guards.length; i++) {
			const guardResult = guards[i](params);
			if (!guardResult.passed) {
				return guardResult;
			}
		}
		return { passed: true };
	};
}

function orGuards(...guards: GuardFn[]): GuardFn {
	return (params) => {
		let lastGuardResult: ReturnType<GuardFn> | undefined;
		for (let i = 0; i < guards.length; i++) {
			lastGuardResult = guards[i](params);
			if (lastGuardResult.passed) {
				return lastGuardResult;
			}
		}
		return lastGuardResult ?? { passed: false };
	};
}

const defaultLayoutOptions: PlatformLayoutOptions = {
	sideBar: true,
	mode: "default",
};

export type RouteData<Name extends string, TProps extends Record<string, string>> = {
	name: Name;
	baseUrl: string;
	component: (props: TProps) => ReactNode;
	propsSerde: PropsSerde<TProps>;
	guard: GuardFn;
	layoutOptions: MaybeFn<PlatformLayoutOptions, [RouteOptionsContext]>;
};

function createRoute<Name extends string, TProps extends Record<string, string>>({
	name,
	baseUrl,
	component,
	propsSerde,
	guard,
	layoutOptions,
}: Replace<
	RouteData<Name, TProps>,
	{
		layoutOptions?: MaybeFn<Partial<PlatformLayoutOptions> | null, [RouteOptionsContext]>;
		guard?: GuardFn;
		propsSerde?: PropsSerde<TProps>;
	}
>): Record<Name, RouteData<Name, TProps>> {
	const actualGuard = guard ?? alwaysPassGuard;
	const routeData: RouteData<Name, TProps> = {
		name,
		baseUrl,
		component,
		propsSerde: propsSerde ?? noneSerde(),
		guard: actualGuard,
		layoutOptions: (params: RouteOptionsContext) => {
			const overrides = maybeCall(layoutOptions, params);
			return !overrides
				? defaultLayoutOptions
				: {
						...defaultLayoutOptions,
						...overrides,
				  };
		},
	};
	return {
		[name as Name]: routeData,
	} as Record<Name, RouteData<Name, TProps>>;
}

function noneSerde<T extends Record<string, string>>(): PropsSerde<T> {
	return {
		serialize: () => ({}),
		deserialize: () => ({}) as unknown as T,
	};
}

function querySerde<K extends string, T extends [K, ...K[]]>(
	propKeys: T,
): PropsSerde<Partial<Record<T[number], string>>> {
	return {
		serialize: (props) => ({
			query: new URLSearchParams(
				Object.entries(include(props, propKeys)).filter(([_, value]) => value !== undefined) as [string, string][],
			).toString(),
		}),
		deserialize: (url) => {
			const query = Object.fromEntries(extractSearchParams(url).entries());
			if (query["from"] !== undefined) {
				const { from, ...others } = query;
				let customFrom = from;
				Object.entries(others).map(([key, v]) => {
					if (key === "atubm") {
						return;
					}
					customFrom += `&${key}=${v}`;
				});

				return { from: customFrom, ...others } as Record<T[number], string>;
			}

			return query as Record<T[number], string>;
		},
	};
}

function paramsSerde<K extends string, T extends [K, ...K[]]>(propKeys: T): PropsSerde<Record<T[number], string>> {
	return {
		serialize: (props) => {
			return {
				params: include(props, propKeys),
			};
		},
		deserialize: (url, baseUrl) => {
			const urlParts = extractPath(url).split("/");
			const baseUrlParts = extractPath(baseUrl).split("/");
			const props = {} as Partial<Record<keyof T, string>>;
			for (let i = 0; i < Math.min(urlParts.length, baseUrlParts.length); i++) {
				if (baseUrlParts[i].at(0) === ":") {
					props[baseUrlParts[i].slice(1) as keyof T] = urlParts[i];
				}
			}
			return props as Record<T[number], string>;
		},
	};
}

// TODO: add to pages with both parameters and query
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function multiSerde<T extends [PropsSerde<any>, ...PropsSerde<any>[]]>(
	serdes: T,
): {
	serialize(
		props: Intersect<{ [K in keyof T]: Parameters<T[K]["serialize"]>[0] }[number]>, // TODO: why doesn't keyof T work here?
	): ReturnType<PropsSerde<any>["serialize"]>;
	deserialize(url: string, baseUrl: string): Intersect<{ [K in keyof T]: ReturnType<T[K]["deserialize"]> }[number]>;
} {
	return {
		serialize: (props) => serdes.reduce((acc, serde) => ({ ...acc, ...serde.serialize(props as any) }), {}) as any,
		deserialize: (url, baseUrl) =>
			serdes.reduce((acc, serde) => ({ ...acc, ...serde.deserialize(url, baseUrl) }), {}) as any,
	};
} /*

type T1 = Parameters<PropsSerde<Record<"a", string>>["serialize"]>[0];
type T2 = Parameters<PartialPropsSerde<Record<"b", string>>["serialize"]>[0];
type T3 = T1 | T2;
type T4 = Intersect<T3>;
const a: T4 = {};

type Q1 = ReturnType<ReturnType<typeof paramsSerde<string, ["a"]>>["deserialize"]>;
type Q2 = ReturnType<ReturnType<typeof querySerde<string, ["b"]>>["deserialize"]>;
type Q3 = Q1 & Q2;

type Multi = typeof multiSerde<[PropsSerde<Record<"a", string>>, PartialPropsSerde<Record<"b", string>>]>;
const m1: Multi = () => ({
	serialize(props) {
		return {};
	},
	deserialize() {
		return { a: "hello" };
	},
});
const multi = multiSerde([paramsSerde(["a"]), querySerde(["b"])]);
multi.serialize({ a: "hello" }); */

export const routesMap = {
	/* Test: newRoute({
		baseUrl: "/test",
		component: lazy(() => import("../../pages/Test")),
		propsSerde: multiSerde([paramsSerde(["a"]), querySerde(["b"])]),
		layoutOptions: {
			sideBar: false,
			backgroundImage: BgLogin,
		},
	}), */
	...createRoute({
		name: "Login",
		baseUrl: "/login",
		component: lazy(() => import("../../pages/Login")),
		propsSerde: querySerde(["from", "atubm"]),
		layoutOptions: {
			sideBar: false,
			backgroundImage: BgLogin,
		},
	}),
	...createRoute({
		name: "Index",
		baseUrl: "/",
		component: lazy(() => import("../../pages/IndexRedirect")),
		guard: signInGuard,
	}),
	...createRoute({
		name: "MarketDashboard",
		baseUrl: "/dashboard",
		component: lazy(() => import("../../pages/Dashboard")),
		guard: andGuards(signInGuard, requiresServiceGuard("REPORTS")),
	}),
	...createRoute({
		name: "Outlook",
		baseUrl: "/outlook",
		component: lazy(() => import("../../pages/Outlook")),
		guard: andGuards(signInGuard, requiresServiceGuard("REPORTS")),
	}),
	...createRoute({
		name: "AssetClass",
		baseUrl: "/asset_class",
		propsSerde: querySerde(["filter", "area", "sector"]),
		component: lazy(() => import("../../pages/AssetClass/index")),
		guard: andGuards(signInGuard, requiresServiceGuard("REPORTS")),
	}),
	...createRoute({
		name: "RegimeAnalysisTool",
		baseUrl: "/regime_analysis_tool",
		component: lazy(() => import("../../pages/RegimeAnalysisTool")),
		propsSerde: querySerde(["region"]),
		guard: andGuards(signInGuard, requiresServiceGuard("SIGNALS")),
	}),
	...createRoute({
		name: "CustomReports",
		baseUrl: "/custom_reports",
		component: lazy(() => import("../../pages/CustomReports")),
		guard: signInGuard,
	}),
	// ...createRoute({
	// 	name: "PortfolioZipReadyRedirect",
	// 	baseUrl: "/portfolios",
	// 	component: (props) => <Redirect to={typedUrlForRoute("PortfolioStudio/Portfolios", props)} />,
	// 	propsSerde: querySerde(["zipId"]),
	// 	guard: andGuards(signInGuard, requiresServiceGuard("INVESTMENTS")),
	// }),
	...createRoute({
		name: "PortfoliosStudio",
		baseUrl: "/portfolios",
		component: lazy(() => import("../../pages/PortfoliosStudio/index")),
		propsSerde: querySerde(["tab", "status", "zipId"]),
		guard: andGuards(signInGuard, requiresServiceGuard("INVESTMENTS")),
	}),
	// ...createRoute({
	// 	name: "PortfolioStudio/Portfolios",
	// 	baseUrl: "/portfolio_studio/portfolios",
	// 	component: lazy(() => import("../../pages/PortfoliosStudio/index")), // TODO: replace with new page
	// 	propsSerde: querySerde(["zipId"]),
	// 	guard: andGuards(signInGuard, requiresServiceGuard("INVESTMENTS")),
	// }),
	// ...createRoute({
	// 	name: "PortfolioStudio/References",
	// 	baseUrl: "/portfolio_studio/references",
	// 	component: lazy(() => import("../../pages/PortfoliosStudio/index")), // TODO: replace with new page
	// 	propsSerde: querySerde(["zipId"]),
	// 	guard: andGuards(signInGuard, requiresServiceGuard("INVESTMENTS")),
	// }),
	// ...createRoute({
	// 	name: "PortfolioStudio/Universes",
	// 	baseUrl: "/portfolio_studio/universes",
	// 	component: lazy(() => import("../../pages/PortfoliosStudio/index")), // TODO: replace with new page
	// 	propsSerde: querySerde(["zipId"]),
	// 	guard: andGuards(signInGuard, requiresServiceGuard("INVESTMENTS")),
	// }),
	// ...createRoute({
	// 	name: "PortfolioStudio/MarketViews",
	// 	baseUrl: "/portfolio_studio/market_views",
	// 	component: lazy(() => import("../../pages/PortfoliosStudio/index")), // TODO: replace with new page
	// 	propsSerde: querySerde(["zipId"]),
	// 	guard: andGuards(signInGuard, requiresServiceGuard("INVESTMENTS")),
	// }),
	...createRoute({
		name: "Portfolios/ManualPortfolioCreation",
		baseUrl: "/portfolios/manual_creation/portfolio",
		component: lazy(() => import("../../pages/Portfolios/ManualCreation")),
		propsSerde: querySerde(["uuid", "preselectedInvestments"]),
		guard: andGuards(signInGuard, requiresServiceGuard("INVESTMENTS")),
	}),
	...createRoute({
		name: "Portfolios/ManualCreation",
		baseUrl: "/portfolios/manual_creation/:entity",
		component: lazy(() => import("../../pages/ManualCreation")),
		propsSerde: multiSerde([paramsSerde(["entity"]), querySerde(["selectBenchmarkTemplate"])]),
		guard: andGuards(signInGuard, requiresServiceGuard("INVESTMENTS")),
	}),
	...createRoute({
		name: "Portfolios/ManualEditPortfolio",
		baseUrl: "/portfolios/manual_edit/portfolio/:uuid",
		component: lazy(() => import("../../pages/Portfolios/ManualEdit")),
		propsSerde: multiSerde([paramsSerde(["uuid"]), querySerde(["copyComposition"])]),
		guard: andGuards(signInGuard, requiresServiceGuard("INVESTMENTS")),
	}),
	...createRoute({
		name: "Portfolios/ManualEdit",
		baseUrl: "/portfolios/manual_edit/portfolio/:entity/:uuid",
		component: lazy(() => import("../../pages/ManualCreation/ManualEditComposition")),
		propsSerde: paramsSerde(["entity", "uuid"]),
		guard: andGuards(signInGuard, requiresServiceGuard("INVESTMENTS")),
	}),
	...createRoute({
		/* this creates a proposal */
		name: "Portfolios/EditPortfolio",
		baseUrl: "/portfolios/edit/:portfolioUid",
		component: lazy(() => import("../../pages/Portfolios/CreatePortfolio")),
		propsSerde: paramsSerde(["portfolioUid"]),

		guard: andGuards(signInGuard, requiresServiceGuard("INVESTMENTS")),
	}),
	...createRoute({
		/* this edits the settings without creating a proposal */
		name: "Portfolios/SettingsPortfolio",
		baseUrl: "/portfolios/settings/:portfolioUid",
		component: lazy(() => import("../../pages/Portfolios/CreatePortfolio")),
		propsSerde: multiSerde([paramsSerde(["portfolioUid"]), querySerde(["isSettings"])]),

		guard: andGuards(signInGuard, requiresServiceGuard("INVESTMENTS")),
	}),
	...createRoute({
		name: "Portfolios/EditPortfolioMulti",
		baseUrl: "/portfolios/edit-multi/:proposalUuid",
		component: lazy(() => import("../../pages/Portfolios/CreatePortfolioMulti")),
		propsSerde: paramsSerde(["proposalUuid"]),

		guard: andGuards(signInGuard, requiresServiceGuard("INVESTMENTS")),
	}),
	...createRoute({
		name: "Portfolios/SettingsPortfolioMulti",
		baseUrl: "/portfolios/settings-multi/:bulkUid",
		component: lazy(() => import("../../pages/Portfolios/SettingsPortfolioMulti")),
		propsSerde: paramsSerde(["bulkUid"]),

		guard: andGuards(signInGuard, requiresServiceGuard("INVESTMENTS")),
	}),
	...createRoute({
		name: "Portfolios/CreatePortfolio",
		baseUrl: "/portfolios/new",
		component: lazy(() => import("../../pages/Portfolios/CreatePortfolio")),
		guard: andGuards(signInGuard, requiresServiceGuard("INVESTMENTS")),
	}),
	...createRoute({
		name: "Portfolios/Draft",
		baseUrl: "/portfolios/draft/:portfolioUid", //TODO: unify with Portfolios/CreatePortfolio
		component: lazy(() => import("../../pages/Portfolios/CreatePortfolio")),
		propsSerde: paramsSerde(["portfolioUid"]),

		guard: andGuards(signInGuard, requiresServiceGuard("INVESTMENTS")),
	}),
	...createRoute({
		name: "PortfolioDetails",
		baseUrl: "/portfolio_details/:portfolioUid",
		component: lazy(() => import("../../pages/PortfolioDetails")),
		propsSerde: multiSerde([paramsSerde(["portfolioUid"]), querySerde(["proposal", "tab"])]),
		guard: andGuards(signInGuard, requiresServiceGuard("INVESTMENTS")),
	}),
	...createRoute({
		name: "PortfolioReferenceDetails",
		// just an alias for PortfolioDetails
		baseUrl: "/portfolio_reference_details/:portfolioUid",
		component: lazy(() => import("../../pages/PortfolioDetails")),
		propsSerde: multiSerde([paramsSerde(["portfolioUid"]), querySerde(["proposal", "tab"])]),
		guard: andGuards(signInGuard, requiresServiceGuard("INVESTMENTS")),
	}),
	...createRoute({
		name: "UniverseDetails",
		baseUrl: "/universe_details/:universeUuid",
		component: lazy(() => import("../../pages/UniverseDetails")),
		propsSerde: paramsSerde(["universeUuid"]),
		guard: andGuards(signInGuard, requiresServiceGuard("INVESTMENTS")),
	}),
	// TODO: unused?
	...createRoute({
		name: "WorkspaceSettings",
		baseUrl: "/workspace_settings",
		component: lazy(() => import("../../pages/WorkspaceSettings")),
		guard: signInGuard,
	}),
	...createRoute({
		name: "MarketViewWorkSpace",
		baseUrl: "/workspace_market/:action/:uuid/:type",
		component: lazy(() => import("../../pages/MarketViewWorkSpace")),
		propsSerde: multiSerde([paramsSerde(["action", "uuid", "type"]), querySerde(["isCustom"])]),
		guard: andGuards(signInGuard, requiresServiceGuard("INVESTMENTS")),
	}),
	...createRoute({
		name: "MarketViewWorkSpace/New",
		baseUrl: "/workspace_market/:type",
		component: lazy(() => import("../../pages/MarketViewWorkSpace")),
		propsSerde: multiSerde([paramsSerde(["type"]), querySerde(["isCustom"])]),
		guard: signInGuard,
	}),
	...createRoute({
		name: "ForgotPassword",
		baseUrl: "/forgot_password",
		component: lazy(() => import("../../pages/ForgotPassword")),
		layoutOptions: {
			sideBar: false,
			backgroundImage: BgLogin,
		},
	}),
	...createRoute({
		name: "PortfolioStudioSettings",
		baseUrl: "/portfolio_studio_settings",
		component: lazy(() => import("../../pages/PortfolioStudioSettings")),
		propsSerde: querySerde(["tab", "uuid"]),
		guard: signInGuard,
	}),
	...createRoute({
		name: "PortfolioStudioSettings/ReportEditor",
		baseUrl: "/portfolio_studio_settings/report-editor/:reportUid",
		component: lazy(() => import("../../pages/PortfolioStudioSettings/ReportEditor")),
		propsSerde: paramsSerde(["reportUid"]),
		guard: signInGuard,
	}),
	...createRoute({
		name: "Notification",
		baseUrl: "/notification_settings",
		component: lazy(() => import("../../pages/Notification")),
		guard: andGuards(signInGuard, orGuards(requiresServiceGuard("INVESTMENTS"), requiresServiceGuard("REPORTS"))),
	}),
	...createRoute({
		name: "Report",
		baseUrl: "/report/:templateId",
		component: lazy(() => import("../../pages/Report")),
		propsSerde: multiSerde([
			paramsSerde(["templateId"]),
			querySerde(["variant", "objectId", "status", "enhanced", "autoScroll", "historyEventUuid"]),
		]),
		guard: signInGuard,
		layoutOptions: {
			mode: "print",
			sideBar: false,
		},
	}),
	...createRoute({
		name: "Report/Editor",
		baseUrl: "/report_editor/:customerId",
		component: lazy(() => import("../../pages/Report/ReportEditor")),
		propsSerde: paramsSerde(["customerId"]),
		guard: signInGuard,
		layoutOptions: {
			mode: "print",
			sideBar: false,
		},
	}),
	...createRoute({
		name: "Report/Demo",
		baseUrl: "/report/:templateId",
		component: lazy(() => import("../../pages/Report")),
		propsSerde: paramsSerde(["templateId"]),
		guard: signInGuard,
		layoutOptions: {
			mode: "print",
			sideBar: false,
		},
	}),
	...createRoute({
		name: "CustomBenchmark",
		baseUrl: "/custom_benchmarks/:benchmarkId",
		component: lazy(() => import("../../pages/CustomBenchmark")),
		propsSerde: paramsSerde(["benchmarkId"]),
		guard: signInGuard,
	}),
	/* ...createRoute({
		name: "CustomBenchmark/Creation",
		baseUrl: "/custom_benchmarks/create",
		component: lazy(() => import("../../pages/CustomBenchmark/Creation")),
		guard: signInGuard,
	}), */
	...createRoute({
		name: "AdvanceSettings/UserSettingsPanel",
		baseUrl: "/advance_settings/settings_panel",
		component: lazy(() => import("../../pages/AdvanceSettings/UserSettingsPanel")),
		guard: signInGuard,
	}),
	...createRoute({
		name: "AdvanceSettings/AdvancedEditor",
		baseUrl: "/advance_settings/advanced_editor",
		component: lazy(() => import("../../pages/AdvanceSettings/AdvancedEditor")),
		guard: signInGuard,
	}),
	...createRoute({
		name: "AdvanceSettings/CommentaryPrompt",
		baseUrl: "/advance_settings/chatgpt_prompt",
		component: lazy(() => import("../../pages/AdvanceSettings/CommentaryPrompt")),
		guard: signInGuard,
	}),
	...createRoute({
		name: "AdvanceSettings/PlatformInfo",
		baseUrl: "/advance_settings/platform_info",
		component: lazy(() => import("../../pages/AdvanceSettings/PlatformInfo")),
		guard: signInGuard,
	}),
	...createRoute({
		name: "Maintenance",
		baseUrl: "/maintenance",
		component: lazy(() => import("../../pages/Maintenance")),
		layoutOptions: {
			mode: "print",
			sideBar: false,
		},
	}),
	...createRoute({
		name: "Storyfolio/Details",
		baseUrl: "/storyfolio/details/:uuid",
		component: lazy(() => import("../../pages/Storyfolio/StoryfolioDetails")),
		propsSerde: paramsSerde(["uuid"]),
		guard: andGuards(signInGuard, requiresServiceGuard("COMMENTARY_BUILDER")),
	}),
	...createRoute({
		name: "Storyfolio/Creation",
		baseUrl: "/storyfolio/new",
		component: lazy(() => import("../../pages/Storyfolio/StoryfolioCreation")),
		guard: andGuards(signInGuard, requiresServiceGuard("COMMENTARY_BUILDER")),
	}),
	...createRoute({
		name: "Storyfolio/Studio",
		baseUrl: "/storyfolio",
		component: lazy(() => import("../../pages/Storyfolio")),
		guard: andGuards(signInGuard, requiresServiceGuard("COMMENTARY_BUILDER")),
	}),
	...createRoute({
		name: "Page404",
		baseUrl: "*",
		component: lazy(() => import("../../pages/404")),
		layoutOptions: {
			mode: "print",
			sideBar: false,
		},
	}),
};

export function useCurrentRoutesMap(): {
	[K in keyof typeof routesMap]: Omit<(typeof routesMap)[K], "layoutOptions" | "sideBarOptions"> & {
		layoutOptions: PlatformLayoutOptions;
	};
} {
	const user = useUserValue();
	return useMemo(() => {
		const ctx = { user };
		return Object.fromEntries(
			Object.entries(routesMap).map(([key, route]) => [
				key,
				{
					...route,
					layoutOptions: maybeCall(route.layoutOptions, ctx),
				},
			]),
		) as any;
	}, [user]);
}

type RouteName = keyof typeof routesMap;
type PropsForRouteHelper<K extends RouteName> = ComponentPropsWithoutRef<Awaited<(typeof routesMap)[K]["component"]>>;
type PropsForRoute<K extends RouteName> = PropsForRouteHelper<K> extends Record<string, string>
	? PropsForRouteHelper<K>
	: null;

function typedNavigation<K extends RouteName>(baseFn: (url: string) => void, target: K, props: PropsForRoute<K>): void {
	baseFn(typedUrlForRoute(target, props));
}

export function typedUrlForRoute<K extends RouteName>(target: K, props: PropsForRoute<K>): string {
	const serializedProps = props ? routesMap[target].propsSerde.serialize(props as any) : {};
	return (
		Object.entries(serializedProps.params ?? {}).reduce(
			(url, [key, value]) => url.replace(`:${key}`, value),
			routesMap[target].baseUrl,
		) +
		`${serializedProps.query ? `${routesMap[target].baseUrl.includes("?") ? "&" : "?"}${serializedProps.query}` : ""}`
	);
}

export function typedPropsFromUrl<K extends RouteName>(target: K, url: string): PropsForRoute<K> {
	return routesMap[target].propsSerde.deserialize(url, routesMap[target].baseUrl) as PropsForRoute<K>;
}

export function useTypedNavigation(): {
	push<K extends RouteName>(target: K, props: PropsForRoute<K>): void;
	replace<K extends RouteName>(target: K, props: PropsForRoute<K>): void;
} {
	const { push, replace } = useHistory();
	return useMemo(
		() => ({
			push: (target, props) => typedNavigation(push, target, props),
			replace: (target, props) => typedNavigation(replace, target, props),
		}),
		[push, replace],
	);
}
/* sideBarOptions: (params: RouteOptionsContext) => {
			const overrides = maybeCall(sideBarOptions, params);
			return !overrides
				? null
				: {
						subMenuItems: null,
						...overrides,
						show: actualGuard(params).passed && overrides.show !== false,
				  };
		}, */
