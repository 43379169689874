import {
	EntityEditorControllerApiFactory,
	InvestmentEnhancementReportsControllerApiFactory,
	InvestmentReportsControllerApiFactory,
} from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { CustomLabels } from "$root/components/CustomLabels";
import { typedUrlForRoute } from "$root/components/PlatformRouter/RoutesDef";
import { useLocaleFormatters } from "$root/localization/hooks";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { ActionText, CircularProgressBar, Text } from "@mdotm/mdotui/components";
import { useMemo } from "react";

export type SmallInvestmentSummaryProps = { portfolio?: { uuid?: string; reference?: boolean }; enhanced: boolean };

export function SmallInvestmentSummary(props: SmallInvestmentSummaryProps): JSX.Element {
	const { portfolio, enhanced } = props;

	const investmentApi = useApiGen(InvestmentReportsControllerApiFactory);
	const investmentEnhanceApi = useApiGen(InvestmentEnhancementReportsControllerApiFactory);
	const editorApi = useApiGen(EntityEditorControllerApiFactory);

	const formatters = useLocaleFormatters();
	const { formatNumber } = formatters;

	const { data, isLoading, isError } = useQueryNoRefetch(["investmentSummaryData", portfolio?.uuid, portfolio], {
		enabled: Boolean(portfolio?.uuid),
		queryFn: async () => {
			if (!portfolio || !portfolio.uuid) {
				throw new Error("missing portfolio");
			}

			if (enhanced) {
				const [enhanceInvestment, compositionEnhanche] = await Promise.all([
					investmentEnhanceApi.getInvestmentEnhancementSummary(portfolio.uuid),
					investmentEnhanceApi.getInvestmentComposition1(portfolio.uuid),
				]);
				return {
					summary: enhanceInvestment.data,
					composition: compositionEnhanche.data,
					upload: undefined,
					type: "enhance" as const,
				};
			}

			const [investment, composition] = await Promise.all([
				investmentApi.getInvestmentSummary(portfolio.uuid),
				investmentApi.getInvestmentComposition(portfolio.uuid),
			]);

			if (
				["REVIEW", "CALCULATING"].includes(investment.data.status ?? "ERROR") &&
				investment.data.createdFromUpload &&
				investment.data.action !== "ENHANCEMENT"
			) {
				//rick: getInvestmentUploadDetails
				const upload = await editorApi.getEditorReviewComposition(
					portfolio.uuid ?? "",
					portfolio.reference ? "TARGET_INVESTMENT" : "INVESTMENT",
				);
				return {
					summary: investment.data,
					composition: composition.data,
					upload: upload.data,
					type: "investment" as const,
				};
			}

			return {
				summary: investment.data,
				composition: composition.data,
				type: "investment" as const,
			};
		},
	});

	const instrumentsNumber = useMemo(() => {
		if (!data) {
			return null;
		}
		// if (["RETRIEVING_DATA", "REVIEW"].includes(data.summary.status ?? "-")) {
		// 	if (data.type === "enhance") {
		// 		return data.composition.composition?.length;
		// 	}
		// 	return data.upload?.composition?.length;
		// }
		// return data.composition.composition?.length;
		return data.summary.nofInstruments;
	}, [data]);

	if (isLoading) {
		return (
			<div className="bg-white rounded px-2 py-2">
				<div className="flex flex-1 w-full items-center justify-center">
					<CircularProgressBar value="indeterminate" outerDiameter={24} innerDiameter={20} />
				</div>
			</div>
		);
	}

	if (isError || data === undefined) {
		return <></>;
	}

	if (data.summary.reference) {
		return (
			<div className="bg-white rounded px-2 py-2">
				<div className="flex items-center gap-4">
					<div>
						<Text type="Body/M/Book">Currency</Text>
						&nbsp;
						<Text type="Body/M/Bold">{data.summary.baseCurrency ?? "-"}</Text>
					</div>
					<div>
						<Text type="Body/M/Book">Total instruments</Text>
						&nbsp;
						<Text type="Body/M/Bold">{data.summary.nofInstruments ?? "-"}</Text>
					</div>
				</div>
			</div>
		);
	}

	/* if (["RETRIEVING_DATA", "REVIEW"].includes(data.summary.status ?? "-")) {
		return (
			<ReviewInfoBlock summary={data.summary} composition={data.composition} upload={data.upload} type={data.type} />
		);
	} */

	return (
		<div className="bg-white rounded px-2 py-2">
			<div className="flex items-center gap-4">
				<div>
					<Text type="Body/M/Book">Currency</Text>
					&nbsp;
					<Text type="Body/M/Bold">{data.summary.baseCurrency ?? "-"}</Text>
				</div>
				<div>
					<Text type="Body/M/Book">Benchmark</Text>
					&nbsp;
					{data.summary.primaryBenchmarkType === "CUSTOM_BENCHMARK" ? (
						<ActionText
							as="a"
							href={typedUrlForRoute("CustomBenchmark", {
								benchmarkId: data.summary.primaryBenchmarkIdentifier ?? "",
							})}
							type="Body/M/Bold"
						>
							{data.summary.primaryBenchmarkName ?? "-"}
						</ActionText>
					) : (
						<Text type="Body/M/Bold">{data.summary.primaryBenchmarkName ?? "-"}</Text>
					)}
				</div>
				{data.summary.universeIdentifier && (
					<div>
						<Text type="Body/M/Book">Universe</Text>
						&nbsp;
						<ActionText
							as="a"
							href={typedUrlForRoute("UniverseDetails", {
								universeUuid: data.summary.universeIdentifier ?? "",
							})}
							type="Body/M/Bold"
						>
							{data.summary.universeName ?? "-"}
						</ActionText>
					</div>
				)}
				<div>
					<Text type="Body/M/Book">Total instruments</Text>
					&nbsp;
					<Text type="Body/M/Bold">{instrumentsNumber ?? "-"}</Text>
				</div>
				{data.summary.scoreIdentifier && data.summary.scoreValue && (
					<div>
						<Text type="Body/M/Book">Avg. Score</Text>
						&nbsp;
						<Text type="Body/M/Bold">
							{formatNumber(data.summary.scoreValue, 2)}&nbsp; (
							<CustomLabels labelKey={data.summary.scoreIdentifier ?? "-"} fallback="SCORE" />)
						</Text>
					</div>
				)}
			</div>
		</div>
	);
}
