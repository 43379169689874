import { reportPlatformError } from "$root/api/error-reporting";
import { makeRestartable } from "$root/utils/promise";
import { useUnsafeUpdatedRef } from "@mdotm/mdotui/react-extensions";
import type { MaybePromise } from "@mdotm/mdotui/headless";
import { sleep } from "@mdotm/mdotui/utils";
import { useMemo, useRef, useState } from "react";

export function useDebouncedNameUniquenessChecker({
	isNameAvailableApi,
	currentName,
	debounceMs,
}: {
	isNameAvailableApi: (name: string, opts?: { signal?: AbortSignal }) => MaybePromise<boolean>;
	currentName?: string;
	debounceMs?: number;
}): {
	checkIfNameIsAvailable: (name: string) => Promise<boolean>;
	checkingNameUniqueness: boolean;
} {
	const [pendingRequests, setPendingRequests] = useState(0);
	const cacheRef = useRef<Record<string, boolean | undefined>>({});
	const refs = useUnsafeUpdatedRef({ currentName, debounceMs, isNameAvailableApi });
	const checkIfNameIsAvailable = useMemo(
		() =>
			makeRestartable(async (name: string, opts?: { signal?: AbortSignal }): Promise<boolean> => {
				setPendingRequests((n) => n + 1);
				try {
					if (refs.current.currentName && refs.current.currentName === name) {
						return true;
					}
					const cache = cacheRef.current;
					const candidate = cache[name];
					if (candidate !== undefined) {
						return candidate;
					}
					try {
						await sleep(refs.current.debounceMs ?? 400, opts); // debounce
						const data = await refs.current.isNameAvailableApi(name, opts);
						cache[name] = data;
					} catch (err) {
						if (opts?.signal?.aborted) {
							return true;
						}
						reportPlatformError(err, "WARN", undefined, { message: "check if entity name is available" });
						cache[name] = false;
						return false;
					}

					return cache[name] ?? false;
				} finally {
					setPendingRequests((n) => n - 1);
				}
			}),
		[refs],
	);

	return {
		checkIfNameIsAvailable,
		checkingNameUniqueness: pendingRequests > 0,
	};
}
