import type { DialogProps } from "@mdotm/mdotui/components";
import type { InvestmentListEntry, ReviewTicker } from "$root/api/api-gen";
import { EditorSelectableBasketsRequestEntityEnum } from "$root/api/api-gen";

export type InstrumentEditorEntity = EditorSelectableBasketsRequestEntityEnum;
export const InstrumentEditorEntity = EditorSelectableBasketsRequestEntityEnum;
export type InstrumentEditorEntry = ReviewTicker & {
	rowId: string;
	investment?: InvestmentListEntry;
	someInstrumentsAreExcluded?: boolean;
	nOfInstrumentExcluded?: number;
	stableWeight?: number;
};

export type GroupedInstrumentEditorEntry = {
	rows: InstrumentEditorEntry[];
	assetClass: string;
	weight: number;
	differenceWeight?: number;
	enhancedWeight?: number;
	previousWeight?: number;
};

export type MinimunDialogProps = {
	show: boolean;
	onClose?(): void;
	onAnimationStateChange?: DialogProps["onAnimationStateChange"];
};

export enum RowTypeEnum {
	UPLOAD = "UPLOAD",
	ADD = "ADD",
	SELECT = "SELECT",
	CASH = "CASH",
}
export type rowType = keyof typeof RowTypeEnum;

export type MandatoryColumnEnum = "CHECKBOX" | "DELETE" | "WEIGHT";
