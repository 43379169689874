import {
	PortfolioStudioPreferencesApiFactory,
	type UserCompositionColumnPreference,
	type UserCompositionColumnPreferencePreferenceTypeEnum,
} from "$root/api/api-gen";
import { IconWalls } from "$root/components/IconWall";
import type { TableColumn } from "@mdotm/mdotui/components";
import { AutoSortHScrollTable, BatchActions, useSelectableTableColumn } from "@mdotm/mdotui/components";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { useMemo } from "react";
import {
	normaliseAndVerifyInstrumentPortfolioCompositon,
	useCompositionToolBar,
	verifySomeInstrumentsAreExcluded,
} from "../../builder";
import type { InstrumentEditorEntry, MandatoryColumnEnum } from "../../const";
import type { InstrumentCompostionEditorProps } from "../../instrumentEditor";
import NormaliseAndExcessToCashBanner from "../NormaliseAndExcessToCashBanner";
import { useInstrumentCompositionColumns } from "../../columns";
import InstrumentLimitReachedBanner from "../InstrumentLimitReachedBanner";
import NormalisedInvestmentInstrumentBanner from "../NormalisedInvestmentInstrumentBanner";
import { useApiGen } from "$root/api/hooks";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { ReactQueryWrapperBase } from "$root/components/ReactQueryWrapper";
import { useLocaleFormatters } from "$root/localization/hooks";

type PortfolioColumnPreferencesEnum = UserCompositionColumnPreferencePreferenceTypeEnum | MandatoryColumnEnum;
const defaultPortfolioColumnPreference: Array<PortfolioColumnPreferencesEnum> = [
	"CHECKBOX",
	"INSTRUMENT_NAME",
	"IDENTIFIER",
	"ASSET_CLASS",
	"MICRO_ASSET_CLASS",
	"WEIGHT",
	"DELETE",
];

type DefaultInstrumentTableProps = InstrumentCompostionEditorProps & {
	filteredRows: InstrumentEditorEntry[];
	rows: InstrumentEditorEntry[];
};

function BaseInstrumentTable(props: DefaultInstrumentTableProps): JSX.Element {
	const portfolioStudioPreferencesApi = useApiGen(PortfolioStudioPreferencesApiFactory);
	const queryCompositionColumn = useQueryNoRefetch(["queryBaseColumnPreferences"], {
		queryFn: () => axiosExtract(portfolioStudioPreferencesApi.getUserCompositionColumnOrderingPreferences()),
		refetchOnMount: false,
	});

	return (
		<ReactQueryWrapperBase query={queryCompositionColumn}>
			{({ userCompositionColumnPreferences }) => (
				<BaseInstrumentTableInner {...props} columnPreferences={userCompositionColumnPreferences} />
			)}
		</ReactQueryWrapperBase>
	);
}

function BaseInstrumentTableInner({
	instrumentBuilder,
	filteredRows,
	rows,
	columnPreferences,
	entity,
	limit,
}: DefaultInstrumentTableProps & {
	columnPreferences?: UserCompositionColumnPreference[];
}): JSX.Element {
	const toolbar = useCompositionToolBar();
	const flatInstrumentCompositionColumns = useInstrumentCompositionColumns();
	const tagsMap = instrumentBuilder.watchTags();
	const tagOptions = useMemo(() => Array.from(tagsMap.values()), [tagsMap]);
	const { formatNumber } = useLocaleFormatters();

	const selectableTableColumn = useSelectableTableColumn({
		rows,
		filteredRows,
		selectBy: (r) => r.rowId,
	});

	const totalWeight = useMemo(() => {
		if (rows.length > 0) {
			return `${formatNumber(instrumentBuilder.getTotal("weight"))}%`;
		}
		return undefined;
	}, [formatNumber, instrumentBuilder, rows.length]);

	const totalScore = useMemo(() => {
		if (rows.length > 0) {
			return `Scored: ${formatNumber(instrumentBuilder.getTotal("score"))}`;
		}
		return undefined;
	}, [formatNumber, instrumentBuilder, rows.length]);

	const mappedColumns = useMemo<Record<PortfolioColumnPreferencesEnum, TableColumn<InstrumentEditorEntry>>>(
		() => ({
			INSTRUMENT_NAME: flatInstrumentCompositionColumns.name({
				onCompare(row, action) {
					if (action === "add") {
						instrumentBuilder.setInstrumentToCompare([row]);
						return;
					}

					instrumentBuilder.deleteComparedInstruments(row.rowId);
				},
				isRowCompared(rowId) {
					return instrumentBuilder.getComparedInstrument(rowId) !== undefined;
				},
			}),
			IDENTIFIER: flatInstrumentCompositionColumns.identifier(),
			ASSET_CLASS: flatInstrumentCompositionColumns.assetClass(),
			MICRO_ASSET_CLASS: flatInstrumentCompositionColumns.microAssetClass(),
			SCORE: {
				...flatInstrumentCompositionColumns.score({}),
				footerCellClassList: "font-semibold justify-end",
				footer: totalScore,
				align: "end",
			},
			TAG: flatInstrumentCompositionColumns.tag({
				options: tagOptions,
			}),
			WEIGHT: {
				...flatInstrumentCompositionColumns.weight({
					onChangeWeight: (rowId, v) => instrumentBuilder.setWeight(rowId, v ?? undefined),
					isInstrumentDeleted: (rowId) => instrumentBuilder.isInstrumentDeleted(rowId),
					onCheckIsMinimumWeightValid: async (row, weight) => {
						const instrumentsExcluded = await verifySomeInstrumentsAreExcluded(
							{ ...row, weight: weight ?? undefined },
							entity,
						);
						instrumentBuilder.setInstrument(row.rowId, {
							...row,
							weight: weight ?? undefined,
							someInstrumentsAreExcluded: instrumentsExcluded.length > 0,
							nOfInstrumentExcluded: instrumentsExcluded.length,
						});
					},
					weightProvider: (rowId) => instrumentBuilder.getWeight(rowId),
				}),
				footerCellClassList: "font-semibold justify-end",
				footer: totalWeight,
				align: "end",
				minWidth: 130,
			},
			CHECKBOX: selectableTableColumn.column,
			DELETE: flatInstrumentCompositionColumns.deleteRestore({
				deleted: instrumentBuilder.watchDeleted(),
				onChange: (row, deleteSet) => instrumentBuilder.delete("soft", row.rowId, deleteSet.has(row.rowId)),
			}),
		}),
		[
			entity,
			flatInstrumentCompositionColumns,
			instrumentBuilder,
			selectableTableColumn.column,
			tagOptions,
			totalScore,
			totalWeight,
		],
	);

	const columns = useMemo(() => {
		if (!columnPreferences) {
			const mainColumns = defaultPortfolioColumnPreference.map((column) => mappedColumns[column]);
			return [...mainColumns];
		}
		const enabledColumns = columnPreferences.flatMap((preference) =>
			preference.enabled && preference.preferenceType && preference.preferenceType !== "WEIGHT"
				? [preference.preferenceType]
				: [],
		);
		const enrichedColumns: PortfolioColumnPreferencesEnum[] = ["CHECKBOX", ...enabledColumns, "WEIGHT", "DELETE"];
		return enrichedColumns.map((column) => mappedColumns[column]);
	}, [columnPreferences, mappedColumns]);

	const actions = useMemo(
		() => [
			toolbar.softDelete({
				instrumentBuilder,
				selected: selectableTableColumn.multiSelectCtx,
			}),
			toolbar.restoreDeleted({
				instrumentBuilder,
				selected: selectableTableColumn.multiSelectCtx,
			}),
			toolbar.compare({
				instrumentBuilder,
				selected: selectableTableColumn.multiSelectCtx,
			}),
		],
		[instrumentBuilder, selectableTableColumn.multiSelectCtx, toolbar],
	);

	return (
		<>
			<BatchActions
				total={rows.length}
				selected={selectableTableColumn.multiSelectCtx.selection.size}
				palette="tertiary"
				actions={actions}
				classList="py-4 h-[58px] shrink-0"
			/>
			<NormalisedInvestmentInstrumentBanner
				entity={entity}
				someInvestmentsHasExcluedInstrument={rows.some((instrument) => instrument.someInstrumentsAreExcluded)}
			/>
			<AutoSortHScrollTable
				columns={columns}
				rows={filteredRows}
				pinnedColumns={[
					{ name: "name", side: "left" },
					{ name: "deleteRestore", side: "right" },
				]}
				noDataText={<IconWalls.EditorEmptyData entity={entity} />}
				onRowClick={(row) => selectableTableColumn.toggle(row.rowId)}
				rowStyle={(row) => {
					if (row.someInstrumentsAreExcluded) {
						return { backgroundColor: themeCSSVars.global_palette_warning_100 };
					}

					if (instrumentBuilder.isInstrumentDeleted(row.rowId)) {
						return { backgroundColor: themeCSSVars.Table_highlightedRowBackgroundColor };
					}
				}}
				palette="uniform"
			/>
			<br />
			{limit && rows.length > limit && <InstrumentLimitReachedBanner limit={limit} />}
			<br />
			<NormaliseAndExcessToCashBanner
				disableNormalise={selectableTableColumn.multiSelectCtx.selection.size === 0}
				disableNormaliseTooltip={selectableTableColumn.multiSelectCtx.selection.size > 0}
				entity={entity}
				instrumentBuilder={instrumentBuilder}
				onAsyncNormalise={() =>
					normaliseAndVerifyInstrumentPortfolioCompositon({
						instrumentBuilder,
						entity,
						selection: selectableTableColumn.multiSelectCtx.selection,
					})
				}
				onExcessToCash={() => instrumentBuilder.excessToCash()}
			/>
		</>
	);
}

export default BaseInstrumentTable;
