import type { Currencies, InvestmentBenchmark, InvestmentReference } from "$root/api/api-gen";
import {
	EditorSaveEditOrReviewRequestPortfolioSavingModeEnum,
	EntityEditorControllerApiFactory,
	InvestmentControllerV4ApiFactory,
} from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { ReactQueryWrapperBase } from "$root/components/ReactQueryWrapper";
import type { InstrumentEditorEntity, MinimunDialogProps } from "$root/functional-areas/instruments-editor/const";
import { useDebouncedNameUniquenessChecker } from "$root/functional-areas/named-entities/uniqueness";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { FormController } from "$root/third-party-integrations/react-hook-form";
import { FormFields } from "$root/ui-lib/form/FormFields";
import { valueByPath } from "$root/utils/objects";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { zodResolver } from "@hookform/resolvers/zod";
import type { Option, OptionWithOptionalGroup } from "@mdotm/mdotui/components";
import {
	Button,
	CircularProgressBar,
	Dialog,
	DialogFooter,
	FormField,
	Radio,
	RadioGroup,
	SubmitButton,
} from "@mdotm/mdotui/components";
import type { MaybePromise } from "@mdotm/mdotui/headless";
import type { SpawnResult } from "@mdotm/mdotui/react-extensions";
import { adaptAnimatedNodeProvider, spawn, useRefProxyWithState } from "@mdotm/mdotui/react-extensions";
import { useRef } from "react";
import { flushSync } from "react-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";

export type ManualCreationFormProps = {
	baseCurrency?: Currencies;
	name?: string;
	primaryBenchmark?: InvestmentBenchmark;
	investmentReference?: InvestmentReference;
	portfolioSavingMode?: EditorSaveEditOrReviewRequestPortfolioSavingModeEnum;
};

type SubmitManualCreationDialogMode = "save-as-portfolio" | "save-as-draft";
const defaultSubmitManualCreationDialogMode: SubmitManualCreationDialogMode = "save-as-portfolio";
export type SubmitManualCreationPayload = ManualCreationFormProps & { submitMode: SubmitManualCreationDialogMode };
export type SubmitManualCreationDialogProps = {
	includesInvestments: boolean;
	defaultManualCreation?: ManualCreationFormProps;
	onAsyncSubmit(payload: SubmitManualCreationPayload): MaybePromise<void>;
	entity: Extract<InstrumentEditorEntity, "INVESTMENT" | "INVESTMENT_DRAFT">;
} & MinimunDialogProps;

function SubmitManualCreationDialog(props: SubmitManualCreationDialogProps) {
	const {
		includesInvestments,
		show,
		onClose,
		onAnimationStateChange,
		defaultManualCreation = {},
		onAsyncSubmit,
		entity,
	} = props;

	const { t } = useTranslation();
	const entityEditorApi = useApiGen(EntityEditorControllerApiFactory);
	const investmentApi = useApiGen(InvestmentControllerV4ApiFactory);

	const submitModeRef = useRef<SubmitManualCreationDialogMode>(defaultSubmitManualCreationDialogMode);
	const [submitMode, setSubmitMode] = useRefProxyWithState(defaultSubmitManualCreationDialogMode, submitModeRef);

	const { checkIfNameIsAvailable, checkingNameUniqueness } = useDebouncedNameUniquenessChecker({
		isNameAvailableApi: (name, opts) => axiosExtract(investmentApi.isInvestmentNameAvailable(name, opts)),
	});

	const { control, formState, handleSubmit } = useForm({
		defaultValues: defaultManualCreation,
		resolver: zodResolver(
			z.object(
				(submitMode === "save-as-draft"
					? {
							name: z
								.string()
								.min(1, t("MISSING_FIELD.INVESTMENT"))
								.refine(
									(name) => {
										if (defaultManualCreation.name || entity === "INVESTMENT_DRAFT") {
											return true;
										}
										return checkIfNameIsAvailable(name);
									},
									{
										message: t("MISSING_FIELD.NAME_NOT_AVAILABLE"),
									},
								),
							baseCurrency: z.string().optional(),
							primaryBenchmark: z
								.object(
									{
										benchmarkIdentifier: z.string(),
										benchmarkType: z.string(),
									},
									{
										invalid_type_error: "Please select a benchmark",
										required_error: "Please select a valid benchmark",
									},
								)
								.optional()
								.nullable(),
							investmentReference: z
								.object({
									referenceType: z.any(),
									referenceIdentifier: z.string().nullable(),
								} satisfies Record<keyof InvestmentReference, unknown>)
								.optional()
								.nullable(),
							portfolioSavingMode: includesInvestments
								? z.nativeEnum(EditorSaveEditOrReviewRequestPortfolioSavingModeEnum).optional()
								: z.nativeEnum(EditorSaveEditOrReviewRequestPortfolioSavingModeEnum).optional(),
					  }
					: {
							name: z
								.string()
								.min(1, t("MISSING_FIELD.INVESTMENT"))
								.refine(
									(name) => {
										if (defaultManualCreation.name || entity === "INVESTMENT_DRAFT") {
											return true;
										}
										return checkIfNameIsAvailable(name);
									},
									{
										message: t("MISSING_FIELD.NAME_NOT_AVAILABLE"),
									},
								),
							primaryBenchmark: z
								.object(
									{
										benchmarkIdentifier: z.string(),
										benchmarkType: z.string(),
									},
									{
										invalid_type_error: "Please select a benchmark",
										required_error: "Please select a valid benchmark",
									},
								)
								.required()
								.passthrough(),
							baseCurrency: z.string(),
							investmentReference: z
								.object({
									referenceType: z.any(),
									referenceIdentifier: z.string().nullable(),
								} satisfies Record<keyof InvestmentReference, unknown>)
								.optional()
								.nullable(),
							portfolioSavingMode: includesInvestments
								? z.nativeEnum(EditorSaveEditOrReviewRequestPortfolioSavingModeEnum)
								: z.nativeEnum(EditorSaveEditOrReviewRequestPortfolioSavingModeEnum).optional(),
					  }) satisfies Record<keyof ManualCreationFormProps, unknown>,
			),
		),
	});

	const selectableQuery = useQueryNoRefetch({
		queryKey: ["selectable", "currencies", "benchmarks"],
		queryFn: async () => {
			const selectable = await axiosExtract(entityEditorApi.getEditorNewSelectableMainInfo());

			const currencies = (selectable.availableCurrencies ?? []).map(
				(currency): Option<Currencies> => ({
					label: currency ?? "",
					value: currency,
				}),
			);

			const benchmarks = (selectable.availablePrimaryBenchmarks ?? []).map(
				(el): OptionWithOptionalGroup<InvestmentBenchmark, string> => ({
					label: el.benchmarkName ?? "",
					value: { benchmarkIdentifier: el.benchmarkIdentifier, benchmarkType: el.benchmarkType },
					group: t(`INVESTMENT_REFERENCE_CATEGORIES.${el.benchmarkType!}`),
					disabled: !el.available,
				}),
			);

			const references = (selectable.availableInvestmentReferences ?? []).map(
				(el): OptionWithOptionalGroup<InvestmentReference, string> => ({
					label: el.name ?? "-",
					value: { referenceIdentifier: el.identifier, referenceType: el.type } satisfies InvestmentReference,
					group: t(`INVESTMENT_REFERENCE_CATEGORIES.${el.type!}`),
					disabled: !el.available,
				}),
			);

			return { currencies, benchmarks, references };
		},
		refetchOnMount: false,
		cacheTime: 1000 * 60 * 60 * 4,
	});

	return (
		<Dialog
			size="medium"
			noValidate
			show={show}
			onClose={onClose}
			header={entity === "INVESTMENT" ? "Save portfolio" : "Save portfolio draft"}
			onAnimationStateChange={onAnimationStateChange}
			footer={
				<DialogFooter
					neutralAction={
						<Button palette="tertiary" onClick={onClose}>
							{t("BUTTON.CANCEL")}
						</Button>
					}
					secondaryAction={
						<SubmitButton
							palette="secondary"
							data-qualifier="CompositionEditor/Modal/SaveAsDraft"
							onClick={() => flushSync(() => setSubmitMode("save-as-draft"))}
						>
							{entity === "INVESTMENT_DRAFT" ? t("BUTTON.SAVE_DRAFT") : t("BUTTON.SAVE_AS_DRAFT")}
						</SubmitButton>
					}
					primaryAction={
						<SubmitButton
							data-qualifier="CompositionEditor/Modal/Save"
							onClick={() => flushSync(() => setSubmitMode("save-as-portfolio"))}
						>
							{t("BUTTON.GENERATE")}
						</SubmitButton>
					}
				/>
			}
			onSubmitAsync={async () => {
				await handleSubmit((payload) => onAsyncSubmit({ ...payload, submitMode }))();
			}}
		>
			<div className="grid gap-4 transition-all ease-elastic">
				<FormFields.Text
					control={control}
					formState={formState}
					name="name"
					label="Portfolio name"
					data-qualifier="CompositionEditor/SaveDialog/Name"
					placeholder="Name"
					disabled={entity === "INVESTMENT_DRAFT"}
					rightContent={
						checkingNameUniqueness ? <CircularProgressBar classList="w-3" value="indeterminate" /> : undefined
					}
				/>

				<ReactQueryWrapperBase
					query={selectableQuery}
					loadingFallback={<CircularProgressBar style={{ width: "1rem" }} value="indeterminate" />}
				>
					{({ currencies }) => (
						<FormFields.Select
							control={control}
							formState={formState}
							name="baseCurrency"
							label="Base currency"
							i18n={{ triggerPlaceholder: () => t("PORTFOLIOS.PORTFOLIO_CURRENCY_PLACEHOLDER") }}
							style={{ width: "100%" }}
							options={currencies}
							data-qualifier="CompositionEditor/SaveDialog/Currency"
							enableSearch
						/>
					)}
				</ReactQueryWrapperBase>
				<ReactQueryWrapperBase
					query={selectableQuery}
					loadingFallback={<CircularProgressBar style={{ width: "1rem" }} value="indeterminate" />}
				>
					{({ benchmarks }) => (
						<FormFields.Select
							enableSearch
							control={control}
							formState={formState}
							name="primaryBenchmark"
							label="Comparative benchmark"
							options={benchmarks}
							data-qualifier="CompositionEditor/SaveDialog/Benchmark"
							i18n={{
								triggerPlaceholder: () => t("PORTFOLIOS.PORTFOLIO_BENCHMARK_PLACEHOLDER"),
							}}
							style={{ width: "100%" }}
						/>
					)}
				</ReactQueryWrapperBase>

				<ReactQueryWrapperBase
					query={selectableQuery}
					loadingFallback={<CircularProgressBar style={{ width: "1rem" }} value="indeterminate" />}
				>
					{({ references }) => (
						<FormFields.Select
							enableSearch
							control={control}
							formState={formState}
							name="investmentReference"
							label="Reference"
							options={references}
							data-qualifier="CompositionEditor/SaveDialog/investmentReference"
							i18n={{
								triggerPlaceholder: () => t("PORTFOLIOS.PORTFOLIO_REFERENCE_PLACEHOLDER"),
							}}
							style={{ width: "100%" }}
							unselectOnMatch
						/>
					)}
				</ReactQueryWrapperBase>

				{includesInvestments && (
					<FormField
						label="Composition type"
						error={(valueByPath(formState.errors, "portfolioSavingMode") as { message?: string })?.message}
					>
						{(fieldProps) => (
							<FormController
								control={control}
								name="portfolioSavingMode"
								defaultValue={EditorSaveEditOrReviewRequestPortfolioSavingModeEnum.NestPortfolios}
								render={({ field: { ref: _ref, ...controllerProps } }) => (
									<RadioGroup {...controllerProps} {...fieldProps} onChange={controllerProps.onChange}>
										<div className="flex flex-row flex-wrap gap-4">
											<Radio
												value={EditorSaveEditOrReviewRequestPortfolioSavingModeEnum.NestPortfolios}
												data-qualifier="CompositionEditor/SaveDialog/CompositionMode(Nested)"
											>
												Nested
											</Radio>

											<Radio
												value={EditorSaveEditOrReviewRequestPortfolioSavingModeEnum.MixInstruments}
												data-qualifier="CompositionEditor/SaveDialog/CompositionMode(Aggregate)"
											>
												Aggregate
											</Radio>
										</div>
									</RadioGroup>
								)}
							/>
						)}
					</FormField>
				)}
			</div>
		</Dialog>
	);
}

export type SpawnSubmitManualCreationDialogProps = Omit<SubmitManualCreationDialogProps, "show" | "onClose">;
export function spawnSubmitManualCreationDialog(props: SpawnSubmitManualCreationDialogProps): SpawnResult<void> {
	return spawn<void>(
		adaptAnimatedNodeProvider(({ show, resolve, onHidden }) => (
			<SubmitManualCreationDialog
				{...props}
				show={show}
				onAnimationStateChange={(state) => state === "hidden" && onHidden()}
				onClose={() => resolve()}
				onAsyncSubmit={async (payload) => {
					await props.onAsyncSubmit(payload);
					resolve();
				}}
			/>
		)),
	);
}
