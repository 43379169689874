import { ActionText, AutoTooltip, Banner } from "@mdotm/mdotui/components";
import type { MaybePromise } from "@mdotm/mdotui/headless";
import BigNumber from "bignumber.js";
import { useTranslation } from "react-i18next";
import type { InstrumentCompostionEditorProps } from "../instrumentEditor";

export type NormaliseAndExcessToCashBannerProps = InstrumentCompostionEditorProps & {
	disableNormalise: boolean;
	disableNormaliseTooltip: boolean;
	onAsyncNormalise(): MaybePromise<void>;
	onExcessToCash(): void;
};

function NormaliseAndExcessToCashBanner(props: NormaliseAndExcessToCashBannerProps): JSX.Element {
	const { instrumentBuilder, onAsyncNormalise, onExcessToCash, disableNormaliseTooltip, disableNormalise } = props;
	const { t } = useTranslation();
	const totalWeight = BigNumber(instrumentBuilder.getTotal("weight"));
	if (totalWeight.eq(100)) {
		return <></>;
	}

	return (
		<Banner
			title={
				totalWeight.lt(100)
					? t("EDIT_COMPOSITION.NORMALIZE_BOX.LESS_THAN_100.TITLE")
					: t("EDIT_COMPOSITION.NORMALIZE_BOX.MORE_THAN_100.TITLE")
			}
			severity="warning"
		>
			<span>
				Select at least one instrument and click&nbsp;
				<AutoTooltip
					severity="warning"
					disabled={disableNormaliseTooltip}
					trigger={({ innerRef }) => (
						<ActionText
							classList="cursor-pointer"
							innerRef={innerRef}
							disabled={disableNormalise}
							onClickAsync={onAsyncNormalise}
						>
							Normalise.
						</ActionText>
					)}
				>
					Select at least one instrument
				</AutoTooltip>
				&nbsp;
				{totalWeight.lt(100)
					? "Sphere will proportionally add weights to the selected instruments."
					: "Sphere will proportionally remove weights to the selected instruments."}
			</span>
			<p>
				Otherwise&nbsp;
				<ActionText onClick={onExcessToCash}>Excess to Cash</ActionText>
			</p>
		</Banner>
	);
}

export default NormaliseAndExcessToCashBanner;
