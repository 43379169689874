export const instrumentFilters = {
	assetClass: [
		{ label: "Alternative", value: "Alternative" },
		{ label: "Equity", value: "Equity" },
		{ label: "Fixed Income", value: "Fixed Income" },
		{ label: "Currency", value: "Currency" },
		{ label: "Multi Asset", value: "Multi Asset" },
		{ label: "Commodities", value: "Commodities" },
		{ label: "Money Market", value: "Money Market" },
	],
	microAssetClass: [
		{ label: "Hedge Funds Strategy", value: "Hedge Funds Strategy" },
		{ label: "Currency", value: "Currency" },
		{ label: "Thematic", value: "Thematic" },
		{ label: "Large Cap", value: "Large Cap" },
		{ label: "Mid Cap", value: "Mid Cap" },
		{ label: "Small Cap", value: "Small Cap" },
		{ label: "Geographic", value: "Geographic" },
		{ label: "Broad Market", value: "Broad Market" },
		{ label: "Precious Metals", value: "Precious Metals" },
		{ label: "Agriculture", value: "Agriculture" },
		{ label: "Energy", value: "Energy" },
		{ label: "Industrial Metals", value: "Industrial Metals" },
		{ label: "Communication Services", value: "Communication Services" },
		{ label: "Consumer Discretionary", value: "Consumer Discretionary" },
		{ label: "Consumer Staples", value: "Consumer Staples" },
		{ label: "Financials", value: "Financials" },
		{ label: "Health Care", value: "Health Care" },
		{ label: "Industrials", value: "Industrials" },
		{ label: "Information Technology", value: "Information Technology" },
		{ label: "Materials", value: "Materials" },
		{ label: "Real Estate", value: "Real Estate" },
		{ label: "Utilities", value: "Utilities" },
		{ label: "Fixed Income", value: "Fixed Income" },
		{ label: "Corporate", value: "Corporate" },
		{ label: "Government", value: "Government" },
		{ label: "Money Market", value: "Money Market" },
	],
	geography: [
		{ label: "Emerging Markets", value: "Emerging Markets" },
		{ label: "Global", value: "Global" },
		{ label: "Europe", value: "Europe" },
		{ label: "USA and Canada", value: "USA and Canada" },
		{ label: "Japan", value: "Japan" },
		{ label: "United Kingdom", value: "United Kingdom" },
		{ label: "China", value: "China" },
		{ label: "France", value: "France" },
		{ label: "Switzerland", value: "Switzerland" },
		{ label: "Australia", value: "Australia" },
		{ label: "India", value: "India" },
		{ label: "Germany", value: "Germany" },
		{ label: "Italy", value: "Italy" },
		{ label: "Spain", value: "Spain" },
		{ label: "Israel", value: "Israel" },
		{ label: "Hong Kong", value: "Hong Kong" },
		{ label: "Finland", value: "Finland" },
		{ label: "Denmark", value: "Denmark" },
		{ label: "Norway", value: "Norway" },
		{ label: "Sweden", value: "Sweden" },
		{ label: "Netherlands", value: "Netherlands" },
		{ label: "Luxembourg", value: "Luxembourg" },
		{ label: "Belgium", value: "Belgium" },
		{ label: "Austria", value: "Austria" },
		{ label: "Portugal", value: "Portugal" },
		{ label: "Greece", value: "Greece" },
		{ label: "Ireland", value: "Ireland" },
		{ label: "Czech Republic", value: "Czech Republic" },
		{ label: "Hungary", value: "Hungary" },
		{ label: "Poland", value: "Poland" },
		{ label: "Turkey", value: "Turkey" },
		{ label: "South Korea", value: "South Korea" },
		{ label: "Taiwan", value: "Taiwan" },
		{ label: "New Zealand", value: "New Zealand" },
		{ label: "Philippines", value: "Philippines" },
		{ label: "Malaysia", value: "Malaysia" },
		{ label: "Indonesia", value: "Indonesia" },
		{ label: "Thailand", value: "Thailand" },
		{ label: "Singapore", value: "Singapore" },
		{ label: "Brazil", value: "Brazil" },
		{ label: "Mexico", value: "Mexico" },
		{ label: "Argentina", value: "Argentina" },
		{ label: "Chile", value: "Chile" },
		{ label: "Colombia", value: "Colombia" },
		{ label: "Peru", value: "Peru" },
		{ label: "Saudi Arabia", value: "Saudi Arabia" },
		{ label: "United Arab Emirates", value: "United Arab Emirates" },
		{ label: "Qatar", value: "Qatar" },
		{ label: "Kuwait", value: "Kuwait" },
		{ label: "South Africa", value: "South Africa" },
		{ label: "Egypt", value: "Egypt" },
		{ label: "Russia", value: "Russia" },
		{ label: "Bahamas", value: "Bahamas" },
		{ label: "Jordan", value: "Jordan" },
		{ label: "Gibraltar", value: "Gibraltar" },
		{ label: "Bermuda", value: "Bermuda" },
		{ label: "Puerto Rico", value: "Puerto Rico" },
		{ label: "Mauritius", value: "Mauritius" },
		{ label: "Liechtenstein", value: "Liechtenstein" },
		{ label: "Bangladesh", value: "Bangladesh" },
		{ label: "Azerbaijan", value: "Azerbaijan" },
		{ label: "Oman", value: "Oman" },
		{ label: "Panama", value: "Panama" },
		{ label: "Uruguay", value: "Uruguay" },
		{ label: "Gabon", value: "Gabon" },
		{ label: "Lithuania", value: "Lithuania" },
		{ label: "Iceland", value: "Iceland" },
		{ label: "Aland Islands", value: "Aland Islands" },
		{ label: "Georgia", value: "Georgia" },
		{ label: "Bahrain", value: "Bahrain" },
		{ label: "Kenya", value: "Kenya" },
		{ label: "Cayman Islands", value: "Cayman Islands" },
		{ label: "Kazakhstan", value: "Kazakhstan" },
		{ label: "La Reunion", value: "La Reunion" },
		{ label: "Ukraine", value: "Ukraine" },
		{ label: "Cyprus", value: "Cyprus" },
		{ label: "Vietnam", value: "Vietnam" },
		{ label: "British Virgin Islands", value: "British Virgin Islands" },
		{ label: "Pakistan", value: "Pakistan" },
		{ label: "Malta", value: "Malta" },
		{ label: "Russian Federation", value: "Russian Federation" },
		{ label: "Morocco", value: "Morocco" },
		{ label: "Sri Lanka", value: "Sri Lanka" },
		{ label: "Zimbabwe", value: "Zimbabwe" },
		{ label: "Mongolia", value: "Mongolia" },
		{ label: "Macau", value: "Macau" },
		{ label: "Slovenia", value: "Slovenia" },
		{ label: "Cambodia", value: "Cambodia" },
		{ label: "Romania", value: "Romania" },
		{ label: "Faroe Islands", value: "Faroe Islands" },
		{ label: "Monaco", value: "Monaco" },
		{ label: "Costa Rica", value: "Costa Rica" },
		{ label: "Serbia", value: "Serbia" },
		{ label: "Uzbekistan", value: "Uzbekistan" },
		{ label: "Ecuador", value: "Ecuador" },
		{ label: "Bolivia", value: "Bolivia" },
		{ label: "Montenegro", value: "Montenegro" },
		{ label: "Belize", value: "Belize" },
		{ label: "Bosnia and Herzegovina", value: "Bosnia and Herzegovina" },
		{ label: "Lebanon", value: "Lebanon" },
		{ label: "Burundi", value: "Burundi" },
		{ label: "Mozambique", value: "Mozambique" },
		{ label: "Jamaica", value: "Jamaica" },
		{ label: "Zambia", value: "Zambia" },
		{ label: "Angola", value: "Angola" },
		{ label: "American Samoa", value: "American Samoa" },
		{ label: "Ivory Coast", value: "Ivory Coast" },
		{ label: "Armenia", value: "Armenia" },
		{ label: "Albania", value: "Albania" },
		{ label: "Tajikistan", value: "Tajikistan" },
		{ label: "Venezuela", value: "Venezuela" },
		{ label: "Benin", value: "Benin" },
		{ label: "Papua New Guinea", value: "Papua New Guinea" },
		{ label: "Bulgaria", value: "Bulgaria" },
		{ label: "Iraq", value: "Iraq" },
		{ label: "Macedonia", value: "Macedonia" },
		{ label: "Andorra", value: "Andorra" },
		{ label: "Dominican Republic", value: "Dominican Republic" },
		{ label: "Ethiopia", value: "Ethiopia" },
		{ label: "Dominica", value: "Dominica" },
		{ label: "Croatia", value: "Croatia" },
		{ label: "Grenada", value: "Grenada" },
		{ label: "Nigeria", value: "Nigeria" },
		{ label: "Honduras", value: "Honduras" },
		{ label: "U.S. Virgin Islands", value: "U.S. Virgin Islands" },
		{ label: "Barbados", value: "Barbados" },
		{ label: "Paraguay", value: "Paraguay" },
		{ label: "Ghana", value: "Ghana" },
		{ label: "Mali", value: "Mali" },
		{ label: "Belarus", value: "Belarus" },
		{ label: "El Salvador", value: "El Salvador" },
		{ label: "Suriname", value: "Suriname" },
		{ label: "Togo", value: "Togo" },
		{ label: "Trinidad and Tobago", value: "Trinidad and Tobago" },
		{ label: "Slovakia", value: "Slovakia" },
		{ label: "Rwanda", value: "Rwanda" },
		{ label: "Tunisia", value: "Tunisia" },
		{ label: "San Marino", value: "San Marino" },
		{ label: "Congo (Rep. of)", value: "Congo (Rep. of)" },
		{ label: "Guam", value: "Guam" },
		{ label: "Cameroon", value: "Cameroon" },
		{ label: "Estonia", value: "Estonia" },
		{ label: "Latvia", value: "Latvia" },
		{ label: "Namibia", value: "Namibia" },
		{ label: "Guatemala", value: "Guatemala" },
		{ label: "Senegal", value: "Senegal" },
		{ label: "Pacific Ex Japan", value: "Pacific Ex Japan" },
	],
	instrumentType: [
		{ label: "Index", value: "index" },
		{ label: "Single Stock", value: "singleStocks" },
		{ label: "Multi-Asset", value: "multiAsset" },
	],
};
