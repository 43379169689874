import { InvestmentBulkEnhancementConfigurationControllerV4ApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { LeavePrompt } from "$root/components/LeavePrompt";
import { PageHeader } from "$root/components/PageHeader";
import { typedUrlForRoute, useTypedNavigation } from "$root/components/PlatformRouter/RoutesDef";
import {
	EditPortfolioMultiV4ContextProvider,
	EditPortfolioMultiV4InContext,
} from "$root/components/Portfolio/CreatePortfolio/EditPortfolioMulti";
import { ReactQueryWrapperBase } from "$root/components/ReactQueryWrapper";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { noop } from "@mdotm/mdotui/utils";
import { ActionText, AsyncButton, AutoTooltip, Button, Icon, Text, TooltipContent } from "@mdotm/mdotui/components";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { PortfolioStudioTab } from "../PortfoliosStudio";
import { platformToast } from "$root/notification-system/toast";
import { makeUnsavedDataHandler } from "$root/components/Portfolio/CreatePortfolio/shared";
import { SavePortfolioLoadingModal } from "./CreatePortfolio";

/** New/Edit merged together */
export default function CreatePortfolioMulti({ proposalUuid }: { proposalUuid: string }): JSX.Element {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const { t } = useTranslation();

	const { push } = useTypedNavigation();
	const bulkEnhancementApi = useApiGen(InvestmentBulkEnhancementConfigurationControllerV4ApiFactory);

	const portfoliosQuery = useQueryNoRefetch({
		queryKey: ["portfolioNames", proposalUuid],
		queryFn: () => axiosExtract(bulkEnhancementApi.getEnhancementConfigurationStepsAvailability1(proposalUuid)),
	});

	async function Submit(uuid: string) {
		try {
			setIsSubmitting(true);
			await bulkEnhancementApi.runEnhancementConfiguration1(uuid);
			setIsSubmitting(false);
			platformToast({
				children: "Sphere has taken over your request",
				severity: "success",
				icon: "Portfolio",
			});
			push("PortfoliosStudio", {});
		} catch (error) {
			platformToast({
				children: t("PORTFOLIOS.PORTFOLIO_GENERIC_UPLOAD_ERROR"),
				severity: "error",
				icon: "Portfolio",
			});
			console.error(error);
		}
	}

	const [showLoadingModal, setShowLoadingModal] = useState(false);

	return (
		<ReactQueryWrapperBase query={portfoliosQuery}>
			{({ investments }) => (
				<EditPortfolioMultiV4ContextProvider
					area={{ name: "create-bulk-proposal", proposalUuid, edit: true, editable: true }}
				>
					{({ canSubmit, stepsMetadata, currentStep, setStepsMetadata, area, submittersRef }) => (
						<>
							<PageHeader
								title="Create bulk proposal"
								crumbs={[
									{
										children: "Portfolios",
										href: typedUrlForRoute("PortfoliosStudio", {}),
									},
									{
										children: "Proposal",
									},
								]}
								subTitle={
									<div className="flex justify-between items-center py-2.5">
										<Text>
											{investments?.length === 2 ? (
												<>
													You are creating a proposal for:{" "}
													<ActionText
														href={typedUrlForRoute("PortfolioDetails", { portfolioUid: investments?.[0].uuid ?? "" })}
													>
														{investments[0].name ?? "-"}
													</ActionText>{" "}
													and{" "}
													<ActionText
														href={typedUrlForRoute("PortfolioDetails", { portfolioUid: investments?.[1].uuid ?? "" })}
													>
														{investments[1].name ?? "-"}
													</ActionText>
												</>
											) : (
												<>
													You are creating a proposal for:{" "}
													<ActionText
														href={typedUrlForRoute("PortfolioDetails", { portfolioUid: investments?.[0].uuid ?? "" })}
													>
														{investments?.[0].name ?? "-"}
													</ActionText>{" "}
													and{" "}
													<AutoTooltip
														overrideColor={themeCSSVars.palette_N200}
														trigger={({ innerRef }) => (
															<ActionText onClick={noop} innerRef={innerRef}>
																{(investments?.length ?? 0) - 1} more
															</ActionText>
														)}
													>
														<TooltipContent>
															<ul className="list-disc pl-6">
																{(investments?.slice(1) ?? []).map((p, i) => (
																	<li key={p.uuid ?? i}>{p.name ?? "-"}</li>
																))}
															</ul>
														</TooltipContent>
													</AutoTooltip>
												</>
											)}
										</Text>
										<div className="flex items-center space-x-2">
											<Button
												size="small"
												onClick={() => push("PortfoliosStudio", { tab: PortfolioStudioTab.Portfolios })}
												palette="tertiary"
											>
												Cancel
											</Button>
											<AsyncButton
												size="small"
												onClickAsync={async () => {
													try {
														const saveHandler = makeUnsavedDataHandler({
															currentStep,
															setStepsMetadata,
															submittersRef,
														});
														const shouldContinue = (await saveHandler()) === "continue";
														if (!shouldContinue) {
															throw new Error(
																"unable to proceed, some step may require additional data or corrections",
															);
														}
														await Submit(area.proposalUuid ?? "");
													} catch (error) {
														platformToast({
															children: `Unable to proceed, ${t(
																`PORTFOLIO_WIZARD.STEP_NAME.${currentStep}`,
															)} step requires additional data or correction`,
															severity: "info",
															icon: "Portfolio",
														});
														throw error;
													}
												}}
												palette="primary"
											>
												<Icon icon="Enhance" size="1.2em" />
												&nbsp;Generate
											</AsyncButton>
										</div>
									</div>
								}
							/>
							<EditPortfolioMultiV4InContext />

							<SavePortfolioLoadingModal show={showLoadingModal} />

							<LeavePrompt
								when={stepsMetadata[currentStep].dirty || !isSubmitting}
								title={t("LEAVE_PAGE")}
								pathToNotBlock={[
									"/login",
									`${typedUrlForRoute("Portfolios/EditPortfolioMulti", { proposalUuid })}#${currentStep}`,
									typedUrlForRoute("Portfolios/EditPortfolioMulti", { proposalUuid }),
								]}
							>
								{t("PORTFOLIOS.CREATE_LEAVE")}
							</LeavePrompt>
						</>
					)}
				</EditPortfolioMultiV4ContextProvider>
			)}
		</ReactQueryWrapperBase>
	);
}
