import type { InnerRefProps } from "@mdotm/mdotui/components";
import { Icon, ProgressBar, Text } from "@mdotm/mdotui/components";
import { Switch } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import type {
	FakeAiLoaderActions,
	FakeAiLoaderStep,
	UseFakeAiLoaderParams,
} from "$root/components/FakeAiLoader/common";
import { useFakeAiLoader } from "$root/components/FakeAiLoader/common";
import type { ImperativeHandlesRefProps } from "$root/utils/react-extra";
import { useImperativeHandlesRef } from "$root/utils/react-extra";

export type FakeAiLoaderBaseProps = {
	state: FakeAiLoaderStep;
} & InnerRefProps<HTMLDivElement> &
	UseFakeAiLoaderParams;

export function FakeAiLoaderBase(props: FakeAiLoaderBaseProps): JSX.Element {
	return (
		<div ref={props.innerRef} className={`bg-[color:${themeCSSVars.palette_P50}] rounded overflow-hidden`}>
			<div className="w-full pt-2">
				<div className="flex justify-center space-x-2 items-center">
					<Icon icon="calulating" color={themeCSSVars.palette_P400} size={16} classList="animate-spin" />
					<Text type="Body/L/Book" as="p" color={themeCSSVars.palette_N700}>
						<Switch
							case={props.state.stepName}
							match={{
								recovering: () => "1/3 - Retrieving data",
								analysing: () => "2/3 - Analysing data",
								preparing: () => "3/3 - Preparing and formatting your response",
							}}
						/>
					</Text>
				</div>

				<div className="min-w-0 w-full mt-2">
					<ProgressBar accentColor={themeCSSVars.palette_P400} classList="w-full" value={props.state.progress} />
				</div>
			</div>
		</div>
	);
}

export type FakeAiLoaderProps = ImperativeHandlesRefProps<FakeAiLoaderActions> &
	InnerRefProps<HTMLDivElement> &
	UseFakeAiLoaderParams;
function FakeAiLoader(props: FakeAiLoaderProps): JSX.Element {
	const { state, ...handles } = useFakeAiLoader(props);
	useImperativeHandlesRef(props.handlesRef, handles);

	return <FakeAiLoaderBase state={state} />;
}

export default FakeAiLoader;
