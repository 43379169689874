import { IconWalls } from "$root/components/IconWall";
import { useLocaleFormatters } from "$root/localization/hooks";
import { stableColorGenerator } from "$root/utils/chart/colorGenerator";
import type { TableWithGroupsProps } from "@mdotm/mdotui/components";
import {
	AutoSortHScrollTableWithGroups,
	BatchActions,
	useSelectableTableWithGroupColumn,
} from "@mdotm/mdotui/components";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import Color from "color";
import { Set } from "immutable";
import { useCallback, useMemo, useState } from "react";
import { match } from "ts-pattern";
import {
	groupInstruments,
	normaliseAndVerifyInstrumentPortfolioCompositon,
	provideAssetClass,
	useCompositionToolBar,
	verifySomeInstrumentsAreExcluded,
} from "../../builder";
import { useGroupedInstrumentCompositionColumns } from "../../columns";
import type { GroupedInstrumentEditorEntry, InstrumentEditorEntry } from "../../const";
import type { InstrumentCompostionEditorProps } from "../../instrumentEditor";
import InstrumentLimitReachedBanner from "../InstrumentLimitReachedBanner";
import NormaliseAndExcessToCashBanner from "../NormaliseAndExcessToCashBanner";
import NormalisedInvestmentInstrumentBanner from "../NormalisedInvestmentInstrumentBanner";

type DefaultInstrumentTableProps = InstrumentCompostionEditorProps & {
	filteredRows: InstrumentEditorEntry[];
	rows: InstrumentEditorEntry[];
};

function GroupedInstrumentBaseTable({
	instrumentBuilder,
	filteredRows,
	rows,
	entity,
	limit,
}: DefaultInstrumentTableProps): JSX.Element {
	const toolbar = useCompositionToolBar();
	const grupedInstrumentCompositionColumns = useGroupedInstrumentCompositionColumns();
	const { formatNumber } = useLocaleFormatters();

	const groupedRows = useMemo<Array<GroupedInstrumentEditorEntry>>(() => groupInstruments(rows), [rows]);
	const [initialExpandGroupByKey] = useState(() => Set(groupedRows.map((x) => x.assetClass)));

	const filteredGroupedRows = useMemo<Array<GroupedInstrumentEditorEntry>>(
		() => groupInstruments(filteredRows),
		[filteredRows],
	);

	const selectableTableColumn = useSelectableTableWithGroupColumn({
		groupedRows,
		filteredGroupedRows,
		selectBy: (r) => r.rowId,
	});

	const totalWeight = useMemo(() => {
		if (rows.length > 0) {
			return `${formatNumber(instrumentBuilder.getTotal("weight"))}%`;
		}
		return undefined;
	}, [formatNumber, instrumentBuilder, rows.length]);

	const totalLabel = useMemo(() => {
		if (rows.length > 0) {
			return `Total`;
		}
		return undefined;
	}, [rows.length]);

	const columns = useCallback<TableWithGroupsProps<GroupedInstrumentEditorEntry>["columns"]>(
		({ bandColumn, expandColumn }) => [
			bandColumn,
			expandColumn,
			selectableTableColumn.column,
			grupedInstrumentCompositionColumns.name({
				onCompare(row, action) {
					if (action === "add") {
						instrumentBuilder.setInstrumentToCompare([row]);
						return;
					}

					instrumentBuilder.deleteComparedInstruments(row.rowId);
				},
				isRowCompared(rowId) {
					return instrumentBuilder.getComparedInstrument(rowId) !== undefined;
				},
			}),
			grupedInstrumentCompositionColumns.identifier(),
			{
				...grupedInstrumentCompositionColumns.microAssetClass(),
				footerCellClassList: "font-semibold justify-end",
				footer: totalLabel,
			},
			{
				...grupedInstrumentCompositionColumns.weight({
					onChangeWeight: (rowId, v) => instrumentBuilder.setWeight(rowId, v ?? undefined),
					isInstrumentDeleted: (rowId) => instrumentBuilder.isInstrumentDeleted(rowId),
					onCheckIsMinimumWeightValid: async (row, weight) => {
						const instrumentsExcluded = await verifySomeInstrumentsAreExcluded(
							{ ...row, weight: weight ?? undefined },
							entity,
						);
						instrumentBuilder.setInstrument(row.rowId, {
							...row,
							weight: weight ?? undefined,
							someInstrumentsAreExcluded: instrumentsExcluded.length > 0,
							nOfInstrumentExcluded: instrumentsExcluded.length,
						});
					},
					weightProvider: (rowId) => instrumentBuilder.getWeight(rowId),
				}),
				footerCellClassList: "font-semibold justify-end",
				footer: totalWeight,
				align: "end",
				minWidth: 130,
			},
			grupedInstrumentCompositionColumns.delete({
				onDelete: (rowId) => instrumentBuilder.delete("hard", rowId),
			}),
		],
		[
			entity,
			grupedInstrumentCompositionColumns,
			instrumentBuilder,
			selectableTableColumn.column,
			totalLabel,
			totalWeight,
		],
	);

	const actions = useMemo(
		() =>
			match(entity)
				.with("INVESTMENT", () => [
					toolbar.delete({
						instrumentBuilder,
						selected: selectableTableColumn.multiSelectCtx,
					}),
					toolbar.compare({ instrumentBuilder, selected: selectableTableColumn.multiSelectCtx }),
				])
				.otherwise(() => [
					toolbar.delete({
						instrumentBuilder,
						selected: selectableTableColumn.multiSelectCtx,
					}),
				]),
		[entity, instrumentBuilder, selectableTableColumn.multiSelectCtx, toolbar],
	);

	return (
		<>
			<BatchActions
				total={rows.length}
				selected={selectableTableColumn.multiSelectCtx.selection.size}
				palette="tertiary"
				actions={actions}
				classList="py-4 h-[58px] shrink-0"
			/>
			<NormalisedInvestmentInstrumentBanner
				entity={entity}
				someInvestmentsHasExcluedInstrument={rows.some((instrument) => instrument.someInstrumentsAreExcluded)}
			/>
			<AutoSortHScrollTableWithGroups
				columns={columns}
				groupedRows={filteredGroupedRows}
				groupRowKey={(row) => row.assetClass}
				pinnedColumns={[
					{ name: "name", side: "left" },
					{ name: "weight", side: "right" },
					{ name: "delete", side: "right" },
				]}
				noDataText={<IconWalls.EditorEmptyData entity={entity} />}
				onRowClick={(row) => selectableTableColumn.toggle(row.rowId)}
				expandGroupByKey={initialExpandGroupByKey}
				rowStyle={(row) => {
					if (row.someInstrumentsAreExcluded) {
						return { backgroundColor: themeCSSVars.global_palette_warning_100 };
					}

					if (instrumentBuilder.isInstrumentDeleted(row.rowId)) {
						return { backgroundColor: themeCSSVars.Table_highlightedRowBackgroundColor };
					}
				}}
				groupColor={(row) => stableColorGenerator(row.assetClass)}
				rowColor={(row) =>
					Color(stableColorGenerator(provideAssetClass(row)))
						.alpha(0.4)
						.toString()
				}
				palette="uniform"
			/>
			<br />
			{limit && rows.length > limit && <InstrumentLimitReachedBanner limit={limit} />}
			<br />
			<NormaliseAndExcessToCashBanner
				disableNormalise={selectableTableColumn.multiSelectCtx.selection.size === 0}
				disableNormaliseTooltip={selectableTableColumn.multiSelectCtx.selection.size > 0}
				entity={entity}
				instrumentBuilder={instrumentBuilder}
				onAsyncNormalise={() =>
					normaliseAndVerifyInstrumentPortfolioCompositon({
						instrumentBuilder,
						entity,
						selection: selectableTableColumn.multiSelectCtx.selection,
					})
				}
				onExcessToCash={() => instrumentBuilder.excessToCash()}
			/>
		</>
	);
}

export default GroupedInstrumentBaseTable;
