import { DebouncedSearchInput } from "$root/components/DebouncedSearchInput";
import { IconWalls } from "$root/components/IconWall";
import { Card } from "$root/widgets-architecture/layout/Card";
import type { ActionOrActionWithGroup } from "@mdotm/mdotui/components";
import { Button, Checkbox, DropdownMenu, Icon } from "@mdotm/mdotui/components";
import type { ReactNode } from "react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { match } from "ts-pattern";
import { useSearchableInstrumentTable } from "../instruments/hooks";
import type { InstrumentEditorBuilderProps } from "./builder";
import { useCompositionHeaderAction } from "./builder";
import type { InstrumentEditorEntity, InstrumentEditorEntry } from "./const";
import { BaseInstrumentTable } from "./table/base-table";
import { EditInstrumentTable } from "./table/editPortfolio-table";
import { EnhanceInstrumentTable } from "./table/enhance-table";
import { UniverseInstrumentTable } from "./table/universe-table";
import MinNumberOfInstrumentBanner from "./table/MinNumberOfInstrumentBanner";
import type { EditTagOptions } from "../instruments/edit-tags";
import { EditTagButton } from "../instruments/edit-tags";
import { defaultTagLabels } from "$root/components/tags/shared";
import colorGenerator, { stableColorGenerator } from "$root/utils/chart/colorGenerator";
import { Set } from "immutable";

type InstrumentCompostionMode = { mode: "new" } | { mode: "edit"; uuid: string };
export type InstrumentCompostionEditorProps = {
	entity: InstrumentEditorEntity;
	instrumentBuilder: InstrumentEditorBuilderProps;
	minThreshold?: number;
	limit?: number;
};

const placholderSearch = "Filter by instrument name, ISIN, asset class or micro asset class";

function InstrumentCompostionEditor(props: InstrumentCompostionEditorProps & InstrumentCompostionMode): JSX.Element {
	const { instrumentBuilder, entity, minThreshold } = props;

	const [groupByAssetClass, setGroupByAssetClass] = useState(false);
	const compositionMap = instrumentBuilder.watchComposition();
	const composition = useMemo(() => Array.from(compositionMap.values()), [compositionMap]);

	const {
		filtered,
		setQuery: setSearchInstrument,
		query: searchInstrument,
	} = useSearchableInstrumentTable(composition, {
		mode: "keyword",
	});

	const InstrumentEditorTable = useMemo(
		() =>
			match(props)
				.returnType<
					(componentProps: {
						rows: InstrumentEditorEntry[];
						filteredRows: InstrumentEditorEntry[];
						instrumentBuilder: InstrumentEditorBuilderProps;
						limit?: number;
					}) => ReactNode
				>()
				.with({ mode: "new" }, (params) => {
					return match(params.entity)
						.returnType<
							(componentProps: {
								rows: InstrumentEditorEntry[];
								filteredRows: InstrumentEditorEntry[];
								instrumentBuilder: InstrumentEditorBuilderProps;
							}) => ReactNode
						>()
						.with(
							"INVESTMENT",
							"BENCHMARK",
							"TARGET_INVESTMENT",
							(matchedEntity) =>
								function Editor(componentProps) {
									return <BaseInstrumentTable.Flat entity={matchedEntity} {...componentProps} />;
								},
						)
						.with(
							"UNIVERSE",
							(matchedEntity) =>
								function Editor(componentProps) {
									return <UniverseInstrumentTable.Flat entity={matchedEntity} {...componentProps} />;
								},
						)
						.with(
							"INVESTMENT_ENHANCEMENT",
							"INVESTMENT_DRAFT",
							() =>
								function Empty() {
									return <></>;
								},
						)
						.exhaustive();
				})
				.with({ mode: "edit" }, (params) => {
					return match(params.entity)
						.returnType<
							(componentProps: {
								rows: InstrumentEditorEntry[];
								filteredRows: InstrumentEditorEntry[];
								instrumentBuilder: InstrumentEditorBuilderProps;
								limit?: number;
							}) => ReactNode
						>()
						.with(
							"BENCHMARK",
							"TARGET_INVESTMENT",
							(matchedEntity) =>
								function Editor(componentProps) {
									return <BaseInstrumentTable.Flat entity={matchedEntity} {...componentProps} />;
								},
						)
						.with(
							"UNIVERSE",
							(matchedEntity) =>
								function Editor(componentProps) {
									return <UniverseInstrumentTable.Flat entity={matchedEntity} {...componentProps} />;
								},
						)
						.with(
							"INVESTMENT",
							(matchedEntity) =>
								function Editor(componentProps) {
									return <EditInstrumentTable.Flat entity={matchedEntity} {...componentProps} />;
								},
						)
						.with(
							"INVESTMENT_ENHANCEMENT",
							(matchedEntity) =>
								function Editor(componentProps) {
									return <EnhanceInstrumentTable.Flat entity={matchedEntity} {...componentProps} />;
								},
						)
						.with(
							"INVESTMENT_DRAFT",
							(matchedEntity) =>
								function Editor(componentProps) {
									return <BaseInstrumentTable.Flat entity={matchedEntity} {...componentProps} />;
								},
						)
						.exhaustive();
				})
				.otherwise(
					() =>
						function Empty() {
							return <></>;
						},
				),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[props.mode, props.entity],
	);

	const GroupInstrumentEditorTable = useMemo(
		() =>
			match(props)
				.returnType<
					(componentProps: {
						rows: InstrumentEditorEntry[];
						filteredRows: InstrumentEditorEntry[];
						instrumentBuilder: InstrumentEditorBuilderProps;
						limit?: number;
					}) => ReactNode
				>()
				.with({ mode: "new" }, (params) => {
					return match(params.entity)
						.returnType<
							(componentProps: {
								rows: InstrumentEditorEntry[];
								filteredRows: InstrumentEditorEntry[];
								instrumentBuilder: InstrumentEditorBuilderProps;
							}) => ReactNode
						>()
						.with(
							"INVESTMENT",
							"BENCHMARK",
							"TARGET_INVESTMENT",
							(matchedEntity) =>
								function Editor(componentProps) {
									return <BaseInstrumentTable.Grouped entity={matchedEntity} {...componentProps} />;
								},
						)
						.with(
							"UNIVERSE",
							(matchedEntity) =>
								function Editor(componentProps) {
									return <UniverseInstrumentTable.Grouped entity={matchedEntity} {...componentProps} />;
								},
						)
						.with(
							"INVESTMENT_ENHANCEMENT",
							"INVESTMENT_DRAFT",
							() =>
								function Empty() {
									return <></>;
								},
						)
						.exhaustive();
				})
				.with({ mode: "edit" }, (params) => {
					return match(params.entity)
						.returnType<
							(componentProps: {
								rows: InstrumentEditorEntry[];
								filteredRows: InstrumentEditorEntry[];
								instrumentBuilder: InstrumentEditorBuilderProps;
								limit?: number;
							}) => ReactNode
						>()
						.with(
							"BENCHMARK",
							"TARGET_INVESTMENT",
							(matchedEntity) =>
								function Editor(componentProps) {
									return <BaseInstrumentTable.Grouped entity={matchedEntity} {...componentProps} />;
								},
						)
						.with(
							"UNIVERSE",
							(matchedEntity) =>
								function Editor(componentProps) {
									return <UniverseInstrumentTable.Grouped entity={matchedEntity} {...componentProps} />;
								},
						)
						.with(
							"INVESTMENT",
							(matchedEntity) =>
								function Editor(componentProps) {
									return <EditInstrumentTable.Grouped entity={matchedEntity} {...componentProps} />;
								},
						)
						.with(
							"INVESTMENT_ENHANCEMENT",
							(matchedEntity) =>
								function Editor(componentProps) {
									return <EnhanceInstrumentTable.Grouped entity={matchedEntity} {...componentProps} />;
								},
						)
						.with(
							"INVESTMENT_DRAFT",
							(matchedEntity) =>
								function Editor(componentProps) {
									return <BaseInstrumentTable.Grouped entity={matchedEntity} {...componentProps} />;
								},
						)
						.exhaustive();
				})
				.otherwise(
					() =>
						function Empty() {
							return <></>;
						},
				),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[props.mode, props.entity],
	);

	const InstrumentToggles = useMemo(
		() =>
			match(props.entity)
				.returnType<(componentProps: { checked: boolean; onChange?(newValue: boolean): void }) => ReactNode>()
				.with(
					"INVESTMENT",
					"INVESTMENT_ENHANCEMENT",
					"INVESTMENT_DRAFT",
					"BENCHMARK",
					"TARGET_INVESTMENT",
					() =>
						function Block(checkBoxProps) {
							return (
								<Checkbox switchType="switch" {...checkBoxProps}>
									Group by Asset Class
								</Checkbox>
							);
						},
				)
				.with(
					"UNIVERSE",
					() =>
						function Empty() {
							return <></>;
						},
				)
				.exhaustive(),
		[props],
	);

	return (
		<Card classList="flex-1">
			{minThreshold != null && (
				<MinNumberOfInstrumentBanner threshold={minThreshold} instrumentBuilder={instrumentBuilder} />
			)}
			<div className="flex justify-between">
				<DebouncedSearchInput
					query={searchInstrument}
					onChange={setSearchInstrument}
					placeholder={placholderSearch}
					classList="max-w-[500px] flex-1"
				/>
				<div className="flex gap-2 mb-4">
					<InstrumentToggles checked={groupByAssetClass} onChange={setGroupByAssetClass} />
					{props.mode === "edit" ? <InstrumentHeaderActions {...props} /> : <InstrumentHeaderActions {...props} />}
				</div>
			</div>
			{composition.length > 0 ? (
				groupByAssetClass ? (
					<GroupInstrumentEditorTable
						filteredRows={filtered}
						rows={composition}
						instrumentBuilder={instrumentBuilder}
						limit={props.limit}
					/>
				) : (
					<InstrumentEditorTable
						filteredRows={filtered}
						rows={composition}
						instrumentBuilder={instrumentBuilder}
						limit={props.limit}
					/>
				)
			) : (
				<IconWalls.EditorEmptyData entity={entity} />
			)}
		</Card>
	);
}

function InstrumentHeaderActions(props: InstrumentCompostionEditorProps & InstrumentCompostionMode) {
	const { instrumentBuilder, entity } = props;
	const { t } = useTranslation();
	const compositionHeaderAction = useCompositionHeaderAction({
		instrumentBuilder,
		entity,
		uuid: props.mode === "edit" ? props.uuid : undefined,
	});

	const currentAvailableTags = instrumentBuilder.watchTags();
	const tagOptions = useMemo(
		() =>
			Array.from(currentAvailableTags.values()).map(
				(tag): EditTagOptions => ({
					color: tag.color,
					value: tag.value,
					deletable: !defaultTagLabels.includes(tag.value),
				}),
			),
		[currentAvailableTags],
	);
	const actions = useMemo(
		() =>
			match(props)
				.returnType<ActionOrActionWithGroup<string>[]>()
				.with({ mode: "new" }, (params) => {
					return match(params.entity)
						.with("INVESTMENT", "INVESTMENT_DRAFT", "UNIVERSE", () => [
							compositionHeaderAction.addInstruments(),
							compositionHeaderAction.addPortfolio(),
							compositionHeaderAction.uploadInstruments(),
						])
						.with("TARGET_INVESTMENT", "BENCHMARK", () => [
							compositionHeaderAction.addInstruments(),
							compositionHeaderAction.uploadInstruments(),
						])
						.with("INVESTMENT_ENHANCEMENT", () => [])
						.exhaustive();
				})
				.with({ mode: "edit" }, (params) => {
					return match(params.entity)
						.with("INVESTMENT", "INVESTMENT_DRAFT", "UNIVERSE", () => [
							compositionHeaderAction.addInstruments(),
							compositionHeaderAction.addPortfolio(),
							compositionHeaderAction.uploadInstruments(),
						])
						.with("TARGET_INVESTMENT", "BENCHMARK", () => [
							compositionHeaderAction.addInstruments(),
							compositionHeaderAction.uploadInstruments(),
						])
						.with("INVESTMENT_ENHANCEMENT", () => [
							compositionHeaderAction.addInstruments(),
							compositionHeaderAction.addPortfolio(),
						])
						.exhaustive();
				})
				.otherwise(() => []),

		[compositionHeaderAction, props],
	);

	return (
		<div className="flex gap-2">
			{entity === "UNIVERSE" && (
				<EditTagButton
					options={tagOptions}
					enableDebounce
					onAdd={(tagValue) =>
						instrumentBuilder.setTag(tagValue, {
							color: stableColorGenerator(tagValue),
							label: tagValue,
							value: tagValue,
						})
					}
					onDelete={(tagValue) => {
						instrumentBuilder.deleteTag(tagValue);
						const composition = instrumentBuilder.getComposition();
						const instrumentWithDeletedTag = composition.filter((instrument) => instrument.tagLabel === tagValue);
						instrumentBuilder.bulkEditInstruments(Set(instrumentWithDeletedTag.keys()), (instrument) => ({
							...instrument,
							tagLabel: undefined,
						}));
					}}
				/>
			)}
			<DropdownMenu
				trigger={(dropdownMenuProps) => (
					<Button
						{...dropdownMenuProps}
						palette="secondary"
						size="small"
						classList="flex gap-2"
						data-qualifier="CompositionEditor/HeaderAction/DropdownMenu"
					>
						<Icon icon="add-ptf" size={18} />
						{t("BUTTON.ADD")}
					</Button>
				)}
				align="endToEnd"
				position="bottom"
				actions={actions}
			/>
		</div>
	);
}

export default InstrumentCompostionEditor;
