import { OutlookControllerV3ApiFactory } from "$root/api/api-gen";
import { getApiGen } from "$root/api/factory";
import useCrumbs from "$root/components/Crumbs/useCrumbs";
import { OutlookWidgetData } from "$root/components/OutlookWidgetData";
import { PageHeader } from "$root/components/PageHeader";
import { downloadContentDisposition } from "$root/utils/files";
import WidgetsGrid from "$root/widgets-architecture/layout/WidgetsGrid";
import { useHistory } from "react-router";
import { PageDownloadMenu } from "../components/PageDownloadMenu";

const PAGE_NAME = "DASHBOARD";

const Dashboard = (): JSX.Element => {
	const crumbs = useCrumbs();
	const history = useHistory();
	return (
		<>
			<PageHeader
				title="Market dashboard"
				crumbs={[
					crumbs.marketInsights,
					crumbs.selectMarketAreas({
						selectedArea: history.location.pathname,
						onChange(url) {
							history.push(url);
						},
					}),
				]}
				titleAction={
					<div className="flex items-center justify-between gap-2">
						<div>
							<OutlookWidgetData />
						</div>
						<div>
							<PageDownloadMenu
								area="dashboard"
								actions={[
									{
										icon: "xls",
										label: "Market outlook",
										onClickAsync: async () =>
											downloadContentDisposition(
												await getApiGen(OutlookControllerV3ApiFactory).createExcelReport({
													responseType: "blob",
												}),
											),
										"data-qualifier": "Dashboard/DropdownMenu/DropdownItem(XLSReport)",
									},
								]}
							/>
						</div>
					</div>
				}
			/>
			<WidgetsGrid gridName={PAGE_NAME} isDraggable={false} />
		</>
	);
};

export default Dashboard;
