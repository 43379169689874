import { sleep, noop } from "@mdotm/mdotui/utils";

export async function* fakeProgressProvider(opts: {
	signal?: AbortSignal;
	expCoeff?: number;
	deltaX?: number;
	sleepMs: number;
	initialProgress?: number;
}): AsyncGenerator<number, void, unknown> {
	const expCoeff = opts.expCoeff ?? 1;
	const deltaX = opts.deltaX ?? 1;
	let x = opts.initialProgress ?? 0;
	while (!opts.signal?.aborted) {
		yield 1 - Math.exp(-x * expCoeff);
		await sleep(opts.sleepMs, { signal: opts.signal }).catch(noop);
		x += deltaX;
	}
	yield 1; // finally return 1
}
