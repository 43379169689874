import type { InvestmentsReportTemplate } from "$root/api/api-gen";
import { InvestmentsReportTemplateControllerApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { DebouncedSearchInput } from "$root/components/DebouncedSearchInput";
import { typedUrlForRoute, useTypedNavigation } from "$root/components/PlatformRouter/RoutesDef";
import ReactQueryWrapper from "$root/components/ReactQueryWrapper";
import { spawnDeleteDialog } from "$root/components/spawnable/entity-management/delete-dialog";
import { spawnDuplicateDialog } from "$root/components/spawnable/entity-management/duplicate-dialog";
import { spawnRenameDialog } from "$root/components/spawnable/entity-management/rename-dialog";
import type { DefaultReportTemplate } from "$root/functional-areas/reports/default-templates";
import {
	defaultPortfolioTemplates,
	defaultTemplateName,
	isDefaultReportTemplate,
	startingTemplate,
} from "$root/functional-areas/reports/default-templates";
import { platformToast } from "$root/notification-system/toast";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { actionsColumn } from "$root/ui-lib/interactive-collections/common-table-actions";
import { builtInCaseInsensitiveSortFor } from "$root/utils/collections";
import { parallelize, type Abortable } from "$root/utils/promise";
import { noop } from "@mdotm/mdotui/utils";
import type { ActionOrActionWithGroup, OrderBy, TableColumn } from "@mdotm/mdotui/components";
import {
	ActionText,
	AsyncButton,
	Badge,
	BaseHScrollTable,
	BatchActions,
	Controller,
	DropdownMenuActionButton,
	Icon,
	IconTooltip,
	TableDataCell,
	TableHeadCell,
	TinyIconButton,
	sortRows,
	useSelectableTableColumn,
} from "@mdotm/mdotui/components";
import type { MaybePromise } from "@mdotm/mdotui/headless";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { builtInSortFnFor } from "@mdotm/mdotui/utils";
import { useMemo, useState } from "react";
import { qualifier } from "$root/utils/qualifiers";
import { reportRepository } from "$root/functional-areas/reports/repository";

const ReportCustomisation = ({ active }: { active: boolean }): JSX.Element => {
	const investmentsReportTemplateApi = useApiGen(InvestmentsReportTemplateControllerApiFactory);
	const { push } = useTypedNavigation();

	async function shadowCreation(name: string) {
		try {
			const sphereTemplate = defaultPortfolioTemplates.find((x) => x.id === "sphere");

			const { uuid } = await axiosExtract(
				investmentsReportTemplateApi.createPTFReportTemplate({
					templateName: name,
					visible: false,
					format: "pdf",
					data: {
						...(name === sphereTemplate?.templateName ? sphereTemplate?.data : startingTemplate?.data),
						status: "draft",
					},
				}),
			);
			push("PortfolioStudioSettings/ReportEditor", { reportUid: uuid! });
		} catch (error) {
			platformToast({
				children: "Oops, Something went wrong, pleas re-try",
				icon: "pdf",
				severity: "error",
			});
			throw error;
		}
	}

	return (
		<ReactQueryWrapper queryFn={reportRepository.fetchAll} queryKey={["reportCustomisation", active]} enabled={active}>
			{(data, query) => (
				<ReportCustomisationInner
					rows={data}
					onDelete={async (uuid) => {
						await investmentsReportTemplateApi.deletePTFReportTemplate(uuid);
						await query.refetch();
					}}
					checkIfNameIsAvailable={(name, opts) =>
						axiosExtract(investmentsReportTemplateApi.checkTemplateNameAvailability(name, opts))
					}
					onDuplicate={async (uuid, name) => {
						await investmentsReportTemplateApi.createPTFReportTemplate({
							...data.find((x) => !isDefaultReportTemplate(x) && x.uuid === uuid)!,
							templateName: name,
						});
						await query.refetch();
					}}
					onRename={async (uuid, newName) => {
						await investmentsReportTemplateApi.updatePTFReportTemplate(uuid, {
							...data.find((x) => !isDefaultReportTemplate(x) && x.uuid === uuid)!,
							templateName: newName,
						});
						await query.refetch();
					}}
					onCreate={async () => {
						const defaultTemplates = data.filter((x) => x.templateName?.includes(defaultTemplateName));
						const findMax = defaultTemplates.reduce((acc, el) => {
							const [name, version] = el.templateName?.split(defaultTemplateName) ?? [];
							if (version && version !== "" && !isNaN(Number(version))) {
								acc = Math.max(Number(version), acc);
							}
							return acc;
						}, 0);
						await shadowCreation(
							defaultTemplates.length > 0 ? `${defaultTemplateName} ${findMax + 1}` : defaultTemplateName,
						);
					}}
					toggleVisibility={async (row) => {
						if (isDefaultReportTemplate(row)) {
							await axiosExtract(
								investmentsReportTemplateApi.createPTFReportTemplate({
									templateName: row.templateName,
									visible: false,
									format: "pdf",
									data: row.data,
								}),
							);
						} else {
							if (!row.uuid) {
								throw new Error("missing template uuid");
							}
							await investmentsReportTemplateApi.updatePTFReportTemplate(row.uuid, {
								...row,
								visible: !row.visible,
							});
							await query.refetch();
						}
					}}
					onBulkDelete={async (uuids) => {
						let successCounter = 0;
						await parallelize(
							uuids.map(
								(uuid) => () => investmentsReportTemplateApi.deletePTFReportTemplate(uuid).then(() => successCounter++),
							),
						);
						platformToast({
							children: `You have successfully deleted “${successCounter}” templates`,
							icon: "pdf",
							severity: "success",
						});
						await query.refetch();
					}}
				/>
			)}
		</ReactQueryWrapper>
	);
};

function ReportCustomisationInner(props: {
	rows: Array<InvestmentsReportTemplate | DefaultReportTemplate>;
	toggleVisibility: (row: InvestmentsReportTemplate | DefaultReportTemplate) => MaybePromise<void>;
	onCreate: () => MaybePromise<void>;
	onDelete: (uuid: string) => MaybePromise<void>;
	checkIfNameIsAvailable: (name: string, opts?: Abortable) => MaybePromise<boolean>;
	onRename: (uuid: string, newName: string) => MaybePromise<void>;
	onDuplicate: (uuid: string, name: string) => MaybePromise<void>;
	onBulkDelete: (uuids: string[]) => Promise<void>;
}) {
	const [query, setQuery] = useState("");

	const rows = useMemo(
		() =>
			props.rows.reduce<{
				default: Array<InvestmentsReportTemplate | DefaultReportTemplate>;
				custom: Array<InvestmentsReportTemplate | DefaultReportTemplate>;
			}>(
				(acc, report) => {
					if (isDefaultReportTemplate(report) || report.templateName === defaultPortfolioTemplates[0].templateName) {
						acc.custom.push(report);
					} else {
						acc.default.push(report);
					}

					return acc;
				},
				{ default: [], custom: [] },
			),
		[props.rows],
	);

	const filtered = useMemo(
		() => rows.default.filter((item) => item.templateName?.toLowerCase().includes(query.toLowerCase()), []),
		[query, rows.default],
	);

	const checkboxColumnData = useSelectableTableColumn({
		rows: props.rows.filter((r) => !isDefaultReportTemplate(r)),
		selectBy: (r) => (!isDefaultReportTemplate(r) ? r.uuid ?? "" : ""),
		selectableRowIds: props.rows.flatMap((r) =>
			isDefaultReportTemplate(r) || r.templateName === defaultPortfolioTemplates[0].templateName ? [] : [r.uuid ?? ""],
		),
		filteredRows: filtered,
	});

	const { toggleVisibility, onDelete, onRename, onDuplicate, checkIfNameIsAvailable } = props;
	const isSpereDefaultTemplateSelected = useMemo(
		() =>
			checkboxColumnData.multiSelectCtx.selection
				.toArray()
				.some((x) =>
					props.rows.find((row) =>
						isDefaultReportTemplate(row)
							? false
							: row.uuid === x && row.templateName === defaultPortfolioTemplates[0].templateName,
					),
				),
		[checkboxColumnData.multiSelectCtx.selection, props.rows],
	);

	const columns = useMemo<Array<TableColumn<InvestmentsReportTemplate | DefaultReportTemplate, string>>>(
		() => [
			checkboxColumnData.column,
			{
				name: "name",
				header: "Name",
				content: (row, cellProps) => (
					<TableDataCell {...cellProps}>
						<div className="flex space-x-2 items-center">
							<ActionText
								href={typedUrlForRoute("PortfolioStudioSettings/ReportEditor", {
									reportUid: isDefaultReportTemplate(row) ? row.id : row.uuid!,
								})}
								data-qualifier={qualifier.reportCustomisation.studio.templateLink(row.templateName)}
							>
								{row.templateName}
							</ActionText>
							{(isDefaultReportTemplate(row) || row.templateName === defaultPortfolioTemplates[0].templateName) && (
								<Badge size="x-small" backgroundColor={themeCSSVars.palette_N200} color={themeCSSVars.palette_N800}>
									Default
								</Badge>
							)}
						</div>
					</TableDataCell>
				),
				width: 560,
				sortFn: builtInCaseInsensitiveSortFor("templateName"),
			},
			{
				name: "format",
				header: "Format",
				content: (row) => row.format,
				width: 192,
				sortFn: builtInCaseInsensitiveSortFor("format"),
			},
			{
				name: "visibility",
				sortFn: builtInSortFnFor("visible"),
				header: (headerProps) => (
					<TableHeadCell {...headerProps}>
						<span className="inline-flex items-center gap-2">
							<span className="line-clamp-2">Available on menu</span>
							<IconTooltip severity="info" overrideColor={themeCSSVars.palette_N300} iconSize={14}>
								You can choose to make this template visible in the download area for the portfolio list and portfolio
								detail page
							</IconTooltip>
						</span>
					</TableHeadCell>
				),
				content: (row, contentProps) => (
					<TableDataCell {...contentProps} onClick={(e) => e.stopPropagation()}>
						<TinyIconButton
							size={20}
							color={themeCSSVars.palette_N400}
							icon={row.visible ? "show" : "Hide"}
							onClickAsync={() => toggleVisibility(row)}
							data-qualifier={qualifier.reportCustomisation.studio.toggleVisibility}
						/>
					</TableDataCell>
				),
				width: 192,
			},
			actionsColumn({
				hideHeader: true,
				onSettingsClick: noop,
				dropdownActions: (row) =>
					isDefaultReportTemplate(row) || row.templateName === defaultPortfolioTemplates[0].templateName
						? []
						: ([
								{
									children: ({ onClose }) => (
										<DropdownMenuActionButton
											onClick={() => {
												spawnDeleteDialog({
													entityType: "template",
													entityName: row.templateName ?? "",
													onDeleteAsync: () => onDelete(row.uuid!),
												}).catch(noop);
												onClose();
											}}
											icon="Delete"
											data-qualifier={qualifier.reportCustomisation.studio.delete}
										>
											Delete
										</DropdownMenuActionButton>
									),
								},
								{
									children: ({ onClose }) => (
										<DropdownMenuActionButton
											onClick={() => {
												spawnRenameDialog({
													entityType: "template",
													onSubmitAsync: (name) => onRename(row.uuid!, name),
													checkIfNameIsAvailable,
													currentName: row.templateName!,
													placeholder: "Template 123",
												}).catch(noop);

												onClose();
											}}
											icon="Edit"
											data-qualifier={qualifier.reportCustomisation.studio.rename}
										>
											Rename
										</DropdownMenuActionButton>
									),
								},
								{
									children: ({ onClose }) => (
										<DropdownMenuActionButton
											onClick={() => {
												spawnDuplicateDialog({
													entityType: "template",
													onSubmitAsync: (name) => onDuplicate(row.uuid!, name),
													checkIfNameIsAvailable,
													originalName: row.templateName!,
													placeholder: "Template 123",
												}).catch(noop);
												onClose();
											}}
											icon="Content-Copy"
											data-qualifier={qualifier.reportCustomisation.studio.duplicate}
										>
											Duplicate
										</DropdownMenuActionButton>
									),
								},
						  ] satisfies Array<ActionOrActionWithGroup<string>>),
				triggerProps: {
					"data-qualifier": qualifier.reportCustomisation.studio.moreActions,
				},
			}),
		],
		[checkboxColumnData.column, toggleVisibility, onDelete, checkIfNameIsAvailable, onRename, onDuplicate],
	);

	return (
		<>
			<div className="flex flex-row justify-between">
				<div className="max-w-md grow">
					<DebouncedSearchInput
						query={query}
						onChange={setQuery}
						placeholder="Filter by name"
						data-qualifier={qualifier.reportCustomisation.studio.search}
					/>
				</div>
				<div>
					<AsyncButton
						onClickAsync={() => props.onCreate()}
						palette="secondary"
						size="small"
						data-qualifier={qualifier.reportCustomisation.studio.newTemplate}
					>
						<Icon icon="Outline1" size={16} />
						&nbsp;New template
					</AsyncButton>
				</div>
			</div>
			<BatchActions
				selected={checkboxColumnData.multiSelectCtx.selection.size}
				total={props.rows.length}
				actions={[
					{
						label: "Delete",
						icon: "Delete",
						"data-qualfier": qualifier.reportCustomisation.studio.bulkDelete,
						...(isSpereDefaultTemplateSelected
							? {
									onClick: noop,
									disabled: true,
									tooltip: {
										children: "Sphere template cannot be deleted",
										overrideColor: themeCSSVars.palette_N300,
									},
							  }
							: {
									onClick: () => {
										spawnDeleteDialog({
											entityNames: checkboxColumnData.multiSelectCtx.selection
												.toArray()
												.map(
													(id) =>
														props.rows.find((r) => !isDefaultReportTemplate(r) && r.uuid === id)?.templateName ?? "",
												),
											onDeleteAsync: async () => {
												await props.onBulkDelete(checkboxColumnData.multiSelectCtx.selection.toArray());
												checkboxColumnData.multiSelectCtx.reset();
											},
										}).catch(noop);
									},
							  }),
					},
				]}
				classList="my-2"
			/>
			<Controller value={defaultReportOrderBy}>
				{({ value: orderBy, onChange: onOrderByChange }) => (
					<BaseHScrollTable
						palette="uniform"
						// TODO: refactor
						style={{ maxHeight: "calc(100dvh - 165px)" }}
						columns={columns}
						rows={rows.custom.concat(sortRows({ rows: filtered, columns, orderByArr: orderBy }))}
						orderBy={orderBy}
						onOrderByChange={onOrderByChange}
						pinnedColumns={[
							{ name: "name", side: "left" },
							{ name: "settings-action", side: "right" },
						]}
						onRowClick={(row) => (!isDefaultReportTemplate(row) ? checkboxColumnData.toggle(row.uuid ?? "") : noop)}
						rowClassList={checkboxColumnData.rowClassList}
					/>
				)}
			</Controller>
		</>
	);
}

const defaultReportOrderBy: Array<OrderBy<"name">> = [{ columnName: "name", direction: "asc" }];

export default ReportCustomisation;
