import { useLocaleFormatters } from "$root/localization/hooks";
import { builtInCaseInsensitiveSortFor } from "$root/utils/collections";
import type { TableColumn } from "@mdotm/mdotui/components";
import { ActionText, AutoSortTable, Banner, useSelectableTableColumn } from "@mdotm/mdotui/components";
import { Button, Icon, NullableNumberInput } from "@mdotm/mdotui/components";
import { useMultiSelect } from "@mdotm/mdotui/headless";
import { overrideClassName, toClassListRecord, useUnsafeUpdatedRef } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import type { ReactNode } from "react";
import { useEffect } from "react";
import { useMemo } from "react";
import type { MinimumViableInstrument } from "./InstrumentsSelectorTable";
import type { UseCompositionBuilderResult } from "../universe/composition";
import BigNumber from "bignumber.js";
import { useTranslation } from "react-i18next";
import { Trans } from "react-i18next";

export type InstrumentsWeightsTable<T extends MinimumViableInstrument> = {
	instruments: T[];
	tableTitle?: ReactNode;
	onRemoveInstrument?(ticker: string): void;
	compositionBuilder: UseCompositionBuilderResult;
};

export function InstrumentsWeightsTable<T extends MinimumViableInstrument>({
	instruments: rows,
	tableTitle,
	onRemoveInstrument,
	compositionBuilder,
}: InstrumentsWeightsTable<T>): JSX.Element {
	const weightsMultiSelectCtx = useMultiSelect<string>();
	const { t } = useTranslation();

	const refs = useUnsafeUpdatedRef({ weightsMultiSelectCtx, onRemoveInstrument });
	useEffect(() => {
		refs.current.weightsMultiSelectCtx.setSelection(
			refs.current.weightsMultiSelectCtx.selection.intersect(rows.map((r) => r.ticker ?? "")),
		);
	}, [rows, refs]);

	const {
		column: checkBoxColumn,
		rowClassList,
		toggle,
	} = useSelectableTableColumn({
		rows,
		multiSelectCtx: weightsMultiSelectCtx,
		selectBy: ({ ticker }) => ticker ?? "",
		mode: "checkbox",
	});

	const { formatNumber } = useLocaleFormatters();
	const sumOfWeights = compositionBuilder.getTotalWeight();
	const columns = useMemo<TableColumn<T>[]>(
		() => [
			checkBoxColumn,
			{
				header: "index",
				content: ({ instrument }) => instrument,
				sortFn: builtInCaseInsensitiveSortFor("assetClass"),
				name: "assetClass",
			},
			{
				header: "weight",
				align: "end",
				content: ({ ticker }, cellProps) => (
					<div className={overrideClassName(cellProps.classList, "flex items-center")} style={cellProps.style}>
						<NullableNumberInput
							onClick={(e) => e.stopPropagation()}
							min={0}
							max={100}
							step={BigNumber(10).pow(-compositionBuilder.getDecimalPlaces()).toNumber()}
							inputAppearance={{
								classList: "text-right",
							}}
							size="x-small"
							classList="w-full"
							value={compositionBuilder.getWeight(ticker ?? "")?.toNumber() ?? null}
							onChange={(value) => compositionBuilder.setWeight(ticker ?? "", value === null ? null : BigNumber(value))}
							rightContent={<Icon icon="Percentile" />}
						/>
					</div>
				),
				sortFn: (a, b) =>
					compositionBuilder.getWeight(a.ticker || "")?.comparedTo(compositionBuilder.getWeight(b.ticker || "") ?? 0) ??
					0,
				name: "weight",
				footer: () => `${formatNumber(sumOfWeights.toNumber(), compositionBuilder.getDecimalPlaces())}%`,
				maxWidth: 130,
				minWidth: 130,
			},
			{
				name: "actions",
				header: "",
				content: ({ ticker }, cellProps) => (
					<div
						className={overrideClassName(cellProps.classList, "flex items-center justify-center")}
						style={cellProps.style}
					>
						<Button
							onClick={() => {
								refs.current.onRemoveInstrument?.(ticker ?? "");
							}}
							unstyled
							data-qualifier="InstrumentEditorTable/Column(Action)/Delete"
						>
							<Icon size={24} color={themeCSSVars.global_palette_primary_600} icon="Delete" />
						</Button>
					</div>
				),
				minWidth: 38,
				maxWidth: 38,
			},
		],
		[checkBoxColumn, compositionBuilder, formatNumber, sumOfWeights, refs],
	);
	return (
		<>
			{tableTitle && <div>{tableTitle}</div>}
			<AutoSortTable
				rows={rows}
				rowClassList={(row, rowIndex) => ({
					...toClassListRecord(rowClassList(row, rowIndex)),
					InstrumentEditorTableRow: true,
				})}
				columns={columns}
				classList="max-h-[410px]"
				onRowClick={({ ticker }) => toggle(ticker ?? "")}
			/>
			{!sumOfWeights.eq(100) && rows.length > 0 && (
				<Banner
					title={
						sumOfWeights.lt(100)
							? t("EDIT_COMPOSITION.NORMALIZE_BOX.LESS_THAN_100.TITLE")
							: t("EDIT_COMPOSITION.NORMALIZE_BOX.MORE_THAN_100.TITLE")
					}
					severity="warning"
				>
					<Trans
						i18nKey={
							sumOfWeights.lt(100)
								? "EDIT_COMPOSITION.NORMALIZE_BOX.LESS_THAN_100.DESCRIPTION_NORMALISE"
								: "EDIT_COMPOSITION.NORMALIZE_BOX.MORE_THAN_100.DESCRIPTION_NORMALISE"
						}
						components={{
							action: (
								<ActionText
									disabled={weightsMultiSelectCtx.selection.size === 0}
									onClick={() => {
										if (weightsMultiSelectCtx.selection.size > 0) {
											compositionBuilder.normalise(weightsMultiSelectCtx.selection);
										}
									}}
								>
									Normalise
								</ActionText>
							),
						}}
					/>
				</Banner>
			)}
		</>
	);
}
