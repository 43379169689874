import type {
	MarketViewListEntry,
	MarketViewSettings,
	UserMarketViewColumnOrdering,
	UserMarketViewColumnPreference,
	UserMarketViewColumnPreferencePreferencesTypeEnum,
} from "$root/api/api-gen";
import { PortfolioStudioPreferencesApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { useTypedNavigation } from "$root/components/PlatformRouter/RoutesDef";
import { spawnYesNoDialog } from "$root/components/spawnable/yes-no-dialog";
import type { ColumnMetadata } from "$root/components/tables-extra/CustomizeColumns";
import { CustomizeColumns } from "$root/components/tables-extra/CustomizeColumns";
import { aclByArea, roleByArea } from "$root/functional-areas/acl/checkers/all";
import { validateACLPermissions } from "$root/functional-areas/acl/checkers/shared";
import { useUpdateAccessListControlDialog } from "$root/functional-areas/acl/hook/useUpdateAccessListControlDialog";
import { marketViewEntityManagementActions } from "$root/functional-areas/market-view/entity-management";
import { useUserValue } from "$root/functional-areas/user";
import { platformToast } from "$root/notification-system/toast";
import { actionsColumn } from "$root/ui-lib/interactive-collections/common-table-actions";
import { preventSubmitOnPressEnter } from "$root/utils/experimental";
import { useDebouncedMemo } from "$root/utils/react-extra";
import { noop } from "@mdotm/mdotui/utils";
import type { DropdownMenuProps, TableColumn } from "@mdotm/mdotui/components";
import {
	BaseHScrollTable,
	BatchActions,
	Icon,
	TextInput,
	contextMenuHandler,
	sortRows,
	useSelectableTableColumn,
} from "@mdotm/mdotui/components";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { nullary, unpromisify } from "@mdotm/mdotui/utils";
import type { QueryObserverBaseResult } from "@tanstack/react-query";
import { Map } from "immutable";
import type { FC } from "react";
import { useCallback, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { multipleEntityDelete, notifyUser } from "../common";
import { usePortfolioStudioTableSettings } from "../portfolio-studio-table-settings";
import { useMarketViewColumn } from "./columns";

type MarketViewsListProps = {
	markets: MarketViewListEntry[];
	marketViewSetting?: MarketViewSettings;
	columnsPreferences?: UserMarketViewColumnPreference[];
	isVisible: boolean;
	refetch: {
		marketView: QueryObserverBaseResult<MarketViewListEntry[]>["refetch"];
		columnsPreferences: QueryObserverBaseResult<UserMarketViewColumnOrdering>["refetch"];
	};
	portfolioStudioOuterDiv: HTMLDivElement | null;
};

const MarketViewsList: FC<MarketViewsListProps> = ({
	markets,
	refetch,
	marketViewSetting,
	columnsPreferences,
	isVisible,
	portfolioStudioOuterDiv,
}) => {
	const [searchQuery, setSearchQuery] = useState("");
	const [customizeTableModalVisible, setCustomizeTableModalVisible] = useState(false);

	const { aclSpawn } = useUpdateAccessListControlDialog("MARKET_VIEW");
	const { t } = useTranslation();
	const { push } = useTypedNavigation();
	const portfolioStudioPreferencesApi = useApiGen(PortfolioStudioPreferencesApiFactory);

	const user = useUserValue();

	const { value: debouncedLowerCaseSearchQuery } = useDebouncedMemo(
		() => searchQuery.toLocaleLowerCase(),
		[searchQuery],
		{
			debounceInterval: 200,
		},
	);

	const filteredRows = useMemo(
		() =>
			markets.filter((item) =>
				[item.name ?? "", item.forecastHorizon ?? ""].some((el) =>
					el.toLowerCase().includes(debouncedLowerCaseSearchQuery),
				),
			),
		[debouncedLowerCaseSearchQuery, markets],
	);

	const marketByUid = useMemo(() => Map(markets.map((p) => [p.uuid ?? "", p])), [markets]);

	const {
		column: checkboxColumn,
		rowClassList,
		multiSelectCtx: tableMultiSelectCtx,
	} = useSelectableTableColumn({
		rows: markets,
		filteredRows,
		selectBy: (r) => r.uuid!,
	});
	const selection = useMemo(() => tableMultiSelectCtx.selection.toArray(), [tableMultiSelectCtx]);

	const { marketViewListOrderByName, setMarketViewListOrderByName } = usePortfolioStudioTableSettings();
	const visibleColumns = useMarketViewColumn(columnsPreferences, marketViewSetting);

	const getDropdownActions = useCallback(
		(marketView: MarketViewListEntry): DropdownMenuProps<HTMLButtonElement, "">["actions"] => {
			const entityManagementActions = marketViewEntityManagementActions(user, marketView, {
				onRename: unpromisify(nullary(refetch.marketView)),
				onDuplicate: unpromisify(nullary(refetch.marketView)),
				onDelete: unpromisify(nullary(refetch.marketView)),
			});

			return [
				entityManagementActions.deleteAsync && {
					icon: "Delete",
					onClick: unpromisify(entityManagementActions.deleteAsync),
					"data-qualifier": "PortfolioStudio/MarketViewList/DropdownMenu/DropdownItem(Delete)",
					label: "Delete",
				},
				entityManagementActions.renameAsync && {
					icon: "Edit",
					onClick: unpromisify(entityManagementActions.renameAsync),
					"data-qualifier": "PortfolioStudio/MarketViewList/DropdownMenu/DropdownItem(Rename)",
					label: "Rename",
				},
				entityManagementActions.duplicateAsync && {
					icon: "Content-Copy",
					onClick: () =>
						push("MarketViewWorkSpace", {
							action: "clone",
							uuid: marketView.uuid!,
							type: marketView.type!,
							isCustom: String(Boolean(marketView.custom)),
						}),
					"data-qualifier": "PortfolioStudio/MarketViewList/DropdownMenu/DropdownItem(Duplicate)",
					label: "Duplicate",
				},
				validateACLPermissions(user.id, marketView.richAcl?.acl ?? [], roleByArea.marketView.EDITOR) && {
					icon: "share",
					onClickAsync: async () => {
						await aclSpawn(marketView.name, marketView.uuid, refetch.marketView);
					},
					"data-qualifier": "PortfolioStudio/MarketViewList/DropdownMenu/DropdownItem(Share)",
					label: "Share",
				},
			];
		},
		[user, refetch.marketView, push, aclSpawn],
	);

	const columns = useMemo<TableColumn<MarketViewListEntry>[] | null>(
		() =>
			visibleColumns
				? [
						checkboxColumn,
						...visibleColumns,
						actionsColumn({
							onSettingsClick: () => setCustomizeTableModalVisible(true),
							triggerProps: {
								"data-qualifier": "PortfolioStudio/UniverseList/DropdownMenu",
							},
							dropdownActions: getDropdownActions,
						}),
				  ]
				: null,
		[visibleColumns, checkboxColumn, getDropdownActions],
	);

	const filteredSortedRows = useMemo(
		() => (columns ? sortRows({ rows: filteredRows, columns, orderByArr: marketViewListOrderByName }) : filteredRows),
		[columns, filteredRows, marketViewListOrderByName],
	);

	const onChangeTableColumn = useCallback(
		async (data: ColumnMetadata<UserMarketViewColumnPreferencePreferencesTypeEnum>[]) => {
			try {
				const payload = {
					userMarketViewColumnPreferences: data.map((preference) => ({
						enabled: preference.visible,
						preferencesType: preference.id,
					})),
				} satisfies UserMarketViewColumnOrdering;
				await portfolioStudioPreferencesApi.setUserMarketViewColumnMetricsOrderingPreferences(payload);
				await refetch.columnsPreferences({ throwOnError: true });
				platformToast({
					children: "Table Successfully Edited.",
					severity: "success",
					icon: "Settings",
				});
			} catch (error) {
				platformToast({
					children: "Failed to update table",
					severity: "error",
					icon: "Settings",
				});
				throw new Error(String(error));
			} finally {
				setCustomizeTableModalVisible(false);
			}
		},
		[portfolioStudioPreferencesApi, refetch],
	);

	const customizableColumns = (columnsPreferences ?? []).map((c) => ({
		label: c.preferencesType ? t(`TABLE.HEADERS.${c.preferencesType}`) : "-",
		id: c.preferencesType!,
		visible: c.enabled ?? false,
		disabled: c.preferencesType === "NAME",
		hidden: c.preferencesType === "NAME",
	}));

	return (
		<>
			<CustomizeColumns
				show={customizeTableModalVisible}
				onClose={() => setCustomizeTableModalVisible(false)}
				columns={customizableColumns}
				// limit={Math.min(7, customizableColumns?.filter((x) => x.hidden === false).length ?? 1)}
				onSubmitAsync={onChangeTableColumn}
			/>

			<TextInput
				value={searchQuery}
				name="search"
				maxLength={60}
				onChangeText={setSearchQuery}
				placeholder={t("PORTFOLIOS.CONSTRAINTS_TARGETS.PORTFOLIO_LIST")}
				onKeyDown={preventSubmitOnPressEnter}
				style={{ width: 692 }}
				leftContent={<Icon icon="Search" size="1.4em" />}
				classList="mb-4"
			/>

			{columns && (
				<BaseHScrollTable
					onRowClick={(row) => row.uuid && tableMultiSelectCtx.toggle(row.uuid)}
					onRowContextMenu={(row, _index, e) => contextMenuHandler(e, getDropdownActions(row))}
					columns={columns}
					rows={filteredSortedRows}
					rowClassList={rowClassList}
					rowStyle={({ uuid }) => {
						const selected = tableMultiSelectCtx.selection?.has(uuid!);
						return selected ? { backgroundColor: themeCSSVars.Table_highlightedRowBackgroundColor } : {};
					}}
					// TODO: refactor
					style={{ height: "calc(100dvh - 160px)" }}
					noDataText="No data available"
					pinnedColumns={[
						{ name: "name", side: "left" },
						{ name: "settings-action", side: "right" },
					]}
					orderBy={marketViewListOrderByName}
					onOrderByChange={setMarketViewListOrderByName}
				/>
			)}

			{portfolioStudioOuterDiv &&
				createPortal(
					isVisible && tableMultiSelectCtx.selection.size > 0 ? (
						<BatchActions
							classList={`sticky bottom-5 !bg-[color:${themeCSSVars.palette_N500}] !text-white h-11 px-2 rounded-md shadow-xl shadow-[color:${themeCSSVars.palette_N200}] [&>button:hover]:hover:!text-[color:${themeCSSVars.palette_N700}]`}
							selected={tableMultiSelectCtx.selection.size}
							total={markets.length}
							actions={[
								{
									label: "Delete",
									icon: "Delete",
									onClickAsync: () =>
										spawnYesNoDialog({
											header: t("PORTFOLIOS.MODAL.BULK_DELETE.TITLE"),
											yesButton: t("BUTTON.DELETE"),
											noButton: t("BUTTON.CANCEL"),
											children: t("PORTFOLIOS.MODAL.BULK_DELETE.DESCRIPTION", {
												size: tableMultiSelectCtx.selection.size,
											}),
											onSubmitAsync: async () => {
												const items = selection.flatMap((uuid) => {
													const marketView = marketByUid.get(uuid);
													return marketView ? [marketView] : [];
												});
												const response = await multipleEntityDelete({ area: "MarketViews", items });
												await refetch.marketView({ throwOnError: true });
												tableMultiSelectCtx.reset();
												await notifyUser({ t, succeded: response.succeded, area: "MarketViews" });
											},
										})
											.then(noop)
											.catch(noop),
									disabled: tableMultiSelectCtx.selection.some(
										(id) => aclByArea.portfolio.canDelete(user.id, marketByUid?.get(id)?.richAcl?.acl ?? []) === false,
									),
									"data-qualifier": "MarketViewList/BatchAction(Delete)",
								},
							]}
						/>
					) : (
						<></>
					),
					portfolioStudioOuterDiv,
				)}
		</>
	);
};

export default MarketViewsList;
