import InfiniteLoader from "$root/components/InfiniteLoader";
import PageLayout from "$root/components/PageLayout";
import { useUserValue } from "$root/functional-areas/user";
import { Suspense, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import GlobalErrorBoundary from "../GlobalErrorBoundary";
import { extractPath, routesMap, typedPropsFromUrl, typedUrlForRoute } from "./RoutesDef";
import { useSystemValue } from "$root/functional-areas/system";
import { AppDrawer } from "../AppDrawer";
import { usePlatformLayoutOptionsState, usePlatformLayoutOptionsValue } from "./layout-options";
import { trackMixPanelEvent } from "$root/third-party-integrations/initMixPanel";
import { maybeCall } from "@mdotm/mdotui/utils";
import { useEventListener } from "@mdotm/mdotui/react-extensions";
import { Row } from "@mdotm/mdotui/components";

const PlatformRouter = (): JSX.Element => {
	return (
		<GlobalErrorBoundary>
			<PlatformRouterInner />
		</GlobalErrorBoundary>
	);
};

const PlatformRouterInner = (): JSX.Element => {
	const { systemMode } = useSystemValue();
	const history = useHistory();

	useEffect(() => {
		// MixPanel Page View => Need Refactor
		trackMixPanelEvent("Page View", {
			Page: location.pathname,
		});
	}, [history.location.pathname]);

	// Intercept regular <a href="...">...</a> when the origin is the same as the window
	useEventListener(window, "click", (e) => {
		const a = e.target instanceof HTMLAnchorElement ? e.target : (e.target as HTMLElement | undefined)?.closest("a");
		if (
			// Not an anchor element
			!a ||
			// Modifiers or middle/right click
			e.metaKey ||
			e.shiftKey ||
			e.altKey ||
			e.ctrlKey ||
			e.button !== 0 ||
			// Download anchors
			a.getAttribute("download") ||
			// Anchors to different origins
			a.origin !== window.location.origin
		) {
			return;
		}

		e.preventDefault();
		history.push(a.pathname + a.search + a.hash);
	});

	const platformLayoutOptions = usePlatformLayoutOptionsValue();
	const currentUser = useUserValue();

	const routes = useMemo(
		() =>
			systemMode === "MAINTENANCE" ? (
				<Route path="*">
					<PageWrapper routeKey="Maintenance" route={routesMap["Maintenance"]} />
				</Route>
			) : (
				Object.entries(routesMap).map(([key, route]) => {
					const routePath = route.baseUrl === "*" ? route.baseUrl : extractPath(route.baseUrl);
					return (
						<Route key={key} exact={route.baseUrl !== "*"} path={routePath}>
							<PageWrapper routeKey={key as keyof typeof routesMap} route={route} />
						</Route>
					);
				})
			),
		[systemMode],
	);

	return (
		<Row alignItems="stretch" justifyContent="start" childrenGrow={1} childrenShrink={1}>
			{platformLayoutOptions.sideBar && currentUser.signedIn && <AppDrawer key={currentUser.id + "sideBar"} />}
			<Switch>{routes}</Switch>
		</Row>
	);
};

function PageWrapper<K extends keyof typeof routesMap>({
	routeKey,
	route,
}: {
	routeKey: K;
	route: (typeof routesMap)[K];
}) {
	const currentUser = useUserValue();
	const { setPlatformLayoutOptions, platformLayoutOptions } = usePlatformLayoutOptionsState();
	useLayoutEffect(() => {
		setPlatformLayoutOptions(maybeCall(route.layoutOptions, { user: currentUser }));
	}, [currentUser, route.layoutOptions, setPlatformLayoutOptions]);
	const history = useHistory();
	const [props, setProps] = useState(() => {
		return typedPropsFromUrl(routeKey, history.location.pathname + history.location.search) ?? ({} as any);
	});
	useEffect(() => {
		const sub = history.listen((location) =>
			setProps(typedPropsFromUrl(routeKey, location.pathname + location.search) ?? ({} as any)),
		);
		return sub;
	}, [history, routeKey]);

	const DynamicallyImportedPage = useMemo(() => {
		return route.component;
	}, [route]);

	if (route.guard) {
		const guardResult = route.guard({ user: currentUser });
		if (!guardResult.passed) {
			if (guardResult.reason === "requires-login") {
				return <Redirect to={typedUrlForRoute("Login", { from: window.location.pathname + window.location.search })} />;
			}
			return <Redirect to={typedUrlForRoute("Index", {})} />;
		}
	}
	return (
		<>
			<PageLayout
				hideSidebar={!platformLayoutOptions.sideBar}
				backgroundImage={platformLayoutOptions.backgroundImage}
				mode={platformLayoutOptions.mode}
			>
				<Suspense fallback={<InfiniteLoader />}>
					<DynamicallyImportedPage {...props} />
				</Suspense>
			</PageLayout>
		</>
	);
}

export default PlatformRouter;
