import type {
	EditorSaveEditOrReviewRequestEditPolicyEnum,
	InvestmentExposureResponse,
	InvestmentSummary,
	ReviewTicker,
} from "$root/api/api-gen";
import {
	EditorSaveEditOrReviewRequestPortfolioSavingModeEnum,
	EntityEditorControllerApiFactory,
	InvestmentReportsControllerApiFactory,
} from "$root/api/api-gen";
import { reportPlatformError } from "$root/api/error-reporting";
import { useApiGen } from "$root/api/hooks";
import { hasAccess } from "$root/components/AuthorizationGuard";
import type { Crumb } from "$root/components/Breadcrumbs";
import { LeavePrompt } from "$root/components/LeavePrompt";
import { PageHeader } from "$root/components/PageHeader";
import { typedUrlForRoute, useTypedNavigation } from "$root/components/PlatformRouter/RoutesDef";
import { ReactQueryWrapperBase } from "$root/components/ReactQueryWrapper";
import { defaultTagLabels, labelToTag } from "$root/components/tags/shared";
import type { CompareDataItem } from "$root/functional-areas/compare-portfolio/CompareOverlay";
import { CompareOverlay } from "$root/functional-areas/compare-portfolio/CompareOverlay";
import type { InstrumentEditorBuilderProps, Tag } from "$root/functional-areas/instruments-editor/builder";
import {
	covertInstrumentEditorToReviewTicker,
	instrumentEntryKey,
	useInstrumentEditorBuilder,
} from "$root/functional-areas/instruments-editor/builder";
import type { InstrumentEditorEntry, MinimunDialogProps } from "$root/functional-areas/instruments-editor/const";
import InstrumentCompostionEditor from "$root/functional-areas/instruments-editor/instrumentEditor";
import ExposureSankeyBlock from "$root/functional-areas/instruments-editor/InstrumentExposureSankey";
import { spawnOverridePortfolioDialog } from "$root/functional-areas/instruments-editor/spawn/portfolio-template";
import { useUserValue } from "$root/functional-areas/user";
import { platformToast } from "$root/notification-system/toast";
import { manualSaveEditFactory } from "$root/pages/ManualCreation/handler";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { trackMixPanelEvent } from "$root/third-party-integrations/initMixPanel";
import { FormController } from "$root/third-party-integrations/react-hook-form";
import {
	INVESTMENT_INSTRUMENT_LIMIT,
	MIN_NUMBER_OF_INSTRUMENTS,
	MIN_NUMBER_OF_INVESTMENT_INSTRUMENTS,
} from "$root/utils/const";
import { valueByPath } from "$root/utils/objects";
import { parallelize } from "$root/utils/promise";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { useSearchParams } from "$root/utils/react-router-extra";
import { zodResolver } from "@hookform/resolvers/zod";
import type { DataAttributesProps } from "@mdotm/mdotui/components";
import {
	AsyncButton,
	Button,
	Dialog,
	DialogFooter,
	DialogHeader,
	FormField,
	Radio,
	RadioGroup,
	SubmitButton,
} from "@mdotm/mdotui/components";
import type { TrackableAsyncFn } from "@mdotm/mdotui/headless";
import type { SpawnResult } from "@mdotm/mdotui/react-extensions";
import { adaptAnimatedNodeProvider, spawn } from "@mdotm/mdotui/react-extensions";
import type { AxiosError } from "axios";
import { Map, Set } from "immutable";
import type { MouseEvent, MouseEventHandler } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { flushSync } from "react-dom";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import * as z from "zod";

function MaybeAsyncButton<T>(
	props: {
		onClickAsync: TrackableAsyncFn<T, [MouseEvent]>;
		onClick: MouseEventHandler<HTMLButtonElement>;
		label: string;
		mode: "sync" | "async";
		disabled?: boolean;
	} & DataAttributesProps,
) {
	const { label, mode, onClick, onClickAsync, disabled, ...dataAttributes } = props;
	if (mode === "sync") {
		return (
			<Button {...dataAttributes} size="small" onClick={onClick} palette="primary" disabled={disabled}>
				{label}
			</Button>
		);
	}

	return (
		<AsyncButton {...dataAttributes} size="small" palette="primary" onClickAsync={onClickAsync} disabled={disabled}>
			{label}
		</AsyncButton>
	);
}

const crumbs = {
	portfolioStudios: { children: "Portfolio studio" },
	portolioList: {
		children: "Portfolios",
		href: typedUrlForRoute("PortfoliosStudio", {}),
	},
	portolioName: (summary?: InvestmentSummary) => ({
		children: summary?.name ?? "...",
		href: summary?.uuid
			? typedUrlForRoute("PortfolioDetails", { portfolioUid: summary?.uuid })
			: typedUrlForRoute("PortfoliosStudio", {}),
	}),
	editPortfolio: {
		children: "Edit",
	},
} satisfies Record<string, Crumb | ((...params: any[]) => Crumb)>;

function ManualPortfolioEdit(): JSX.Element {
	const { uuid } = useParams<{ uuid: string }>();
	const { copyComposition } = useSearchParams<"copyComposition">();

	const { t } = useTranslation();
	const { push } = useTypedNavigation();
	const user = useUserValue();

	const [pathToNotBlock, setPathToNotBlock] = useState<string[]>([typedUrlForRoute("Login", {})]);

	const investmentReportApi = useApiGen(InvestmentReportsControllerApiFactory);

	const querySummary = useQueryNoRefetch(["queryInvestmentSummary", uuid], {
		queryFn: () => axiosExtract(investmentReportApi.getInvestmentSummary(uuid)),
	});

	const instrumentBuilder = useInstrumentEditorBuilder({ composition: Map() });
	const compositionMap = instrumentBuilder.watchComposition();
	const compositionMapWithouthDeleteInstruments = compositionMap.removeAll(instrumentBuilder.getDeleted());
	const totalCompositionWeight = instrumentBuilder.getTotal("weight");

	const instrumentsInComposition = useMemo(
		() =>
			compositionMapWithouthDeleteInstruments.reduce((sum, instument) => {
				if (instument.proxyOverwriteType === "PORTFOLIO_MIXED") {
					const instrumentsInInvestment = instument.investment?.nofInstruments ?? 0;
					const instrumentsExcluded = instument.nOfInstrumentExcluded ?? 0;
					return instrumentsInInvestment - instrumentsExcluded + sum;
				}

				return sum + 1;
			}, 0),
		[compositionMapWithouthDeleteInstruments],
	);

	const maxInstrumentsInComposition = hasAccess(user, { requiredService: "NUMBER_OF_INSTRUMENTS_CHECK_BYPASS" })
		? +Infinity
		: INVESTMENT_INSTRUMENT_LIMIT;

	const minInstrumentsInComposition = hasAccess(user, { requiredService: "NUMBER_OF_INSTRUMENTS_CHECK_BYPASS" })
		? MIN_NUMBER_OF_INSTRUMENTS
		: MIN_NUMBER_OF_INVESTMENT_INSTRUMENTS;

	const isSubmitDiabled = useMemo(() => {
		return (
			totalCompositionWeight !== 100 ||
			instrumentsInComposition < minInstrumentsInComposition ||
			instrumentsInComposition > maxInstrumentsInComposition
		);
	}, [instrumentsInComposition, maxInstrumentsInComposition, minInstrumentsInComposition, totalCompositionWeight]);

	const hasPortfolioInComposition = useMemo(() => {
		return compositionMapWithouthDeleteInstruments.some((x) => x.proxyOverwriteType === "PORTFOLIO_MIXED");
	}, [compositionMapWithouthDeleteInstruments]);

	const onSubmit = useCallback(
		async (params: {
			uuid: string;
			portfolioSavingMode: EditorSaveEditOrReviewRequestPortfolioSavingModeEnum;
			composition: ReviewTicker[];
		}) => {
			const { portfolioSavingMode, composition } = params;
			try {
				if (params.uuid === undefined) {
					throw Error("unable to submit a composition of undefined");
				}

				await manualSaveEditFactory.saveInvestment(params.uuid, {
					composition,
					form: { portfolioSavingMode },
				});
				flushSync(() => setPathToNotBlock([typedUrlForRoute("PortfolioDetails", { portfolioUid: uuid })]));
				trackMixPanelEvent("Portfolio", {
					Type: "Update",
					Area: `Composition`,
					Mode: "normal",
					ID: uuid ?? "",
				});
				push("PortfolioDetails", { portfolioUid: params.uuid });
			} catch (error) {
				const e = error as AxiosError<{ message?: string }>;
				const message = e.response?.data.message;

				if (message === "maxValue should be greater or equal to 0.02") {
					platformToast({
						children: "Please review your instrument, minimum weight acceptable is 0.02",
						severity: "error",
						icon: "Portfolio",
					});
				} else {
					platformToast({
						children: "Something went wrong, please try later",
						severity: "error",
						icon: "Portfolio",
					});
				}

				reportPlatformError(error, "ERROR", "portfolio", {
					message: `unable to save the edited portfolio composition (${uuid})`,
				});

				throw error;
			}
		},
		[push, uuid],
	);

	const onSubmitEnhanceComposition = useCallback(
		async (params: {
			uuid: string;
			portfolioSavingMode: EditorSaveEditOrReviewRequestPortfolioSavingModeEnum;
			savePolicy: EditorSaveEditOrReviewRequestEditPolicyEnum;
			composition: ReviewTicker[];
		}) => {
			const { uuid, portfolioSavingMode, composition, savePolicy } = params;
			try {
				if (uuid === undefined) {
					throw Error("unable to submit a composition of undefined");
				}

				await manualSaveEditFactory.saveInvestmentEnhancement(uuid, {
					form: { editPolicy: savePolicy, portfolioSavingMode },
					composition,
				});
				flushSync(() => setPathToNotBlock([typedUrlForRoute("PortfolioDetails", { portfolioUid: uuid })]));
				trackMixPanelEvent("Portfolio", {
					Type: "Update",
					Area: `Composition`,
					Mode: savePolicy === "SAVE_AND_ACCEPT" ? "enhance" : "enhance Without Accept",
					ID: uuid,
				});
				push("PortfolioDetails", { portfolioUid: uuid });
			} catch (error) {
				const e = error as AxiosError<{ message?: string }>;
				const message = e.response?.data.message;

				if (message === "maxValue should be greater or equal to 0.02") {
					platformToast({
						children: "Please review your instrument, minimum weight acceptable is 0.02",
						severity: "error",
						icon: "Portfolio",
					});
				} else {
					platformToast({
						children: "Something went wrong, please try later",
						severity: "error",
						icon: "Portfolio",
					});
				}

				reportPlatformError(error, "ERROR", "portfolio", {
					message: `unable to save the edited portfolio composition (${uuid})`,
				});

				throw error;
			}
		},
		[push],
	);

	return (
		<ReactQueryWrapperBase query={querySummary}>
			{(summary) => (
				<>
					<PageHeader
						title="Edit portfolio composition"
						crumbs={[crumbs.portfolioStudios, crumbs.portolioList, crumbs.portolioName(summary), crumbs.editPortfolio]}
						titleAction={
							<div className="flex items-center space-x-2">
								<Button
									size="small"
									onClick={() => push("PortfolioDetails", { portfolioUid: uuid })}
									palette="tertiary"
									data-qualifier="CompositionEditor/Cancel"
								>
									{t("BUTTON.CANCEL")}
								</Button>
								{summary.status === "PROPOSAL_READY" ? (
									<>
										<MaybeAsyncButton
											label={t("BUTTON.UPDATE_PROPOSAL")}
											mode={hasPortfolioInComposition ? "sync" : "async"}
											onClick={() =>
												spawnChooseEntitySavingModeDialog({
													onSubmitAsync: ({ portfolioSavingMode }) =>
														onSubmitEnhanceComposition({
															composition: covertInstrumentEditorToReviewTicker(
																Array.from(instrumentBuilder.getComposition().values()),
															),
															portfolioSavingMode,
															uuid,
															savePolicy: "SAVE_WITHOUT_ACCEPT",
														}),
													portfolioSavingMode: "NEST_PORTFOLIOS",
												})
											}
											onClickAsync={() =>
												onSubmitEnhanceComposition({
													composition: covertInstrumentEditorToReviewTicker(
														Array.from(instrumentBuilder.getComposition().values()),
													),
													portfolioSavingMode: "MIX_INSTRUMENTS",
													uuid,
													savePolicy: "SAVE_WITHOUT_ACCEPT",
												})
											}
											disabled={isSubmitDiabled}
											data-qualifier="EditPortfolioComposition/UpdateProposal/Button"
										/>
										<MaybeAsyncButton
											label={t("BUTTON.SAVE_AND_ACCEPT")}
											mode={hasPortfolioInComposition ? "sync" : "async"}
											onClick={() =>
												spawnChooseEntitySavingModeDialog({
													onSubmitAsync: ({ portfolioSavingMode }) =>
														onSubmitEnhanceComposition({
															composition: covertInstrumentEditorToReviewTicker(
																Array.from(instrumentBuilder.getComposition().values()),
															),
															portfolioSavingMode,
															uuid,
															savePolicy: "SAVE_AND_ACCEPT",
														}),
													portfolioSavingMode: "NEST_PORTFOLIOS",
												})
											}
											onClickAsync={() =>
												onSubmitEnhanceComposition({
													composition: covertInstrumentEditorToReviewTicker(
														Array.from(instrumentBuilder.getComposition().values()),
													),
													portfolioSavingMode: "MIX_INSTRUMENTS",
													uuid,
													savePolicy: "SAVE_AND_ACCEPT",
												})
											}
											disabled={isSubmitDiabled}
											data-qualifier="EditPortfolioComposition/SaveAndAccepEnhancement/Button"
										/>
									</>
								) : (
									<MaybeAsyncButton
										label={t("BUTTON.SAVE")}
										mode={hasPortfolioInComposition ? "sync" : "async"}
										onClick={() =>
											spawnChooseEntitySavingModeDialog({
												onSubmitAsync: ({ portfolioSavingMode }) =>
													onSubmit({
														composition: covertInstrumentEditorToReviewTicker(
															Array.from(instrumentBuilder.getComposition().values()),
														),
														portfolioSavingMode,
														uuid,
													}),
												portfolioSavingMode: "NEST_PORTFOLIOS",
											})
										}
										onClickAsync={() =>
											onSubmit({
												composition: covertInstrumentEditorToReviewTicker(
													Array.from(instrumentBuilder.getComposition().values()),
												),
												portfolioSavingMode: "MIX_INSTRUMENTS",
												uuid,
											})
										}
										disabled={isSubmitDiabled}
										data-qualifier="EditPortfolioComposition/Save/Button"
									/>
								)}
							</div>
						}
					/>
					<InstrumentEditorTable
						instrumentBuilder={instrumentBuilder}
						summary={summary}
						uuid={uuid}
						maxInstrumentsInComposition={maxInstrumentsInComposition}
						minInstrumentsInComposition={minInstrumentsInComposition}
						copyComposition={copyComposition}
					/>
					<LeavePrompt
						title={t("NOTIFICATION_SETTINGS.LEAVE_PROMPT.TITLE")}
						when={instrumentBuilder.isDirty}
						pathToNotBlock={pathToNotBlock}
					>
						{t("NOTIFICATION_SETTINGS.LEAVE_PROMPT.MESSAGE")}
					</LeavePrompt>
				</>
			)}
		</ReactQueryWrapperBase>
	);
}

function InstrumentEditorTable({
	summary,
	instrumentBuilder,
	uuid,
	minInstrumentsInComposition,
	maxInstrumentsInComposition,
	copyComposition,
}: {
	summary: InvestmentSummary;
	instrumentBuilder: InstrumentEditorBuilderProps;
	uuid: string;
	minInstrumentsInComposition: number;
	maxInstrumentsInComposition: number;
	copyComposition?: string;
}) {
	const { replace } = useTypedNavigation();

	const [expandExposure, setExpandExposure] = useState(false);
	const entityEditorApi = useApiGen(EntityEditorControllerApiFactory);
	const investmentReportApi = useApiGen(InvestmentReportsControllerApiFactory);

	const queryInitManualEdit = useQueryNoRefetch(["queryInitManulEditComposition", uuid], {
		queryFn: async () => {
			const editorResponse = await axiosExtract(
				entityEditorApi.getEditorEditComposition(
					uuid,
					summary.status === "PROPOSAL_READY" ? "INVESTMENT_ENHANCEMENT" : "INVESTMENT",
				),
			);

			const { composition } = editorResponse;
			const nonNullableComposition = composition ?? [];

			let investmentSummaryMap = Map<string, InvestmentSummary>();
			let investmentExposureMap = Map<string, InvestmentExposureResponse>();
			let verifiedInvestmentsMap = Map<string, ReviewTicker[]>();
			const investments = nonNullableComposition.flatMap((instrument) => {
				if (instrument.ticker && instrument.proxyOverwriteType === "PORTFOLIO_MIXED") {
					return [{ uuid: instrument.ticker, weight: instrument.weight }];
				}
				return [];
			});

			await parallelize(
				investments.map((investment) => async () => {
					const investmentSummary = await axiosExtract(investmentReportApi.getInvestmentSummary(investment.uuid));
					const exposure = await axiosExtract(
						investmentReportApi.getTwoLevelsInvestmentExposure(
							investment.uuid,
							investmentSummary.primaryBenchmarkIdentifier!,
							"MACRO_ASSET_CLASS",
						),
					);
					const { removedTickers } = await axiosExtract(
						entityEditorApi.verifyEditorPortfolio(
							investment.uuid,
							investment.weight ?? 0,
							investmentSummary.status === "PROPOSAL_READY" ? "INVESTMENT_ENHANCEMENT" : "INVESTMENT",
						),
					);

					verifiedInvestmentsMap = verifiedInvestmentsMap.set(investment.uuid, removedTickers ?? []);
					investmentExposureMap = investmentExposureMap.set(investment.uuid, exposure);
					investmentSummaryMap = investmentSummaryMap.set(investment.uuid, investmentSummary);
				}),
				{ concurrency: 3 },
			);

			return {
				...editorResponse,
				composition: nonNullableComposition,
				investments,
				investmentSummaryMap,
				investmentExposureMap,
				verifiedInvestmentsMap,
			};
		},
		onSuccess({
			composition,
			cashTicker,
			investments,
			investmentSummaryMap,
			investmentExposureMap,
			verifiedInvestmentsMap,
		}) {
			const uniqueTags = Set(defaultTagLabels.concat(composition.flatMap((x) => (x.tagLabel ? [x.tagLabel] : []))));
			const tags = Map<string, Tag>(
				uniqueTags.map((tag): [string, Tag] => {
					const convertedTag = labelToTag({ label: tag }, Array.from(uniqueTags));
					return [convertedTag.name, { label: convertedTag.name, color: convertedTag.color, value: convertedTag.name }];
				}),
			);

			if (investments.length === 0) {
				const compositionMap = Map(
					composition.map((instrument): [string, InstrumentEditorEntry] => {
						const rowId = instrumentEntryKey(instrument);
						return [rowId, { ...instrument, rowId, stableWeight: instrument.weight }];
					}),
				);

				instrumentBuilder.reset(
					{
						composition: compositionMap,
						cash: cashTicker ? { ...cashTicker, rowId: instrumentEntryKey(cashTicker) } : undefined,
						tags,
					},
					{ disableToggleDirty: true },
				);
				return;
			}

			const compositionMap = Map(
				composition.map((instrument): [string, InstrumentEditorEntry] => {
					const rowId = instrumentEntryKey(instrument);
					if (instrument.proxyOverwriteType === "PORTFOLIO_MIXED" && instrument.ticker) {
						const exposure = investmentExposureMap.get(instrument.ticker);
						const investmentSummary = investmentSummaryMap.get(instrument.ticker);
						const someInstrumentAreRemoved = verifiedInvestmentsMap.get(instrument.ticker) ?? [];

						return [
							rowId,
							{
								...instrument,
								rowId,
								investment: {
									nofInstruments: investmentSummary?.nofInstruments,
									macroAssetClassExposure: exposure?.investmentComposition,
									macroAssetClassExposureEnhanced: exposure?.enhancementComposition,
									name: investmentSummary?.name,
									lastActionNote: investmentSummary?.lastActionNote,
									uuid: investmentSummary?.uuid,
									action: investmentSummary?.action,
								},
								identifier: "Portfolio",
								instrument: investmentSummary?.name,
								weight: instrument.weight ?? 10,
								stableWeight: instrument.weight,
								nOfInstrumentExcluded: someInstrumentAreRemoved.length,
								someInstrumentsAreExcluded: someInstrumentAreRemoved.length > 0,
							},
						];
					}
					return [rowId, { ...instrument, rowId }];
				}),
			);

			instrumentBuilder.reset(
				{
					composition: compositionMap,
					cash: cashTicker ? { ...cashTicker, rowId: instrumentEntryKey(cashTicker) } : undefined,
					tags,
				},
				{ disableToggleDirty: true },
			);

			setExpandExposure(true);
		},
	});

	const comparedInvestments = useMemo((): CompareDataItem[] => {
		const investmentsCompared = instrumentBuilder
			.watchComposition()
			.filter((x) => instrumentBuilder.getComparedInstrument(x.rowId) !== undefined)
			.values();

		return Array.from(investmentsCompared).map((instrument) => ({
			id: instrument.rowId,
			composition:
				instrument.investment?.macroAssetClassExposure?.map((x) => ({
					quality: x.firstQualityLevel,
					weight: x.weight,
				})) ?? [],
			numberOfInstrument: instrument.investment?.nofInstruments ?? 0,
			portfolioName: instrument.investment?.name ?? "-",
			uuid: instrument.investment?.uuid,
			note: instrument.investment?.lastActionNote,
			action: instrument.investment?.action,
		}));
	}, [instrumentBuilder]);

	useEffect(() => {
		if (copyComposition) {
			spawnOverridePortfolioDialog({
				entity: "INVESTMENT",
				uuid,
				async onSubmitAsync(investment) {
					const editorResponse = await axiosExtract(
						entityEditorApi.getEditorEditComposition(investment.uuid!, "INVESTMENT"),
					);

					const { composition, cashTicker } = editorResponse;
					const nonNullableComposition = composition ?? [];

					let investmentSummaryMap = Map<string, InvestmentSummary>();
					let investmentExposureMap = Map<string, InvestmentExposureResponse>();
					let verifiedInvestmentsMap = Map<string, ReviewTicker[]>();
					const investments = nonNullableComposition.flatMap((instrument) => {
						if (instrument.ticker && instrument.proxyOverwriteType === "PORTFOLIO_MIXED") {
							return [{ uuid: instrument.ticker, weight: instrument.weight }];
						}
						return [];
					});

					await parallelize(
						investments.map((investment) => async () => {
							const investmentSummary = await axiosExtract(investmentReportApi.getInvestmentSummary(investment.uuid));
							const exposure = await axiosExtract(
								investmentReportApi.getTwoLevelsInvestmentExposure(
									investment.uuid,
									investmentSummary.primaryBenchmarkIdentifier!,
									"MACRO_ASSET_CLASS",
								),
							);
							const { removedTickers } = await axiosExtract(
								entityEditorApi.verifyEditorPortfolio(investment.uuid, investment.weight ?? 0, "INVESTMENT"),
							);

							verifiedInvestmentsMap = verifiedInvestmentsMap.set(investment.uuid, removedTickers ?? []);
							investmentExposureMap = investmentExposureMap.set(investment.uuid, exposure);
							investmentSummaryMap = investmentSummaryMap.set(investment.uuid, investmentSummary);
						}),
						{ concurrency: 3 },
					);

					const uniqueTags = Set(nonNullableComposition.flatMap((x) => (x.tagLabel ? [x.tagLabel] : [])));
					const tags = Map<string, Tag>(
						uniqueTags.map((tag): [string, Tag] => {
							const convertedTag = labelToTag({ label: tag }, Array.from(uniqueTags));
							return [
								convertedTag.name,
								{ label: convertedTag.name, color: convertedTag.color, value: convertedTag.name },
							];
						}),
					);

					if (investments.length === 0) {
						const compositionToMap = Map(
							nonNullableComposition.map((instrument): [string, InstrumentEditorEntry] => {
								const rowId = instrumentEntryKey(instrument);
								return [rowId, { ...instrument, rowId, stableWeight: instrument.weight }];
							}),
						);

						instrumentBuilder.reset({
							composition: compositionToMap,
							cash: cashTicker ? { ...cashTicker, rowId: instrumentEntryKey(cashTicker) } : undefined,
							tags,
						});
						replace("Portfolios/ManualEditPortfolio", { uuid });
						return;
					}

					const compositionToMap = Map(
						nonNullableComposition.map((instrument): [string, InstrumentEditorEntry] => {
							const rowId = instrumentEntryKey(instrument);
							if (instrument.proxyOverwriteType === "PORTFOLIO_MIXED" && instrument.ticker) {
								const exposure = investmentExposureMap.get(instrument.ticker);
								const investmentSummary = investmentSummaryMap.get(instrument.ticker);
								const someInstrumentAreRemoved = verifiedInvestmentsMap.get(instrument.ticker) ?? [];
								return [
									rowId,
									{
										...instrument,
										rowId,
										investment: {
											nofInstruments: investmentSummary?.nofInstruments,
											macroAssetClassExposure: exposure?.investmentComposition,
											macroAssetClassExposureEnhanced: exposure?.enhancementComposition,
											name: investmentSummary?.name,
											lastActionNote: investmentSummary?.lastActionNote,
											uuid: investmentSummary?.uuid,
											action: investmentSummary?.action,
										},
										identifier: "Portfolio",
										instrument: investmentSummary?.name,
										weight: instrument.weight ?? 10,
										stableWeight: instrument.weight,
										nOfInstrumentExcluded: someInstrumentAreRemoved.length,
										someInstrumentsAreExcluded: someInstrumentAreRemoved.length > 0,
									},
								];
							}
							return [rowId, { ...instrument, rowId }];
						}),
					);

					instrumentBuilder.reset({
						composition: compositionToMap,
						cash: cashTicker ? { ...cashTicker, rowId: instrumentEntryKey(cashTicker) } : undefined,
						tags,
					});
					setExpandExposure(true);
					replace("Portfolios/ManualEditPortfolio", { uuid });
				},
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [replace, copyComposition, uuid]);

	return (
		<ReactQueryWrapperBase query={queryInitManualEdit}>
			{() => (
				<>
					<div className="flex h-[calc(100dvh_-_186px)]">
						{summary.status === "PROPOSAL_READY" ? (
							<InstrumentCompostionEditor
								entity="INVESTMENT_ENHANCEMENT"
								mode="edit"
								uuid={uuid}
								instrumentBuilder={instrumentBuilder}
								minThreshold={minInstrumentsInComposition}
								limit={maxInstrumentsInComposition}
							/>
						) : (
							<InstrumentCompostionEditor
								entity="INVESTMENT"
								mode="edit"
								uuid={uuid}
								instrumentBuilder={instrumentBuilder}
								minThreshold={minInstrumentsInComposition}
								limit={maxInstrumentsInComposition}
							/>
						)}
						<ExposureSankeyBlock
							expand={expandExposure}
							onExpandChange={setExpandExposure}
							instrumentBuilder={instrumentBuilder}
						/>
					</div>

					<CompareOverlay
						show={comparedInvestments.length > 0}
						onClose={() => instrumentBuilder.clearComparedInstruments()}
						onRemove={(id) => instrumentBuilder.deleteComparedInstruments(id)}
						compareData={comparedInvestments}
					/>
				</>
			)}
		</ReactQueryWrapperBase>
	);
}

type ChooseEntitySavingModeDialogProps = {
	onSubmitAsync(payload: { portfolioSavingMode: EditorSaveEditOrReviewRequestPortfolioSavingModeEnum }): Promise<void>;
	portfolioSavingMode: EditorSaveEditOrReviewRequestPortfolioSavingModeEnum;
} & MinimunDialogProps;

function ChooseEntitySavingModeDialog(props: ChooseEntitySavingModeDialogProps) {
	const { t } = useTranslation();

	const { control, formState, handleSubmit } = useForm({
		defaultValues: {
			portfolioSavingMode: props.portfolioSavingMode,
		},
		resolver: zodResolver(
			z.object({
				portfolioSavingMode: z.nativeEnum(EditorSaveEditOrReviewRequestPortfolioSavingModeEnum),
			}),
		),
	});

	return (
		<Dialog
			show={props.show}
			onAnimationStateChange={props.onAnimationStateChange}
			size="large"
			onClose={props.onClose}
			onSubmitAsync={() => handleSubmit((x) => props.onSubmitAsync({ portfolioSavingMode: x.portfolioSavingMode }))()}
			header={<DialogHeader>Would you like to save this as a Nested Portfolio?</DialogHeader>}
			footer={({ loading }) => (
				<DialogFooter
					primaryAction={
						<SubmitButton
							palette="primary"
							disabled={loading}
							data-qualifier="EditPortfolioComposition/SaveDialog/Save"
						>
							{t("BUTTON.SAVE")}
						</SubmitButton>
					}
					neutralAction={
						<Button
							palette="tertiary"
							disabled={loading}
							onClick={props.onClose}
							data-qualifier="EditPortfolioComposition/SaveDialog/Cancel"
						>
							{t("BUTTON.CANCEL")}
						</Button>
					}
				/>
			)}
		>
			<p className="mb-4">By keeping it as a Nested Portfolio, internal portfolios will automatically update</p>
			<FormField
				label="Composition mode"
				error={(valueByPath(formState.errors, "portfolioSavingMode") as { message?: string })?.message}
			>
				{(fieldProps) => (
					<FormController
						control={control}
						name="portfolioSavingMode"
						defaultValue={EditorSaveEditOrReviewRequestPortfolioSavingModeEnum.NestPortfolios}
						render={({ field: { ref: _ref, ...controllerProps } }) => (
							<RadioGroup {...controllerProps} {...fieldProps} onChange={controllerProps.onChange}>
								<div className="flex flex-row flex-wrap gap-4">
									<Radio
										value={EditorSaveEditOrReviewRequestPortfolioSavingModeEnum.NestPortfolios}
										data-qualifier="EditPortfolioComposition/SaveDialog/CompositionMode(Nested)"
									>
										Keep as Nested
									</Radio>
									<Radio
										value={EditorSaveEditOrReviewRequestPortfolioSavingModeEnum.MixInstruments}
										data-qualifier="EditPortfolioComposition/SaveDialog/CompositionMode(Aggregate)"
									>
										Aggregate the instruments
									</Radio>
								</div>
							</RadioGroup>
						)}
					/>
				)}
			</FormField>
		</Dialog>
	);
}

type SpawnChooseEntitySavingModeDialogProps = Omit<ChooseEntitySavingModeDialogProps, "show" | "onClose">;
function spawnChooseEntitySavingModeDialog(params: SpawnChooseEntitySavingModeDialogProps): SpawnResult<void> {
	return spawn<void>(
		adaptAnimatedNodeProvider(({ resolve, show, onHidden }) => (
			<ChooseEntitySavingModeDialog
				{...params}
				show={show}
				onAnimationStateChange={(state) => state === "hidden" && onHidden()}
				onClose={() => resolve()}
				onSubmitAsync={async ({ portfolioSavingMode }) => {
					await params.onSubmitAsync({ portfolioSavingMode });
					resolve();
				}}
			/>
		)),
	);
}

export default ManualPortfolioEdit;
