import env from "$root/env";
import type { Updater } from "$root/utils/functions";
import type { StoreApi, UseBoundStore } from "zustand";
import { create } from "zustand";
import { createJSONStorage, devtools, persist, PersistOptions } from "zustand/middleware";

export type AtomContent<T> = { value: T; set: (newValue: T | Updater<T>) => void };

export function createAtom<T extends object | null>(
	defaultValue: T,
	opts?: {
		onSet?(newValue: T): void;
	},
): UseBoundStore<StoreApi<AtomContent<T>>> {
	return create(
		devtools<AtomContent<T>>(
			(set, get) => ({
				value: defaultValue,
				set: (newValueOrUpdater) => {
					const newValue = typeof newValueOrUpdater === "function" ? newValueOrUpdater(get().value) : newValueOrUpdater;
					set({ value: newValue });
					opts?.onSet?.(newValue);
				},
			}),
			{ enabled: env.appEnv !== "production" },
		),
	);
}

export function createPersistentAtom<T extends object | string | number | boolean | null>(
	defaultValue: T,
	localStorageKey: string,
	opts?: {
		onSet?(newValue: T): void;
		migrate?(persistedState: unknown, version: number): AtomContent<T>;
		version?: number;
	},
): UseBoundStore<StoreApi<AtomContent<T>>> {
	return create(
		devtools(
			persist<AtomContent<T>>(
				(set, get) => ({
					value: defaultValue,
					set: (newValueOrUpdater) => {
						const newValue =
							typeof newValueOrUpdater === "function" ? newValueOrUpdater(get().value) : newValueOrUpdater;
						set({ value: newValue });
						opts?.onSet?.(newValue);
					},
				}),
				{
					name: localStorageKey,
					storage: createJSONStorage(() => localStorage),
					migrate: opts?.migrate,
					version: opts?.version,
				},
			),
			{ enabled: env.appEnv !== "production" },
		),
	);
}
