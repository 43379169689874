import { CherryBankControllerV6ApiFactory } from "$root/api/api-gen";
import { getApiGen } from "$root/api/factory";
import AuthorizationGuard, { hasAccess } from "$root/components/AuthorizationGuard";
import useCrumbs from "$root/components/Crumbs/useCrumbs";
import { DefaultTemplateId } from "$root/components/EvolvedPrint/configuration/hooks/useExtractReports";
import { OutlookWidgetData } from "$root/components/OutlookWidgetData";
import { PageDownloadMenu } from "$root/components/PageDownloadMenu";
import { PageHeader } from "$root/components/PageHeader";
import { typedUrlForRoute } from "$root/components/PlatformRouter/RoutesDef";
import { downloadSinglePdf } from "$root/functional-areas/pdf";
import { useUserValue } from "$root/functional-areas/user";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { trackMixPanelEvent } from "$root/third-party-integrations/initMixPanel";
import { downloadFileResponse } from "$root/utils/files";
import WidgetsMapper from "$root/widgets-architecture/layout/WidgetsMapper";
import { useUnsafeUpdatedRef } from "@mdotm/mdotui/react-extensions";
import * as dateFn from "date-fns";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

async function downloadPositioningPdfReport() {
	const reportName = `Cherry_Bank_Report_${dateFn.format(new Date(), "MMddyyyy")}`;
	await downloadSinglePdf(reportName, { templateId: "custom-report" as DefaultTemplateId, name: reportName });
	trackMixPanelEvent("Custom-reports", {
		Type: "Export",
		Mode: "PDF",
	});
}

async function downloadPositioningXlsxReport() {
	const cherryBankControllerV6Api = getApiGen(CherryBankControllerV6ApiFactory);
	const template = await axiosExtract(cherryBankControllerV6Api.retrieveReport());
	trackMixPanelEvent("Custom-reports", {
		Type: "Export",
		Mode: "XLS",
	});
	downloadFileResponse(template, {
		fileName: `Cherry_Bank_Report_${dateFn.format(new Date(), "MMddyyyy")}.xlsx`,
	});
}

const CustomReports = (): JSX.Element => {
	const { t } = useTranslation();
	const user = useUserValue();
	const crumbs = useCrumbs();
	const history = useHistory();
	const downloadPfRef = useUnsafeUpdatedRef(downloadPositioningPdfReport);
	const downloadXlsRef = useUnsafeUpdatedRef(downloadPositioningXlsxReport);

	return (
		<AuthorizationGuard requiredService="CUSTOM_REPORT_CB1" placeholder={<>{t("NOT_AUTHORIZED")}</>}>
			{() => (
				<>
					<PageHeader
						title="Custom reports"
						crumbs={[
							crumbs.marketInsights,
							crumbs.selectMarketAreas({
								selectedArea: history.location.pathname,
								onChange(url) {
									history.push(url);
								},
							}),
						]}
						titleAction={
							<div className="flex items-center justify-between gap-2">
								<div>
									<OutlookWidgetData />
								</div>
								<div>
									<PageDownloadMenu
										area="custom-report"
										actions={[
											{
												icon: "Dowload",
												onClickAsync: () => downloadXlsRef.current(),
												label: "XLS report",
												"data-qualifier": "CustomReports/DropdownMenu/DropdownItem(XLSReport)",
											},
											{
												icon: "Dowload",
												onClickAsync: () => downloadPfRef.current(),
												label: "PDF report",
												"data-qualifier": "CustomReports/DropdownMenu/DropdownItem(PDFReport)",
											},
											!hasAccess(user, { requiredRoles: ["ROOT"] })
												? null
												: {
														group: "ROOT FEATURES",
														icon: "Expand",
														onClick: () => {
															window.open(typedUrlForRoute("Report/Demo", { templateId: "custom-report" }), "_blank");
														},
														label: "Vertical Report",
														"data-qualifier": "CustomReports/DropdownMenu/DropdownItem(Preview)",
												  },
										]}
									/>
								</div>
							</div>
						}
					/>
					<div className="relative">
						<WidgetsMapper
							widgetName="AssetClassOverviewBlock"
							style={{ paddingBottom: "1rem", marginBottom: "1rem" }}
						/>
						<WidgetsMapper widgetName="CustomOutlookBlock" style={{ paddingBottom: "1rem" }} />
					</div>
				</>
			)}
		</AuthorizationGuard>
	);
};

export default CustomReports;
