import type { InvestmentExposureEntry, InvestmentExposureResponseExposureTypeEnum } from "$root/api/api-gen";
import { InfoDelta } from "$root/components/InfoDelta";
import { useLocaleFormatters } from "$root/localization/hooks";
import { customObjectEntriesFn, getGraphMarkers2, roundCustomByStep } from "$root/utils/experimental";
import { exposureCategoryInfo } from "$root/widgets-architecture/widgets/ExposureEvolve";
import type { TableWithGroupsProps } from "@mdotm/mdotui/components";
import { BaseTableWithGroups, Text } from "@mdotm/mdotui/components";
import { groupBy } from "@mdotm/mdotui/utils";
import * as Immutable from "immutable";
import { useCallback, useMemo } from "react";
import { BarGraphPCSvg } from "$root/ui-lib/charts/BarGraphPCSvg";
import colorGenerator from "$root/utils/chart/colorGenerator";
import type { PrintableProps } from "$root/components/EvolvedPrint/configuration";
import { Card } from "$root/components/EvolvedPrint/components/Card";
import {
	TinyTableDataCell,
	TinyTableHeadCell,
	tinyTableHeadCellFontSize,
} from "$root/components/EvolvedPrint/components/table/tiny-table";
import { overrideClassName } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { useReportTranslation } from "../translation";

type ExposureCompareEntryProps = {
	enhancedSum: number;
	currentSum: number;
	groupIndex: number;
	subLabel: string;
	enhancedWeight: number;
	currentWeight: number;
	label: string;
	netLong: boolean;
	hideHeader: boolean;
};

export function exposureCompareEntriesToSplittableProps({
	currentComposition,
	enhancedComposition,
}: {
	enhancedComposition: InvestmentExposureEntry[];
	currentComposition: InvestmentExposureEntry[];
}): Array<ExposureCompareEntryProps> {
	const compostion = [
		...enhancedComposition.map((x) => ({ ...x, enhanced: true })),
		...currentComposition.map((x) => ({ ...x, enhanced: false })),
	];

	const mappedComposition = Immutable.Map(groupBy(compostion, (comp) => comp.firstQualityLevel!));

	return Array.from(mappedComposition.keys()).flatMap((firstQualityLevel, i) => {
		const compositionEntries = mappedComposition.get(firstQualityLevel);
		const sum = (compositionEntries ?? []).reduce(
			(acc, x) => {
				if (x.enhanced) {
					acc.enhancedSum += x.weight ?? 0;
					return acc;
				}

				acc.currentSum += x.weight ?? 0;
				return acc;
			},
			{ enhancedSum: 0, currentSum: 0 },
		);

		return Object.values(
			(compositionEntries ?? []).reduce<{
				[key: string]: {
					enhancedSum: number;
					currentSum: number;
					groupIndex: number;
					subLabel: string;
					enhancedWeight: number;
					currentWeight: number;
					label: string;
					netLong: boolean;
					hideHeader: boolean;
				};
			}>((acc, x, index) => {
				if (!acc[x.secondQualityLevel!]) {
					acc[x.secondQualityLevel!] = {
						label: firstQualityLevel,
						enhancedSum: sum.enhancedSum,
						currentSum: sum.currentSum,
						groupIndex: i,
						enhancedWeight: x.enhanced ? x.weight ?? 0 : 0,
						currentWeight: x.enhanced === false ? x.weight ?? 0 : 0,
						subLabel: x.secondQualityLevel ?? "-",
						netLong: x.netLong ?? false,
						hideHeader: index > 0,
					};
					return acc;
				}

				acc[x.secondQualityLevel!] = {
					...acc[x.secondQualityLevel!],
					enhancedWeight: x.enhanced ? x.weight ?? 0 : acc[x.secondQualityLevel!]!.enhancedWeight,
					currentWeight: x.enhanced === false ? x.weight ?? 0 : acc[x.secondQualityLevel!]!.currentWeight,
				};
				return acc;
			}, {}),
		);
	});
}

export function ExposureCompare({
	comparison,
	list,
	firstRender,
}: PrintableProps<
	{
		comparison: Extract<
			InvestmentExposureResponseExposureTypeEnum,
			| "MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS"
			| "MACRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY"
			| "MACRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY"
			| "MICRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY"
			| "MICRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY"
			| "MACRO_GEOGRAPHY_VS_MICRO_GEOGRAPHY"
			| "CURRENCY"
			| "TAG"
		>;
		composition: Array<InvestmentExposureEntry>;
	},
	ExposureCompareEntryProps
>): JSX.Element {
	const { t } = useReportTranslation();
	const { formatNumber } = useLocaleFormatters();

	const tableData = customObjectEntriesFn(groupBy(list, (x) => `index-${x.groupIndex}`)).map(([_k, compositions]) => ({
		...compositions![0],
		rows: compositions ?? [],
	}));

	const exposureGraphLimits = useMemo(() => {
		const value = tableData.reduce((acc, group) => {
			const rowValues = group.rows.reduce(
				(a, row) => {
					return { value: a.value + row.enhancedWeight, previousValue: a.previousValue + row.currentWeight };
				},
				{ value: 0, previousValue: 0 },
			);
			return Math.max(acc, rowValues.value, rowValues.previousValue);
		}, 0);
		return roundCustomByStep(value, 10);
	}, [tableData]);

	const graphColumnMarkers = useMemo(
		() =>
			getGraphMarkers2({
				min: 0,
				max: exposureGraphLimits,
				nOfMarkers: 8,
				suffix: "%",
			}),
		[exposureGraphLimits],
	);

	return (
		<Card
			title={
				firstRender
					? `${t(`REPORT_BUILDER.EXPOSURE.TITLE`)} - ${t(`REPORT_BUILDER.EXPOSURE.OPTIONS.${comparison}`)}`
					: undefined
			}
		>
			<BaseTableWithGroups
				groupRowKey={(group) => `index-${group.groupIndex}`}
				expandGroupByKey={Immutable.Set(tableData.map((group) => `index-${group.groupIndex}`))}
				groupedRows={tableData}
				groupRowClassList={(row) => (row.hideHeader ? "hidden" : "!transition-none")}
				classList="pointer-events-none [&_.transition-\[height\]]:!transition-none [&_.transition-\[transform\,opacity\]]:!transition-none"
				columns={useCallback<TableWithGroupsProps<(typeof tableData)[number]>["columns"]>(
					({ bandColumn, expandColumn }) => [
						bandColumn,
						expandColumn,
						{
							minWidth: 200,
							name: "label",
							header: (props) => (
								<TinyTableHeadCell {...props}>{exposureCategoryInfo[comparison].columnTitle}</TinyTableHeadCell>
							),
							groupContent: (groupedRow, props) => (
								<TinyTableDataCell {...props}>
									<strong>{groupedRow.label}</strong>
								</TinyTableDataCell>
							),
							content: (row, props) => (
								<TinyTableDataCell {...props}>
									<span title={row.subLabel}>{row.subLabel} </span>
									{!row.netLong && (
										<>
											-{" "}
											<span className="whitespace-nowrap">
												[{" "}
												<svg
													className="inline w-[8px] mb-1"
													width="8"
													height="8"
													viewBox="0 0 8 8"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<rect opacity="0.3" width="8" height="8" rx="4" fill={colorGenerator(1)} />
												</svg>{" "}
												<span className="truncate">Net Short</span> ]
											</span>
										</>
									)}
								</TinyTableDataCell>
							),
							orderable: false,
						},
						{
							name: "current",
							width: 60,
							header: (props) => (
								<TinyTableHeadCell {...props}>{t(`REPORT_BUILDER.EXPOSURE.TABLE.CURRENT_WEIGHT`)}</TinyTableHeadCell>
							),
							align: "end",
							groupContent: (groupedRow, props) => (
								<TinyTableDataCell {...props}>
									<strong className="tabular-nums">{`${formatNumber(groupedRow.currentSum, 2)}%`}</strong>
								</TinyTableDataCell>
							),
							cellClassList: "tabular-nums",
							content: (row, props) => (
								<TinyTableDataCell {...props}>{`${formatNumber(row.currentWeight, 2)}%`}</TinyTableDataCell>
							),
							orderable: false,
						},
						{
							name: "proposal",
							width: 60,
							align: "end",
							header: (props) => (
								<TinyTableHeadCell {...props}>{t(`REPORT_BUILDER.EXPOSURE.TABLE.ENHANCED_WEIGHT`)}</TinyTableHeadCell>
							),
							groupContent: (groupedRow, props) => (
								<TinyTableDataCell {...props}>
									<strong className="text-[#00AEEF] tabular-nums">{`${formatNumber(
										groupedRow.enhancedSum,
										2,
									)}%`}</strong>
								</TinyTableDataCell>
							),
							content: (row, props) => (
								<TinyTableDataCell {...props}>
									<span className="text-[#00AEEF] tabular-nums">{`${formatNumber(row.enhancedWeight, 2)}%`}</span>
								</TinyTableDataCell>
							),
							orderable: false,
						},
						{
							name: "graph",
							minWidth: 140,
							header: (props) => (
								<div
									style={{ ...props.style, minHeight: 31 }}
									className={overrideClassName(props.classList, "flex items-center justify-between grow")}
								>
									{graphColumnMarkers.map((m, idx) => (
										<Text
											as="div"
											type="Body/S/BOLD-UPPERCASE"
											style={{ fontSize: tinyTableHeadCellFontSize }}
											color={themeCSSVars.palette_N500}
											key={`marker-${idx}`}
										>
											{m.label}
										</Text>
									))}
								</div>
							),
							groupContent: ({ currentSum, enhancedSum }, props) => (
								<div
									style={{ ...props.style, minHeight: 31 }}
									className={overrideClassName(props.classList, "flex grow font-semibold")}
								>
									<BarGraphPCSvg
										classList="w-full"
										options={{
											animated: false,
											resize: true,
											marksLabels: false,
											scale: { max: exposureGraphLimits, min: 0 },
											bars: {
												height: (31 - 8 * 2) / 2,
												gap: 0,
											},
											vPadding: 8,
										}}
										data={[
											{ value: currentSum, color: "#BFC4CE" },
											{ value: enhancedSum, color: "#00AEEF" },
										]}
									/>
								</div>
							),
							content: ({ currentWeight, enhancedWeight }, props) => (
								<div
									style={{ ...props.style, minHeight: 31 }}
									className={overrideClassName(props.classList, "flex grow")}
								>
									<BarGraphPCSvg
										classList="w-full"
										options={{
											animated: false,
											resize: true,
											marksLabels: false,
											scale: { max: exposureGraphLimits, min: 0 },
											bars: {
												height: (31 - 8 * 2) / 2,
												gap: 0,
											},
											vPadding: 8,
										}}
										data={[
											{ value: currentWeight, color: "#BFC4CE" },
											{ value: enhancedWeight, color: "#00AEEF" },
										]}
									/>
								</div>
							),
							orderable: false,
						},
						{
							align: "end",
							name: "difference",
							width: 96,
							header: (props) => (
								<TinyTableHeadCell {...props}>{t(`REPORT_BUILDER.EXPOSURE.TABLE.DIFFERENCE`)}</TinyTableHeadCell>
							),
							groupContent: ({ currentSum, enhancedSum }, props) => (
								<TinyTableDataCell {...props}>
									<InfoDelta
										className="font-semibold"
										diff={(enhancedSum ?? 0) - (currentSum ?? 0)}
										enh={enhancedSum ?? 0}
									/>
								</TinyTableDataCell>
							),
							content: ({ currentWeight, enhancedWeight }, props) => (
								<TinyTableDataCell {...props}>
									<InfoDelta diff={(enhancedWeight ?? 0) - (currentWeight ?? 0)} enh={enhancedWeight ?? 0} />
								</TinyTableDataCell>
							),
							orderable: false,
						},
					],
					[comparison, exposureGraphLimits, formatNumber, graphColumnMarkers, t],
				)}
			/>
		</Card>
	);
}
