import type { ReferenceUniverseListEntry, UserUniverseColumnPreference } from "$root/api/api-gen";
import { typedUrlForRoute } from "$root/components/PlatformRouter/RoutesDef";
import { useLocaleFormatters } from "$root/localization/hooks";
import type { TableColumn } from "@mdotm/mdotui/components";
import { ActionText, Icon, TableDataCell } from "@mdotm/mdotui/components";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { builtInSort, builtInSortFnFor } from "@mdotm/mdotui/utils";
import { t } from "i18next";
import { useMemo } from "react";
import { match } from "ts-pattern";
import { statusIconMap } from "../statusIconMap";
import { LinkedPortfolioCounterWithTooltip } from "../shared";
import { useUserValue } from "$root/functional-areas/user";
import { aclToUsersListEntries } from "$root/functional-areas/acl/checkers/all";
import UserShareIcon from "$root/functional-areas/acl/UserShareIcon";
import { overrideClassName } from "@mdotm/mdotui/react-extensions";
import { sharingWithColumn } from "$root/functional-areas/acl/table-columns";

export function useUniverseColumn(
	columnsPreferences?: UserUniverseColumnPreference[],
): TableColumn<ReferenceUniverseListEntry>[] | null {
	const user = useUserValue();
	const { formatDate, formatNumber } = useLocaleFormatters();

	const columns = useMemo(
		() =>
			columnsPreferences
				? columnsPreferences.map(
						(columnMeta) =>
							({
								header: columnMeta.preferencesType ? t(`TABLE.HEADERS.${columnMeta.preferencesType}`) : "-",
								hidden: columnMeta.enabled === false,
								content: () => null,
								...match(columnMeta)
									.with(
										{ preferencesType: "NAME" },
										(): Omit<TableColumn<ReferenceUniverseListEntry>, "header"> => ({
											content: ({ uuid, name, richAcl }, props) => (
												<div style={props.style} className={overrideClassName(props.classList, "items-center flex")}>
													<ActionText
														classList="line-clamp-2 mr-1"
														color={themeCSSVars.palette_N900}
														href={typedUrlForRoute("UniverseDetails", { universeUuid: uuid ?? "" })}
														title={name}
														data-qualifier="PortfolioStudio/UniverseList/Table/Column(Name)"
													>
														{name}
													</ActionText>
													<UserShareIcon
														entity="UNIVERSE"
														userId={user.id}
														permission={richAcl?.currentUserPermissions}
														color={themeCSSVars.palette_N300}
														acl={richAcl?.acl}
													/>
												</div>
											),
											sortFn: builtInSortFnFor("name"),
											name: "name",
											width: 320,
										}),
									)
									.with(
										{ preferencesType: "SHARING_VIEWS" },
										(): Omit<TableColumn<ReferenceUniverseListEntry>, "header"> =>
											sharingWithColumn({
												users: ({ richAcl }) => aclToUsersListEntries("universe", richAcl?.acl ?? []),
											}),
									)
									.with(
										{ preferencesType: "STATUS" },
										(): Omit<TableColumn<ReferenceUniverseListEntry>, "header"> => ({
											content: ({ status }, props) => {
												const sanitizedStatus = status ? statusIconMap[status] : "-";
												if (sanitizedStatus === "-") {
													return (
														<TableDataCell
															{...props}
															data-qualifier="PortfolioStudio/UniverseList/Table/Column(Status)"
														>
															{sanitizedStatus}
														</TableDataCell>
													);
												}
												return (
													<div
														style={props.style}
														onClick={props.onClick}
														className={overrideClassName(props.classList, "flex items-center px-2")}
														title={sanitizedStatus.title}
														data-qualifier="PortfolioStudio/UniverseList/Table/Column(Status)"
													>
														<Icon
															classList="mr-1"
															icon={sanitizedStatus.icon}
															color={themeCSSVars.palette_N800}
															size={sanitizedStatus.size}
														/>
														<div style={{ fontSize: 12, fontFamily: "Gotham", fontWeight: "300" }}>
															{sanitizedStatus.title}
														</div>
													</div>
												);
											},
											sortFn: builtInSortFnFor("status"),
											name: "status",
											minWidth: 128,
										}),
									)
									.with(
										{ preferencesType: "INCEPTION_DATE" },
										(): Omit<TableColumn<ReferenceUniverseListEntry>, "header"> => ({
											content: ({ creationTime }) => {
												const sanitizedCreationTime = creationTime ? formatDate(new Date(creationTime)) : "-";
												return sanitizedCreationTime;
											},
											sortFn: ({ creationTime: a }, { creationTime: b }) => {
												const rowA = new Date(a ?? "").getTime();
												const rowB = new Date(b ?? "").getTime();
												return rowA > rowB ? 1 : rowA < rowB ? -1 : 0;
											},
											name: "creation",
											cellClassList: "tabular-nums",
											align: "end",
											minWidth: 128,
										}),
									)
									.with(
										{ preferencesType: "LAST_STATUS_UPDATE" },
										(): Omit<TableColumn<ReferenceUniverseListEntry>, "header"> => ({
											content: ({ creationTime }) => {
												const sanitizedModificationTime = creationTime ? formatDate(new Date(creationTime)) : "-";
												return sanitizedModificationTime;
											},
											sortFn: ({ creationTime: a }, { creationTime: b }) => {
												const rowA = new Date(a ?? "").getTime();
												const rowB = new Date(b ?? "").getTime();
												return rowA > rowB ? 1 : rowA < rowB ? -1 : 0;
											},
											name: "lastUpdate",
											cellClassList: "tabular-nums",
											align: "end",
											minWidth: 92,
										}),
									)
									.with(
										{ preferencesType: "NO_OF_INSTRUMENTS" },
										(): Omit<TableColumn<ReferenceUniverseListEntry>, "header"> => ({
											content: ({ nofInstruments }) => formatNumber(nofInstruments, 0),
											sortFn: builtInSortFnFor("nofInstruments"),
											name: "nofInstruments",
											minWidth: 92,
											cellClassList: "tabular-nums",
											align: "end",
										}),
									)
									.with(
										{ preferencesType: "LINKED_PORTFOLIOS" },
										(): Omit<TableColumn<ReferenceUniverseListEntry>, "header"> => ({
											name: "linkedPtfs",
											content: ({ referralInvestments, nofPortfolios }, props) => (
												<div
													style={props.style}
													className={overrideClassName("flex items-center justify-end", props.classList)}
												>
													<LinkedPortfolioCounterWithTooltip
														type="Universe"
														portfolios={referralInvestments}
														othersCount={(nofPortfolios ?? 0) - (referralInvestments?.length ?? 0)}
													/>
												</div>
											),
											width: 128,
											sortFn: (a, b) =>
												builtInSort(a.referralInvestments?.length ?? 0, b.referralInvestments?.length ?? 0),
										}),
									)
									.otherwise(() => ({})),
							}) as unknown as TableColumn<ReferenceUniverseListEntry>,
				  )
				: null,
		[columnsPreferences, formatDate, formatNumber, user.id],
	);

	return columns;
}
