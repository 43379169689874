import type { PortfolioMetric, PortfolioMetricPreference, PortfolioMetricTypeEnum } from "$root/api/api-gen";
import { PortfolioMetricPreferenceMetricTypeEnum } from "$root/api/api-gen";
import { InvestmentReportsControllerApiFactory, PortfolioStudioPreferencesApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { axiosExtract } from "$root/third-party-integrations/axios";
import type { ContextContent } from "$root/utils/react-extra";
import { withContext } from "$root/utils/react-extra";
import { PortfolioContext } from "$root/widgets-architecture/contexts/portfolio";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import type { PortfolioMetricsRow } from "../PortfolioMetrics/PortfolioMetricsBase";
import { PortfolioMetricsBase, ReapplySphereMetricsIconTooltip } from "../PortfolioMetrics/PortfolioMetricsBase";
import { WidgetStatus, portfolioWidgetMissingDataReason } from "$root/pages/PortfolioDetails/PortfolioWidgetStatus";
import { Row, Text } from "@mdotm/mdotui/components";
import { qualifier } from "$root/utils/qualifiers";
import { Map } from "immutable";
import { typedObjectValues } from "$root/utils/objects";

export const performanceMetricsBlockBaseQueryKey = "Performance H Metrics";
const PerformanceMetricsBlock = (props: ContextContent<typeof PortfolioContext>) => {
	const uuid = props.portfolio?.uuid;
	const benchmarkId = props.selectedBenchmark;
	const { t } = useTranslation();
	const reportInvestmentApi = useApiGen(InvestmentReportsControllerApiFactory);
	const portfolioStudioPreferences = useApiGen(PortfolioStudioPreferencesApiFactory);

	const isCalculating = useMemo(
		() =>
			(props.portfolio?.action === "UPLOAD" || props.portfolio?.action === "CREATION") &&
			props.portfolio?.status === "CALCULATING",
		[props.portfolio?.action, props.portfolio?.status],
	);

	return (
		<PortfolioMetricsBase
			hasBenchmark={Boolean(benchmarkId)}
			title={({ lastImportDate, hasCustomMetrics }) => (
				<Row alignItems="center" gap={8}>
					<Text
						type="Body/XL/Bold"
						title={t("PERFORMANCE_METRICS.TITLE")}
						classList="truncate"
						data-qualifier={qualifier.widgets.portfolioPerformanceMetrics.name}
					>
						{t("PERFORMANCE_METRICS.TITLE")}
					</Text>
					{lastImportDate && hasCustomMetrics && (
						<ReapplySphereMetricsIconTooltip lastUpdate={lastImportDate} uuid={uuid} />
					)}
				</Row>
			)}
			widgetTooltip={t("EXPOSURE.TOOLTIP")}
			noDataText={t("PERFORMANCE_METRICS.NO_DATA")}
			queryKey={[
				performanceMetricsBlockBaseQueryKey,
				props.portfolio?.uuid,
				benchmarkId,
				props.portfolio?.status,
				props.reportExcutionCounter,
			]}
			saveHandler={async (data) => {
				const portfolioMetricPreferences: Array<PortfolioMetricPreference> = data.map((x) => ({
					enabled: x.visible,
					metricType: x.type!,
				}));

				await axiosExtract(
					portfolioStudioPreferences.setPortfolioMetricsOrderingPreferences({ portfolioMetricPreferences }),
				);
			}}
			metricsProvider={async () => {
				const preferences = await axiosExtract(portfolioStudioPreferences.getPortfolioMetricsOrderingPreferences());
				const metrics = await axiosExtract(reportInvestmentApi.getPortfolioMetrics(uuid!, benchmarkId!, preferences));
				if (isCalculating || (!metrics.portfolioMetrics?.length && !metrics.customPortfolioMetrics?.length)) {
					return {
						data: undefined,
						widgetStatus: portfolioWidgetMissingDataReason(props.portfolio!, "PerformanceMetricsBlock"),
					};
				}

				const metricsMap = Map<PortfolioMetricTypeEnum, PortfolioMetric>(
					metrics.portfolioMetrics?.flatMap((metric) => (metric.type ? [[metric.type, metric]] : [])),
				);

				const customMetricsMap = Map<PortfolioMetricTypeEnum, PortfolioMetric>(
					metrics.customPortfolioMetrics?.flatMap((metric) => (metric.type ? [[metric.type, metric]] : [])),
				);

				const provider = (
					preferences.portfolioMetricPreferences ??
					typedObjectValues(PortfolioMetricPreferenceMetricTypeEnum).map((metricType) => ({
						enabled: true,
						metricType,
					}))
				).flatMap((preference): PortfolioMetricsRow[] => {
					if (!preference.metricType) {
						return [];
					}
					return [
						{
							label: t(`PERFORMANCE_METRICS.METRICS.FULL.${preference.metricType}`) ?? "-",
							name: t(`PERFORMANCE_METRICS.METRICS.FULL.${preference.metricType}`) ?? "-",
							type: preference.metricType,
							visible: preference.enabled ?? false,
							current:
								customMetricsMap.get(preference.metricType)?.value ?? metricsMap.get(preference.metricType)?.value,
							benchmark:
								customMetricsMap.get(preference.metricType)?.benchmarkValue ??
								metricsMap.get(preference.metricType)?.benchmarkValue,
							isCurrentCustom: customMetricsMap.get(preference.metricType)?.value != null,
							isBenchmarkCustom: customMetricsMap.get(preference.metricType)?.benchmarkValue != null,
						},
					];
				});

				return {
					data: {
						metrics: provider,
						lastImportDate: metrics.metadataUpdate,
						hasCustomMetrics: customMetricsMap.size > 0,
					},
					widgetStatus: WidgetStatus.READY as const,
				};
			}}
		/>
	);
};

export default withContext(PortfolioContext)(PerformanceMetricsBlock);
