import type { InvestmentExposureResponse, InvestmentExposureResponseExposureTypeEnum } from "$root/api/api-gen";
import {
	InvestmentEnhancementExportControllerApiFactory,
	InvestmentEnhancementReportsControllerApiFactory,
} from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import GraphLegend from "$root/components/GraphLegend";
import { InfoDelta } from "$root/components/InfoDelta";
import ColoredRectangle from "$root/components/icons/ColoredRectangle";
import { filterMapAlerts } from "$root/functional-areas/portfolio/alerts";
import { useLocaleFormatters } from "$root/localization/hooks";
import {
	PortfolioQueryWidgetBase,
	WidgetStatus,
	portfolioWidgetMissingDataReason,
} from "$root/pages/PortfolioDetails/PortfolioWidgetStatus";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { trackMixPanelEvent } from "$root/third-party-integrations/initMixPanel";
import { BarGraphPCSvg } from "$root/ui-lib/charts";
import colorGenerator from "$root/utils/chart/colorGenerator";
import { builtInSort, builtInSortFnFor } from "$root/utils/collections";
import { getGraphMarkers, roundCustomByStep } from "$root/utils/experimental";
import { downloadContentDisposition } from "$root/utils/files";
import { pxToRem } from "$root/utils/pxToRem";
import type { ContextContent } from "$root/utils/react-extra";
import { withContext } from "$root/utils/react-extra";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { PortfolioContext } from "$root/widgets-architecture/contexts/portfolio";
import { InfoTooltip } from "$root/widgets-architecture/layout/WidgetsMapper/InfoTooltip";
import { useWidgetOptions } from "$root/widgets-architecture/layout/WidgetsMapper/context";
import type { TableWithGroupsProps } from "@mdotm/mdotui/components";
import {
	AutoSortTableWithGroups,
	DropdownMenu,
	Icon,
	Row,
	Select,
	TableDataCell,
	Text,
} from "@mdotm/mdotui/components";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { groupBy } from "@mdotm/mdotui/utils";
import { Map } from "immutable";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { exposureCategoryInfo } from "../ExposureEvolve";
import { qualifier } from "$root/utils/qualifiers";

// type WidgetStatuses = Exclude<InvestmentStatuses, "RETRIEVING_DATA" | "REVIEW">;
// type WidgetActions = Exclude<InvestmentActions, "RETRIEVING_DATA_FOR_UPLOAD">;
// "MACRO_ASSET_CLASS" | "MICRO_ASSET_CLASS" | "MACRO_GEOGRAPHY" | "MICRO_GEOGRAPHY"

const exposureOptionsCategories = [
	"MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS",
	"MACRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY",
	"MACRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY",
	"MICRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY",
	"MICRO_ASSET_CLASS_VS_MICRO_GEOGRAPHY",
	"MACRO_GEOGRAPHY_VS_MICRO_GEOGRAPHY",
	"CURRENCY",
	"TAG",
] satisfies Array<InvestmentExposureResponseExposureTypeEnum>;

const exposureOptions = exposureOptionsCategories.map((category) => ({
	label: exposureCategoryInfo[category].label,
	value: category,
}));

const ExposureCompare = (props: ContextContent<typeof PortfolioContext>) => {
	const { portfolio, selectedBenchmark } = props;
	const uuid = portfolio?.uuid;
	const benchmarkId = selectedBenchmark ?? portfolio?.primaryBenchmarkIdentifier;

	const investmentEnhancementReportApi = useApiGen(InvestmentEnhancementReportsControllerApiFactory);

	const [category, setCategory] = useState<InvestmentExposureResponseExposureTypeEnum>(
		"MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS",
	);

	const query = useQueryNoRefetch(
		[
			"queryExposureLevelEvolve",
			"enhance",
			uuid,
			benchmarkId,
			props.portfolio?.status,
			props.reportExcutionCounter,
			category,
		],
		{
			queryFn: async () => {
				if (!uuid || !benchmarkId) {
					return {
						data: undefined,
						widgetStatus: portfolioWidgetMissingDataReason(props.portfolio!, "ExanteContributionVolatility"),
					};
				}

				const exposure = await axiosExtract(
					investmentEnhancementReportApi.getTwoLevelsInvestmentExposure1(uuid, benchmarkId, category),
				);

				if (!exposure.enhancementComposition || !exposure.investmentComposition) {
					return {
						data: undefined,
						widgetStatus: portfolioWidgetMissingDataReason(props.portfolio!, "ExanteContributionVolatility"),
					};
				}

				return {
					data: exposure,
					widgetStatus: WidgetStatus.READY,
				};
			},
		},
	);

	const { t } = useTranslation();
	const formatters = useLocaleFormatters();
	const exportApi = useApiGen(InvestmentEnhancementExportControllerApiFactory);

	const exportPortfolioExposure = useCallback(
		async (cat: typeof category) => {
			const response = await exportApi.exportExposure1(portfolio!.uuid!, cat, { responseType: "blob" });
			downloadContentDisposition(response);
			trackMixPanelEvent("Portfolio", {
				Type: "Export",
				Area: "Exposure Compare",
				Mode: "XLS",
				ID: portfolio!.uuid!,
			});
		},
		[exportApi, portfolio],
	);

	useWidgetOptions(
		() => ({
			title: (
				<Text
					type="Body/XL/Bold"
					title={t("EXPOSURE.TITLE")}
					classList="truncate"
					data-qualifier={qualifier.widgets.portfolioExposureCompare.name}
				>
					{t("EXPOSURE.TITLE")}
				</Text>
			),
			// alertsActive: currentTab !== 1,
			alerts: filterMapAlerts(
				{ t, ...formatters, requestOptimize: props.requestOptimize, requestEnhance: props.requestEnhance },
				"ExposureEvolve",
				props.alerts,
			),
			actionHeader: function Download() {
				return (
					<div style={{ display: "flex", flexDirection: "row" }} className="space-x-2">
						{/* FIXME add noData Option*/}
						{!(query.isError || query.isLoading) && (
							<DropdownMenu
								trigger={({ innerRef, open, ...forward }) => (
									<button ref={innerRef} aria-expanded={open} type="button" {...forward}>
										<Icon icon="Dowload" color={themeCSSVars.MessageSeverity_success} size={20} />
									</button>
								)}
								actions={[
									{
										icon: "xls",
										onClickAsync: async () => {
											await exportPortfolioExposure(category);
										},
										label: t("EXPOSURE.DOWNLOAD_TITLE"),
									},
								]}
							/>
						)}
						<InfoTooltip>{t("EXPOSURE.TOOLTIP")}</InfoTooltip>
					</div>
				);
			},
		}),
		[
			category,
			exportPortfolioExposure,
			formatters,
			props.alerts,
			props.requestEnhance,
			props.requestOptimize,
			query.isError,
			query.isLoading,
			t,
		],
	);

	return (
		<PortfolioQueryWidgetBase
			query={query}
			errorFallback={
				<div className="flex flex-col gap-4">
					<div>
						<Select value={category} onChange={setCategory} options={exposureOptions} />
					</div>
					<Text as="div" classList="px-1" type="Body/M/Book">
						Data not available for the selected comparison, please pick another option.
					</Text>
				</div>
			}
		>
			{(exposure) => <ExposureCompareInner exposure={exposure} category={category} onChangeCategory={setCategory} />}
		</PortfolioQueryWidgetBase>
	);
};

const ExposureCompareInner = ({
	category,
	exposure,
	onChangeCategory,
}: {
	exposure: InvestmentExposureResponse;
	onChangeCategory(opt: InvestmentExposureResponseExposureTypeEnum): void;
	category: InvestmentExposureResponseExposureTypeEnum;
}) => {
	const formatters = useLocaleFormatters();

	const tableData = useMemo(() => {
		const { enhancementComposition, investmentComposition } = exposure ?? {};

		if (!enhancementComposition || !investmentComposition) {
			return [];
		}

		const compostion = [
			...enhancementComposition.map((x) => ({ ...x, enhanced: true })),
			...investmentComposition.map((x) => ({ ...x, enhanced: false })),
		];

		const mappedComposition = Map(groupBy(compostion, (comp) => comp.firstQualityLevel!));

		return Array.from(mappedComposition.keys())
			.map((firstQualityLevel, i) => {
				const compositionEntries = mappedComposition.get(firstQualityLevel);
				const sum = (compositionEntries ?? []).reduce(
					(acc, x) => {
						if (x.enhanced) {
							acc.enhancedSum += x.weight ?? 0;
							return acc;
						}

						acc.currentSum += x.weight ?? 0;
						return acc;
					},
					{ enhancedSum: 0, currentSum: 0 },
				);

				return {
					label: firstQualityLevel,
					enhancedSum: sum.enhancedSum,
					currentSum: sum.currentSum,
					groupIndex: i,
					rows: Object.values(
						(compositionEntries ?? []).reduce<{
							[key: string]: { enhancedWeight: number; currentWeight: number; label: string; netLong: boolean };
						}>((acc, x) => {
							if (!acc[x.secondQualityLevel!]) {
								acc[x.secondQualityLevel!] = {
									enhancedWeight: x.enhanced ? x.weight ?? 0 : 0,
									currentWeight: x.enhanced === false ? x.weight ?? 0 : 0,
									label: x.secondQualityLevel ?? "-",
									netLong: x.netLong ?? false,
								};
								return acc;
							}

							acc[x.secondQualityLevel!] = {
								...acc[x.secondQualityLevel!],
								enhancedWeight: x.enhanced ? x.weight ?? 0 : acc[x.secondQualityLevel!]!.enhancedWeight,
								currentWeight: x.enhanced === false ? x.weight ?? 0 : acc[x.secondQualityLevel!]!.currentWeight,
							};
							return acc;
						}, {}),
					),
				};
			})
			.sort((a, b) => (a.enhancedSum > b.enhancedSum ? -1 : 1));
	}, [exposure]);

	// Correction Of Graph Scala
	const exposureGraphLimits = useMemo(() => {
		const value = tableData.reduce((acc, group) => {
			const rowValues = group.rows.reduce(
				(a, row) => {
					return { value: a.value + row.enhancedWeight, previousValue: a.previousValue + row.currentWeight };
				},
				{ value: 0, previousValue: 0 },
			);
			return Math.max(acc, rowValues.value, rowValues.previousValue);
		}, 0);
		return roundCustomByStep(value, 10);
	}, [tableData]);

	const graphColumnMarkers = useMemo(() => getGraphMarkers(exposureGraphLimits, 0, 10, "%", 6), [exposureGraphLimits]);

	return (
		<div className="h-full">
			<div>
				<Select value={category} onChange={onChangeCategory} options={exposureOptions} />
			</div>
			<div className="h-[85%] w-full pb-3 mt-3 mb-2 flex flex-col items-stretch">
				<AutoSortTableWithGroups
					groupedRows={tableData}
					groupRowKey={(group) => group.label}
					columns={useCallback<TableWithGroupsProps<(typeof tableData)[number]>["columns"]>(
						({ expandColumn }) => [
							expandColumn,
							{
								header: exposureCategoryInfo[category].columnTitle,
								groupContentTextType: "Body/M/Bold",
								groupContent: (groupedRow) => groupedRow.label,
								content: (row, cellProps) => (
									<TableDataCell {...cellProps} title={row.label}>
										{row.label}{" "}
										{!row.netLong && (
											<>
												-{" "}
												<span className="whitespace-nowrap">
													[{" "}
													<svg
														className="inline w-[8px] mb-1"
														width="8"
														height="8"
														viewBox="0 0 8 8"
														fill="none"
														xmlns="http://www.w3.org/2000/svg"
													>
														<rect opacity="0.3" width="8" height="8" rx="4" fill={colorGenerator(1)} />
													</svg>{" "}
													<span className="truncate">Net Short</span> ]
												</span>
											</>
										)}
									</TableDataCell>
								),
								sortFn: builtInSortFnFor("label"),
								name: "name",
								//relativeWidth: 2,
								minWidth: 240,
								maxWidth: 240,
							},
							{
								name: "currentWeight",
								header: "Current Weight",
								groupContentTextType: "Body/M/Bold",
								groupCellClassList: "tabular-nums",
								groupContent: (groupedRow) => `${formatters.formatNumber(groupedRow.currentSum, 2)}%`,
								cellClassList: "tabular-nums",
								content: (row) => `${formatters.formatNumber(row.currentWeight, 2)}%`,
								sortFn: builtInSortFnFor("currentSum"),
								//relativeWidth: 1,
								align: "end",
								minWidth: 140,
								maxWidth: 140,
							},
							{
								name: "enhancedWeight",
								header: "Enhanced Weight",
								groupCellClassList: "tabular-nums",
								groupContent: (groupedRow, cellProps) => (
									<TableDataCell {...cellProps}>
										<Text type="Body/M/Bold" color="#00AEEF">{`${formatters.formatNumber(
											groupedRow.enhancedSum,
											2,
										)}%`}</Text>
									</TableDataCell>
								),
								cellClassList: "tabular-nums",
								content: (row, cellProps) => (
									<TableDataCell {...cellProps}>
										<span className="text-[#00AEEF]">{`${formatters.formatNumber(row.enhancedWeight, 2)}%`}</span>
									</TableDataCell>
								),
								sortFn: builtInSortFnFor("enhancedSum"),
								//relativeWidth: 1,
								align: "end",
								minWidth: 140,
								maxWidth: 140,
							},
							{
								name: "graph",
								orderable: false,
								header: (headerProps) => (
									<Row {...headerProps} justifyContent="space-between" alignItems="center">
										{graphColumnMarkers.map((m, i) => (
											<Text as="div" key={`marker-${i}`} type="Body/S/BOLD-UPPERCASE" color={themeCSSVars.palette_N500}>
												{m.label}
											</Text>
										))}
									</Row>
								),
								groupContent: ({ currentSum, enhancedSum }, cellProps) => {
									return (
										<Row justifyContent="center" {...cellProps} childrenGrow={1} alignItems="center">
											<BarGraphPCSvg
												classList="w-full"
												options={{ resize: true, marksLabels: false, scale: { max: exposureGraphLimits, min: 0 } }}
												data={[
													{ value: currentSum, color: "#BFC4CE" },
													{ value: enhancedSum, color: "#00AEEF" },
												]}
											/>
										</Row>
									);
								},
								content: ({ currentWeight, enhancedWeight }, cellProps) => {
									return (
										<Row justifyContent="center" {...cellProps} childrenGrow={1} alignItems="center">
											<BarGraphPCSvg
												classList="w-full"
												options={{ resize: true, marksLabels: false, scale: { max: exposureGraphLimits, min: 0 } }}
												data={[
													{ value: currentWeight, color: "#BFC4CE" },
													{ value: enhancedWeight, color: "#00AEEF" },
												]}
											/>
										</Row>
									);
								},
								//relativeWidth: 4,
								minWidth: 180,
							},
							{
								header: "DIFFERENCE",
								align: "end",
								groupContent: ({ currentSum, enhancedSum }, cellProps) => (
									<Row {...cellProps} classList="font-bold" justifyContent="stretch" alignItems="center">
										<InfoDelta diff={(enhancedSum ?? 0) - (currentSum ?? 0)} enh={enhancedSum ?? 0} />
									</Row>
								),
								content: ({ currentWeight, enhancedWeight }, cellProps) => (
									<Row {...cellProps} classList="font-bold" justifyContent="stretch" alignItems="center">
										<InfoDelta diff={(enhancedWeight ?? 0) - (currentWeight ?? 0)} enh={enhancedWeight ?? 0} />
									</Row>
								),
								minWidth: 125,
								maxWidth: 125,
								//relativeWidth: 1,
								sortFn: (a, b) => {
									const deltaA = a.enhancedSum - a.currentSum;
									const deltaB = b.enhancedSum - b.currentSum;

									return builtInSort(deltaA, deltaB);
								},
								name: "difference",
							},
						],
						[category, exposureGraphLimits, formatters, graphColumnMarkers],
					)}
				/>
			</div>
			<GraphLegend>
				<div className="legend-container light book" style={{ marginRight: pxToRem(16) }}>
					<ColoredRectangle color="#BFC4CE" variant="vertical" /> Current weight
				</div>
				<div className="legend-container light book">
					<ColoredRectangle variant="vertical" /> Ehnanced weight
				</div>
			</GraphLegend>
		</div>
	);
};

export default withContext(PortfolioContext)(ExposureCompare);
