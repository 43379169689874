import { useUserValue } from "$root/functional-areas/user";
import type { IconName } from "@mdotm/mdotui/components";
import { typedUrlForRoute } from "./RoutesDef";
import { hasAccess } from "../AuthorizationGuard";
import { unpromisify } from "@mdotm/mdotui/utils";
import { HmmRegion } from "$root/api/api-gen";
import type { ReactNode } from "react";
import { PortfolioStudioTab } from "$root/pages/PortfoliosStudio";
import { PortfolioStudioSettingTabEnum } from "$root/functional-areas/portfolio-studio-settings";
import { spawnChangePasswordDialog } from "../ChangePassword";

export type SidebarSubmenuEntry = {
	label: string;
	matchingUrls?: Array<string>;
	activeOverride?: boolean;
} & (
	| {
			url: string;
			onClick?: undefined;
	  }
	| {
			url?: undefined;
			onClick(): void;
	  }
);

export type SidebarEntry = {
	icon: IconName | ((props: { size: number; color: string }) => ReactNode);
	title: string;
	/** unique name identifying the entry */
	name: string;
	matchingUrls?: Array<string>;
	activeOverride?: boolean;
} & (
	| {
			url: string;
			submenuEntries?: undefined;
			onClick?: undefined;
	  }
	| {
			url?: undefined;
			submenuEntries: Array<SidebarSubmenuEntry>;
			onClick?: undefined;
	  }
	| {
			url?: undefined;
			submenuEntries?: undefined;
			onClick(): void;
	  }
);

export type SidebarSubmenuEntryBuilderParam = {
	enabled?: boolean;
	label: string;
	/**
	 * For pages which are not listed in the provided URLs
	 * but that should also highlight the sidebar item.
	 */
	extraMatchingUrls?: Array<string>;
	activeOverride?: boolean;
} & (
	| {
			url: string;
			onClick?: undefined;
	  }
	| {
			url?: undefined;
			onClick(): void;
	  }
);

export type SidebarEntryBuilderParam = {
	enabled?: boolean;
	icon: IconName | ((props: { size: number; color: string }) => ReactNode);
	title: string;
	/** unique name identifying the entry */
	name: string;
	/**
	 * For pages which are not listed in the provided URLs
	 * but that should also highlight the sidebar item.
	 */
	extraMatchingUrls?: Array<string>;
	activeOverride?: boolean;
} & (
	| {
			url: string;
			submenuEntries?: undefined;
			onClick?: undefined;
	  }
	| {
			url?: undefined;
			submenuEntries: Array<SidebarSubmenuEntryBuilderParam>;
			onClick?: undefined;
	  }
	| {
			url?: undefined;
			submenuEntries?: undefined;
			onClick(): void;
	  }
);

/**
 * Helper function that builds sidebar entries based on the passed
 * configuration, handling edge cases such as empty submenus.
 */
export function sidebarEntry(x: SidebarEntryBuilderParam): SidebarEntry | null {
	if (!(x.enabled ?? true)) {
		return null;
	}
	const baseMatchingUrls = x.url ? [x.url] : [];
	if (!x.submenuEntries) {
		return {
			...x,
			matchingUrls: baseMatchingUrls.concat(x.extraMatchingUrls ?? []),
		};
	}

	// const { enabledSubentries = [], hiddenSubEntries = [] } = groupBy(x.submenuEntries, ({ enabled }) =>
	// 	enabled ?? true ? "enabledSubentries" : "hiddenSubEntries",
	// );

	// if (!enabledSubentries.length) {
	// 	return null;
	// }

	const subentriesWithMatchingUrls = x.submenuEntries.map((entry) => ({
		...entry,
		matchingUrls: (entry.url ? [entry.url] : []).concat(entry.extraMatchingUrls ?? []),
	}));
	const enabledSubentries = subentriesWithMatchingUrls.filter((entry) => entry.enabled ?? true);

	if (!enabledSubentries.length) {
		return null;
	}

	const subUrls = subentriesWithMatchingUrls.flatMap((entry) => entry.matchingUrls ?? []);
	const mainEntry = {
		...x,
		submenuEntries: enabledSubentries.map((entry): SidebarSubmenuEntry => {
			const clone = { ...entry };

			delete clone.enabled;
			delete clone.extraMatchingUrls;

			return clone;
		}),
		matchingUrls: baseMatchingUrls.concat(x.extraMatchingUrls ?? []).concat(subUrls),
	};

	delete mainEntry.enabled;
	delete mainEntry.extraMatchingUrls;

	return mainEntry;
}

export function useSidebarEntries(): Array<SidebarEntry> {
	const user = useUserValue();
	return [
		sidebarEntry({
			enabled: hasAccess(user, { requiredService: "INVESTMENTS" }),
			icon: "portfolio-studio",
			title: "Portfolio studio",
			name: "PortfolioStudio",
			extraMatchingUrls: ["/portfolios/"],
			submenuEntries: [
				{
					label: "Portfolios",
					url: typedUrlForRoute("PortfoliosStudio", { tab: PortfolioStudioTab.Portfolios }),
					extraMatchingUrls: ["/portfolio_details"],
				},
				{
					label: "References",
					url: typedUrlForRoute("PortfoliosStudio", { tab: PortfolioStudioTab.References }),
					extraMatchingUrls: ["/portfolio_reference_details", "/custom_benchmarks"],
				},
				{
					label: "Universes",
					url: typedUrlForRoute("PortfoliosStudio", { tab: PortfolioStudioTab.Universes }),
					extraMatchingUrls: ["/universe_details"],
				},
				{
					label: "Market views",
					url: typedUrlForRoute("PortfoliosStudio", { tab: PortfolioStudioTab.MarketViews }),
					extraMatchingUrls: ["/workspace_market"],
				},
			],
		}),
		sidebarEntry({
			icon: "market-insight",
			title: "Market insights",
			name: "MarketInsight",
			submenuEntries: [
				{
					enabled: hasAccess(user, { requiredService: "REPORTS" }),
					label: "Market dashboard",
					url: typedUrlForRoute("MarketDashboard", {}),
				},
				{
					enabled: hasAccess(user, { requiredService: "REPORTS" }),
					label: "Outlook",
					url: typedUrlForRoute("Outlook", {}),
				},
				{
					enabled: hasAccess(user, { requiredService: "REPORTS" }),
					label: "Outlook focus",
					url: typedUrlForRoute("AssetClass", {}),
				},
				{
					enabled: hasAccess(user, { requiredService: "SIGNALS" }),
					label: "Regime analysis tool",
					url: typedUrlForRoute("RegimeAnalysisTool", {
						region: hasAccess(user, { requiredService: "GLOBAL_RISK_MAP" }) ? HmmRegion.Global : HmmRegion.Eu,
					}),
					extraMatchingUrls: ["/regime_analysis_tool"],
				},
				{
					enabled: hasAccess(user, { requiredService: "CUSTOM_REPORT_CB1" }),
					label: "Custom reports",
					url: typedUrlForRoute("CustomReports", {}),
				},
			],
		}),
		sidebarEntry({
			icon: "Storyfolio",
			title: "Storyfolio",
			name: "Storyfolio",
			submenuEntries: [
				{
					enabled: hasAccess(user, { requiredService: "INVESTMENTS_REPORT_TEMPLATE_EDITOR" }),
					label: "Report builder",
					url: typedUrlForRoute("PortfolioStudioSettings", { tab: PortfolioStudioSettingTabEnum.ReportCustomisation }),
					extraMatchingUrls: ["/report_editor", "/portfolio_studio_settings/report-editor"],
				},
				{
					enabled: hasAccess(user, { requiredService: "COMMENTARY_BUILDER" }),
					label: "Commentary builder",
					url: typedUrlForRoute("Storyfolio/Studio", {}),
				},
			],
		}),
		sidebarEntry({
			icon: "Settings",
			title: "My settings",
			name: "My Settings",
			submenuEntries: [
				{
					label: "Instr. customization",
					url: typedUrlForRoute("PortfolioStudioSettings", {
						tab: PortfolioStudioSettingTabEnum.InstrumentsCustomisation,
					}),
				},
				{
					label: "Market view settings",
					url: typedUrlForRoute("PortfolioStudioSettings", { tab: PortfolioStudioSettingTabEnum.MarketViewSetting }),
				},
				{
					label: "Explainability options",
					url: typedUrlForRoute("PortfolioStudioSettings", {
						tab: PortfolioStudioSettingTabEnum.ExplainabilitySettings,
					}),
				},
				{
					label: "Notification settings",
					url: typedUrlForRoute("Notification", {}),
					enabled: hasAccess(user, { requiredServices: ["INVESTMENTS", "REPORTS"] }),
				},
				{
					label: "Change password",
					onClick: unpromisify(() => spawnChangePasswordDialog({}).promise),
				},
				{
					label: "Cookie Policy",
					onClick: () => (window as any)._iub.cs.api.openPreferences(),
				},
				{
					label: "Privacy",
					onClick: () =>
						window.open("https://www.iubenda.com/privacy-policy/48016693", "_blank", "noopener noreferrer"),
				},
			],
		}),
		sidebarEntry({
			enabled: hasAccess(user, { requiredRoles: ["ROOT"] }),
			icon: "Optimize",
			title: "Admin panel",
			name: "Admin panel",
			submenuEntries: [
				{
					label: "Global settings panel",
					url: typedUrlForRoute("AdvanceSettings/UserSettingsPanel", {}),
				},
				{
					label: "ChatGPT Prompt",
					url: typedUrlForRoute("AdvanceSettings/CommentaryPrompt", {}),
				},
				{
					label: "Info",
					url: typedUrlForRoute("AdvanceSettings/PlatformInfo", {}),
				},
				{
					label: "Advanced editor",
					url: typedUrlForRoute("AdvanceSettings/AdvancedEditor", {}),
				},
			],
		}),
	].filter(Boolean) as Array<SidebarEntry>;
}
