import {
	BenchmarksControllerApiFactory,
	CustomerControllerV3ApiFactory,
	IntegrationReferenceControllerApiFactory,
	IntegrationsControllerApiFactory,
	IntegrationUniverseControllerApiFactory,
	InvestmentControllerV4ApiFactory,
	MarketViewControllerApiFactory,
	PdfControllerApiFactory,
	PortfolioStudioPreferencesApiFactory,
	ReferenceUniversesControllerApiFactory,
} from "$root/api/api-gen";
import { reportPlatformError } from "$root/api/error-reporting";
import { useApiGen } from "$root/api/hooks";
import { IconWalls } from "$root/components/IconWall";
import { PageHeader } from "$root/components/PageHeader";
import { useTypedNavigation } from "$root/components/PlatformRouter/RoutesDef";
import { useEventBus, useGroupEventBus } from "$root/event-bus";
import { useUserValue } from "$root/functional-areas/user";
import { platformToast } from "$root/notification-system/toast";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { downloadFileResponse } from "$root/utils/files";
import { noRefetchDefaults, useQueryNoRefetch } from "$root/utils/react-query";
import { Button, DropdownMenu, Icon } from "@mdotm/mdotui/components";
import { noop } from "@mdotm/mdotui/utils";
import { useQueries } from "@tanstack/react-query";
import { format } from "date-fns";
import { Set } from "immutable";
import type { ReactNode } from "react";
import { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router";
import MarketViewsList from "./MarketViewList";
import PortfolioList from "./PortfolioList";
import ReferenceList from "./ReferenceList";
import UniverseList from "./UniverseList";
import { useDebounced } from "@mdotm/mdotui/react-extensions";
import { hasAccess } from "$root/components/AuthorizationGuard";
import { spawnInvestmentImportDialog } from "$root/functional-areas/portfolio/Import";
import { spawnInvestmentReferenceImportDialog } from "$root/functional-areas/portfolio/ImportReference";
import { spawnUniverseImportDialog } from "$root/functional-areas/universe/Import";
import { spawnMarketViewImportDialog } from "$root/functional-areas/market-view/Import";
import { spawnPortfolioCreationDialog } from "$root/functional-areas/portfolio-studio/dialogs/PortfolioCreationDialog";
import { spawnMarketViewCreationDialog } from "$root/functional-areas/portfolio-studio/dialogs/MarketViewCreationDialog";
import { spawnBenchmarkCreationDialog } from "$root/functional-areas/portfolio-studio/dialogs/BenchmarkCreationDialog";
import { InstrumentEditorEntity } from "$root/functional-areas/instruments-editor/const";
import useCrumbs from "$root/components/Crumbs/useCrumbs";
import { useSearchParams } from "$root/utils/react-router-extra";

export const PortfolioStudioTab = {
	Portfolios: "Portfolios",
	References: "References",
	Universes: "Universes",
	MarketViews: "MarketViews",
} as const;

export type PortfolioStudioTab = (typeof PortfolioStudioTab)[keyof typeof PortfolioStudioTab];

const tabTitle: Record<PortfolioStudioTab, string> = {
	MarketViews: "Market Views",
	Portfolios: "Portfolios",
	References: "References",
	Universes: "Universes",
};

const portfolioStudioDefaultTabOrdering = [
	PortfolioStudioTab.Portfolios,
	PortfolioStudioTab.References,
	PortfolioStudioTab.Universes,
	PortfolioStudioTab.MarketViews,
];

type PortfoliosStudioProps = {
	tab?: string;
	zipId?: string;
};

const PortfoliosStudio = ({ zipId }: PortfoliosStudioProps): JSX.Element => {
	const history = useHistory();
	const location = useLocation();
	const user = useUserValue();
	const { push, replace } = useTypedNavigation();
	const crumbs = useCrumbs();
	const [portfolioStudioOuterDiv, setPortfolioStudioOuterDiv] = useState<HTMLDivElement | null>(null);
	const { tab } = useSearchParams<"tab">();
	const currentTabName = useMemo(() => {
		const queryParamTab = new URLSearchParams(location.search).get("tab");
		const candidate = queryParamTab && portfolioStudioDefaultTabOrdering.find((v) => v === queryParamTab);
		if (!queryParamTab || !candidate) {
			replace("PortfoliosStudio", { tab: PortfolioStudioTab.Portfolios, zipId });
			return PortfolioStudioTab.Portfolios;
		}
		return candidate;
	}, [location.search, replace, zipId]);

	const investmentApi = useApiGen(InvestmentControllerV4ApiFactory);
	const referenceUniverseApi = useApiGen(ReferenceUniversesControllerApiFactory);
	const marketViewApi = useApiGen(MarketViewControllerApiFactory);
	const benchmarkApi = useApiGen(BenchmarksControllerApiFactory);
	const customerV3Api = useApiGen(CustomerControllerV3ApiFactory);
	const portfolioStudioPreferencesApi = useApiGen(PortfolioStudioPreferencesApiFactory);
	const pdfControllerV3Api = useApiGen(PdfControllerApiFactory);
	const integrationsApi = useApiGen(IntegrationsControllerApiFactory);
	const integrationReferenceApi = useApiGen(IntegrationReferenceControllerApiFactory);
	const integrationUniverseApi = useApiGen(IntegrationUniverseControllerApiFactory);

	useQueryNoRefetch(["queryGeneratedReport", zipId, user], {
		enabled: zipId !== undefined,
		queryFn: async () => {
			const zip = await axiosExtract(pdfControllerV3Api.downloadReportsById(zipId!));
			return zip;
		},
		onError: (error: { response: { status: number }; message: string }) => {
			if (error.response.status === 400) {
				platformToast({
					children: "Unable to find an existing report in your account",
					icon: "Portfolio",
					severity: "error",
				});
			} else {
				platformToast({
					children: "Something went wrong, please try later",
					icon: "Portfolio",
					severity: "error",
				});
			}

			const queryParams = new URLSearchParams(location.search);
			queryParams.delete("zipId");
			history.replace({ search: queryParams.toString() });
			reportPlatformError(error, "ERROR", "portfolio", { message: "unable to download the generated zip" });
			throw new Error(String(error));
		},
		onSuccess: (zip) => {
			downloadFileResponse(zip, {
				fileName: `${user.name ? `${user.name}_` : ""}Reports_${format(new Date(), "MMddyyyy")}.zip`,
			});
			//TODO i need to check the expiration date
			// if expiration date is exceeded have not notify user with platformToast
			const queryParams = new URLSearchParams(location.search);
			queryParams.delete("zipId");
			history.replace({ search: queryParams.toString() });
		},
	});

	const [visitedTabs, setVisitedTabs] = useState<Set<typeof currentTabName>>(() => Set());

	useEffect(() => {
		setVisitedTabs((v) => v.add(currentTabName));
	}, [currentTabName]);

	//splitted platform requests
	const portfoliosStudio = useQueries({
		queries: [
			{
				queryKey: ["investmentQueryStudio"],
				queryFn: () => axiosExtract(investmentApi.getInvestmentList()),
				enabled: visitedTabs.includes("Portfolios"),
				...noRefetchDefaults,
			},
			{
				queryKey: ["referenceQueryStudio"],
				queryFn: () => axiosExtract(investmentApi.getInvestmentReferenceList()),
				enabled: visitedTabs.includes("References"),
				...noRefetchDefaults,
			},
			{
				queryKey: ["universeQueryStudio"],
				queryFn: () => axiosExtract(referenceUniverseApi.getUserUniverses()),
				enabled: visitedTabs.includes("Universes"),
				...noRefetchDefaults,
			},
			{
				queryKey: ["marketQueryStudio"],
				queryFn: () => axiosExtract(marketViewApi.getMarketViewList()),
				enabled: visitedTabs.includes("MarketViews"),
				...noRefetchDefaults,
			},
			{
				queryKey: ["benchmarkQueryStudio"],
				queryFn: () => axiosExtract(benchmarkApi.getBenchmarkList()),
				enabled: visitedTabs.includes("References"),
				...noRefetchDefaults,
			},
			{
				queryKey: ["marketStudioTypes"],
				queryFn: () => axiosExtract(marketViewApi.getMarketViewUserSettings()),
				// enabled: visitedTabs.includes("MarketViews"),
				...noRefetchDefaults,
			},
			{
				queryKey: ["queryUserPortfolioColumnMetricsOrderingPreferences"],
				queryFn: () => axiosExtract(portfolioStudioPreferencesApi.getUserPortfolioColumnMetricsOrderingPreferences()),
				enabled: visitedTabs.includes("Portfolios"),
				...noRefetchDefaults,
			},
			{
				queryKey: ["queryUserReferenceColumnOrderingPreferences"],
				queryFn: () => axiosExtract(portfolioStudioPreferencesApi.getUserReferenceColumnOrderingPreferences()),
				enabled: visitedTabs.includes("References"),
				...noRefetchDefaults,
			},
			{
				queryKey: ["queryUserUniverseColumnMetricsOrderingPreferences"],
				queryFn: () => axiosExtract(portfolioStudioPreferencesApi.getUserUniverseColumnMetricsOrderingPreferences()),
				enabled: visitedTabs.includes("Universes"),
				...noRefetchDefaults,
			},
			{
				queryKey: ["queryUserMarketViewColumnMetricsOrderingPreferences"],
				queryFn: () => axiosExtract(portfolioStudioPreferencesApi.getUserMarketViewColumnMetricsOrderingPreferences()),
				enabled: visitedTabs.includes("MarketViews"),
				...noRefetchDefaults,
			},
		],
	});

	const [
		portfolio,
		reference,
		universe,
		marketViews,
		benchmarks,
		marketViewSetting,
		userPortfolioColumnPreferences,
		userReferenceColumnPreferences,
		userUniverseColumnPreferences,
		userMarketViewColumnPreferences,
	] = portfoliosStudio;

	const dataStudio = useMemo(
		() =>
			({
				Portfolios: { list: portfolio, settings: userPortfolioColumnPreferences },
				References: {
					reference,
					benchmarks,
					settings: userReferenceColumnPreferences,
				},
				Universes: { list: universe, settings: userUniverseColumnPreferences },
				MarketViews: { list: marketViews, settings: userMarketViewColumnPreferences },
			}) satisfies Record<PortfolioStudioTab, unknown>,
		[
			benchmarks,
			marketViews,
			portfolio,
			reference,
			universe,
			userMarketViewColumnPreferences,
			userPortfolioColumnPreferences,
			userReferenceColumnPreferences,
			userUniverseColumnPreferences,
		],
	);

	const { data: userSyncInfo } = useQueryNoRefetch(["queryUserSyncInfo"], {
		enabled: user.automaticImport,
		queryFn: () => axiosExtract(customerV3Api.users1()),
	});

	const { invoke: debouncedRefetch } = useDebounced(
		() => {
			portfolio.refetch().catch(noop);
		},
		{ debounceInterval: 10000 },
	);

	const { invoke: debouncedUniverseRefetch } = useDebounced(
		() => {
			universe.refetch().catch(noop);
		},
		{ debounceInterval: 10000 },
	);

	useEventBus("investment-import-update", debouncedRefetch);
	useEventBus("investment-update", (event) => {
		if (event.type === "UNIVERSE_UPDATE") {
			debouncedUniverseRefetch();
			return;
		}

		debouncedRefetch();
	});

	useGroupEventBus("benchmark-update", () => benchmarks.refetch().catch(noop), { timeout: 10000 });
	useEventBus("shared-entity", (e) => {
		if (e.level === "INFO") {
			if (e.sharedEntityType === "INVESTMENT") {
				debouncedRefetch();
			}

			if (e.sharedEntityType === "UNIVERSE") {
				universe.refetch().catch(noop);
			}

			if (e.sharedEntityType === "BENCHMARK") {
				benchmarks.refetch().catch(noop);
			}

			if (e.sharedEntityType === "MARKET_VIEW") {
				marketViews.refetch().catch(noop);
			}
		}
		benchmarks.refetch().catch(noop);
	});

	const iconWall = useMemo((): ReactNode => {
		if (currentTabName === PortfolioStudioTab.References) {
			if (dataStudio[currentTabName].benchmarks.isLoading || dataStudio[currentTabName].reference.isLoading) {
				return <IconWalls.LoadingData />;
			}

			if (dataStudio[currentTabName].benchmarks.isError || dataStudio[currentTabName].reference.isError) {
				return <IconWalls.ErrorData />;
			}

			if (
				(dataStudio[currentTabName].benchmarks.data?.length ?? 0) +
					(dataStudio[currentTabName].reference.data?.length ?? 0) ===
				0
			) {
				return (
					<IconWalls.ReferenceListEmptyData
						onCreate={() => history.push("/portfolios/upload/benchmark")}
						onUpload={() => history.push(`/portfolios/upload/target`)}
						onImporFinished={({ imported }) => {
							if (imported.length > 0) {
								reference.refetch({ throwOnError: true }).catch(noop);
								benchmarks.refetch({ throwOnError: true }).catch(noop);
							}
						}}
					/>
				);
			}

			return null;
		}

		if (dataStudio[currentTabName].list.isLoading || dataStudio[currentTabName].settings.isLoading) {
			return <IconWalls.LoadingData />;
		}

		if (
			dataStudio[currentTabName].list.isError ||
			dataStudio[currentTabName].settings.isError ||
			portfoliosStudio === undefined
		) {
			return <IconWalls.ErrorData />;
		}

		if (currentTabName === PortfolioStudioTab.Universes) {
			if (dataStudio[currentTabName].list?.data?.length === 0) {
				return (
					<IconWalls.UniverseListEmptyData
						onUpload={() => history.push(`/portfolios/upload/universe`)}
						refetch={universe.refetch}
					/>
				);
			}
			return null;
		}

		if (currentTabName === PortfolioStudioTab.Portfolios) {
			if (dataStudio[currentTabName].list?.data?.length === 0) {
				return <IconWalls.PortfolioListEmptyData refetch={portfolio.refetch} />;
			}
			return null;
		}

		if (currentTabName === PortfolioStudioTab.MarketViews) {
			if (dataStudio[currentTabName].list?.data?.length === 0) {
				return (
					<IconWalls.MarketViewListEmptyData
						marketViewSetting={marketViewSetting.data}
						onImporFinished={({ imported }) => {
							if (imported.length > 0) {
								marketViews.refetch({ throwOnError: true }).catch(noop);
							}
						}}
					/>
				);
			}
		}
		return null;
	}, [
		currentTabName,
		dataStudio,
		portfoliosStudio,
		history,
		reference,
		benchmarks,
		universe.refetch,
		portfolio.refetch,
		marketViewSetting.data,
		marketViews,
	]);

	const tabs = useMemo(() => {
		return {
			[PortfolioStudioTab.Portfolios]: iconWall || (
				<PortfolioList
					portfolios={portfolio?.data ?? []}
					columnsPreferences={userPortfolioColumnPreferences.data?.userPortfolioColumnPreferences}
					userSyncInfo={userSyncInfo}
					isVisible
					refetch={{
						investments: portfolio.refetch,
						columnsPreferences: userPortfolioColumnPreferences.refetch,
					}}
					portfolioStudioOuterDiv={portfolioStudioOuterDiv}
				/>
			),
			[PortfolioStudioTab.References]: iconWall || (
				<div className="rounded-lg bg-white px-4 py-6">
					<ReferenceList
						references={reference?.data ?? []}
						benchmarks={benchmarks?.data ?? []}
						columnsPreferences={userReferenceColumnPreferences.data?.userReferenceColumnPreferences}
						refetch={{
							benchmarks: benchmarks.refetch,
							references: reference.refetch,
							columnsPreferences: userReferenceColumnPreferences.refetch,
						}}
						isVisible
						portfolioStudioOuterDiv={portfolioStudioOuterDiv}
					/>
				</div>
			),
			[PortfolioStudioTab.Universes]: iconWall || (
				<div className="rounded-lg bg-white px-4 py-6">
					<UniverseList
						universes={universe?.data ?? []}
						columnsPreferences={userUniverseColumnPreferences.data?.userUniverseColumnPreferences}
						refetch={{
							universe: universe.refetch,
							columnsPreferences: userUniverseColumnPreferences.refetch,
						}}
						isVisible
						portfolioStudioOuterDiv={portfolioStudioOuterDiv}
					/>
				</div>
			),
			[PortfolioStudioTab.MarketViews]: iconWall || (
				<div className="rounded-lg bg-white px-4 py-6">
					<MarketViewsList
						markets={marketViews?.data ?? []}
						marketViewSetting={marketViewSetting.data}
						columnsPreferences={userMarketViewColumnPreferences.data?.userMarketViewColumnPreferences}
						refetch={{
							marketView: marketViews.refetch,
							columnsPreferences: userMarketViewColumnPreferences.refetch,
						}}
						isVisible
						portfolioStudioOuterDiv={portfolioStudioOuterDiv}
					/>
				</div>
			),
		};
	}, [
		benchmarks?.data,
		benchmarks.refetch,
		iconWall,
		marketViewSetting.data,
		marketViews?.data,
		marketViews.refetch,
		portfolio?.data,
		portfolio.refetch,
		reference?.data,
		reference.refetch,
		universe?.data,
		universe.refetch,
		userMarketViewColumnPreferences.data?.userMarketViewColumnPreferences,
		userMarketViewColumnPreferences.refetch,
		userPortfolioColumnPreferences.data?.userPortfolioColumnPreferences,
		userPortfolioColumnPreferences.refetch,
		userReferenceColumnPreferences.data?.userReferenceColumnPreferences,
		userReferenceColumnPreferences.refetch,
		userSyncInfo,
		userUniverseColumnPreferences.data?.userUniverseColumnPreferences,
		userUniverseColumnPreferences.refetch,
		portfolioStudioOuterDiv,
	]);

	return (
		<div ref={setPortfolioStudioOuterDiv}>
			<PageHeader
				title={tabTitle[currentTabName]}
				titleAction={
					<div className="flex items-center justify-between gap-2">
						{hasAccess(user, {
							requiredService: "IMPORT",
							requiredParam: ({ importFormats }) => importFormats !== undefined && importFormats.length > 0,
						}) ? (
							<DropdownMenu
								position="bottom"
								align="endToEnd"
								trigger={(props) => (
									<Button size="small" palette="primary" {...props} data-qualifier="PortfolioStudio/Import">
										<Icon icon="Dowload" size={16} classList="-rotate-90 mr-1" />
										Import
									</Button>
								)}
								actions={[
									{
										label: "Portfolio",
										onClickAsync: async () => {
											const importConverters = await axiosExtract(
												integrationsApi.retrieveInvestmentImportConverterType(),
											);
											const converters = importConverters.filter(
												(converter) => user.importFormats?.includes(converter),
											);

											spawnInvestmentImportDialog({
												converters,
												onImportFinished: ({ imported }) => {
													if (imported.length) {
														portfolio.refetch({ throwOnError: true }).catch(noop);
													}
												},
											});
										},
										"data-qualifier": "PortfolioStudio/Import/Portfolio",
									},
									hasAccess(user, {
										requiredService: "IMPORT",
										requiredParam: ({ importFormats }) => Boolean(importFormats?.includes("SPHERE_TEMPLATE_CONVERTER")),
									})
										? {
												label: "Reference",
												onClickAsync: async () => {
													const converters = await axiosExtract(
														integrationReferenceApi.retrieveReferenceImportConverterType(),
													);
													spawnInvestmentReferenceImportDialog({
														converters,
														onImportFinished: ({ imported }) => {
															if (imported.length) {
																benchmarks.refetch({ throwOnError: true }).catch(noop);
																reference.refetch({ throwOnError: true }).catch(noop);
															}
														},
													});
												},
												"data-qualifier": "PortfolioStudio/Import/Reference",
										  }
										: null,
									{
										label: "Universe",
										onClickAsync: async () => {
											const importConverters = await axiosExtract(
												integrationUniverseApi.retrieveUniverseImportConverterType(),
											);
											const converters = importConverters.filter((x) => user.importFormats?.includes(x));
											spawnUniverseImportDialog({
												converters,
												onImportFinished: ({ imported }) => {
													if (imported.length) {
														universe.refetch({ throwOnError: true }).catch(noop);
													}
												},
											});
										},
										"data-qualifier": "PortfolioStudio/Import/Universe",
									},
									hasAccess(user, {
										requiredService: "IMPORT",
										requiredParam: ({ importFormats }) => Boolean(importFormats?.includes("SPHERE_TEMPLATE_CONVERTER")),
									})
										? {
												label: "Market View",
												onClick: () =>
													spawnMarketViewImportDialog({
														converters: ["SPHERE_TEMPLATE_CONVERTER"] as const,
														onImportFinished: ({ imported }) => {
															if (imported.length) {
																marketViews.refetch({ throwOnError: true }).catch(noop);
															}
														},
													}),
												"data-qualifier": "PortfolioStudio/Import/MarketView",
										  }
										: null,
								]}
							/>
						) : (
							<></>
						)}

						<DropdownMenu
							position="bottom"
							align="endToEnd"
							trigger={(props) => (
								<Button size="small" palette="primary" {...props} data-qualifier="PortfolioStudio/New">
									<Icon icon="Outline1" size={16} />
									New
								</Button>
							)}
							actions={[
								{
									label: "Portfolio",
									onClick: () => spawnPortfolioCreationDialog({}),
									"data-qualifier": "PortfolioStudio/New/Portfolio",
								},
								{
									label: "Target portfolio",
									onClick: () => push("Portfolios/ManualCreation", { entity: InstrumentEditorEntity.TargetInvestment }),
									"data-qualifier": "PortfolioStudio/New/TargetPortfolio",
								},
								{
									label: "Benchmark portfolio",
									onClick: () => spawnBenchmarkCreationDialog({}),
									"data-qualifier": "PortfolioStudio/New/Benchmark",
								},
								{
									label: "Universe",
									onClick: () => push("Portfolios/ManualCreation", { entity: InstrumentEditorEntity.Universe }),
									"data-qualifier": "PortfolioStudio/New/Universe",
								},
								{
									label: "Market View",
									onClick: () => spawnMarketViewCreationDialog({ marketViewSetting: marketViewSetting.data }),
									disabled: !marketViewSetting.data,
									"data-qualifier": "PortfolioStudio/New/MarketView",
								},
							]}
						/>
					</div>
				}
				crumbs={[
					crumbs.portfolioStudio,
					crumbs.selectEntities({
						selectedEntity: tab as PortfolioStudioTab,
						onChange(entityOption) {
							push("PortfoliosStudio", { tab: entityOption });
						},
					}),
				]}
			/>

			<div className="relative z-0 overflow-hidden">{tabs[currentTabName]}</div>
		</div>
	);
};

export default PortfoliosStudio;
