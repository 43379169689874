import type { ContributionEntry, InvestmentContributionResponse } from "$root/api/api-gen";
import { Card } from "$root/components/EvolvedPrint/components/Card";
import {
	TinyTableDataCell,
	TinyTableHeadCell,
	tinyTableHeadCellFontSize,
} from "$root/components/EvolvedPrint/components/table/tiny-table";
import type { PrintableProps } from "$root/components/EvolvedPrint/configuration";
import { formatNumber } from "$root/localization/formatters";
import { BarGraphPCSvg } from "$root/ui-lib/charts";
import { DrillDownByValueMap, getGraphMarkers2, objSumRounded, roundCustomByStep } from "$root/utils/experimental";
import { typedObjectValues } from "$root/utils/objects";
import type { TableWithGroupsProps } from "@mdotm/mdotui/components";
import { BaseTableWithGroups, Text } from "@mdotm/mdotui/components";
import { overrideClassName } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { groupBy } from "@mdotm/mdotui/utils";
import Immutable from "immutable";
import { useCallback, useMemo } from "react";
import { useReportTranslation } from "../translation";

export type PerformanceContributionVolatilitySelectionProps =
	| {
			selector: "assetClass";
			vsSelector: "microAssetClass";
	  }
	| {
			selector: "assetClass";
			vsSelector: "geography";
	  }
	| {
			selector: "microAssetClass";
			vsSelector: "geography";
	  }
	| {
			selector: "geography";
			vsSelector: "assetClass";
	  }
	| {
			selector: "geography";
			vsSelector: "microAssetClass";
	  };

const defaultOptions = [
	{
		label: "Macro Asset Class vs Micro Asset Class",
		selector: "assetClass",
		vsSelector: "microAssetClass",
		key: "MACRO_ASSET_CLASS_VS_MICRO_ASSET_CLASS",
	},
	{
		label: "Macro Asset Class vs Geography",
		selector: "assetClass",
		vsSelector: "geography",
		key: "MACRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY",
	},
	{
		label: "Micro Asset Class vs Geography",
		selector: "microAssetClass",
		vsSelector: "geography",
		key: "MICRO_ASSET_CLASS_VS_MACRO_GEOGRAPHY",
	},
	{
		label: "Geography vs Macro Asset Class",
		selector: "geography",
		vsSelector: "assetClass",
		key: "MACRO_GEOGRAPHY_VS_MACRO_ASSET_CLASS",
	},
	{
		label: "Geography vs Micro Asset Class",
		selector: "geography",
		vsSelector: "microAssetClass",
		key: "MACRO_GEOGRAPHY_VS_MICRO_ASSET_CLASS",
	},
] as const;

type DrillDownProps = {
	index: number;
	value: string | number | ContributionEntry | undefined;
	computedData: Record<"volatilityValue" | "performanceValue" | "maxVol" | "minVol" | "maxPer" | "minPer", number>;
};

type GetPerformanceVolatilityContributionProps = {
	graphColumnMarkers: {
		markersVolatility: {
			value: number;
			label: string;
		}[];
		markersPerformance: {
			value: number;
			label: string;
		}[];
	};
	graphLimit: {
		maxVolatility: number;
		maxPerformance: number;
		minVolatility: number;
		minPerformance: number;
	};
	list: Array<{ group: DrillDownProps; drilldown: DrillDownProps }>;
	opts: PerformanceContributionVolatilitySelectionProps;
};

type PerformanceVolatilityContributionData = Record<
	"contributionVolatility" | "performanceVolatility",
	InvestmentContributionResponse
>;

export function getPerformanceVolatilityContributionProps(
	data: PerformanceVolatilityContributionData,
	comparison: PerformanceContributionVolatilitySelectionProps,
	filterSignificant: boolean,
): GetPerformanceVolatilityContributionProps {
	const { contributionVolatility, performanceVolatility } = data;
	const { selector, vsSelector } = comparison;

	const contribution = {
		currentVolatility: contributionVolatility.current,
		currentPerformance: performanceVolatility.current,
	};

	const aggregateData = {
		volatility: (contribution.currentVolatility ?? []).map((el) => ({
			assetClass: el.assetClass,
			geography: el.geography,
			microAssetClass: el.microAssetClass,
			originalVolatility: el,
			volatilityValue: el.value,
			id: `${el.assetClass}${el.geography}${el.microAssetClass}`.split(" ").join("_"),
		})),

		performance: (contribution.currentPerformance ?? []).map((el) => ({
			assetClass: el.assetClass,
			geography: el.geography,
			microAssetClass: el.microAssetClass,
			originalPerformance: el,
			performanceValue: el.value,
			id: `${el.assetClass}${el.geography}${el.microAssetClass}`.split(" ").join("_"),
		})),
	};

	const decupledByID = [
		...new window.Set([
			...aggregateData.performance.map((el) => el.id),
			...aggregateData.volatility.map((el) => el.id),
		]),
	];

	const result = decupledByID.map((id) => {
		const currentVTK = aggregateData.volatility.find((el) => el.id === id);
		const currentPTK = aggregateData.performance.find((el) => el.id === id);

		return {
			...currentVTK,
			...currentPTK,
		};
	});

	const computedContribution = (() => {
		const drilldown = DrillDownByValueMap(
			result ?? [],
			selector,
			vsSelector,
			{
				volatilityValue: (val) => objSumRounded(val, "volatilityValue"),
				performanceValue: (val) => objSumRounded(val, "performanceValue"),
				maxVol: (val) => objSumRounded(val, "volatilityValue"),
				minVol: (val) => objSumRounded(val, "volatilityValue"),
				maxPer: (val) => objSumRounded(val, "performanceValue"),
				minPer: (val) => objSumRounded(val, "performanceValue"),
			},
			(a, b) => b.volatilityValue - a.volatilityValue,
		);
		if (!filterSignificant) {
			return drilldown;
		}
		return {
			...drilldown,
			drilldownData: drilldown.drilldownData
				.filter(
					(x) => Math.abs(x.computedData.volatilityValue) >= 0.01 || Math.abs(x.computedData.performanceValue) >= 0.01,
				)
				.map((x) => ({
					...x,
					drilldown: x.drilldown.filter(
						(y) =>
							Math.abs(y.computedData.volatilityValue) >= 0.01 || Math.abs(y.computedData.performanceValue) >= 0.01,
					),
				})),
		};
	})();

	const { drilldownData } = computedContribution;

	const list = drilldownData.flatMap(({ drilldown, ...props }) =>
		drilldown.map((item) => ({
			drilldown: { ...item },
			group: { ...props },
		})),
	);

	const graphLimit = drilldownData.reduce(
		(acc, group) => {
			const rowValues = group.drilldown.reduce(
				(a, row) => {
					return {
						maxVolatility: Math.max(a.maxVolatility, row.computedData.maxVol, group.computedData.maxVol),
						maxPerformance: Math.max(a.maxPerformance, row.computedData.maxPer, group.computedData.maxPer),
						minVolatility: Math.min(a.minVolatility, row.computedData.minVol, group.computedData.minVol),
						minPerformance: Math.min(a.minPerformance, row.computedData.minPer, group.computedData.minPer),
					};
				},
				{ maxVolatility: 0, maxPerformance: 0, minVolatility: 0, minPerformance: 0 },
			);
			return {
				maxVolatility: Math.max(acc.maxVolatility, rowValues.maxVolatility),
				maxPerformance: Math.max(acc.maxPerformance, rowValues.maxPerformance),
				minVolatility: Math.min(acc.minVolatility, rowValues.minVolatility),
				minPerformance: Math.min(acc.minPerformance, rowValues.minPerformance),
			};
		},
		{ maxVolatility: 0, maxPerformance: 0, minVolatility: 0, minPerformance: 0 },
	);

	const scaledDrilldown = {
		maxVolatility: roundCustomByStep(graphLimit.maxVolatility, 0.5),
		maxPerformance: roundCustomByStep(graphLimit.maxPerformance, 0.5),
		minVolatility: roundCustomByStep(graphLimit.minVolatility, 0.5),
		minPerformance: roundCustomByStep(graphLimit.minPerformance, 0.5),
	};

	const graphColumnMarkers = {
		markersVolatility: getGraphMarkers2({
			min: scaledDrilldown.minVolatility,
			max: scaledDrilldown.maxVolatility,
			nOfMarkers: 5,
			suffix: "%",
		}),
		markersPerformance: getGraphMarkers2({
			min: scaledDrilldown.minPerformance,
			max: scaledDrilldown.maxPerformance,
			nOfMarkers: 5,
			suffix: "%",
		}),
	};
	return { list, graphColumnMarkers, opts: comparison, graphLimit };
}

export function PerformanceVolatilityContribution({
	comparison,
	graphLimit,
	graphColumnMarkers,
	list,
}: PrintableProps<
	{
		data: PerformanceVolatilityContributionData;
		comparison: PerformanceContributionVolatilitySelectionProps;
		graphLimit: GetPerformanceVolatilityContributionProps["graphLimit"];
		graphColumnMarkers: GetPerformanceVolatilityContributionProps["graphColumnMarkers"];
	},
	GetPerformanceVolatilityContributionProps["list"][number]
>): JSX.Element {
	const { t } = useReportTranslation();

	const opts = defaultOptions.find(
		(opt) => opt.selector === comparison.selector && opt.vsSelector === comparison.vsSelector,
	)!;

	const tableGroupRows = useMemo(() => {
		const groupList = groupBy(list, ({ group }) => `index-${group.index}`);
		return typedObjectValues(groupList).map((group) => ({
			...group![0].group,
			rows:
				group?.map(({ drilldown }) => ({
					...drilldown,
				})) ?? [],
		}));
	}, [list]);

	const columns = useCallback<TableWithGroupsProps<(typeof tableGroupRows)[number]>["columns"]>(
		({ expandColumn }) => [
			expandColumn,
			{
				name: "label",
				header: (props) => (
					<TinyTableHeadCell {...props}>
						{t(`REPORT_BUILDER.PERFORMANCE_AND_VOLATILITY_CONTRIBUTION.TABLE.${opts.selector}`)}
					</TinyTableHeadCell>
				),
				groupContent: (groupedRow, props) => (
					<TinyTableDataCell {...props}>
						<strong>{groupedRow.value as any /* TODO: fix types */}</strong>
					</TinyTableDataCell>
				),
				content: (row, props) => (
					<TinyTableDataCell {...props}>{row.value as any /* TODO: fix types */}</TinyTableDataCell>
				),
				orderable: false,
				minWidth: 140,
			},
			{
				name: "performance",
				align: "end",
				header: (props) => (
					<TinyTableHeadCell {...props}>
						{" "}
						{t("REPORT_BUILDER.PERFORMANCE_AND_VOLATILITY_CONTRIBUTION.TABLE.PORTFOLIO_PERFORMANCE")}
					</TinyTableHeadCell>
				),
				groupContent: (groupedRow, props) => (
					<TinyTableDataCell {...props}>
						<strong className="text-[#005C8B] tabular-nums">
							{formatNumber(groupedRow.computedData.performanceValue, 2)}%
						</strong>
					</TinyTableDataCell>
				),
				content: (row, props) => (
					<TinyTableDataCell {...props}>
						<span className="text-[#005C8B] tabular-nums">{formatNumber(row.computedData.performanceValue, 2)}%</span>
					</TinyTableDataCell>
				),

				orderable: false,
				width: 96,
			},
			{
				name: "graph1",
				orderable: false,
				width: 120,
				header: (props) => (
					<div
						style={{ ...props.style, minHeight: 31 }}
						className={overrideClassName(props.classList, "flex items-center justify-between grow")}
					>
						{graphColumnMarkers.markersPerformance.map((m, i) => (
							<Text
								as="div"
								type="Body/S/BOLD-UPPERCASE"
								style={{ fontSize: tinyTableHeadCellFontSize }}
								color={themeCSSVars.palette_N500}
								key={`marker-${i}`}
							>
								{m.label}
							</Text>
						))}
					</div>
				),
				groupContent: ({ computedData: { performanceValue } }, props) => (
					<div
						style={{ ...props.style, minHeight: 31 }}
						className={overrideClassName(props.classList, "flex grow font-semibold")}
					>
						<BarGraphPCSvg
							classList="w-full"
							options={{
								animated: false,
								resize: true,
								marksLabels: false,
								markerStep: 0.5,
								scale: { max: graphLimit.maxPerformance, min: graphLimit.minPerformance },
							}}
							data={[{ value: performanceValue, color: "#005C8B" }]}
						/>
					</div>
				),
				content: ({ computedData: { performanceValue } }, props) => (
					<div style={{ ...props.style, minHeight: 31 }} className={overrideClassName(props.classList, "flex grow")}>
						<BarGraphPCSvg
							classList="w-full"
							options={{
								animated: false,
								resize: true,
								marksLabels: false,
								markerStep: 0.5,
								scale: { max: graphLimit.maxPerformance, min: graphLimit.minPerformance },
							}}
							data={[{ value: performanceValue, color: "#005C8B" }]}
						/>
					</div>
				),
			},
			{
				name: "volatility",
				align: "end",
				header: (props) => (
					<TinyTableHeadCell {...props}>
						{t("REPORT_BUILDER.PERFORMANCE_AND_VOLATILITY_CONTRIBUTION.TABLE.PORTFOLIO_VOLATILITY")}
					</TinyTableHeadCell>
				),
				groupContent: (groupedRow, props) => (
					<TinyTableDataCell {...props}>
						<strong className="text-[#572C7F] tabular-nums">
							{formatNumber(groupedRow.computedData.volatilityValue, 2)}%
						</strong>
					</TinyTableDataCell>
				),
				content: (row, props) => (
					<TinyTableDataCell {...props}>
						<span className="text-[#572C7F] tabular-nums">{formatNumber(row.computedData.volatilityValue, 2)}%</span>
					</TinyTableDataCell>
				),
				orderable: false,
				width: 96,
			},
			{
				name: "graph2",
				width: 120,
				orderable: false,
				header: (props) => (
					<div
						style={{ ...props.style, minHeight: 31 }}
						className={overrideClassName(props.classList, "flex items-center justify-between grow")}
					>
						{graphColumnMarkers.markersVolatility.map((m, i) => (
							<Text
								as="div"
								type="Body/S/BOLD-UPPERCASE"
								style={{ fontSize: tinyTableHeadCellFontSize }}
								color={themeCSSVars.palette_N500}
								key={`marker-${i}`}
							>
								{m.label}
							</Text>
						))}
					</div>
				),
				groupContent: ({ computedData: { volatilityValue } }, props) => (
					<div
						style={{ ...props.style, minHeight: 31 }}
						className={overrideClassName(props.classList, "flex grow font-semibold")}
					>
						<BarGraphPCSvg
							options={{
								animated: false,
								resize: true,
								marksLabels: false,
								markerStep: 0.5,
								scale: { max: graphLimit.maxVolatility, min: graphLimit.minVolatility },
							}}
							data={[{ value: volatilityValue, color: "#572C7F" }]}
						/>
					</div>
				),
				content: ({ computedData: { volatilityValue } }, props) => (
					<div style={{ ...props.style, minHeight: 31 }} className={overrideClassName(props.classList, "flex grow")}>
						<BarGraphPCSvg
							options={{
								animated: false,
								resize: true,
								marksLabels: false,
								markerStep: 0.5,
								scale: { max: graphLimit.maxVolatility, min: graphLimit.minVolatility },
							}}
							data={[{ value: volatilityValue, color: "#572C7F" }]}
						/>
					</div>
				),
			},
		],
		[
			graphColumnMarkers.markersPerformance,
			graphColumnMarkers.markersVolatility,
			graphLimit.maxPerformance,
			graphLimit.maxVolatility,
			graphLimit.minPerformance,
			graphLimit.minVolatility,
			opts.selector,
			t,
		],
	);

	if (list.length === 0) {
		return <></>;
	}
	return (
		<Card
			title={`${t("REPORT_BUILDER.PERFORMANCE_AND_VOLATILITY_CONTRIBUTION.TITLE")} - ${t(
				`REPORT_BUILDER.PERFORMANCE_AND_VOLATILITY_CONTRIBUTION.OPTIONS.${opts.key}`,
			)}`}
		>
			<BaseTableWithGroups
				palette="uniform"
				groupRowKey={(group) => `index-${group.index}`}
				expandGroupByKey={Immutable.Set(tableGroupRows.map((group) => `index-${group.index}`))}
				groupedRows={tableGroupRows}
				classList="pointer-events-none [&_.transition-\[height\]]:!transition-none [&_.transition-\[transform\,opacity\]]:!transition-none"
				columns={columns}
			/>
		</Card>
	);
}

export default PerformanceVolatilityContribution;
