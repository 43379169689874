import { Banner } from "@mdotm/mdotui/components";
import type { InstrumentEditorEntity } from "../const";

function NormalisedInvestmentInstrumentBanner({
	entity,
	someInvestmentsHasExcluedInstrument,
}: {
	entity: InstrumentEditorEntity;
	someInvestmentsHasExcluedInstrument: boolean;
}): JSX.Element {
	if (!(entity === "INVESTMENT" || entity === "INVESTMENT_ENHANCEMENT") || !someInvestmentsHasExcluedInstrument) {
		return <></>;
	}

	return (
		<Banner
			severity="info"
			title="Instruments with a normalised weight <0.01% will be excluded from the new portfolio."
			classList="mb-4"
		>
			The new portfolio contains instruments with a normalised weight lower than 0.01%. Due to the low weight, these
			instruments have become irrelevant and will be excluded. If you wish to include all instruments, you can add them
			manually or increase the weight of the highlighted portfolio or instruments here below.
		</Banner>
	);
}

export default NormalisedInvestmentInstrumentBanner;
