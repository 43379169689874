import { createPersistentAtom } from "$root/third-party-integrations/zustand";
import type { Updater } from "$root/utils/functions";
import { differenceInSeconds } from "date-fns";
import type { FakeAiLoaderStep } from "./common";
import { stepNames, type FakeAiLoaderStepName } from "./common";

type SerializableStepData = {
	id: string;
	progress: number;
	step: FakeAiLoaderStepName;
	date: Date;
};

const initProgress: Array<SerializableStepData> = [];

export const animationProgressStore = createPersistentAtom<Array<SerializableStepData>>(
	initProgress,
	"animation-progress",
);

export function getAnimationProgressById(id: string): SerializableStepData | undefined {
	const animations = animationProgressStore((animation) => animation.value);
	return animations.find((animation) => animation.id === id);
}

export function animationProgressState(): {
	setAnimationProgress: (newValue: SerializableStepData[] | Updater<SerializableStepData[]>) => void;
	animationProgress: Array<SerializableStepData>;
} {
	const { set, value } = animationProgressStore();
	return { setAnimationProgress: set, animationProgress: value };
}

export function simulateAnimationProgress(
	date: Date,
	progress: number,
	stepName: FakeAiLoaderStepName,
	expCoeff: number,
): FakeAiLoaderStep {
	const deltaProgressInSeconds = differenceInSeconds(new Date(), new Date(date));
	const exceededProgression = Number(expCoeff * deltaProgressInSeconds);
	const simulatedProgression = exceededProgression + Number(progress.toFixed(3));

	if (simulatedProgression <= 1.0) {
		return { stepName, progress: simulatedProgression };
	}

	const deltaSimulatedProgressToNewStep = Math.abs(Number(Math.floor(simulatedProgression - 1).toFixed(3)));
	const currentStepIndex = stepNames.indexOf(stepName);
	const nextStep =
		currentStepIndex > -1 && currentStepIndex < stepNames.length - 1 ? stepNames[currentStepIndex + 1] : stepName;
	return { stepName: nextStep, progress: deltaSimulatedProgressToNewStep >= 1 ? 1 : deltaSimulatedProgressToNewStep };
}
