import { IconWalls } from "$root/components/IconWall";
import { useLocaleFormatters } from "$root/localization/hooks";
import { stableColorGenerator } from "$root/utils/chart/colorGenerator";
import type { TableWithGroupsColumn, TableWithGroupsProps } from "@mdotm/mdotui/components";
import {
	AutoSortHScrollTableWithGroups,
	BatchActions,
	useSelectableTableWithGroupColumn,
} from "@mdotm/mdotui/components";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import Color from "color";
import { Set } from "immutable";
import { useCallback, useMemo, useState } from "react";
import { match } from "ts-pattern";
import {
	groupInstruments,
	normaliseAndVerifyInstrumentPortfolioCompositon,
	provideAssetClass,
	useCompositionToolBar,
	verifySomeInstrumentsAreExcluded,
} from "../../builder";
import { useGroupedInstrumentCompositionColumns } from "../../columns";
import type { GroupedInstrumentEditorEntry, InstrumentEditorEntry, MandatoryColumnEnum } from "../../const";
import type { InstrumentCompostionEditorProps } from "../../instrumentEditor";
import InstrumentLimitReachedBanner from "../InstrumentLimitReachedBanner";
import NormaliseAndExcessToCashBanner from "../NormaliseAndExcessToCashBanner";
import NormalisedInvestmentInstrumentBanner from "../NormalisedInvestmentInstrumentBanner";
import type { UserEnhancementCompositionColumnPreference } from "$root/api/api-gen";
import {
	PortfolioStudioPreferencesApiFactory,
	type UserCompositionColumnPreference,
	type UserEnhancementCompositionColumnPreferencePreferenceTypeEnum,
} from "$root/api/api-gen";
import { ReactQueryWrapperBase } from "$root/components/ReactQueryWrapper";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { useApiGen } from "$root/api/hooks";

type EnhanceColumnPreferencesEnum = UserEnhancementCompositionColumnPreferencePreferenceTypeEnum | MandatoryColumnEnum;
const defaultEnhanceColumnPreference: Array<EnhanceColumnPreferencesEnum> = [
	"CHECKBOX",
	"INSTRUMENT_NAME",
	"IDENTIFIER",
	"ASSET_CLASS",
	"MICRO_ASSET_CLASS",
	"CURRENT_WEIGHT",
	"ENHANCED_WEIGHT",
	"DIFFERENCE",
	"WEIGHT",
	"DELETE",
];

type DefaultInstrumentTableProps = InstrumentCompostionEditorProps & {
	filteredRows: InstrumentEditorEntry[];
	rows: InstrumentEditorEntry[];
};

function GroupedInstrumentBaseTable(props: DefaultInstrumentTableProps): JSX.Element {
	const portfolioStudioPreferencesApi = useApiGen(PortfolioStudioPreferencesApiFactory);
	const queryEnhanceCompositionColumn = useQueryNoRefetch(["queryGroupEnhanceCompositionColumn"], {
		queryFn: () => axiosExtract(portfolioStudioPreferencesApi.getUserEnhancementCompositionColumnOrderingPreferences()),
		refetchOnMount: false,
	});

	return (
		<ReactQueryWrapperBase query={queryEnhanceCompositionColumn}>
			{({ userEnhancementCompositionColumnPreferences }) => (
				<GroupedInstrumentBaseTableInner {...props} columnPreferences={userEnhancementCompositionColumnPreferences} />
			)}
		</ReactQueryWrapperBase>
	);
}

function GroupedInstrumentBaseTableInner({
	instrumentBuilder,
	filteredRows,
	rows,
	entity,
	limit,
	columnPreferences,
}: DefaultInstrumentTableProps & { columnPreferences?: UserEnhancementCompositionColumnPreference[] }): JSX.Element {
	const toolbar = useCompositionToolBar();
	const grupedInstrumentCompositionColumns = useGroupedInstrumentCompositionColumns();
	const { formatNumber } = useLocaleFormatters();

	const tagsMap = instrumentBuilder.watchTags();
	const tagOptions = useMemo(() => Array.from(tagsMap.values()), [tagsMap]);
	const groupedRows = useMemo<Array<GroupedInstrumentEditorEntry>>(() => groupInstruments(rows), [rows]);
	const [initialExpandGroupByKey] = useState(() => Set(groupedRows.map((x) => x.assetClass)));

	const filteredGroupedRows = useMemo<Array<GroupedInstrumentEditorEntry>>(
		() => groupInstruments(filteredRows),
		[filteredRows],
	);

	const selectableTableColumn = useSelectableTableWithGroupColumn({
		groupedRows,
		filteredGroupedRows,
		selectBy: (r) => r.rowId,
	});

	const totalWeight = useMemo(() => {
		if (rows.length > 0) {
			return `${formatNumber(instrumentBuilder.getTotal("weight"))}%`;
		}
		return undefined;
	}, [formatNumber, instrumentBuilder, rows.length]);

	const totalScore = useMemo(() => {
		if (rows.length > 0) {
			return `Scored: ${formatNumber(instrumentBuilder.getTotal("score"))}`;
		}
		return undefined;
	}, [formatNumber, instrumentBuilder, rows.length]);

	const mappedColumns = useMemo<
		Record<EnhanceColumnPreferencesEnum, TableWithGroupsColumn<GroupedInstrumentEditorEntry>>
	>(
		() => ({
			INSTRUMENT_NAME: grupedInstrumentCompositionColumns.name({
				onCompare(row, action) {
					if (action === "add") {
						instrumentBuilder.setInstrumentToCompare([row]);
						return;
					}

					instrumentBuilder.deleteComparedInstruments(row.rowId);
				},
				isRowCompared(rowId) {
					return instrumentBuilder.getComparedInstrument(rowId) !== undefined;
				},
			}),
			IDENTIFIER: grupedInstrumentCompositionColumns.identifier(),
			ASSET_CLASS: grupedInstrumentCompositionColumns.assetClass(),
			MICRO_ASSET_CLASS: grupedInstrumentCompositionColumns.microAssetClass(),
			CURRENT_WEIGHT: grupedInstrumentCompositionColumns.currentWeight(),
			ENHANCED_WEIGHT: grupedInstrumentCompositionColumns.enhancedWeight(),
			DIFFERENCE: grupedInstrumentCompositionColumns.differenceWeight(),
			SCORE: {
				...grupedInstrumentCompositionColumns.score({}),
				footerCellClassList: "font-semibold justify-end",
				footer: totalScore,
				align: "end",
			},
			TAG: grupedInstrumentCompositionColumns.tag({
				options: tagOptions,
			}),
			WEIGHT: {
				...grupedInstrumentCompositionColumns.weight({
					onChangeWeight: (rowId, v) => instrumentBuilder.setWeight(rowId, v ?? undefined),
					isInstrumentDeleted: (rowId) => instrumentBuilder.isInstrumentDeleted(rowId),
					onCheckIsMinimumWeightValid: async (row, weight) => {
						const instrumentsExcluded = await verifySomeInstrumentsAreExcluded(
							{ ...row, weight: weight ?? undefined },
							entity,
						);
						instrumentBuilder.setInstrument(row.rowId, {
							...row,
							weight: weight ?? undefined,
							someInstrumentsAreExcluded: instrumentsExcluded.length > 0,
							nOfInstrumentExcluded: instrumentsExcluded.length,
						});
					},
					weightProvider: (rowId) => instrumentBuilder.getWeight(rowId),
				}),
				footerCellClassList: "font-semibold justify-end",
				footer: totalWeight,
				align: "end",
				minWidth: 130,
				groupContent: () => "",
			},
			CHECKBOX: selectableTableColumn.column,
			DELETE: grupedInstrumentCompositionColumns.deleteRestore({
				deleted: instrumentBuilder.watchDeleted(),
				onChange: (row, deleteSet) => instrumentBuilder.delete("soft", row.rowId, deleteSet.has(row.rowId)),
			}),
		}),
		[
			entity,
			grupedInstrumentCompositionColumns,
			instrumentBuilder,
			selectableTableColumn.column,
			tagOptions,
			totalWeight,
			totalScore,
		],
	);

	const columns = useCallback<TableWithGroupsProps<GroupedInstrumentEditorEntry>["columns"]>(
		({ bandColumn, expandColumn }) => {
			if (!columnPreferences) {
				const mainColumns = defaultEnhanceColumnPreference.map((column) => mappedColumns[column]);
				return [bandColumn, expandColumn, ...mainColumns];
			}
			const filterColumnPreferences = columnPreferences.filter(
				(preference) =>
					preference.preferenceType !== "DIFFERENCE" &&
					preference.preferenceType !== "CURRENT_WEIGHT" &&
					preference.preferenceType !== "ENHANCED_WEIGHT" &&
					preference.preferenceType !== "ASSET_CLASS",
			);
			const enabledColumns = filterColumnPreferences.flatMap((preference) =>
				preference.enabled && preference.preferenceType ? [preference.preferenceType] : [],
			);
			const enrichedColumns: EnhanceColumnPreferencesEnum[] = [
				"CHECKBOX",
				...enabledColumns,
				"CURRENT_WEIGHT",
				"ENHANCED_WEIGHT",
				"DIFFERENCE",
				"WEIGHT",
				"DELETE",
			];
			return [bandColumn, expandColumn, ...enrichedColumns.map((column) => mappedColumns[column])];
		},
		[columnPreferences, mappedColumns],
	);

	const actions = useMemo(
		() => [
			toolbar.softDelete({
				instrumentBuilder,
				selected: selectableTableColumn.multiSelectCtx,
			}),
			toolbar.restoreDeleted({
				instrumentBuilder,
				selected: selectableTableColumn.multiSelectCtx,
			}),
			toolbar.compare({
				instrumentBuilder,
				selected: selectableTableColumn.multiSelectCtx,
			}),
		],
		[instrumentBuilder, selectableTableColumn.multiSelectCtx, toolbar],
	);

	return (
		<>
			<BatchActions
				total={rows.length}
				selected={selectableTableColumn.multiSelectCtx.selection.size}
				palette="tertiary"
				actions={actions}
				classList="py-4 h-[58px] shrink-0"
			/>
			<NormalisedInvestmentInstrumentBanner
				entity={entity}
				someInvestmentsHasExcluedInstrument={rows.some((instrument) => instrument.someInstrumentsAreExcluded)}
			/>
			<AutoSortHScrollTableWithGroups
				columns={columns}
				groupedRows={filteredGroupedRows}
				groupRowKey={(row) => row.assetClass}
				pinnedColumns={[
					{ name: "assetClass", side: "left" },
					{ name: "previousWeight", side: "right" },
					{ name: "enhancedWeight", side: "right" },
					{ name: "difference", side: "right" },
					{ name: "weight", side: "right" },
					{ name: "delete", side: "right" },
				]}
				noDataText={<IconWalls.EditorEmptyData entity={entity} />}
				onRowClick={(row) => selectableTableColumn.toggle(row.rowId)}
				expandGroupByKey={initialExpandGroupByKey}
				rowStyle={(row) => {
					if (row.someInstrumentsAreExcluded) {
						return { backgroundColor: themeCSSVars.global_palette_warning_100 };
					}

					if (instrumentBuilder.isInstrumentDeleted(row.rowId)) {
						return { backgroundColor: themeCSSVars.Table_highlightedRowBackgroundColor };
					}
				}}
				groupColor={(row) => stableColorGenerator(row.assetClass)}
				rowColor={(row) =>
					Color(stableColorGenerator(provideAssetClass(row)))
						.alpha(0.4)
						.toString()
				}
				palette="uniform"
			/>
			<br />
			{limit && rows.length > limit && <InstrumentLimitReachedBanner limit={limit} />}
			<br />
			<NormaliseAndExcessToCashBanner
				disableNormalise={selectableTableColumn.multiSelectCtx.selection.size === 0}
				disableNormaliseTooltip={selectableTableColumn.multiSelectCtx.selection.size > 0}
				entity={entity}
				instrumentBuilder={instrumentBuilder}
				onAsyncNormalise={() =>
					normaliseAndVerifyInstrumentPortfolioCompositon({
						instrumentBuilder,
						entity,
						selection: selectableTableColumn.multiSelectCtx.selection,
					})
				}
				onExcessToCash={() => instrumentBuilder.excessToCash()}
			/>
		</>
	);
}

export default GroupedInstrumentBaseTable;
