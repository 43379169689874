import type { RichTicker } from "$root/api/api-gen";
import { EntityEditorControllerApiFactory, type ReviewTicker } from "$root/api/api-gen";
import { getApiGen } from "$root/api/factory";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { sumArrayLike } from "$root/utils/collections";
import { typedObjectEntries } from "$root/utils/objects";
import { parallelize } from "$root/utils/promise";
import {
	Button,
	Controller,
	NullableNumberInput,
	Row,
	TooltipContext,
	type ActionOrActionWithGroup,
	type BatchAction,
	type DataAttributesProps,
} from "@mdotm/mdotui/components";
import type { MultiSelectCtx } from "@mdotm/mdotui/headless";
import type { MaybeFn } from "@mdotm/mdotui/utils";
import { builtInSortFnFor, groupBy, noop } from "@mdotm/mdotui/utils";
import BigNumber from "bignumber.js";
import { Map, Set } from "immutable";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import type { GroupedInstrumentEditorEntry, InstrumentEditorEntity, InstrumentEditorEntry } from "./const";
import type { spawnBenchmarkTemplateDialogProps } from "./spawn/benchmark-template";
import { spawnBenchmarkTemplateDialog } from "./spawn/benchmark-template";
import { prefetchAvailableInstruments, spawnInstrumentAddersDialog } from "./spawn/instrument-adder";
import { spawnInstrumentUploadDialogProps } from "./spawn/instrument-upload";
import { prefetchAvailablePortfolios, spawnPortfoliosAdderDialog } from "./spawn/portfolio-adder";

export function covertInstrumentEditorToReviewTicker(composition: InstrumentEditorEntry[]): ReviewTicker[] {
	return composition.map((instrument): ReviewTicker => {
		const { rowId, investment, someInstrumentsAreExcluded, nOfInstrumentExcluded, stableWeight, ...reviewTicker } =
			instrument;
		return reviewTicker;
	});
}

export function provideAssetClass(instrument: InstrumentEditorEntry): string {
	if (!instrument.assetClass && instrument.proxyOverwriteType === "PORTFOLIO_MIXED") {
		return "Portfolio";
	}

	if (!instrument.assetClass) {
		return "Uncategorised";
	}
	return instrument.assetClass;
}

export function groupInstruments(rows: InstrumentEditorEntry[]): GroupedInstrumentEditorEntry[] {
	const groupRowsByAssetClass = groupBy(rows, (instrument) => provideAssetClass(instrument));

	return typedObjectEntries(groupRowsByAssetClass)
		.map(([assetClass, instruments]) => {
			const sumWeight = instruments ? sumArrayLike(instruments, (x) => x.weight ?? 0) : 0;
			const sumDifferenceWeight = instruments
				? sumArrayLike(instruments, (x) => Number(((x.stableWeight ?? 0) - (x.previousWeight ?? 0)).toFixed(2)))
				: 0;
			const previousWeight = instruments ? sumArrayLike(instruments, (x) => x.previousWeight ?? 0) : 0;

			return {
				rows: instruments ?? [],
				assetClass,
				weight: sumWeight,
				differenceWeight: sumDifferenceWeight,
				enhancedWeight: sumWeight,
				previousWeight,
			};
		})
		.sort(builtInSortFnFor("assetClass"));
}

/**
 * Given the instrument weight check if his composition has some instrument excleded
 * @param instrument
 * @param entity
 * @returns Promise<RichTicker[]>
 */
export async function verifySomeInstrumentsAreExcluded(
	instrument: InstrumentEditorEntry,
	entity: InstrumentEditorEntity,
): Promise<RichTicker[]> {
	const entityEditorApi = getApiGen(EntityEditorControllerApiFactory);
	const { investment, weight } = instrument;
	if (investment?.uuid === undefined) {
		throw Error("missing uuid");
	}
	if (weight === null || weight === undefined || weight < 0.01) {
		throw Error("weight too low");
	}
	const { removedTickers } = await axiosExtract(
		entityEditorApi.verifyEditorPortfolio(investment?.uuid, weight, entity),
	);
	return removedTickers ?? [];
}

/**
 * Normalise compision and {@link verifySomeInstrumentsAreExcluded} if portfolio are included in composition
 * @param params
 */
export async function normaliseAndVerifyInstrumentPortfolioCompositon(params: {
	selection: Immutable.Set<string>;
	entity: InstrumentEditorEntity;
	instrumentBuilder: InstrumentEditorBuilderProps;
}): Promise<void> {
	const { entity, selection, instrumentBuilder } = params;
	instrumentBuilder.normalise(selection);
	const latestComposition = Array.from(instrumentBuilder.watchComposition().values());
	const portfolios = latestComposition.filter(
		(x) => x.proxyOverwriteType === "PORTFOLIO_MIXED" && selection.has(x.rowId),
	);
	if (portfolios.length > 0) {
		await parallelize(
			portfolios.map((portfolio) => async () => {
				//TODO: api to do bulk check on composition
				//handle promise all
				const instrumentsExcluded = await verifySomeInstrumentsAreExcluded(portfolio, entity);
				instrumentBuilder.setInstrument(portfolio.rowId, {
					...portfolio,
					someInstrumentsAreExcluded: instrumentsExcluded.length > 0,
					nOfInstrumentExcluded: instrumentsExcluded.length,
				});
			}),
			{ concurrency: 3 },
		);
	}
}

/**
 * generate key for instrument
 * @param instrument
 * @returns
 */
export function instrumentEntryKey(instrument: ReviewTicker): string {
	return `${instrument.ticker}-${instrument.tickerId}-${instrument.isin}`;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useCompositionToolBar() {
	const { t } = useTranslation();
	return useMemo(
		() =>
			({
				delete: (params: { instrumentBuilder: InstrumentEditorBuilderProps; selected: MultiSelectCtx<string> }) => ({
					label: "Delete",
					icon: "Delete",
					onClick: () => {
						const rowsToDelete = params.selected.selection.values();
						params.instrumentBuilder.delete("hard", rowsToDelete);
						params.selected.reset();
					},
				}),
				softDelete: (params: {
					instrumentBuilder: InstrumentEditorBuilderProps;
					selected: MultiSelectCtx<string>;
				}) => ({
					label: "Delete",
					icon: "Delete",
					onClick: () => {
						const rowsToDelete = params.selected.selection.values();
						params.instrumentBuilder.delete("soft", rowsToDelete, true);
					},
				}),
				restoreDeleted: (params: {
					instrumentBuilder: InstrumentEditorBuilderProps;
					selected: MultiSelectCtx<string>;
				}) => {
					const deleted = params.instrumentBuilder.getDeleted();
					return {
						label: "Restore",
						icon: "restore",
						onClick: () => {
							const rowsToDelete = params.selected.selection.values();
							console.log(rowsToDelete);
							params.instrumentBuilder.delete("soft", rowsToDelete, false);
						},
						disabled: params.selected.selection.flatMap((rowId) => (deleted.has(rowId) ? [rowId] : [])).size === 0,
					};
				},
				compare: (params: { instrumentBuilder: InstrumentEditorBuilderProps; selected: MultiSelectCtx<string> }) => ({
					label: "Compare",
					icon: "compare",
					onClick: () => {
						const investments = params.selected.selection.flatMap((rowId) => {
							const instrument = params.instrumentBuilder.getInstrument(rowId);
							return instrument && instrument.proxyOverwriteType === "PORTFOLIO_MIXED" ? [instrument] : [];
						});
						params.instrumentBuilder.setInstrumentToCompare(Array.from(investments));
					},
					disabled: params.selected.selection.some(
						(rowId) => params.instrumentBuilder.getInstrument(rowId)?.proxyOverwriteType !== "PORTFOLIO_MIXED",
					),
				}),
				assingScore: (params: { onSubmit?(score: number | null): void }) => ({
					label: "Assign score",
					icon: "score",
					tooltip: {
						mode: "click",
						children: () => {
							return (
								<TooltipContext.Consumer>
									{(ctx) => (
										<div className="py-2 px-4 rounded">
											<Controller value={null}>
												{({ value, onChange }) => (
													<Row gap={8}>
														<NullableNumberInput value={value} onChange={onChange} size="small" />
														<Button
															onClick={() => {
																params.onSubmit?.(value);
																ctx?.onClose?.();
															}}
															size="small"
															palette="primary"
														>
															{t("BUTTON.CANCEL")}
														</Button>
													</Row>
												)}
											</Controller>
										</div>
									)}
								</TooltipContext.Consumer>
							);
						},
						hideArrow: true,
						align: "startToStart",
					},
				}),
				replaceTag: (params: { actions: ActionOrActionWithGroup<string>[] }) => ({
					label: "Edit tags",
					icon: "Edit",
					dropdown: {
						actions: params.actions,
						listboxAppearance: { classList: "max-h-[215px]" },
					},
				}),
				restoreTag: (params: {
					instrumentBuilder: InstrumentEditorBuilderProps;
					selected: MultiSelectCtx<string>;
				}) => ({
					label: "Restore tags",
					icon: "restore",
					onClick() {
						params.instrumentBuilder.bulkEditInstruments(params.selected.selection, (instrument) => ({
							...instrument,
							tagLabel: undefined,
						}));
					},
				}),
			}) satisfies Record<
				"delete" | "compare" | "softDelete" | "restoreDeleted" | "replaceTag" | "restoreTag" | "assingScore",
				(...params: any[]) => BatchAction<string> & DataAttributesProps
			>,
		[t],
	);
}

type HeaderActions = "addInstruments" | "addPortfolio" | "uploadInstruments" | "benchmarkTemplate";
const defaultInvestmentWeight = 10;
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useCompositionHeaderAction({
	instrumentBuilder,
	entity,
	uuid,
}: {
	instrumentBuilder: InstrumentEditorBuilderProps;
	entity: InstrumentEditorEntity;
	uuid: string | undefined;
}) {
	const compositionMap = instrumentBuilder.watchComposition();
	const composition = useMemo(() => Array.from(compositionMap.values()), [compositionMap]);

	const entityAndUuidOnMount = useRef({ entity, uuid });
	useEffect(() => {
		prefetchAvailableInstruments(entityAndUuidOnMount.current);
		prefetchAvailablePortfolios(entityAndUuidOnMount.current);
	}, []);

	return useMemo(
		() =>
			({
				addInstruments: () => ({
					label: "Add instruments",
					onClick: () =>
						spawnInstrumentAddersDialog({
							uuid,
							entity,
							instrumentsInComposition: composition,
							onSubmit(instruments) {
								const instrumentsWithRowId = instruments.map(
									(instrument): InstrumentEditorEntry => ({
										...instrument,
										rowId: instrumentEntryKey(instrument),
										weight: entity === "UNIVERSE" ? undefined : 0,
									}),
								);
								instrumentBuilder.bulkAddInstruments(instrumentsWithRowId);
							},
							onSubmitMissingInstrument(instrument) {
								const rowId = instrumentEntryKey({ ticker: instrument.identifier });
								if (!compositionMap.has(rowId)) {
									instrumentBuilder.addInstrument(rowId, {
										rowId,
										alias: instrument.identifier,
										weight: instrument.weight,
									});
								}
							},
						}),
					"data-qualifier": "CompositionEditor/HeaderAction/DropdownMenu(AddInstrument)",
				}),
				addPortfolio: () => ({
					label: "Add portfolio",
					onClick: () =>
						spawnPortfoliosAdderDialog({
							uuid,
							entity,
							instrumentsInComposition: composition,
							async onSubmitAsync(investments) {
								if (entity === "UNIVERSE") {
									const investmentsWithRowId = investments.map((investment): InstrumentEditorEntry => {
										const rowId = instrumentEntryKey({ ticker: investment.uuid });
										return {
											rowId,
											ticker: investment.uuid,
											identifier: "Portfolio",
											instrument: investment.name,
											proxyOverwriteType: "PORTFOLIO_MIXED",
											weight: undefined,
											investment,
										};
									});
									instrumentBuilder.bulkAddInstruments(investmentsWithRowId);
									return;
								}

								const verifiedInvestments = await parallelize(
									investments.map((investment) => async () => {
										const rowId = instrumentEntryKey({ ticker: investment.uuid });
										const removedInstruments = await verifySomeInstrumentsAreExcluded(
											{
												investment,
												weight: defaultInvestmentWeight,
												rowId,
											},
											entity,
										);
										return { rowId, removedInstruments };
									}),
									{ concurrency: 3 },
								);

								const verifiedInstrumentsWeight = Map<string, ReviewTicker[]>(
									verifiedInvestments.map((x) => [x.rowId, x.removedInstruments]),
								);

								const investmentsWithRowId = investments.map((investment): InstrumentEditorEntry => {
									const rowId = instrumentEntryKey({ ticker: investment.uuid });
									const instrumentsRemoved = verifiedInstrumentsWeight.get(rowId) ?? [];
									return {
										rowId,
										ticker: investment.uuid,
										identifier: "Portfolio",
										instrument: investment.name,
										proxyOverwriteType: "PORTFOLIO_MIXED",
										weight: 10,
										investment,
										someInstrumentsAreExcluded: instrumentsRemoved.length > 0,
										nOfInstrumentExcluded: instrumentsRemoved.length,
									};
								});
								instrumentBuilder.bulkAddInstruments(investmentsWithRowId);
							},
						}),
					"data-qualifier": "CompositionEditor/HeaderAction/DropdownMenu(AddPortfolio)",
				}),
				uploadInstruments: () => ({
					label: "Upload composition",
					onClick: () =>
						spawnInstrumentUploadDialogProps({
							entity,
							composition,
							onSubmit(newComposition) {
								instrumentBuilder.reset((innerBuilder) => ({
									composition: Map(
										newComposition.map((instrument): [string, InstrumentEditorEntry] => {
											const rowId = instrumentEntryKey(instrument);
											return [
												rowId,
												{
													...instrument,
													rowId,
													weight: instrument.weight ?? 0,
												},
											];
										}),
									),
									cash: innerBuilder.cash,
									decimalPlaces: innerBuilder.decimalPlaces,
									deleted: Set(),
									tags: innerBuilder.tags,
								}));
							},
						}),
					"data-qualifier": "CompositionEditor/HeaderAction/DropdownMenu(Upload)",
				}),
				benchmarkTemplate: (params: spawnBenchmarkTemplateDialogProps) => ({
					label: "Copy template",
					onClick: () => spawnBenchmarkTemplateDialog({ ...params }),
					"data-qualifer": "CompositionEditor/HeaderAction/DropdownMenu(CopyBenchmarkTemplate)",
				}),
			}) satisfies Record<
				HeaderActions,
				ActionOrActionWithGroup<string> | ((...params: any[]) => ActionOrActionWithGroup<string>)
			>,
		[composition, compositionMap, entity, instrumentBuilder, uuid],
	);
}

export type Tag = { label: string; color: string; value: string };
export type InstrumentEditorBuilderProps = {
	getTotal(key: Extract<keyof InstrumentEditorEntry, "score" | "weight">): number;
	getWeight(ticker: string): number | null;
	getDeleted(): Set<string>;
	getComposition(opts?: { excludeDeleted?: boolean }): Map<string, InstrumentEditorEntry>;
	watchComposition(): Map<string, InstrumentEditorEntry>;

	watchDeleted(): Set<string>;
	delete(mode: "soft", keys: string | Iterable<string>, toggle: boolean): InstrumentEditor;
	delete(mode: "hard", keys: string | Iterable<string>): InstrumentEditor;
	setWeight(ticker: string, newWeight?: number): InstrumentEditor;
	setInstrument(key: string, entry: InstrumentEditorEntry): InstrumentEditor;
	addInstrument(key: string, entry: InstrumentEditorEntry): InstrumentEditor;
	bulkAddInstruments(entry: InstrumentEditorEntry[]): InstrumentEditor;
	bulkEditInstruments(
		keys: Set<string>,
		provider: (entry: InstrumentEditorEntry) => InstrumentEditorEntry,
	): InstrumentEditor;
	getInstrument(key: string): InstrumentEditorEntry | undefined;

	watchTags(): Map<string, Tag>;
	setTag(key: string, tag: Tag): InstrumentEditor;
	deleteTag(key: string): InstrumentEditor;
	getCash(): InstrumentEditorEntry | undefined;

	normalise(distributeTo: Set<string>): InstrumentEditor;
	excessToCash(): InstrumentEditor;
	isInstrumentDeleted(id: string): boolean;
	watchComparedInstruments: () => Map<string, InstrumentEditorEntry>;
	setInstrumentToCompare(investments: InstrumentEditorEntry[]): InstrumentEditor;
	deleteComparedInstruments(keys: string | Iterable<string>): InstrumentEditor;
	getComparedInstrument(key: string): InstrumentEditorEntry | undefined;
	clearComparedInstruments(): InstrumentEditor;
	isDirty: boolean;

	reset(
		resetDataOrFn: MaybeFn<
			{
				composition: Map<string, InstrumentEditorEntry>;
				deleted?: Set<string>;
				cash?: InstrumentEditorEntry;
				decimalPlaces?: number;
				tags?: Map<string, Tag>;
				comparedInstruments?: Map<string, InstrumentEditorEntry>;
			},
			[
				{
					composition: Map<string, InstrumentEditorEntry>;
					deleted?: Set<string>;
					cash?: InstrumentEditorEntry;
					decimalPlaces?: number;
					tags?: Map<string, Tag>;
					comparedInstruments?: Map<string, InstrumentEditorEntry>;
				},
			]
		>,
		opt?: { disableToggleDirty?: boolean },
	): void;
};

type InstrumentBuilderInit = {
	composition: Map<string, InstrumentEditorEntry>;
	deleted?: Set<string>;
	cash?: InstrumentEditorEntry;
	decimalPlaces?: number;
	tags?: Map<string, Tag>;
	comparedInstruments?: Map<string, InstrumentEditorEntry>;
};
/**
 * A hook that creates a {@link InstrumentEditor composition builder} and wraps every direct operation that would
 * normally return a new instance in a React "setState".
 *
 * Example:
 * ```tsx
 * const compositionBuilder = useCompositionBuilder(() => ({
 * 	composition: Map<string, number>([["demo", 20]]),
 * }));
 *
 * return (
 * 	<Button onClick={() => compositionBuilder.setWeight("demo", 30)}>
 * 		Current weight: {compositionBuilder.getWeight("demo")?.toNumber() ?? '-'}
 * 	</Button>
 * );
 * ```
 *
 * @param initializer The initial value or a function that produces it.
 */
export function useInstrumentEditorBuilder(initializer: InstrumentBuilderInit): InstrumentEditorBuilderProps {
	const [builder, setBuilder] = useState(() => {
		const { composition, decimalPlaces, deleted, cash, tags, comparedInstruments } = initializer;
		return new InstrumentEditor(composition, deleted, cash, decimalPlaces, tags, comparedInstruments);
	});

	const [isDirty, setIsDirty] = useState(false);

	const latestCompositionBuilderRef = useRef(builder);
	const updateBuilder = useCallback((newBuilder: InstrumentEditor, opt?: { disableToggleDirty?: boolean }) => {
		if (!opt?.disableToggleDirty) {
			setIsDirty((prevStateDirty) => (prevStateDirty ? prevStateDirty : !prevStateDirty));
		}
		setBuilder(newBuilder);
		latestCompositionBuilderRef.current = newBuilder;
		return newBuilder;
	}, []);

	return useMemo(() => {
		noop(builder); // track to trigger re-renders
		return {
			setWeight: (...args) => updateBuilder(latestCompositionBuilderRef.current.setWeight(...args)),
			getWeight: (...args) => latestCompositionBuilderRef.current.getWeight(...args),
			excessToCash: (...args) => updateBuilder(latestCompositionBuilderRef.current.excessToCash(...args)),
			normalise: (...args) => updateBuilder(latestCompositionBuilderRef.current.normalise(...args)),
			getTotal: (...args) => latestCompositionBuilderRef.current.getTotal(...args),
			getComposition: (...args) => latestCompositionBuilderRef.current.getComposition(...args),
			setInstrument: (...args) => updateBuilder(latestCompositionBuilderRef.current.setInstrument(...args)),
			addInstrument: (...args) => updateBuilder(latestCompositionBuilderRef.current.addInstrument(...args)),
			bulkAddInstruments: (...args) => updateBuilder(latestCompositionBuilderRef.current.bulkAddInstruments(...args)),
			bulkEditInstruments: (...args) => updateBuilder(latestCompositionBuilderRef.current.bulkEditInstruments(...args)),
			getInstrument: (...args) => latestCompositionBuilderRef.current.getInstrument(...args),
			watchComposition: () => latestCompositionBuilderRef.current.composition,
			watchTags: () => latestCompositionBuilderRef.current.tags,
			deleteTag: (...args) => updateBuilder(latestCompositionBuilderRef.current.deleteTag(...args)),
			watchDeleted: () => latestCompositionBuilderRef.current.deleted,
			watchComparedInstruments: () => latestCompositionBuilderRef.current.comparedInstruments,
			getCash: () => latestCompositionBuilderRef.current.getCash(),
			setTag: (...args) => updateBuilder(latestCompositionBuilderRef.current.setTag(...args)),
			getDeleted: (...args) => latestCompositionBuilderRef.current.getDeleted(...args),
			isInstrumentDeleted: (...args) => latestCompositionBuilderRef.current.isInstrumentDeleted(...args),
			setInstrumentToCompare: (...args) =>
				updateBuilder(latestCompositionBuilderRef.current.setInstrumentToCompare(...args)),
			deleteComparedInstruments: (...args) =>
				updateBuilder(latestCompositionBuilderRef.current.deleteComparedInstruments(...args)),
			getComparedInstrument: (...args) => latestCompositionBuilderRef.current.getComparedInstrument(...args),
			clearComparedInstruments: (...args) =>
				updateBuilder(latestCompositionBuilderRef.current.clearComparedInstruments(...args)),
			delete: (
				...args:
					| [mode: "soft", keys: string | Iterable<string>, toggle: boolean]
					| [mode: "hard", keys: string | Iterable<string>]
			) => {
				if (args[0] === "hard") {
					return updateBuilder(latestCompositionBuilderRef.current.delete(...args));
				}
				return updateBuilder(latestCompositionBuilderRef.current.delete(...args));
			},
			reset(resetDataOrFn: MaybeFn<InstrumentBuilderInit, [InstrumentBuilderInit]>, opt) {
				if (typeof resetDataOrFn === "function") {
					const { composition, deleted, cash, decimalPlaces, comparedInstruments, tags } = resetDataOrFn(
						latestCompositionBuilderRef.current,
					);
					updateBuilder(
						new InstrumentEditor(composition, deleted, cash, decimalPlaces, tags, comparedInstruments),
						opt,
					);
					return;
				}

				const { composition, deleted, cash, decimalPlaces, comparedInstruments, tags } = resetDataOrFn;
				updateBuilder(new InstrumentEditor(composition, deleted, cash, decimalPlaces, tags, comparedInstruments), opt);
			},
			isDirty,
		};
	}, [builder, isDirty, updateBuilder]);
}

/**
 * A class that represents a collection of instruments that constitute a composition and exposes utility methods to interact with it.
 * A composition is the combination of tickers and their respective weights. When normalised, all the weights should
 * sum up to 100 (%).
 */
export class InstrumentEditor
	implements
		Omit<
			InstrumentEditorBuilderProps,
			"reset" | "watchComposition" | "watchTags" | "watchDeleted" | "watchComparedInstruments" | "isDirty"
		>
{
	composition: Map<string, InstrumentEditorEntry>;
	deleted: Set<string>;
	cash?: InstrumentEditorEntry;
	decimalPlaces: number;
	tags: Map<string, Tag>;
	comparedInstruments: Map<string, InstrumentEditorEntry>;
	constructor(
		composition: Map<string, InstrumentEditorEntry>,
		deleted?: Set<string>,
		cash?: InstrumentEditorEntry,
		decimalPlaces?: number,
		tags?: Map<string, Tag>,
		comparedInstruments?: Map<string, InstrumentEditorEntry>,
	) {
		this.composition = composition;
		this.deleted = deleted ?? Set();
		this.cash = cash;
		this.decimalPlaces = decimalPlaces ?? 2;
		this.tags = tags ?? Map();
		this.comparedInstruments = comparedInstruments ?? Map();
	}

	bulkEditInstruments(
		keys: Set<string>,
		provider: (entry: InstrumentEditorEntry) => InstrumentEditorEntry,
	): InstrumentEditor {
		const newComposition = this.composition.flatMap<string, InstrumentEditorEntry>((instrument) => {
			if (keys.has(instrument.rowId)) {
				return [[instrument.rowId, provider(instrument)]];
			}
			return [[instrument.rowId, instrument]];
		});

		return new InstrumentEditor(
			newComposition,
			this.deleted,
			this.cash,
			this.decimalPlaces,
			this.tags,
			this.comparedInstruments.clear(),
		);
	}

	clearComparedInstruments(): InstrumentEditor {
		return new InstrumentEditor(
			this.composition,
			this.deleted,
			this.cash,
			this.decimalPlaces,
			this.tags,
			this.comparedInstruments.clear(),
		);
	}

	getComparedInstrument(key: string): InstrumentEditorEntry | undefined {
		return this.comparedInstruments.get(key);
	}

	deleteComparedInstruments(keys: string | Iterable<string>): InstrumentEditor {
		return new InstrumentEditor(
			this.composition,
			this.deleted,
			this.cash,
			this.decimalPlaces,
			this.tags,
			typeof keys === "string" ? this.comparedInstruments.delete(keys) : this.comparedInstruments.deleteAll(keys),
		);
	}

	setInstrumentToCompare(investments: InstrumentEditorEntry[]): InstrumentEditor {
		const investmentsToCompare = Map(
			investments.flatMap((investment) => {
				const instrument = this.comparedInstruments?.has(investment.rowId);
				return instrument ? [] : [[investment.rowId, investment]];
			}),
		);

		return new InstrumentEditor(
			this.composition,
			this.deleted,
			this.cash,
			this.decimalPlaces,
			this.tags,
			this.comparedInstruments?.merge(investmentsToCompare),
		);
	}

	getCash(): InstrumentEditorEntry | undefined {
		return this.cash;
	}

	deleteTag(key: string): InstrumentEditor {
		return new InstrumentEditor(
			this.composition,
			this.deleted,
			this.cash,
			this.decimalPlaces,
			this.tags?.delete(key),
			this.comparedInstruments,
		);
	}

	setTag(key: string, tag: Tag): InstrumentEditor {
		return new InstrumentEditor(
			this.composition,
			this.deleted,
			this.cash,
			this.decimalPlaces,
			this.tags?.set(key, tag),
			this.comparedInstruments,
		);
	}

	setInstrument(key: string, entry: InstrumentEditorEntry): InstrumentEditor {
		return new InstrumentEditor(
			this.composition.set(key, entry),
			this.deleted,
			this.cash,
			this.decimalPlaces,
			this.tags,
			this.comparedInstruments,
		);
	}

	getInstrument(key: string): InstrumentEditorEntry | undefined {
		return this.composition.get(key);
	}

	addInstrument(key: string, entry: InstrumentEditorEntry): InstrumentEditor {
		const compositionArray = this.composition.toArray();
		compositionArray.unshift([key, entry]);
		return new InstrumentEditor(
			Map(compositionArray),
			this.deleted,
			this.cash,
			this.decimalPlaces,
			this.tags,
			this.comparedInstruments,
		);
	}

	bulkAddInstruments(instruments: InstrumentEditorEntry[]): InstrumentEditor {
		const instrumentsToAdd = instruments.flatMap<[string, InstrumentEditorEntry]>((instrument) => {
			return this.composition?.has(instrument.rowId) ? [] : [[instrument.rowId, instrument]];
		});
		const compositionArray = this.composition.toArray();
		compositionArray.unshift(...instrumentsToAdd);
		return new InstrumentEditor(
			Map(compositionArray),
			this.deleted,
			this.cash,
			this.decimalPlaces,
			this.tags,
			this.comparedInstruments,
		);
	}

	delete(mode: "soft", keys: string | Iterable<string>, toggle: boolean): InstrumentEditor;
	delete(mode: "hard", keys: string | Iterable<string>): InstrumentEditor;
	delete(mode: "hard" | "soft", keys: string | Iterable<string>, toggle?: boolean): InstrumentEditor {
		if (mode === "hard") {
			return new InstrumentEditor(
				typeof keys === "string" ? this.composition.delete(keys) : this.composition.deleteAll(keys),
				this.deleted,
				this.cash,
				this.decimalPlaces,
				this.tags,
				this.comparedInstruments,
			);
		}

		return new InstrumentEditor(
			this.composition,
			typeof keys === "string"
				? toggle
					? this.deleted.add(keys)
					: this.deleted.remove(keys)
				: toggle
				  ? this.deleted.union(keys)
				  : this.deleted.subtract(keys),
			this.cash,
			this.decimalPlaces,
			this.tags,
			this.comparedInstruments,
		);
	}

	setWeight(ticker: string, newWeight?: number): InstrumentEditor {
		const entry = this.composition.get(ticker);
		if (entry) {
			return new InstrumentEditor(
				this.composition.set(ticker, {
					...entry,
					weight: newWeight,
				}),
				this.deleted,
				this.cash,
				this.decimalPlaces,
				this.tags,
				this.comparedInstruments,
			);
		}

		return new InstrumentEditor(
			this.composition,
			this.deleted,
			this.cash,
			this.decimalPlaces,
			this.tags,
			this.comparedInstruments,
		);
	}

	getWeight(ticker: string): number | null {
		const weight = this.composition.get(ticker)?.weight;
		return weight ?? null;
	}

	excessToCash(): InstrumentEditor {
		if (this.cash === undefined) {
			console.warn("No cashId specified, cannot move excess to cash");
			return this;
		}
		if (BigNumber(this.getTotal("weight")).gt(100)) {
			console.warn("No excess to move to cash available, the total weight is already above 100");
			return this;
		}

		const totalExcludingCash = this.composition
			.delete(this.cash.rowId)
			.removeAll(this.deleted)
			.reduce((sum, cur) => sum.plus(BigNumber(cur.weight ?? 0)), BigNumber(0));

		return this.setInstrument(this.cash.rowId, {
			...this.cash,
			weight: BigNumber(100).minus(totalExcludingCash).decimalPlaces(this.decimalPlaces).toNumber(),
		});
	}

	normalise(distributeTo: Iterable<string>): InstrumentEditor {
		const totalOvershot = BigNumber(this.getTotal("weight")).minus(100).decimalPlaces(this.decimalPlaces);

		if (totalOvershot.eq(0)) {
			// Nothing to normalise.
			return this;
		}

		const editableTickerIds = Set(distributeTo).subtract(this.deleted);
		const totalWeightOfSelected = editableTickerIds.reduce(
			(sum, cur) => sum.plus(this.composition.get(cur)?.weight ?? BigNumber(0)),
			BigNumber(0),
		);
		if (totalWeightOfSelected.lt(totalOvershot)) {
			// Normalisation is impossible to achieve but we can approach it by setting all the weights to 0.
			return new InstrumentEditor(
				this.composition.withMutations((editableCompositionEntry) => {
					for (const tickerId of editableTickerIds) {
						const entry = editableCompositionEntry.get(tickerId);
						if (entry) {
							editableCompositionEntry.set(tickerId, { ...entry, weight: BigNumber(0).toNumber() });
						}
					}
				}),
				this.deleted,
				this.cash,
				this.decimalPlaces,
				this.tags,
				this.comparedInstruments,
			);
		}
		if (totalWeightOfSelected.eq(0)) {
			if (totalOvershot.gt(0)) {
				// Normalisation is impossible to achieve with the current selection.
				console.warn(
					"Cannot normalise: the current selection has a total weight of 0 and we have to reduce the total, therefore by proceeding we would have to set negative weights, which are not allowed.",
				);
				return this;
			}
			const newWeightsTmp = this.composition.withMutations((editableCompositionEntry) => {
				const partialWeight = totalOvershot.times(-1).div(editableTickerIds.size).decimalPlaces(this.decimalPlaces);
				for (const tickerId of editableTickerIds) {
					const entry = editableCompositionEntry.get(tickerId);
					if (entry) {
						editableCompositionEntry.set(tickerId, { ...entry, weight: partialWeight.toNumber() });
					}
				}
			});

			const remainingOvershot = newWeightsTmp
				.removeAll(this.deleted)
				.reduce((sum, cur) => sum.plus(BigNumber(cur.weight ?? 0)), BigNumber(0))
				.minus(100)
				.decimalPlaces(this.decimalPlaces);

			const newWeights = newWeightsTmp.update(editableTickerIds.first(), (entry) => {
				const weight = entry?.weight;
				if (!weight) {
					return entry;
				}
				return { ...entry, weight: BigNumber(weight).minus(remainingOvershot).toNumber() };
			});

			return new InstrumentEditor(
				newWeights,
				this.deleted,
				this.cash,
				this.decimalPlaces,
				this.tags,
				this.comparedInstruments,
			);
		}

		const newWeightsTmp = this.composition.withMutations((editableCompositionEntry) => {
			for (const tickerId of editableTickerIds) {
				const entry = editableCompositionEntry.get(tickerId);
				if (entry) {
					editableCompositionEntry.set(tickerId, {
						...entry,
						weight: BigNumber(entry?.weight ?? 0)
							.minus(
								BigNumber(entry?.weight ?? 0)
									.div(totalWeightOfSelected)
									.times(totalOvershot),
							)
							.decimalPlaces(this.decimalPlaces)
							.toNumber(),
					});
				}
			}
		});
		const remainingOvershot = newWeightsTmp
			.removeAll(this.deleted)
			.reduce((sum, cur) => sum.plus(cur.weight ?? BigNumber(0)), BigNumber(0))
			.minus(100)
			.decimalPlaces(this.decimalPlaces);
		const newWeights = newWeightsTmp.update(editableTickerIds.first(), (entry) => {
			const weight = entry?.weight;
			if (!weight) {
				return entry;
			}
			return { ...entry, weight: BigNumber(weight).minus(remainingOvershot).toNumber() };
		});

		return new InstrumentEditor(
			newWeights,
			this.deleted,
			this.cash,
			this.decimalPlaces,
			this.tags,
			this.comparedInstruments,
		);
	}

	getTotal(key: Extract<keyof InstrumentEditorEntry, "score" | "weight">): number {
		const compositionWithoutDeleted = this.composition.removeAll(this.deleted);
		return compositionWithoutDeleted.reduce((sum, cur) => sum.plus(cur[key] ?? BigNumber(0)), BigNumber(0)).toNumber();
	}

	getTotalWeightWithoutCash(): number {
		if (this.cash === undefined) {
			return this.getTotal("weight");
		}
		const compositionWithoutDeleted = this.composition.removeAll(this.deleted).delete(this.cash.rowId);
		return compositionWithoutDeleted
			.reduce((sum, cur) => sum.plus(cur.weight ?? BigNumber(0)), BigNumber(0))
			.toNumber();
	}

	getDeleted(): Set<string> {
		return this.deleted;
	}

	isInstrumentDeleted(id: string): boolean {
		return this.deleted.has(id);
	}

	getComposition(opts?: { excludeDeleted?: boolean }): Map<string, InstrumentEditorEntry> {
		if (opts?.excludeDeleted) {
			return this.composition.removeAll(this.deleted);
		}
		return this.composition;
	}
}
