import type { ReviewTicker, RichTicker } from "$root/api/api-gen";
import { DefaultTagLabels, ReferenceUniversesControllerApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { hasAccess } from "$root/components/AuthorizationGuard";
import { CustomLabels } from "$root/components/CustomLabels";
import { IconWalls, WallOverlay } from "$root/components/IconWall";
import { typedUrlForRoute } from "$root/components/PlatformRouter/RoutesDef";
import { TagBadge } from "$root/components/tags/TagBadge";
import { useEventBus } from "$root/event-bus";
import {
	instrumentColumnsMetadataByKey,
	useInstrumentColumnsTableV2,
	useSearchableInstrumentTable,
} from "$root/functional-areas/instruments/hooks";
import { PortfolioStudioSettingTabEnum } from "$root/functional-areas/portfolio-studio-settings";
import { exportUniverse } from "$root/functional-areas/universe/export";
import { useUserValue } from "$root/functional-areas/user";
import { formatNumber } from "$root/localization/formatters";
import { PortfolioDetailsTabs } from "$root/pages/PortfolioDetails";
import { axiosExtract } from "$root/third-party-integrations/axios";
import colorGenerator, { stableColorGenerator } from "$root/utils/chart/colorGenerator";
import { objMatchFn } from "$root/utils/objects";
import type { ContextContent } from "$root/utils/react-extra";
import { withContext } from "$root/utils/react-extra";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { noop } from "@mdotm/mdotui/utils";
import { UniverseContext } from "$root/widgets-architecture/contexts/universe";
import { InfoTooltip } from "$root/widgets-architecture/layout/WidgetsMapper/InfoTooltip";
import { useWidgetOptions } from "$root/widgets-architecture/layout/WidgetsMapper/context";
import type { TableColumn } from "@mdotm/mdotui/components";
import {
	ActionText,
	AutoSortTable,
	AutoTooltip,
	DropdownMenu,
	Icon,
	TableDataCell,
	TableHeadCell,
	TextInput,
} from "@mdotm/mdotui/components";
import { overrideClassName } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { builtInSortFnFor } from "@mdotm/mdotui/utils";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

function UniverseComposition({ universe: externalUniverse }: ContextContent<typeof UniverseContext>) {
	const universeUuid = externalUniverse?.uuid;
	const { t } = useTranslation();
	const referenceUniverseV4Api = useApiGen(ReferenceUniversesControllerApiFactory);
	const user = useUserValue();

	const universeQuery = useQueryNoRefetch(["universeCompositionWidget", externalUniverse], {
		enabled: Boolean(universeUuid),
		queryFn: async () => {
			if (!universeUuid) {
				throw new Error("unable to load a universe of undefined");
			}

			const { composition } = await axiosExtract(referenceUniverseV4Api.getUniverseComposition(universeUuid));
			return composition as ReviewTicker[];
		},
	});

	const { data: instrumentRows, isError, isLoading, refetch } = universeQuery;

	const { query, filtered, setQuery } = useSearchableInstrumentTable(instrumentRows ?? []);

	useEventBus("investment-update", {
		filter: objMatchFn({ uuid: universeUuid }),
		listener: () => {
			refetch().catch(noop);
		},
	});

	useWidgetOptions(
		() => ({
			isDraggable: false,
			title: t("UNIVERSE.READ_ONLY.NAME"),
			actionHeader: (
				<div className="flex space-x-2">
					<DropdownMenu
						position="bottom"
						align="startToEnd"
						trigger={({ innerRef, open, ...forward }) => (
							<button ref={innerRef} aria-expanded={open} type="button" {...forward}>
								<Icon icon="Dowload" color={themeCSSVars.MessageSeverity_success} size={20} />
							</button>
						)}
						actions={[
							{
								icon: "xls",
								onClickAsync: async () => {
									if (!universeUuid) {
										throw new Error("unable to load a universe of undefined");
									}
									await exportUniverse.downloadComposition(universeUuid);
								},
								label: "Instrument list",
							},
						]}
					/>
					<InfoTooltip>{t("UNIVERSE.READ_ONLY.DESCRIPTION")}</InfoTooltip>
				</div>
			),
		}),
		[t, universeUuid],
	);

	const mappedTags = useMemo(() => {
		const defaultLabels = Object.values(DefaultTagLabels) as string[];

		const tagsSet = new Set(
			defaultLabels.concat(
				instrumentRows?.filter(({ tagLabel }) => tagLabel).map((instument) => instument.tagLabel!) ?? [],
			),
		);

		return Array.from(tagsSet).reduce<{ [key: string]: string }>((acc, el) => {
			return {
				...acc,
				[el]: stableColorGenerator(el),
			};
		}, {});
	}, [instrumentRows]);

	const numberOfInstrumentTagged = useMemo(
		() => instrumentRows?.filter(({ tagLabel }) => tagLabel).length,
		[instrumentRows],
	);

	const commonCols = useInstrumentColumnsTableV2("UniverseComposition");
	const columns = useMemo<TableColumn<RichTicker>[]>(
		() => [
			{
				header: "Instrument name",
				content: (row, props) => {
					if (row.proxyOverwriteType !== "PORTFOLIO_MIXED") {
						return <TableDataCell {...props}>{row.instrument}</TableDataCell>;
					}

					return (
						<TableDataCell {...props}>
							<div className="flex flex-row items-center flex-nowrap w-full pr-4">
								<ActionText
									classList="inline-flex items-center gap-1"
									onClick={() =>
										window.open(
											typedUrlForRoute("PortfolioDetails", {
												portfolioUid: row.ticker ?? "",
												tab: PortfolioDetailsTabs.COMPOSITION,
											}),
											"_blank",
										)
									}
								>
									<span className="font-[weight:500] truncate items-center">{row.instrument ?? "-"}</span>
									<svg
										width="12"
										height="12"
										viewBox="0 0 12 12"
										fill="none"
										xmlns="http://www.w3.org/2000/svg"
										className="shrink-0"
									>
										<path
											d="M8 1.5H10.5V4"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M7 5L10.5 1.5"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
										<path
											d="M8.5 7.4375V9.625C8.5 10.1084 8.10844 10.5 7.625 10.5H2.375C1.89156 10.5 1.5 10.1084 1.5 9.625V4.375C1.5 3.89156 1.89156 3.5 2.375 3.5H4.5625"
											stroke="currentColor"
											strokeWidth="1.5"
											strokeLinecap="round"
											strokeLinejoin="round"
										/>
									</svg>
								</ActionText>
							</div>
						</TableDataCell>
					);
				},
				minWidth: 244,
				...instrumentColumnsMetadataByKey.instrument,
			},
			commonCols.identifier,
			commonCols.assetClass,
			commonCols.microAssetClass,
			{
				header: t("PORTFOLIOS.CONSTRAINTS_TARGETS.TABLE.TAGS"),
				content: ({ tagLabel }, props) => (
					<div
						style={props.style}
						className={overrideClassName("flex items-center", props.classList)}
						onClick={props.onClick}
					>
						{!tagLabel || mappedTags[tagLabel] === undefined ? (
							""
						) : (
							<TagBadge color={mappedTags[tagLabel]}>{tagLabel}</TagBadge>
						)}
					</div>
				),
				minWidth: 150,
				sortFn: builtInSortFnFor("tagLabel"),
				name: "tagLabel",
				footer: numberOfInstrumentTagged
					? `Total instruments tagged ${numberOfInstrumentTagged}`
					: "No instruments tagged",
			},
			{
				header: (props) => (
					<TableHeadCell {...props}>
						{universeUuid ? <CustomLabels labelKey={`${universeUuid}_score1`} fallback={t("SCORE")} /> : t("SCORE")}
					</TableHeadCell>
				),
				content: ({ score }) => (score ? formatNumber(score) : ""),
				sortFn: builtInSortFnFor("score"),
				name: "score",
				align: "end",
				minWidth: 150,
				cellClassList: "tabular-nums",
				hidden: !hasAccess(user, { requiredService: "CUSTOM_QUALITIES" }),
			},
			commonCols.commentary(),
		],
		[commonCols, t, numberOfInstrumentTagged, user, mappedTags, universeUuid],
	);

	return (
		<WallOverlay
			showOverlay={isLoading || isError}
			overlay={isError ? <IconWalls.ErrorData /> : <IconWalls.InfiniteProgress />}
		>
			<div className="flex justify-between mb-4">
				<div className="max-w-[500px] flex-1">
					<TextInput
						leftContent={<Icon icon="Search" />}
						value={query}
						onChangeText={setQuery}
						placeholder="Filter by instrument name, ISIN, asset class or micro asset class"
					/>
				</div>
				<div className="flex space-x-1 items-end">
					<ActionText
						color={themeCSSVars.palette_N400}
						href={typedUrlForRoute("PortfolioStudioSettings", {
							tab: PortfolioStudioSettingTabEnum.InstrumentsCustomisation,
							uuid: universeUuid,
						})}
					>
						Instruments customisation
					</ActionText>

					<AutoTooltip
						position="top"
						align="endToStart"
						mode="hover"
						overrideColor={themeCSSVars.palette_N400}
						trigger={({ innerRef }) => (
							<div ref={innerRef}>
								<Icon icon="info" color={themeCSSVars.palette_N400} size={18} />
							</div>
						)}
					>
						Explore all instruments used within your account, generate descriptions, and customize instrument details.
					</AutoTooltip>
				</div>
			</div>

			<AutoSortTable
				style={{ maxHeight: "calc(100dvh - 200px)" }}
				rows={filtered}
				columns={columns}
				noDataText="No instrument find in your universe"
			/>
		</WallOverlay>
	);
}

export const ReadonlyUniverseComposition = withContext(UniverseContext)(UniverseComposition);
