import type { InstrumentInsightsItem, MetricsList, ReportHorizon } from "$root/api/api-gen";
import { CommentaryTemplateControllerApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { LeavePromptBase } from "$root/components/LeavePrompt";
import RoundedButton from "$root/components/RoundedButton";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { FormController } from "$root/third-party-integrations/react-hook-form";
import { FormFields } from "$root/ui-lib/form/FormFields";
import { customObjectEntriesFn, customObjectValuesFn } from "$root/utils/experimental";
import { qualifier } from "$root/utils/qualifiers";
import { useQueryNoRefetch } from "$root/utils/react-query";
import {
	AsyncButton,
	Badge,
	Button,
	Collapsible,
	Column,
	Controller,
	DefaultCollapsibleContent,
	Dialog,
	DialogFooter,
	DialogHeader,
	Icon,
	NullableNumberInput,
	Row,
	Slider,
	Text,
	TextArea,
} from "@mdotm/mdotui/components";
import type { MaybePromise } from "@mdotm/mdotui/headless";
import { ForEach } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { nullary } from "@mdotm/mdotui/utils";
import { useMemo, type ReactNode } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import {
	defaultSelectableHorizonRealisedMetrics,
	selectableHorizonRealisedMetricsOptions,
	selectablePerformanceAnalysisHorizon,
	type AllowedModules,
	type StableConfiguration,
} from "./common";

export function SideHeaderContent(props: {
	title: string;
	description: string;
	onCancel?(): void;
	onSubmit(): MaybePromise<void>;
}): JSX.Element {
	const { t } = useTranslation();
	return (
		<div className={`border-b border-solid border-b-[color:${themeCSSVars.palette_N100}] `}>
			<div className="flex items-center px-4 pt-5 pb-2">
				<Text type="Body/XL/Bold" as="p" classList="grow h-fit line-clamp-2">
					{props.title}
				</Text>
				<div className="flex justify-end space-x-2 bg-white items-center">
					<Button
						size="small"
						palette="tertiary"
						onClick={props.onCancel}
						data-qualifier={qualifier.storyfolio.component.sideHeaderContent.cancel}
					>
						{t("BUTTON.CANCEL")}
					</Button>
					<AsyncButton
						size="small"
						palette="primary"
						onClickAsync={props.onSubmit}
						data-qualifier={qualifier.storyfolio.component.sideHeaderContent.submit}
					>
						{t("BUTTON.APPLY")}
					</AsyncButton>
				</div>
			</div>
			<Text type="Body/M/Book" classList="px-4 pb-4" color={themeCSSVars.palette_N700} as="p">
				{props.description}
			</Text>
		</div>
	);
}

export function SideBarContent(props: {
	title: string;
	description: string;
	onSubmit(): MaybePromise<void>;
	onCancel(): void;
	children: ReactNode;
}): JSX.Element {
	// bg-[color:${themeCSSVars.palette_N20}]
	return (
		<div className="grow bg-white overflow-y-auto pb-6">
			<SideHeaderContent
				title={props.title}
				description={props.description}
				onSubmit={props.onSubmit}
				onCancel={props.onCancel}
			/>
			<div className={`px-4 pt-4 border-t border-solid border-t-[color:${themeCSSVars.palette_N100}]`}>
				{props.children}
			</div>
		</div>
	);
}

type CommonModuleProps<K extends AllowedModules> = {
	onCancel(): void;
	module: StableConfiguration[K];
	onChange(module: StableConfiguration[K]): void;
};
const activerRoundedButtonClass = `!bg-[${themeCSSVars.palette_P100}] !text-[${themeCSSVars.palette_P600}]`;
export function ModuleStylingBlock(props: {
	description: string | undefined;
	onChange(description?: string): void;
}): JSX.Element {
	const commentaryTemplateApi = useApiGen(CommentaryTemplateControllerApiFactory);
	const { isFetching, data } = useQueryNoRefetch(["loadModuleStyling"], {
		queryFn: () => axiosExtract(commentaryTemplateApi.getModuleStyling()),
	});
	return (
		<>
			<div className="flex gap-2 items-center mb-2">
				<Text type="Body/L/Bold" as="p">
					Module Styling
				</Text>
				<Badge size="x-small" color="white" backgroundColor={themeCSSVars.palette_P500}>
					Beta
				</Badge>
			</div>

			<div className="flex space-x-2 mb-2">
				<RoundedButton
					size="x-small"
					disabled={isFetching || data === undefined}
					onClick={() => props.onChange(props.description === data?.max40Words ? undefined : data?.max40Words)}
					classList={{ [activerRoundedButtonClass]: props.description === data?.max40Words }}
					data-qualifier={qualifier.storyfolio.component.moduleStyling.summarize}
				>
					Summarize
				</RoundedButton>
				<RoundedButton
					size="x-small"
					disabled={isFetching || data === undefined}
					onClick={() => props.onChange(props.description === data?.max80Words ? undefined : data?.max80Words)}
					classList={{ [activerRoundedButtonClass]: props.description === data?.max80Words }}
					data-qualifier={qualifier.storyfolio.component.moduleStyling.extended}
				>
					Extended
				</RoundedButton>
				<RoundedButton
					size="x-small"
					disabled={isFetching || data === undefined}
					onClick={() => props.onChange(props.description === data?.tableFormat ? undefined : data?.tableFormat)}
					classList={{ [activerRoundedButtonClass]: props.description === data?.tableFormat }}
					data-qualifier={qualifier.storyfolio.component.moduleStyling.tableFormat}
				>
					Table format
				</RoundedButton>
				<RoundedButton
					size="x-small"
					onClick={() => props.onChange(props.description === "" ? undefined : "")}
					classList={{
						[activerRoundedButtonClass]:
							props.description === undefined || data === undefined
								? false
								: props.description === "" || customObjectValuesFn(data).every((x) => x !== props.description),
					}}
					data-qualifier={qualifier.storyfolio.component.moduleStyling.customPrompt}
				>
					Custom prompt
				</RoundedButton>
			</div>
			<Controller value={props.description} onChange={props.onChange}>
				{({ onChange, onCommit, value }) => (
					<TextArea
						rows={4}
						classList={{
							"[&>textarea]:resize-none overflow-y-auto": true,
						}}
						value={value}
						onChangeText={onChange}
						onBlur={nullary(onCommit)}
						data-qualifier={qualifier.storyfolio.component.moduleStyling.content}
					/>
				)}
			</Controller>
		</>
	);
}

function LeavePromptModule(props: { isDirty: boolean; children: ReactNode; onCancel?(): void }) {
	const { t } = useTranslation();
	return (
		<>
			<LeavePromptBase when={props.isDirty} pathToNotBlock={["/login"]}>
				{({ onCancelNavigation, onContinueNavigation, showPrompt }) => (
					<Dialog
						show={showPrompt}
						onClose={onCancelNavigation}
						header={
							<DialogHeader
								icon={<Icon icon="Icon-full-alert" color={themeCSSVars.MessageSeverity_warning} size={22} />}
							>
								You are about to leave the module customization
							</DialogHeader>
						}
						footer={
							<DialogFooter
								primaryAction={
									<Button
										onClick={() => {
											onContinueNavigation();
											props.onCancel?.();
										}}
										palette="primary"
									>
										{t("LEAVE")}
									</Button>
								}
								neutralAction={
									<Button onClick={onCancelNavigation} palette="tertiary">
										{t("STAY")}
									</Button>
								}
							/>
						}
					>
						Are your sure want to leave the module? All unsaved changes will be lost.
					</Dialog>
				)}
			</LeavePromptBase>
			{props.children}
		</>
	);
}

export function MarketOulookModule(props: CommonModuleProps<"marketOutlook">): JSX.Element {
	const { handleSubmit, formState, watch, setValue } = useForm({
		defaultValues: props.module,
	});

	return (
		<LeavePromptModule isDirty={formState.isDirty} onCancel={props.onCancel}>
			<SideBarContent
				title="#Market view"
				description="An overview of the market view associated with the portfolio referenced in the commentary."
				onSubmit={handleSubmit((params) =>
					props.onChange({
						...params,
						enabled: true,
					}),
				)}
				onCancel={props.onCancel}
			>
				<ModuleStylingBlock
					description={watch("moduleStyling")}
					onChange={(modules) => setValue("moduleStyling", modules, { shouldDirty: true })}
				/>
			</SideBarContent>
		</LeavePromptModule>
	);
}

export function PortfolioDynamicModule(props: CommonModuleProps<"dynamicsBlock">): JSX.Element {
	const { control, handleSubmit, formState, watch, setValue } = useForm({
		defaultValues: props.module,
	});

	return (
		<LeavePromptModule isDirty={formState.isDirty} onCancel={props.onCancel}>
			<SideBarContent
				title="#Portfolio dynamics"
				description="An analysis of portfolio changes, comparing current exposures with previous allocations and assessing their alignment with the market view."
				onSubmit={handleSubmit((params) =>
					props.onChange({
						...params,
						enabled: true,
					}),
				)}
				onCancel={props.onCancel}
			>
				<Text type="Body/M/Bold" as="p">
					Relevance threshold for for delta Micro Asset Class
				</Text>
				{/* <FormFields.Checkbox control={control} formState={formState} name="mainInstrument" classList="mb-1">
					Relevance threshold for for delta Micro Asset Class
				</FormFields.Checkbox> */}
				<FormController
					name="relevanceThreshold"
					control={control}
					render={({ field: { ref, ...controllerProps } }) => (
						<Slider
							min={0}
							max={100}
							step={0.01}
							disabled={!watch("mainInstrument")}
							{...controllerProps}
							input={{
								icon: "Percentile",
								innerRef: ref,
							}}
							data-qualifier={qualifier.storyfolio.modules.portfolioDynamics.relevanceThreshold}
						/>
					)}
				/>
				<div className={`mt-4 pt-4 -mx-4 border-t border-solid border-t-[color:${themeCSSVars.palette_N100}]`}>
					<div className="px-4">
						<ModuleStylingBlock
							description={watch("moduleStyling")}
							onChange={(modules) => setValue("moduleStyling", modules, { shouldDirty: true })}
						/>
					</div>
				</div>
			</SideBarContent>
		</LeavePromptModule>
	);
}

export function PortfolioCompositionModule(props: CommonModuleProps<"portfolioCompositionBlock">): JSX.Element {
	const { control, handleSubmit, formState, watch, setValue } = useForm({
		defaultValues: props.module,
	});

	return (
		<LeavePromptModule isDirty={formState.isDirty} onCancel={props.onCancel}>
			<SideBarContent
				title="#Portfolio Composition"
				description="A detailed breakdown of the current portfolio composition, emphasizing asset class allocation constraints and any relevant comparisons with the benchmark."
				onSubmit={handleSubmit((params) =>
					props.onChange({
						...params,
						enabled: true,
					}),
				)}
				onCancel={props.onCancel}
			>
				<Text type="Body/M/Bold" classList="mb-4" as="p">
					Content customization
				</Text>
				<FormFields.Checkbox
					control={control}
					formState={formState}
					name="commentOnBenchmark"
					classList="mb-1"
					data-qualifier={qualifier.storyfolio.modules.portfolioComposition.commentBenchmark}
				>
					Comment the benchmark
				</FormFields.Checkbox>
				<FormFields.Checkbox
					control={control}
					formState={formState}
					name="commentConstraint"
					classList="mb-1"
					data-qualifier={qualifier.storyfolio.modules.portfolioComposition.commentAssetClassConstrainnts}
				>
					Comment Asset Class constraints
				</FormFields.Checkbox>
				<FormFields.Checkbox
					control={control}
					formState={formState}
					name="showMainInstrumentForMacroAc"
					classList="mb-1"
					data-qualifier={qualifier.storyfolio.modules.portfolioComposition.highlightMainInstrumentMacroAssetClass}
				>
					Highlight main instrument for macro Asset Class
				</FormFields.Checkbox>

				<Text type="Body/M/Bold" classList="mt-4" color={themeCSSVars.palette_N500} as="p">
					Relevance threshold for Micro Asset Class commented
				</Text>
				<FormController
					name="relevanceThresholdForMicroAcCommented"
					control={control}
					render={({ field: { ref, ...controllerProps } }) => (
						<Slider
							min={0}
							max={100}
							step={0.01}
							{...controllerProps}
							input={{
								icon: "Percentile",
								innerRef: ref,
							}}
							data-qualifier={qualifier.storyfolio.modules.portfolioComposition.relevanceThresholdForMicroAcCommented}
						/>
					)}
				/>

				<div className={`mt-4 pt-4 -mx-4 border-t border-solid border-t-[color:${themeCSSVars.palette_N100}]`}>
					<div className="px-4">
						<ModuleStylingBlock
							description={watch("moduleStyling")}
							onChange={(modules) => setValue("moduleStyling", modules, { shouldDirty: true })}
						/>
					</div>
				</div>
			</SideBarContent>
		</LeavePromptModule>
	);
}

function makeInstrumentToupleRequired(data: InstrumentInsightsItem): Required<InstrumentInsightsItem> {
	return { enabled: data.enabled ?? false, threshold: data.threshold ?? 0 };
}

export function InstumentInsightsModule(props: CommonModuleProps<"instrumentInsightsBlock">): JSX.Element {
	const { control, handleSubmit, formState, watch, setValue } = useForm({
		defaultValues: {
			commentedInstrument: makeInstrumentToupleRequired(props.module.commentedInstrument),
			newInstrument: makeInstrumentToupleRequired(props.module.newInstrument),
			deletedInstrument: makeInstrumentToupleRequired(props.module.deletedInstrument),
			instrumentWithNegativeDeltaWeight: makeInstrumentToupleRequired(props.module.instrumentWithNegativeDeltaWeight),
			instrumentWithPositiveDeltaWeight: makeInstrumentToupleRequired(props.module.instrumentWithPositiveDeltaWeight),
			enabled: props.module.enabled,
			moduleStyling: props.module.moduleStyling,
			returnsOnSingleInstrument: props.module.returnsOnSingleInstrument,
		} satisfies Record<keyof typeof props.module, unknown>,
	});

	return (
		<LeavePromptModule isDirty={formState.isDirty} onCancel={props.onCancel}>
			<SideBarContent
				title="#Instrument insights"
				description="Key highlights of instruments bought or sold, with a focus on those that experienced significant changes."
				onSubmit={handleSubmit((params) =>
					props.onChange({
						...params,
						enabled: true,
					}),
				)}
				onCancel={props.onCancel}
			>
				<Text type="Body/M/Bold" classList="mb-4" as="p">
					Content customization
				</Text>
				<div className="flex items-center space-x-2 mb-1">
					<FormFields.Checkbox
						control={control}
						formState={formState}
						name="newInstrument.enabled"
						data-qualifier={qualifier.storyfolio.modules.instrumentInsights.maxNumOfAddedInstruments}
					>
						Max n. of added instruments
					</FormFields.Checkbox>
					<FormFields.NullableNumber
						name="newInstrument.threshold"
						control={control}
						label=""
						formState={formState}
						size="x-small"
						classList="w-[38px]"
						data-qualifier={qualifier.storyfolio.modules.instrumentInsights.maxNumOfAddedInstrumentsThreshold}
					/>
				</div>

				<div className="flex items-center space-x-2 mb-1">
					<FormFields.Checkbox
						control={control}
						formState={formState}
						name="deletedInstrument.enabled"
						data-qualifier={qualifier.storyfolio.modules.instrumentInsights.maxNumOfDeletedInstruments}
					>
						Max n. of deleted instruments
					</FormFields.Checkbox>
					<FormFields.NullableNumber
						name="deletedInstrument.threshold"
						control={control}
						label=""
						formState={formState}
						size="x-small"
						classList="w-[38px]"
						data-qualifier={qualifier.storyfolio.modules.instrumentInsights.maxNumOfDeletedInstrumentsThreshold}
					/>
				</div>

				<div className="flex items-center space-x-2 mb-1">
					<FormFields.Checkbox
						control={control}
						formState={formState}
						name="instrumentWithPositiveDeltaWeight.enabled"
						data-qualifier={qualifier.storyfolio.modules.instrumentInsights.maxNumOfPositiveDeltaInstruments}
					>
						Max n. of instruments with positive delta weights
					</FormFields.Checkbox>
					<FormFields.NullableNumber
						name="instrumentWithPositiveDeltaWeight.threshold"
						control={control}
						label=""
						formState={formState}
						size="x-small"
						classList="w-[38px]"
						data-qualifier={qualifier.storyfolio.modules.instrumentInsights.maxNumOfPositiveDeltaInstrumentsThreshold}
					/>
				</div>

				<div className="flex items-center space-x-2 mb-4">
					<FormFields.Checkbox
						control={control}
						formState={formState}
						name="instrumentWithNegativeDeltaWeight.enabled"
						data-qualifier={qualifier.storyfolio.modules.instrumentInsights.maxNumOfPositiveDeltaInstruments}
					>
						Max n. of instruments with negative delta weights
					</FormFields.Checkbox>
					<FormFields.NullableNumber
						name="instrumentWithNegativeDeltaWeight.threshold"
						control={control}
						label=""
						formState={formState}
						size="x-small"
						classList="w-[38px]"
						data-qualifier={qualifier.storyfolio.modules.instrumentInsights.maxNumOfPositiveDeltaInstruments}
					/>
				</div>

				<Text type="Body/M/Bold" classList="mb-4" as="p">
					Additional content
				</Text>

				<div className="flex items-center mb-4">
					<FormFields.Checkbox
						control={control}
						formState={formState}
						name="returnsOnSingleInstrument"
						data-qualifier={qualifier.storyfolio.modules.instrumentInsights.maxNumOfPositiveDeltaInstruments}
					>
						Realised 3M return
					</FormFields.Checkbox>
				</div>

				<div className={`mt-4 pt-4 -mx-4 border-t border-solid border-t-[color:${themeCSSVars.palette_N100}]`}>
					<div className="px-4">
						<ModuleStylingBlock
							description={watch("moduleStyling")}
							onChange={(modules) => setValue("moduleStyling", modules, { shouldDirty: true })}
						/>
					</div>
				</div>
			</SideBarContent>
		</LeavePromptModule>
	);
}

function getRealizedMetricHorizon(metric: MetricsList): ReportHorizon[] {
	return metric.realisedMetrics?.flatMap((x) => (x.enabled && x.metricHorizon ? [x.metricHorizon] : [])) ?? [];
}

function composeRealisedMetricsFromHorizon(realisedMetric: {
	enabled: boolean;
	horizons: ReportHorizon[];
}): MetricsList {
	const realisedMetrics = defaultSelectableHorizonRealisedMetrics.map(
		(opt): Required<MetricsList>["realisedMetrics"][number] => ({
			metricHorizon: opt.metricHorizon,
			enabled: realisedMetric.horizons.includes(opt.metricHorizon),
		}),
	);
	return { realisedMetrics, enabled: realisedMetric.enabled };
}

export function PortfolioMetricsModule(props: CommonModuleProps<"metricsBlock">): JSX.Element {
	const { t } = useTranslation();
	const { efficiencyRatio, maxDrawDown, performances, sortino, volatility, ...rest } = props.module;
	const { control, handleSubmit, formState, watch } = useForm({
		defaultValues: {
			...rest,
			efficiencyRatio: {
				enabled: efficiencyRatio.enabled ?? false,
				horizons: getRealizedMetricHorizon(efficiencyRatio),
			},
			maxDrawDown: {
				enabled: maxDrawDown.enabled ?? false,
				horizons: getRealizedMetricHorizon(maxDrawDown),
			},
			performances: {
				enabled: performances.enabled ?? false,
				horizons: getRealizedMetricHorizon(performances),
			},
			sortino: {
				enabled: sortino.enabled ?? false,
				horizons: getRealizedMetricHorizon(sortino),
			},
			volatility: {
				enabled: volatility.enabled ?? false,
				horizons: getRealizedMetricHorizon(volatility),
			},
		},
	});

	const remappedPortfolioMetrics = useMemo(
		() =>
			({
				RISK_CONSTRAINTS: [
					"riskBudgetOnVarVolatility",
					"riskBudgetTrackingError",
					"varVolatilityRiskConstraint",
					"trackingError",
				],
				REALISED_METRICS: ["performances", "volatility", "sortino", "maxDrawDown", "efficiencyRatio"],
				EX_ANTE_METRICS: [
					"exAnteAnnualizedReturn",
					"exAnteAnnualizedVolatility",
					"exAnteDiversificationRatio",
					"exAnteEfficiencyRatio",
					"exAnteMaxDrawDown",
					"exAnteVar",
					"exAnteVolatilityContribution",
				],
				FACTOR_EXPOSURE: ["riskFactors"],
			}) as const,
		[],
	);

	const observedMetrics = watch();
	const countOfSelectedMetrics = useMemo(() => {
		return customObjectEntriesFn(remappedPortfolioMetrics).reduce(
			(acc, item) => {
				const [group] = item;
				if (group === "REALISED_METRICS") {
					const selected = remappedPortfolioMetrics[group].reduce((counter, el) => {
						const realisedMetric = observedMetrics[el];
						if (!realisedMetric.enabled) {
							return counter;
						}

						return counter + realisedMetric.horizons.length;
					}, 0);
					acc[group] = selected;
					return acc;
				}
				const selected = remappedPortfolioMetrics[group].reduce(
					(counter, el) => counter + (observedMetrics[el] ? 1 : 0),
					0,
				);
				acc[group] = selected;
				return acc;
			},
			{
				RISK_CONSTRAINTS: 0,
				EX_ANTE_METRICS: 0,
				FACTOR_EXPOSURE: 0,
				REALISED_METRICS: 0,
			},
		);
	}, [remappedPortfolioMetrics, observedMetrics]);

	return (
		<LeavePromptModule isDirty={formState.isDirty} onCancel={props.onCancel}>
			<SideBarContent
				title="#Portfolio metrics"
				description="A summary of the key portfolio metrics."
				onSubmit={handleSubmit((params) => {
					const { efficiencyRatio, maxDrawDown, performances, sortino, volatility, ...rest } = params;
					props.onChange({
						...rest,
						enabled: true,
						efficiencyRatio: composeRealisedMetricsFromHorizon(efficiencyRatio),
						maxDrawDown: composeRealisedMetricsFromHorizon(maxDrawDown),
						performances: composeRealisedMetricsFromHorizon(performances),
						sortino: composeRealisedMetricsFromHorizon(sortino),
						volatility: composeRealisedMetricsFromHorizon(volatility),
					});
				})}
				onCancel={props.onCancel}
			>
				<Text type="Body/L/Bold" classList="mb-2" as="p">
					Select the elements you want to display
				</Text>

				<div className="flex flex-col gap-2 mb-2">
					<ForEach collection={customObjectEntriesFn(remappedPortfolioMetrics)}>
						{({ item: [group, items] }) => {
							if (group === "REALISED_METRICS") {
								return (
									<Collapsible
										header={`${t(`PORTFOLIO_METRICS_MODULE.${group}`)} ${
											countOfSelectedMetrics[group] ? `(${countOfSelectedMetrics[group]})` : ""
										}`}
									>
										{({ expand }) => (
											<DefaultCollapsibleContent expand={expand}>
												<ForEach collection={remappedPortfolioMetrics[group]}>
													{({ item: key }) => (
														<div>
															<div className="flex justify-between items-center mb-2">
																<FormFields.Checkbox
																	control={control}
																	formState={formState}
																	name={`${key}.enabled`}
																	classList="mb-1"
																>
																	{t(`PORTFOLIO_METRICS_MODULE.METRICS.${key}`)}
																</FormFields.Checkbox>
																<FormFields.Select
																	disabled={!watch(`${key}.enabled`)}
																	control={control}
																	formState={formState}
																	name={`${key}.horizons`}
																	multi
																	label=""
																	options={selectableHorizonRealisedMetricsOptions.map((horizon) => ({
																		...horizon,
																		label: t(`FORECAST_HORIZON.${horizon.label}`),
																	}))}
																	i18n={{ triggerPlaceholder: () => "Select time horizon" }}
																	size="small"
																	classList="w-[260px]"
																/>
															</div>
														</div>
													)}
												</ForEach>
											</DefaultCollapsibleContent>
										)}
									</Collapsible>
								);
							}
							return (
								<Collapsible
									header={`${t(`PORTFOLIO_METRICS_MODULE.${group}`)} ${
										countOfSelectedMetrics[group] ? `(${countOfSelectedMetrics[group]})` : ""
									}`}
								>
									{({ expand }) => (
										<DefaultCollapsibleContent expand={expand}>
											<ForEach collection={items}>
												{({ item: key }) => (
													<div>
														<FormFields.Checkbox control={control} formState={formState} name={key} classList="mb-1">
															{t(`PORTFOLIO_METRICS_MODULE.METRICS.${key}`)}
														</FormFields.Checkbox>
													</div>
												)}
											</ForEach>
										</DefaultCollapsibleContent>
									)}
								</Collapsible>
							);
						}}
					</ForEach>
				</div>
				<Text type="Body/L/Bold" classList="mb-2" as="p">
					Compare
				</Text>
				<FormFields.Checkbox control={control} formState={formState} name="compareWithBenchmark" classList="mb-1">
					Show benchmark
				</FormFields.Checkbox>
				{/* <div className={`mt-4 pt-4 -mx-4 border-t border-solid border-t-[color:${themeCSSVars.palette_N100}]`}>
					<div className="px-4">
						<ModuleStylingBlock
							description={watch("moduleStyling")}
							onChange={(modules) => setValue("moduleStyling", modules, { shouldDirty: true })}
						/>
					</div>
				</div> */}
			</SideBarContent>
		</LeavePromptModule>
	);
}

export function PerformanceAnalysisModule(props: CommonModuleProps<"performanceAnalysisBlock">): JSX.Element {
	const { t } = useTranslation();
	const { control, handleSubmit, formState, watch, setValue } = useForm({
		defaultValues: props.module,
	});
	const horizonOptions = useMemo(
		() =>
			selectablePerformanceAnalysisHorizon.map((horizon) => ({
				label: t(`FORECAST_HORIZON.${horizon.label}`),
				value: horizon.value,
			})),
		[t],
	);

	return (
		<LeavePromptModule isDirty={formState.isDirty} onCancel={props.onCancel}>
			<SideBarContent
				title="#Performance analysis"
				description="An evaluation of portfolio performance, highlighting key contributors and the impact of allocation and selection decisions."
				onSubmit={handleSubmit((params) =>
					props.onChange({
						...params,
						enabled: true,
					}),
				)}
				onCancel={props.onCancel}
			>
				<Column gap={8}>
					<Text type="Body/L/Bold" as="p">
						Content customization
					</Text>
					<FormFields.Select
						label="Performance"
						control={control}
						formState={formState}
						name="performanceHorizon"
						data-qualifier={qualifier.storyfolio.modules.performanceAnalysis.horizon}
						options={horizonOptions}
					/>
					<Text as="div" type="Body/M/Bold" color={themeCSSVars.palette_N700}>
						Add details
					</Text>
					<FormFields.Checkbox
						control={control}
						formState={formState}
						name="showAttribution"
						data-qualifier={qualifier.storyfolio.modules.performanceAnalysis.showAttribution}
					>
						Attribution
					</FormFields.Checkbox>
					<div className="border-b" style={{ borderColor: themeCSSVars.palette_N100 }} />
					<FormFields.Checkbox
						control={control}
						formState={formState}
						name="showContribution"
						data-qualifier={qualifier.storyfolio.modules.performanceAnalysis.showContribution}
						onChange={(x) => {
							if (x) {
								setValue("positiveContributionContributors.enabled", true);
								setValue("negativeContributionContributors.enabled", true);
							}
						}}
					>
						Contribution
					</FormFields.Checkbox>
					<Column classList="pl-4 max-w-[300px]" gap={12}>
						<Row alignItems="center" justifyContent="space-between">
							<FormFields.Checkbox
								control={control}
								formState={formState}
								name="positiveContributionContributors.enabled"
								disabled={!watch("showContribution")}
								data-qualifier={qualifier.storyfolio.modules.performanceAnalysis.showPositiveContributors}
								onChange={(x) => {
									if (!x && !watch("negativeContributionContributors.enabled")) {
										setValue("showContribution", false);
									}
								}}
							>
								Show n° of positive contributors
							</FormFields.Checkbox>
							<FormController
								control={control}
								name="positiveContributionContributors.number"
								render={({ field: { ref, ...controllerProps } }) => (
									<NullableNumberInput
										min={1}
										max={1000}
										size="x-small"
										innerRef={ref}
										classList="w-12"
										{...controllerProps}
										value={controllerProps.value ?? null}
										disabled={!watch("showContribution")}
										data-qualifier={qualifier.storyfolio.modules.performanceAnalysis.showNumberOfPositiveContributors}
									/>
								)}
							/>
						</Row>
						<Row alignItems="center" justifyContent="space-between">
							<FormFields.Checkbox
								control={control}
								formState={formState}
								name="negativeContributionContributors.enabled"
								disabled={!watch("showContribution")}
								data-qualifier={qualifier.storyfolio.modules.performanceAnalysis.showNegativeContributors}
								onChange={(x) => {
									if (!x && !watch("positiveContributionContributors.enabled")) {
										setValue("showContribution", false);
									}
								}}
							>
								Show n° of negative contributors
							</FormFields.Checkbox>
							<FormController
								control={control}
								name="negativeContributionContributors.number"
								render={({ field: { ref, ...controllerProps } }) => (
									<NullableNumberInput
										min={1}
										max={1000}
										size="x-small"
										innerRef={ref}
										classList="w-12"
										{...controllerProps}
										value={controllerProps.value ?? null}
										disabled={!watch("showContribution")}
										data-qualifier={qualifier.storyfolio.modules.performanceAnalysis.showNumberOfNegativeContributors}
									/>
								)}
							/>
						</Row>
					</Column>
					<div className="border-b" style={{ borderColor: themeCSSVars.palette_N100 }} />
					<Text as="div" type="Body/M/Bold" color={themeCSSVars.palette_N700}>
						Attribution and contribution type of comment
					</Text>
					<FormFields.Checkbox
						control={control}
						formState={formState}
						name="qualitativeOnly"
						data-qualifier={qualifier.storyfolio.modules.performanceAnalysis.purelyQualitative}
					>
						Purely qualitative
					</FormFields.Checkbox>
					<div className={`mt-4 pt-4 -mx-4 border-t border-solid border-t-[color:${themeCSSVars.palette_N100}]`}>
						<div className="px-4">
							<ModuleStylingBlock
								description={watch("moduleStyling")}
								onChange={(modules) => setValue("moduleStyling", modules, { shouldDirty: true })}
							/>
						</div>
					</div>
				</Column>
			</SideBarContent>
		</LeavePromptModule>
	);
}
