import type { InvestmentExposureResponse, InvestmentSummary } from "$root/api/api-gen";
import { EntityEditorControllerApiFactory, InvestmentReportsControllerApiFactory } from "$root/api/api-gen";
import { reportPlatformError } from "$root/api/error-reporting";
import { useApiGen } from "$root/api/hooks";
import type { Crumb } from "$root/components/Breadcrumbs";
import MonitorWithHourglass2 from "$root/components/glyphs/MonitorWithHourglass2";
import { PageHeader } from "$root/components/PageHeader";
import { typedUrlForRoute, useTypedNavigation } from "$root/components/PlatformRouter/RoutesDef";
import { ReactQueryWrapperBase } from "$root/components/ReactQueryWrapper";
import { useEventBus } from "$root/event-bus";
import type { CompareDataItem } from "$root/functional-areas/compare-portfolio/CompareOverlay";
import { CompareOverlay } from "$root/functional-areas/compare-portfolio/CompareOverlay";
import {
	covertInstrumentEditorToReviewTicker,
	instrumentEntryKey,
	useInstrumentEditorBuilder,
} from "$root/functional-areas/instruments-editor/builder";
import type { InstrumentEditorEntry } from "$root/functional-areas/instruments-editor/const";
import InstrumentCompostionEditor from "$root/functional-areas/instruments-editor/instrumentEditor";
import ExposureSankeyBlock from "$root/functional-areas/instruments-editor/InstrumentExposureSankey";
import { platformToast } from "$root/notification-system/toast";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { trackMixPanelEvent } from "$root/third-party-integrations/initMixPanel";
import {
	INVESTMENT_INSTRUMENT_LIMIT,
	MIN_NUMBER_OF_INSTRUMENTS,
	MIN_NUMBER_OF_INVESTMENT_INSTRUMENTS,
} from "$root/utils/const";
import { objMatchFn } from "$root/utils/objects";
import { parallelize } from "$root/utils/promise";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { useSearchParams } from "$root/utils/react-router-extra";
import { ActionText, Button, Icon, Modal, ProgressBar } from "@mdotm/mdotui/components";
import type { AxiosError } from "axios";
import { Map } from "immutable";
import { useMemo, useState } from "react";
import { flushSync } from "react-dom";
import { useTranslation } from "react-i18next";
import { manualCreationDraftFactory, manualCreationSaveFactory } from "../../ManualCreation/handler";
import type { ManualCreationFormProps } from "./spawn-createPortfolio";
import { spawnSubmitManualCreationDialog } from "./spawn-createPortfolio";
import { LeavePrompt } from "$root/components/LeavePrompt";
import { hasAccess } from "$root/components/AuthorizationGuard";
import { useUserValue } from "$root/functional-areas/user";

const crumbs = {
	portfolioStudio: {
		children: "Portfolio studio",
	},
	portolioList: {
		children: "Portfolio",
		href: typedUrlForRoute("PortfoliosStudio", {}),
	},
	portolioName: (summary: InvestmentSummary) => ({
		children: summary.name ?? "...",
	}),
	newPortfolio: {
		children: "New portfolio",
	},
} satisfies Record<string, Crumb | ((...params: any[]) => Crumb)>;

async function submitWithTryCath(callback: () => Promise<void>) {
	try {
		await callback();
	} catch (error) {
		const e = error as AxiosError<{ message?: string }>;
		const message = e.response?.data.message;

		if (message === "maxValue should be greater or equal to 0.02") {
			platformToast({
				children: "Please review your instrument, minimum weight acceptable is 0.02",
				severity: "error",
				icon: "Portfolio",
			});
		}

		if (message !== "maxValue should be greater or equal to 0.02") {
			platformToast({
				children: "Something went wrong, please try later",
				severity: "error",
				icon: "Portfolio",
			});
		}

		reportPlatformError(error, "ERROR", "portfolio", { message: "unable to create portfolio from upload editor" });
		throw error;
	}
}

function generateDefaultManualCreation(summary: InvestmentSummary): ManualCreationFormProps {
	return {
		baseCurrency: summary.baseCurrency,
		name: summary.name,
		primaryBenchmark:
			summary.primaryBenchmarkIdentifier && summary.primaryBenchmarkType
				? {
						benchmarkIdentifier: summary.primaryBenchmarkIdentifier,
						benchmarkType: summary.primaryBenchmarkType,
				  }
				: undefined,
		investmentReference: {
			referenceIdentifier: summary.referenceIdentifier,
			referenceType: summary.referenceType,
		},
	};
}

function ManualCreation(): JSX.Element {
	const { uuid, preselectedInvestments } = useSearchParams<"uuid" | "preselectedInvestments">();

	const [waitinModalState, setWaitingModalState] = useState<{ show: boolean; uuid?: string }>({ show: false });
	const [expandExposure, setExpandExposure] = useState(false);
	const [pathToNotBlock, setPathToNotBlock] = useState<string[]>([typedUrlForRoute("Login", {})]);

	const { t } = useTranslation();
	const { push } = useTypedNavigation();
	const user = useUserValue();

	const investmentReportApi = useApiGen(InvestmentReportsControllerApiFactory);
	const entityEditorApi = useApiGen(EntityEditorControllerApiFactory);

	const querySummary = useQueryNoRefetch(["queryInvestmentSummary"], {
		enabled: uuid !== undefined,
		queryFn: () => axiosExtract(investmentReportApi.getInvestmentSummary(uuid!)),
	});

	const { data: summary } = querySummary;
	const instrumentBuilder = useInstrumentEditorBuilder({ composition: Map() });

	const preselectedInvestmentsToArray = useMemo(
		() => (preselectedInvestments ? preselectedInvestments?.split(",") : []),
		[preselectedInvestments],
	);

	const queryInitManualCreation = useQueryNoRefetch(["queryInitManualPortfolioCreation", uuid], {
		queryFn: async () => {
			const editorResponse = await (uuid
				? axiosExtract(entityEditorApi.getEditorEditComposition(uuid, "INVESTMENT_DRAFT"))
				: axiosExtract(entityEditorApi.getEditorNewComposition("INVESTMENT")));

			const { composition } = editorResponse;
			const nonNullableComposition = composition ?? [];

			if (preselectedInvestmentsToArray.length > 0) {
				preselectedInvestmentsToArray.forEach((investmentUuid) => {
					nonNullableComposition.push({
						ticker: investmentUuid,
						proxyOverwriteType: "PORTFOLIO_MIXED",
					});
				});
			}

			let investmentSummaryMap = Map<string, InvestmentSummary>();
			let investmentExposureMap = Map<string, InvestmentExposureResponse>();
			const investments = nonNullableComposition.flatMap((instrument) => {
				if (instrument.ticker && instrument.proxyOverwriteType === "PORTFOLIO_MIXED") {
					return [instrument.ticker];
				}
				return [];
			});

			await parallelize(
				investments.map((investmentUuid) => async () => {
					const investmentSummary = await axiosExtract(investmentReportApi.getInvestmentSummary(investmentUuid));
					const exposure = await axiosExtract(
						investmentReportApi.getTwoLevelsInvestmentExposure(
							investmentSummary.uuid!,
							investmentSummary.primaryBenchmarkIdentifier!,
							"MACRO_ASSET_CLASS",
						),
					);

					investmentExposureMap = investmentExposureMap.set(investmentUuid, exposure);
					investmentSummaryMap = investmentSummaryMap.set(investmentUuid, investmentSummary);
				}),
				{ concurrency: 3 },
			);

			return {
				...editorResponse,
				composition: nonNullableComposition,
				investments,
				investmentSummaryMap,
				investmentExposureMap,
			};
		},
		onSuccess({ composition, cashTicker, investments, investmentSummaryMap, investmentExposureMap }) {
			if (investments.length === 0) {
				const compositionMap = Map(
					composition.map((instrument): [string, InstrumentEditorEntry] => {
						const rowId = instrumentEntryKey(instrument);
						return [rowId, { ...instrument, rowId }];
					}),
				);
				instrumentBuilder.reset(
					{
						composition: compositionMap,
						cash: cashTicker ? { ...cashTicker, rowId: instrumentEntryKey(cashTicker) } : undefined,
					},
					{ disableToggleDirty: true },
				);
				return;
			}

			const compositionMap = Map(
				composition.map((instrument): [string, InstrumentEditorEntry] => {
					const rowId = instrumentEntryKey(instrument);
					if (instrument.proxyOverwriteType === "PORTFOLIO_MIXED" && instrument.ticker) {
						const exposure = investmentExposureMap.get(instrument.ticker);
						const investmentSummary = investmentSummaryMap.get(instrument.ticker);
						return [
							rowId,
							{
								...instrument,
								rowId,
								investment: {
									nofInstruments: investmentSummary?.nofInstruments,
									macroAssetClassExposure: exposure?.investmentComposition,
									macroAssetClassExposureEnhanced: exposure?.enhancementComposition,
									name: investmentSummary?.name,
									lastActionNote: investmentSummary?.lastActionNote,
									uuid: investmentSummary?.uuid,
									action: investmentSummary?.action,
								},
								identifier: "Portfolio",
								instrument: investmentSummary?.name,
								weight: instrument.weight ?? 10,
							},
						];
					}
					return [rowId, { ...instrument, rowId }];
				}),
			);

			instrumentBuilder.reset(
				{
					composition: compositionMap,
					cash: cashTicker ? { ...cashTicker, rowId: instrumentEntryKey(cashTicker) } : undefined,
				},
				{ disableToggleDirty: true },
			);

			setExpandExposure(true);
		},
	});

	useEventBus("investment-update", {
		filter: objMatchFn({ uuid: waitinModalState.uuid }),
		listener: () => {
			flushSync(() => setWaitingModalState({ show: false }));
			if (waitinModalState.uuid) {
				push("PortfolioDetails", { portfolioUid: waitinModalState.uuid });
			}
		},
	});

	const comparedInvestments = useMemo((): CompareDataItem[] => {
		const investmentsCompared = instrumentBuilder
			.watchComposition()
			.filter((x) => instrumentBuilder.getComparedInstrument(x.rowId) !== undefined)
			.values();

		return Array.from(investmentsCompared).map((instrument) => ({
			id: instrument.rowId,
			composition:
				instrument.investment?.macroAssetClassExposure?.map((x) => ({
					quality: x.firstQualityLevel,
					weight: x.weight,
				})) ?? [],
			numberOfInstrument: instrument.investment?.nofInstruments ?? 0,
			portfolioName: instrument.investment?.name ?? "-",
			uuid: instrument.investment?.uuid,
			note: instrument.investment?.lastActionNote,
			action: instrument.investment?.action,
		}));
	}, [instrumentBuilder]);

	const compositionMap = instrumentBuilder.watchComposition();
	const compositionMapWithouthDeleteInstruments = compositionMap.removeAll(instrumentBuilder.getDeleted());
	const totalCompositionWeight = instrumentBuilder.getTotal("weight");

	const maxInstrumentsInComposition = hasAccess(user, { requiredService: "NUMBER_OF_INSTRUMENTS_CHECK_BYPASS" })
		? +Infinity
		: INVESTMENT_INSTRUMENT_LIMIT;

	const minInstrumentsInComposition = hasAccess(user, { requiredService: "NUMBER_OF_INSTRUMENTS_CHECK_BYPASS" })
		? MIN_NUMBER_OF_INSTRUMENTS
		: MIN_NUMBER_OF_INVESTMENT_INSTRUMENTS;

	const instrumentsInComposition = useMemo(
		() =>
			compositionMapWithouthDeleteInstruments.reduce((sum, instument) => {
				if (instument.proxyOverwriteType === "PORTFOLIO_MIXED") {
					const instrumentsInInvestment = instument.investment?.nofInstruments ?? 0;
					const instrumentsExcluded = instument.nOfInstrumentExcluded ?? 0;
					return instrumentsInInvestment - instrumentsExcluded + sum;
				}

				return sum + 1;
			}, 0),
		[compositionMapWithouthDeleteInstruments],
	);
	const cannotSubmit = useMemo(() => {
		return (
			totalCompositionWeight !== 100 ||
			instrumentsInComposition < minInstrumentsInComposition ||
			instrumentsInComposition > maxInstrumentsInComposition
		);
	}, [instrumentsInComposition, maxInstrumentsInComposition, minInstrumentsInComposition, totalCompositionWeight]);

	return (
		<>
			<PageHeader
				title="New portfolio"
				crumbs={[
					crumbs.portfolioStudio,
					crumbs.portolioList,
					summary ? crumbs.portolioName(summary) : crumbs.newPortfolio,
				]}
				titleAction={
					<div className="flex items-center space-x-2">
						<Button
							size="small"
							onClick={() => push("PortfoliosStudio", {})}
							palette="tertiary"
							data-qualifier="CompositionEditor/Cancel"
						>
							{t("BUTTON.CANCEL")}
						</Button>

						<Button
							size="small"
							onClick={() =>
								spawnSubmitManualCreationDialog(
									summary
										? {
												entity: "INVESTMENT_DRAFT",
												defaultManualCreation: generateDefaultManualCreation(summary),
												includesInvestments: instrumentBuilder
													.getComposition()
													.some((instrument) => instrument.proxyOverwriteType === "PORTFOLIO_MIXED"),
												onAsyncSubmit: async (form) => {
													await submitWithTryCath(async () => {
														const composition = covertInstrumentEditorToReviewTicker(
															Array.from(instrumentBuilder.getComposition().values()),
														);

														if (uuid && form.submitMode === "save-as-draft") {
															await manualCreationDraftFactory.updateInvestmentDraft({
																form,
																composition,
																uuid: uuid!,
															});
															return;
														}

														if (form.submitMode === "save-as-portfolio") {
															if (instrumentsInComposition < minInstrumentsInComposition) {
																platformToast({
																	children: `You must add at least ${minInstrumentsInComposition} instruments to your composition before submitting. Please include more instruments to proceed.`,
																	severity: "warning",
																	icon: "Portfolio",
																});
																return;
															}

															if (cannotSubmit) {
																platformToast({
																	children: `Unable to generate your Portfolio please review your composition`,
																	severity: "warning",
																	icon: "Portfolio",
																});
																return;
															}

															const portfolio = await manualCreationDraftFactory.createInvestment({
																form,
																composition,
																uuid: uuid!,
															});

															trackMixPanelEvent("Portfolio", {
																Type: "Upload",
																ID: portfolio.identifier ?? "",
																Name: form.name,
																Currency: form.baseCurrency ?? "",
																Benchmark: form.primaryBenchmark?.benchmarkIdentifier ?? "",
															});
															flushSync(() =>
																setPathToNotBlock([
																	typedUrlForRoute("PortfolioDetails", { portfolioUid: portfolio.identifier ?? "" }),
																]),
															);
															setWaitingModalState({ show: true, uuid: portfolio.identifier });
														}
													});
												},
										  }
										: {
												entity: "INVESTMENT",
												includesInvestments: instrumentBuilder
													.getComposition()
													.some((instrument) => instrument.proxyOverwriteType === "PORTFOLIO_MIXED"),
												onAsyncSubmit: async (form) => {
													await submitWithTryCath(async () => {
														const composition = covertInstrumentEditorToReviewTicker(
															Array.from(instrumentBuilder.getComposition().values()),
														);

														if (form.submitMode === "save-as-draft") {
															const { identifier } = await manualCreationSaveFactory.createInvestmentDraft({
																form,
																composition,
															});
															flushSync(() =>
																setPathToNotBlock([
																	typedUrlForRoute("Portfolios/ManualPortfolioCreation", { uuid: identifier }),
																]),
															);
															push("Portfolios/ManualPortfolioCreation", { uuid: identifier });
														}

														if (form.submitMode === "save-as-portfolio") {
															if (instrumentsInComposition < minInstrumentsInComposition) {
																platformToast({
																	children: `You must add at least ${minInstrumentsInComposition} instruments to your composition before submitting. Please include more instruments to proceed.`,
																	severity: "warning",
																	icon: "Portfolio",
																});
																return;
															}

															if (cannotSubmit) {
																platformToast({
																	children: `Unable to generate your Portfolio please review your composition`,
																	severity: "warning",
																	icon: "Portfolio",
																});
																return;
															}
															const portfolio = await manualCreationSaveFactory.createInvestment({ form, composition });
															trackMixPanelEvent("Portfolio", {
																Type: "Upload",
																ID: portfolio.identifier ?? "",
																Name: form.name,
																Currency: form.baseCurrency ?? "",
																Benchmark: form.primaryBenchmark?.benchmarkIdentifier ?? "",
															});
															flushSync(() =>
																setPathToNotBlock([
																	typedUrlForRoute("PortfolioDetails", { portfolioUid: portfolio.identifier ?? "" }),
																]),
															);
															setWaitingModalState({ show: true, uuid: portfolio.identifier });
														}

														platformToast({
															children: `Portfolio ${form.name} saved successfully`,
															severity: "success",
															icon: "Portfolio",
														});
													});
												},
										  },
								)
							}
							palette="primary"
							data-qualifier="CompositionEditor/Save"
						>
							{t("BUTTON.DONE")}
						</Button>
					</div>
				}
			/>
			<ReactQueryWrapperBase query={queryInitManualCreation}>
				{() => (
					<>
						<div className="flex h-[calc(100dvh_-_186px)]">
							{uuid ? (
								<InstrumentCompostionEditor
									entity="INVESTMENT_DRAFT"
									mode="edit"
									uuid={uuid}
									instrumentBuilder={instrumentBuilder}
									minThreshold={MIN_NUMBER_OF_INVESTMENT_INSTRUMENTS}
								/>
							) : (
								<InstrumentCompostionEditor
									entity="INVESTMENT"
									mode="new"
									instrumentBuilder={instrumentBuilder}
									minThreshold={MIN_NUMBER_OF_INVESTMENT_INSTRUMENTS}
								/>
							)}
							<ExposureSankeyBlock
								expand={expandExposure}
								onExpandChange={setExpandExposure}
								instrumentBuilder={instrumentBuilder}
							/>
						</div>

						<CompareOverlay
							show={comparedInvestments.length > 0}
							onClose={() => instrumentBuilder.clearComparedInstruments()}
							onRemove={(id) => instrumentBuilder.deleteComparedInstruments(id)}
							compareData={comparedInvestments}
						/>
					</>
				)}
			</ReactQueryWrapperBase>
			<WaitingDialog
				show={waitinModalState.show}
				isOnCloseDisabled={!waitinModalState.uuid}
				onClose={() => {
					if (waitinModalState.uuid) {
						push("PortfolioDetails", { portfolioUid: waitinModalState.uuid });
					}
				}}
			/>
			<LeavePrompt
				title={t("NOTIFICATION_SETTINGS.LEAVE_PROMPT.TITLE")}
				when={instrumentBuilder.isDirty}
				pathToNotBlock={pathToNotBlock}
			>
				{t("NOTIFICATION_SETTINGS.LEAVE_PROMPT.MESSAGE")}
			</LeavePrompt>
		</>
	);
}

type WaitingDialogProps = { show: boolean; onClose(): void; isOnCloseDisabled: boolean };
function WaitingDialog(props: WaitingDialogProps) {
	const { t } = useTranslation();

	return (
		<Modal show={props.show}>
			<div className="w-[560px] p-[21px] bg-white rounded shadow relative z-0">
				<div className="absolute top-0 right-0">
					<button
						type="button"
						aria-label="close"
						className="cursor-pointer p-5"
						disabled={props.isOnCloseDisabled}
						onClick={props.onClose}
					>
						<Icon icon="Close" classList="text-[16px]" />
					</button>
				</div>
				<div className="flex flex-col items-center text-center px-[43px] pb-[43px] pt-[22px] ">
					<div className="flex justify-center">
						<MonitorWithHourglass2 style={{ margin: "0 auto" }} />
					</div>
					<h2 className="font-bold text-[20px] leading-[24px] mb-[26px] whitespace-pre-line">
						{t("PORTFOLIO_UPLOAD_DIALOG.title")}
					</h2>
					<div className="flex justify-center mb-[26px]">
						<div className="w-[290px]">
							<ProgressBar value="indeterminate" classList="w-full" />
						</div>
					</div>
					<p className="text-[14px] leading-[20px] whitespace-pre-line">{t("PORTFOLIO_UPLOAD_DIALOG.description")}</p>
					<ActionText
						classList="block mt-1"
						disabled={props.isOnCloseDisabled}
						onClick={props.onClose}
						data-qualifier="CompositionEditor/WaitingDialog/SendToBackground"
					>
						{t("PORTFOLIO_UPLOAD_DIALOG.actionable")}
					</ActionText>
				</div>
			</div>
		</Modal>
	);
}

export default ManualCreation;
