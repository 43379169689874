import { hasAccess } from "$root/components/AuthorizationGuard";
import { switchExpr } from "@mdotm/mdotui/utils";
import { useMemo } from "react";
import { useHistory, useLocation } from "react-router";
import type { IUser } from "../user";
import { useUserValue } from "../user";
import ExplainabilitySettings from "./settings/ExplainabilitySettings";
import MarketViewSetting from "./settings/MarketViewSettings";
import { PageHeader } from "$root/components/PageHeader";
import InstrumentCommentarySetting from "./settings/InstrumentCommentarySetting";
import ReportCustomisation from "./settings/ReportCustomisation";
import { Card } from "$root/widgets-architecture/layout/Card";
import { typedObjectEntries } from "$root/utils/objects";
import { useTypedNavigation } from "$root/components/PlatformRouter/RoutesDef";
import { match } from "ts-pattern";
import { Crumb } from "$root/components/Breadcrumbs";
import useCrumbs from "$root/components/Crumbs/useCrumbs";

export const PortfolioStudioSettingTabEnum = {
	MarketViewSetting: "MarketViewSetting",
	ExplainabilitySettings: "ExplainabilitySettings",
	InstrumentsCustomisation: "InstrumentsCustomisation",
	ReportCustomisation: "ReportCustomisation",
} as const;

export type PortfolioStudioSettingTabEnum =
	(typeof PortfolioStudioSettingTabEnum)[keyof typeof PortfolioStudioSettingTabEnum];

const Tabs = {
	InstrumentsCustomisation: {
		label: "Instruments customisation",
		hasAccess: () => true,
	},
	MarketViewSetting: {
		label: "Market view settings",
		hasAccess: (user) => hasAccess(user, { requiredService: "CUSTOM_MARKET_VIEW_SETTINGS_EDITOR" }),
	},
	ExplainabilitySettings: {
		label: "Explainability options",
		hasAccess: (user) => hasAccess(user, { requiredService: "CUSTOM_MARKET_VIEW_SETTINGS_EDITOR" }),
	},
	ReportCustomisation: {
		label: "Report builder",
		hasAccess: (user) => hasAccess(user, { requiredService: "INVESTMENTS_REPORT_TEMPLATE_EDITOR" }),
	},
} satisfies Record<PortfolioStudioSettingTabEnum, { label: string; hasAccess?: (user: IUser) => boolean }>;

const StudioSettings = (): JSX.Element => {
	const location = useLocation();
	const user = useUserValue();
	const TABS = useMemo(() => {
		return typedObjectEntries(Tabs).flatMap(([key, x]) => {
			const guard = x.hasAccess;
			if (guard) {
				return guard(user) ? [key] : [];
			}

			return [key];
		});
	}, [user]);

	const { replace } = useTypedNavigation();
	const history = useHistory();
	const crumbs = useCrumbs();

	const currentTabName = useMemo(() => {
		const queryParamTab = new URLSearchParams(location.search).get("tab");
		const candidate = queryParamTab && TABS.find((v) => v === queryParamTab);
		if (!queryParamTab || !candidate) {
			replace("PortfolioStudioSettings", { tab: PortfolioStudioSettingTabEnum.InstrumentsCustomisation });
			return PortfolioStudioSettingTabEnum.InstrumentsCustomisation;
		}
		return candidate;
	}, [TABS, location.search, replace]);

	return (
		<>
			<PageHeader
				title={Tabs[currentTabName].label}
				crumbs={match(currentTabName)
					.returnType<Array<Crumb>>()
					.with("InstrumentsCustomisation", "MarketViewSetting", "ExplainabilitySettings", () => [
						crumbs.mySettings,
						crumbs.selectmySettingsArea({
							currentPath: history.location.pathname + history.location.search,
							onChange(url) {
								history.push(url);
							},
						}),
					])
					.with("ReportCustomisation", () => [
						crumbs.storyfolio,
						crumbs.selectStoryfolioBuilders({
							currentPath: history.location.pathname + history.location.search,
							onChange(url) {
								history.push(url);
							},
						}),
					])
					.exhaustive()}
			/>
			<Card>
				{switchExpr(currentTabName, {
					ExplainabilitySettings: () => <ExplainabilitySettings active />,
					MarketViewSetting: () => <MarketViewSetting active />,
					InstrumentsCustomisation: () => <InstrumentCommentarySetting active />,
					ReportCustomisation: () => <ReportCustomisation active />,
				})}
			</Card>
		</>
	);
};

export default StudioSettings;
