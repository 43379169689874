import { IconWalls } from "$root/components/IconWall";
import type { TableColumn } from "@mdotm/mdotui/components";
import { AutoSortHScrollTable, BatchActions, useSelectableTableColumn } from "@mdotm/mdotui/components";
import { useMemo } from "react";
import { useInstrumentCompositionColumns, useTagDropDownActions } from "../../columns";
import type { InstrumentEditorEntry } from "../../const";
import type { InstrumentCompostionEditorProps } from "../../instrumentEditor";
import InstrumentLimitReachedBanner from "../InstrumentLimitReachedBanner";
import { useCompositionToolBar } from "../../builder";

function BaseInstrumentTable({
	instrumentBuilder,
	filteredRows,
	rows,
	entity,
	limit,
}: InstrumentCompostionEditorProps & {
	filteredRows: InstrumentEditorEntry[];
	rows: InstrumentEditorEntry[];
}): JSX.Element {
	const flatInstrumentCompositionColumns = useInstrumentCompositionColumns();
	const toolbar = useCompositionToolBar();

	const selectableTableColumn = useSelectableTableColumn({
		rows,
		filteredRows,
		selectBy: (r) => r.rowId,
	});

	const composition = instrumentBuilder.watchComposition();
	const tagsMap = instrumentBuilder.watchTags();
	const tagOptions = useMemo(() => Array.from(tagsMap.values()), [tagsMap]);

	const taggedInstruments = useMemo(() => composition.filter((instrument) => instrument.tagLabel), [composition]);
	const scoredInstruments = useMemo(() => composition.filter((instrument) => instrument.score), [composition]);

	const columns = useMemo<Array<TableColumn<InstrumentEditorEntry>>>(
		() => [
			selectableTableColumn.column,
			flatInstrumentCompositionColumns.name({ disableTooltip: true }),
			flatInstrumentCompositionColumns.identifier(),
			{
				...flatInstrumentCompositionColumns.microAssetClass(),
				footerCellClassList: "font-semibold justify-end",
				footer: "Number of instrument",
			},
			{
				...flatInstrumentCompositionColumns.tag({
					options: tagOptions,
					onChange(row, newTag) {
						instrumentBuilder.setInstrument(row.rowId, { ...row, tagLabel: newTag });
					},
				}),
				footerCellClassList: "font-semibold",
				footer: `Tagged: ${taggedInstruments.size}`,
			},
			{
				...flatInstrumentCompositionColumns.score({
					onChange(row, newScore) {
						instrumentBuilder.setInstrument(row.rowId, { ...row, score: newScore ?? undefined });
					},
				}),
				footerCellClassList: "font-semibold",
				footer: `Scored: ${scoredInstruments.size}`,
			},
			flatInstrumentCompositionColumns.delete({
				onDelete: (rowId) => instrumentBuilder.delete("hard", rowId),
			}),
		],
		[
			flatInstrumentCompositionColumns,
			instrumentBuilder,
			selectableTableColumn.column,
			tagOptions,
			taggedInstruments.size,
			scoredInstruments.size,
		],
	);

	const tagDropDownActions = useTagDropDownActions({
		options: tagOptions,
		onChange(tagLabel) {
			instrumentBuilder.bulkEditInstruments(selectableTableColumn.multiSelectCtx.selection, (instrument) => ({
				...instrument,
				tagLabel,
			}));
		},
	});

	const actions = useMemo(
		() => [
			toolbar.delete({
				instrumentBuilder,
				selected: selectableTableColumn.multiSelectCtx,
			}),
			toolbar.replaceTag({ actions: tagDropDownActions }),
			toolbar.restoreTag({ instrumentBuilder, selected: selectableTableColumn.multiSelectCtx }),
			toolbar.assingScore({
				onSubmit(score) {
					instrumentBuilder.bulkEditInstruments(selectableTableColumn.multiSelectCtx.selection, (instrument) => ({
						...instrument,
						score: score ?? undefined,
					}));
				},
			}),
		],
		[instrumentBuilder, selectableTableColumn.multiSelectCtx, tagDropDownActions, toolbar],
	);

	return (
		<>
			<BatchActions
				total={rows.length}
				selected={selectableTableColumn.multiSelectCtx.selection.size}
				palette="tertiary"
				actions={actions}
				classList="py-4 h-[58px] shrink-0"
			/>
			<AutoSortHScrollTable
				columns={columns}
				rows={filteredRows}
				pinnedColumns={[
					{ name: "name", side: "left" },
					{ name: "delete", side: "right" },
				]}
				noDataText={<IconWalls.EditorEmptyData entity={entity} />}
				onRowClick={(row) => selectableTableColumn.toggle(row.rowId)}
				palette="uniform"
			/>
			<br />
			{limit && rows.length > limit && <InstrumentLimitReachedBanner limit={limit} />}
		</>
	);
}

export default BaseInstrumentTable;
