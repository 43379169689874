import type { EditorSelectablePortfoliosResponse, InvestmentListEntry } from "$root/api/api-gen";
import { EntityEditorControllerApiFactory } from "$root/api/api-gen";
import { DebouncedSearchInput } from "$root/components/DebouncedSearchInput";
import { IconWalls } from "$root/components/IconWall";
import { ReactQueryWrapperBase } from "$root/components/ReactQueryWrapper";
import { usePortfolioColumns } from "$root/pages/PortfoliosStudio/PortfolioList/columns";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { objectTextSearchMatchFns } from "$root/utils/strings";
import type { TableColumn } from "@mdotm/mdotui/components";
import {
	AutoSortTable,
	AutoTooltip,
	Button,
	Dialog,
	DialogFooter,
	SubmitButton,
	TableDataCell,
	TooltipContent,
	useSelectableTableColumn,
} from "@mdotm/mdotui/components";
import type { MaybePromise } from "@mdotm/mdotui/headless";
import { useSearchable } from "@mdotm/mdotui/headless";
import type { ClassList, SpawnResult } from "@mdotm/mdotui/react-extensions";
import { adaptAnimatedNodeProvider, spawn, toClassListRecord } from "@mdotm/mdotui/react-extensions";
import { themeCSSVars } from "@mdotm/mdotui/themes";
import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import type { InstrumentEditorEntity, InstrumentEditorEntry, MinimunDialogProps } from "../const";
import { Set } from "immutable";
import { Map } from "immutable";
import type { RequireProperty } from "$root/utils/types";
import { getApiGen } from "$root/api/factory";
import { reportPlatformError } from "$root/api/error-reporting";
import { withCache } from "$root/caching/with-cache";
import { minutes } from "$root/utils/time";

type HealtyInvestmentListEntry = RequireProperty<InvestmentListEntry, "uuid">;
type PortfoliosAdderDialogProps = {
	uuid?: string;
	onSubmitAsync(investments: Array<HealtyInvestmentListEntry>): MaybePromise<void>;
	instrumentsInComposition: InstrumentEditorEntry[];
	entity: InstrumentEditorEntity;
} & MinimunDialogProps;

function lowerRowOpacity(fn: (row: HealtyInvestmentListEntry, index: number) => ClassList | undefined) {
	return function inner(row: HealtyInvestmentListEntry, index: number): ClassList | undefined {
		return {
			...toClassListRecord(fn(row, index)),
			"opacity-60": !row.canUseAsMixedPortfolio,
		};
	};
}

function PortfoliosAdderDialog({
	show,
	onClose,
	onSubmitAsync,
	uuid,
	entity,
	instrumentsInComposition,
	onAnimationStateChange,
}: PortfoliosAdderDialogProps) {
	const [query, setQuery] = useState("");

	const { t } = useTranslation();

	const preSelectedInstruments = useMemo(
		() =>
			Set(
				instrumentsInComposition.flatMap((x) =>
					x.ticker && x.proxyOverwriteType === "PORTFOLIO_MIXED" ? [x.ticker] : [],
				),
			),
		[instrumentsInComposition],
	);

	const querySelectablePortfolios = useQueryNoRefetch(["querySelectablePortfolios", uuid], {
		queryFn: () => getAvailablePortfolios({ uuid, entity }),
	});

	const { healtyRows, rows } = useMemo<{
		rows: Array<HealtyInvestmentListEntry>;
		healtyRows: Array<HealtyInvestmentListEntry>;
	}>(() => {
		const selectablePortfolios = querySelectablePortfolios.data?.selectablePortfolios?.flatMap(
			(x): HealtyInvestmentListEntry[] => {
				const portfolioUuid = x.uuid;
				return portfolioUuid ? [{ ...x, uuid: portfolioUuid }] : [];
			},
		);

		if (!selectablePortfolios) {
			return { rows: [], healtyRows: [] };
		}

		const healtySelectable = selectablePortfolios?.filter((x) => x.canUseAsMixedPortfolio && x.uuid);
		return { rows: selectablePortfolios, healtyRows: healtySelectable };
	}, [querySelectablePortfolios]);

	const { filtered } = useSearchable({
		query,
		collection: rows,
		matchFn: objectTextSearchMatchFns.keyword,
	});

	const selectableRowIds = useMemo(
		() =>
			healtyRows.flatMap((healtyRow) =>
				healtyRow.uuid && !preSelectedInstruments.has(healtyRow.uuid) ? [healtyRow.uuid] : [],
			),
		[healtyRows, preSelectedInstruments],
	);

	const selectableTableColumn = useSelectableTableColumn({
		filteredRows: filtered,
		rows,
		selectBy: (row) => row.uuid ?? "",
		selectableRowIds,
		alwaysSelected: preSelectedInstruments,
	});

	const portfolioColumn = usePortfolioColumns();

	const column = useMemo<TableColumn<HealtyInvestmentListEntry, string>[]>(
		() => [
			selectableTableColumn.column,
			{
				...portfolioColumn.NAME(),
				content: (row, cellProps) => {
					if (!row.canUseAsMixedPortfolio && row.currentlyContainsNestedPortfolios) {
						return (
							<AutoTooltip
								mode="hover"
								align="startToStart"
								position="top"
								overrideColor={themeCSSVars.palette_N200}
								trigger={({ innerRef }) => (
									<TableDataCell {...cellProps}>
										<span ref={innerRef}>{row.name}</span>
									</TableDataCell>
								)}
							>
								<TooltipContent>
									<p className="text-center">
										You cannot add portfolios that already include other portfolios in their metrics and performance
										calculation
									</p>
								</TooltipContent>
							</AutoTooltip>
						);
					}

					return row.name;
				},
			},
			portfolioColumn.STATUS(),
			portfolioColumn.LAST_STATUS_UPDATE(),
		],
		[portfolioColumn, selectableTableColumn.column],
	);

	const onSubmitSelectedPortfolios = useCallback(
		async (list: RequireProperty<InvestmentListEntry, "uuid">[], ctx: Immutable.Set<string>) => {
			const selectedPortfolios = ctx.toArray();
			const selectableInstrumentMap = Map(
				list?.flatMap((portfolio) => (portfolio.uuid ? [[portfolio.uuid, portfolio]] : [])),
			);
			const portfolios = selectedPortfolios.flatMap((portfolioUuid) => {
				const selectedInstrument = selectableInstrumentMap.get(portfolioUuid);
				return selectedInstrument ? [selectedInstrument] : [];
			});
			await onSubmitAsync?.(portfolios);
		},
		[onSubmitAsync],
	);

	return (
		<Dialog
			size="xxlarge"
			noValidate
			show={show}
			onClose={onClose}
			header="Select"
			footer={
				<DialogFooter
					neutralAction={
						<Button palette="tertiary" onClick={onClose}>
							{t("BUTTON.CANCEL")}
						</Button>
					}
					primaryAction={
						<SubmitButton disabled={querySelectablePortfolios.isFetching}>{t("BUTTON.CONFIRM")}</SubmitButton>
					}
				/>
			}
			onAnimationStateChange={onAnimationStateChange}
			onSubmitAsync={() => onSubmitSelectedPortfolios(rows, selectableTableColumn.multiSelectCtx.selection)}
		>
			<ReactQueryWrapperBase query={querySelectablePortfolios}>
				{() => (
					<>
						<div className="mb-4">
							<DebouncedSearchInput
								query={query}
								onChange={setQuery}
								placeholder={placholderSearch}
								classList="max-w-[280px]"
								data-qualifier="PortfolioModal/Input/Search"
							/>
						</div>
						<AutoSortTable
							rows={filtered}
							rowClassList={lowerRowOpacity(selectableTableColumn.rowClassList)}
							columns={column}
							classList="max-h-[410px]"
							onRowClick={(row) => selectableTableColumn.toggle(row.uuid ?? "")}
							noDataText={<IconWalls.PortfolioListEmptyData />}
						/>
					</>
				)}
			</ReactQueryWrapperBase>
		</Dialog>
	);
}

const placholderSearch = "Search a portfolio";

export type SpawnPortfoliosAdderDialogProps = Omit<PortfoliosAdderDialogProps, "show" | "onClose">;
const getAvailablePortfolios = withCache(
	(params: {
		uuid: string | undefined;
		entity: Parameters<ReturnType<typeof EntityEditorControllerApiFactory>["getEditorNewSelectablePortfolios"]>[0];
	}): Promise<EditorSelectablePortfoliosResponse> => {
		const editorApi = getApiGen(EntityEditorControllerApiFactory);
		return axiosExtract(
			params.uuid
				? editorApi.getEditorEditSelectablePortfolios(params.uuid, params.entity)
				: editorApi.getEditorNewSelectablePortfolios(params.entity),
		);
	},
	{
		cacheTime: minutes(5),
		cacheKey: (params) => `getAvailablePortfolios(${JSON.stringify(params)})`,
	},
);

export function prefetchAvailablePortfolios(params: Parameters<typeof getAvailablePortfolios>[0]): void {
	getAvailablePortfolios(params).catch((err) =>
		reportPlatformError(err, "ERROR", "portfolio", {
			message: params.uuid
				? "retrieve instruments modal on edit compositon"
				: "retrieve instruments modal on new compositon",
			payload: JSON.stringify(params),
		}),
	);
}

export function spawnPortfoliosAdderDialog(props: SpawnPortfoliosAdderDialogProps): SpawnResult<void> {
	return spawn<void>(
		adaptAnimatedNodeProvider(({ show, resolve, onHidden }) => (
			<PortfoliosAdderDialog
				{...props}
				show={show}
				onAnimationStateChange={(state) => state === "hidden" && onHidden()}
				onClose={() => resolve()}
				onSubmitAsync={async (portfolio) => {
					await props.onSubmitAsync(portfolio);
					resolve();
				}}
			/>
		)),
	);
}
