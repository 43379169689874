import { minutes } from "$root/utils/time";

const cacheByKey = new Map<string, { expiresAt: number; contentPromise: Promise<unknown> }>();

export function withCache<T, TParams extends [unknown, ...unknown[]] | [] = []>(
	fn: (...params: TParams) => Promise<T>,
	opts: { cacheTime?: number; cacheKey: (...params: TParams) => string },
): (...params: TParams) => Promise<T> {
	return (...params) => {
		const cacheKey = opts.cacheKey(...params);
		const cache = cacheByKey.get(cacheKey);

		if (!cache || cache.expiresAt < Date.now()) {
			const contentPromise = fn(...params).catch((err) => {
				cacheByKey.delete(cacheKey);
				throw err;
			});

			cacheByKey.set(cacheKey, { expiresAt: Date.now() + (opts.cacheTime ?? minutes(5)), contentPromise });
			return contentPromise;
		}

		return cache.contentPromise as Promise<T>;
	};
}
