import type { EditorSaveEditOrReviewRequestPortfolioSavingModeEnum } from "$root/api/api-gen";
import { ReferenceUniversesControllerApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import type { MinimunDialogProps } from "$root/functional-areas/instruments-editor/const";
import { useDebouncedNameUniquenessChecker } from "$root/functional-areas/named-entities/uniqueness";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { FormFields } from "$root/ui-lib/form/FormFields";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, CircularProgressBar, Dialog, DialogFooter, SubmitButton } from "@mdotm/mdotui/components";
import type { MaybePromise } from "@mdotm/mdotui/headless";
import type { SpawnResult } from "@mdotm/mdotui/react-extensions";
import { adaptAnimatedNodeProvider, spawn } from "@mdotm/mdotui/react-extensions";
import { noop } from "@mdotm/mdotui/utils";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { errorMessageNameRequired } from "./handler";

type UniverseManualCreationFormProps = {
	name: string;
	portfolioSavingMode?: EditorSaveEditOrReviewRequestPortfolioSavingModeEnum;
};
export type SubmitUniverseCreationDialogProps = {
	onAsyncSubmit(payload: UniverseManualCreationFormProps): MaybePromise<void>;
} & MinimunDialogProps;
const defaultManualCreationData: UniverseManualCreationFormProps = { name: "" };
function SubmitUniverseCreationDialog(props: SubmitUniverseCreationDialogProps) {
	const { show, onClose, onAnimationStateChange, onAsyncSubmit } = props;

	const { t } = useTranslation();
	const referenceUniversesApi = useApiGen(ReferenceUniversesControllerApiFactory);

	const { checkIfNameIsAvailable, checkingNameUniqueness } = useDebouncedNameUniquenessChecker({
		isNameAvailableApi: (name, opts) => axiosExtract(referenceUniversesApi.isUniverseNameAvailable(name, opts)),
	});

	const { control, formState, handleSubmit } = useForm({
		defaultValues: defaultManualCreationData,
		resolver: zodResolver(
			z.object({
				name: z
					.string()
					.min(1, errorMessageNameRequired.UNIVERSE)
					.refine(checkIfNameIsAvailable, {
						message: t("MISSING_FIELD.NAME_NOT_AVAILABLE"),
					}),
			}),
		),
	});

	return (
		<Dialog
			size="medium"
			noValidate
			show={show}
			onClose={onClose}
			header="Universe name"
			onAnimationStateChange={onAnimationStateChange}
			footer={
				<DialogFooter
					neutralAction={
						<Button palette="tertiary" data-qualifier="CompositionEditor/Modal/Cancel" onClick={onClose}>
							{t("BUTTON.CANCEL")}
						</Button>
					}
					primaryAction={
						<SubmitButton data-qualifier="CompositionEditor/Modal/Save" onClick={noop}>
							{t("BUTTON.GENERATE")}
						</SubmitButton>
					}
				/>
			}
			onSubmitAsync={async () => {
				await handleSubmit((payload) => onAsyncSubmit(payload))();
			}}
		>
			<div className="grid gap-4 transition-all ease-elastic">
				<FormFields.Text
					control={control}
					formState={formState}
					name="name"
					label="Universe name"
					data-qualifier="CompositionEditor/SaveDialog/Name"
					placeholder="Name"
					rightContent={
						checkingNameUniqueness ? <CircularProgressBar classList="w-3" value="indeterminate" /> : undefined
					}
				/>
			</div>
		</Dialog>
	);
}

export type SpawnSubmitUniverseCreationDialogProps = Omit<SubmitUniverseCreationDialogProps, "show" | "onClose">;
export function spawnSubmitUniverseCreationDialog(props: SpawnSubmitUniverseCreationDialogProps): SpawnResult<void> {
	return spawn<void>(
		adaptAnimatedNodeProvider(({ show, resolve, onHidden }) => (
			<SubmitUniverseCreationDialog
				{...props}
				show={show}
				onAnimationStateChange={(state) => state === "hidden" && onHidden()}
				onClose={() => resolve()}
				onAsyncSubmit={async (payload) => {
					await props.onAsyncSubmit(payload);
					resolve();
				}}
			/>
		)),
	);
}
