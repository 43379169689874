import type { InvestmentStatuses, ReferenceUniverseDetails } from "$root/api/api-gen";
import { EntityEditorControllerApiFactory, ReferenceUniversesControllerApiFactory } from "$root/api/api-gen";
import { useApiGen } from "$root/api/hooks";
import { LabelRounded } from "$root/components/LabelRounded/Index";
import type { PageHeaderProps } from "$root/components/PageHeader";
import { PageHeader } from "$root/components/PageHeader";
import ReactQueryWrapper, { ReactQueryWrapperBase } from "$root/components/ReactQueryWrapper";
import ReviewCompositionErrors from "$root/components/ReviewCompositionErrors";
import { useEventBus } from "$root/event-bus";
import { EditProxiedInstrumentSection } from "$root/functional-areas/proxies/EditProxiedInstrumentSection";
import { formatDate } from "$root/localization/formatters";
import type { CustomAxiosError } from "$root/third-party-integrations/axios";
import { axiosExtract } from "$root/third-party-integrations/axios";
import { objMatchFn } from "$root/utils/objects";
import { useQueryNoRefetch } from "$root/utils/react-query";
import { UniverseContext } from "$root/widgets-architecture/contexts/universe";
import { Card } from "$root/widgets-architecture/layout/Card";
import {
	ActionText,
	AsyncButton,
	AutoSortHScrollTable,
	Banner,
	Button,
	CircularProgressBar,
	Icon,
	ProgressBar,
	Select,
	TableDataCell,
	Text,
} from "@mdotm/mdotui/components";
import { noop } from "@mdotm/mdotui/utils";

import { PageCtxConsumer } from "$root/components/PageCtx";
import { PageDownloadAndMoreActionsMenu } from "$root/components/PageDownloadAndMoreActionsMenu";
import { typedUrlForRoute, useTypedNavigation } from "$root/components/PlatformRouter/RoutesDef";
import { spawnAccessDialog } from "$root/functional-areas/acl/AccessDialog";
import EntityStatus from "$root/functional-areas/acl/EntityStatus";
import { aclByArea } from "$root/functional-areas/acl/checkers/all";
import { InstrumentEditorEntity } from "$root/functional-areas/instruments-editor/const";
import { SmallUniverseSummary } from "$root/functional-areas/universe/SmallUniverseSummary";
import { useUniverseEntityManagementActions } from "$root/functional-areas/universe/entity-management";
import { exportUniverse } from "$root/functional-areas/universe/export";
import { useUserValue } from "$root/functional-areas/user";
import { platformToast } from "$root/notification-system/toast";
import WidgetsMapper from "$root/widgets-architecture/layout/WidgetsMapper";
import { nullary, unpromisify } from "@mdotm/mdotui/utils";
import type { AxiosError } from "axios";
import { useCallback, useEffect } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router";
import { PortfolioDetailsTabs } from "./PortfolioDetails";
import "./Portfolios/Portfolios.scss";
import { PortfolioStudioTab } from "./PortfoliosStudio";

type PageSubtitleProps = {
	universe?: ReferenceUniverseDetails;
	onChangeAcl?: () => Promise<unknown>;
	onEdit?: () => void;
	entityManagementActions: ReturnType<typeof useUniverseEntityManagementActions>;
};

const PageSubtitle = (props: PageSubtitleProps) => {
	const { t } = useTranslation();
	const { universe } = props;
	const { richAcl, status } = universe ?? {};
	const user = useUserValue();
	const canEditComposition = aclByArea.portfolio.canEditComposition(user.id, richAcl?.acl ?? []);

	if (status === "REVIEW") {
		return <></>;
	}

	return (
		<div className="flex justify-between items-center py-2.5 text-[#585D68]">
			<div className="flex gap-4 items-center">
				<div className="flex items-center" data-qualifier="UniverseDetails/PageHeader/Status">
					<span className="mr-2 uppercase">{t("STATUS")}</span>
					<LabelRounded type="status" content={{ label: universe?.status ?? "-", component: "" }} />
				</div>
				<div>
					<span className="mr-2 uppercase">{t("INCEPTION_DATE")}:</span>
					{universe?.creationTime ? formatDate(universe.creationTime) : "..."}
				</div>
				<div>
					<span className="mr-2 uppercase">{t("PORTFOLIOS.PF_LAST_ACTION")}:</span>
					<span className="font-semibold text-[#616161]">{universe?.action}</span>
					&nbsp;
					{universe?.modificationTime ? <span>({formatDate(universe?.modificationTime)})</span> : "..."}
				</div>
				<EntityStatus
					accessControl={richAcl}
					entity="UNIVERSE"
					entityId={universe?.uuid}
					entityName={universe?.name}
					refetch={props.onChangeAcl}
				/>
			</div>
			<div className="flex gap-2 items-center">
				{universe?.status === "READY" && canEditComposition && (
					<Button palette="primary" size="small" onClick={props.onEdit}>
						<Icon icon="Edit" size={16} />
						&nbsp;
						{t("BUTTON.EDIT")}
					</Button>
				)}

				<PageDownloadAndMoreActionsMenu
					area="universe"
					downloadActions={
						universe?.status === "READY"
							? [
									{
										icon: "xls",
										disabled: !universe?.uuid,
										onClickAsync: async () => {
											if (!universe!.uuid) {
												throw new Error("unable to load a universe of undefined");
											}
											await exportUniverse.downloadUniverse(universe!.uuid);
										},
										label: "Universe template",
										"data-qualifier": "UniverseDetails/DropdownMenu/DropdownItem(UniverseTemplate)",
									},
									{
										icon: "xls",
										disabled: !universe?.uuid,
										onClickAsync: async () => {
											if (!universe!.uuid) {
												throw new Error("unable to load a universe of undefined");
											}
											await exportUniverse.downloadComposition(universe!.uuid);
										},
										label: "Instrument list",
										"data-qualifier": "UniverseDetails/DropdownMenu/DropdownItem(InstrumentList)",
									},
							  ]
							: undefined
					}
					moreActions={[
						aclByArea.universe.canDelete(user.id, universe?.richAcl?.acl ?? []) && {
							icon: "Delete",
							"data-qualifier": "UniverseDetails/DropdownMenu/DropdownItem(Delete)",
							disabled: !props.entityManagementActions.deleteAsync,
							onClick: unpromisify(props.entityManagementActions.deleteAsync ?? noop),
							label: "Delete",
						},
						{
							icon: "Content-Copy",
							"data-qualifier": "UniverseDetails/DropdownMenu/DropdownItem(Duplicate)",
							disabled: !props.entityManagementActions.duplicateAsync,
							onClick: unpromisify(props.entityManagementActions.duplicateAsync ?? noop),
							label: "Duplicate",
						},
						props.entityManagementActions.renameAsync && {
							icon: "Edit",
							"data-qualifier": "UniverseDetails/DropdownMenu/DropdownItem(Rename)",
							onClick: unpromisify(props.entityManagementActions.renameAsync ?? noop),
							label: "Rename",
						},
					]}
				/>
			</div>
		</div>
	);
};

interface IpageUrl {
	universeUuid: string;
}

const severityByStatus: Record<InvestmentStatuses, PageHeaderProps["severity"]> = {
	ACCEPTED: "success",
	READY: "success",
	PROPOSAL_READY: "info",
	ERROR: "error",
	CALCULATING: "calculating",
	REVIEW: undefined,
	RETRIEVING_DATA: "calculating",
	DRAFT: "info",
};

function UniverseDetails(): JSX.Element {
	const { universeUuid } = useParams<IpageUrl>();

	const history = useHistory();
	const user = useUserValue();
	const { t } = useTranslation();
	const { push } = useTypedNavigation();

	const referenceUniverseV4Api = useApiGen(ReferenceUniversesControllerApiFactory);
	const editorApi = useApiGen(EntityEditorControllerApiFactory);

	useEffect(() => {
		if (!universeUuid) {
			push("PortfoliosStudio", { tab: PortfolioStudioTab.Universes, status: "notFound" });
		}
	}, [history, push, universeUuid]);

	const queryUniverseList = useQueryNoRefetch(["universeList"], {
		queryFn: () => axiosExtract(referenceUniverseV4Api.getUserUniverses()),
	});

	const {
		data: universe,
		isLoading,
		refetch,
	} = useQueryNoRefetch(["universeDetails", universeUuid], {
		enabled: Boolean(universeUuid),
		queryFn: () => axiosExtract(referenceUniverseV4Api.getUniverse(universeUuid)),
		onError: (error: AxiosError<CustomAxiosError>) => {
			if (error.response?.data.code === 404 || error.response?.data.message === "Accesso negato") {
				spawnAccessDialog({
					onClick: (onClose) => {
						push("PortfoliosStudio", { tab: PortfolioStudioTab.Universes });
						onClose();
					},
				});
				return;
			}

			platformToast({
				children: t("SOMETHING_WENT_WRONG"),
				severity: "error",
				icon: "Portfolio",
			});
			push("PortfoliosStudio", { status: "notFound", tab: PortfolioStudioTab.Universes });
		},
	});

	useEventBus("investment-update", {
		filter: objMatchFn({ uuid: universeUuid }),
		listener: () => {
			refetch().catch(noop);
		},
	});

	useEventBus("shared-entity", {
		filter: objMatchFn({ sharedEntityUuid: universeUuid }),
		listener: () => {
			refetch().catch(noop);
		},
	});

	const { status, uuid, name } = universe ?? {};
	const canEditComposition = aclByArea.portfolio.canEditComposition(user.id, universe?.richAcl?.acl ?? []);

	const onChangeUniverseUuid = useCallback(
		(id: string) => {
			push("UniverseDetails", { universeUuid: id });
		},
		[push],
	);

	const entityManagementActions = useUniverseEntityManagementActions(universe, {
		onRename: unpromisify(nullary(refetch)),
		onDuplicate: () => push("PortfoliosStudio", { tab: PortfolioStudioTab.Universes }),
		onDelete: () => push("PortfoliosStudio", { tab: PortfolioStudioTab.Universes }),
	});

	return (
		<>
			<PageHeader
				name="UniverseDetails"
				severity={severityByStatus[status as InvestmentStatuses]} // TODO: is this the same enum from Portfolio?
				title={isLoading ? "..." : name ? name : "Untitled"}
				crumbs={[
					{ children: "Portfolio studio " },
					{
						children: "Universes", // TODO: translate
						href: typedUrlForRoute("PortfoliosStudio", { tab: PortfolioStudioTab.Universes }),
					},
					{
						children: (
							<ReactQueryWrapperBase
								query={queryUniverseList}
								loadingFallback={<CircularProgressBar value="indeterminate" outerDiameter={12} innerDiameter={8} />}
							>
								{(universes) => (
									<Select
										unstyled
										classList="flex items-center w-40 truncate"
										strategy="fixed"
										enableSearch
										options={universes.map(({ name, uuid }) => ({ label: name!, value: uuid! })).reverse()}
										value={uuid}
										i18n={{ triggerPlaceholder: () => t("UNIVERSE.SELECT_PLACEHOLDER") }}
										onChange={onChangeUniverseUuid}
									/>
								)}
							</ReactQueryWrapperBase>
						),
					},
				]}
				subTitle={
					<PageSubtitle
						entityManagementActions={entityManagementActions}
						universe={universe}
						onChangeAcl={refetch}
						onEdit={() =>
							push("Portfolios/ManualEdit", { entity: InstrumentEditorEntity.Universe, uuid: universe?.uuid ?? "" })
						}
					/>
				}
			/>
			<UniverseContext.Provider value={{ universe: universe ?? null }}>
				<div className="mb-5">
					<SmallUniverseSummary universe={universe} />
				</div>

				{status === "REVIEW" && (
					<ReactQueryWrapper
						queryFn={() => axiosExtract(editorApi.getEditorReviewComposition(universe?.uuid ?? "", "UNIVERSE"))}
						queryKey={["universeUploadComposition", universe?.uuid, universe?.status]}
						enabled={Boolean(universe?.uuid)}
						loadingFallback={<ProgressBar value="indeterminate" />}
					>
						{(upload) => (
							<Card>
								<Text type="Body/XL/Bold" classList="mb-4">
									Instruments
								</Text>
								<ReviewCompositionErrors errors={upload?.uploadErrors} />
								<div className="mb-4">
									<Banner severity="warning" title="Review Before Saving">
										Some instruments require your attention. This may be due to missing information or because they have
										been delisted. Please review the list below and make any necessary updates before saving.
									</Banner>
								</div>
								<EditProxiedInstrumentSection
									uuid={universe?.uuid}
									disabled={canEditComposition === false}
									section="universe"
									composition={upload?.composition ?? []}
									onSaveAsync={async (tickerComposition) => {
										await editorApi.saveEditorReviewEntity("UNIVERSE", {
											identifier: universe?.uuid,
											tickerComposition,
										});
										await refetch();
									}}
									customActions={({ isSaveDisabled, onSaveAsync }) => (
										<PageCtxConsumer>
											{(ctx) =>
												ctx.titleActionPortalTarget &&
												createPortal(
													<div className="flex items-center gap-2">
														<Button
															onClick={() => push("PortfoliosStudio", { tab: PortfolioStudioTab.Universes })}
															size="small"
															palette="tertiary"
														>
															Cancel
														</Button>

														<AsyncButton
															palette="primary"
															size="small"
															onClickAsync={onSaveAsync}
															disabled={isSaveDisabled}
														>
															{t("BUTTON.SAVE")}
														</AsyncButton>
													</div>,
													ctx.titleActionPortalTarget,
												)
											}
										</PageCtxConsumer>
									)}
								/>
							</Card>
						)}
					</ReactQueryWrapper>
				)}

				{status === "RETRIEVING_DATA" && (
					<ReactQueryWrapper
						queryFn={() => axiosExtract(editorApi.getEditorReviewComposition(universe?.uuid ?? "", "UNIVERSE"))}
						queryKey={["retriveUniverseComposition", universe?.uuid, universe?.status]}
						enabled={Boolean(universe?.uuid)}
						loadingFallback={<ProgressBar value="indeterminate" />}
					>
						{({ composition }) => (
							<Card>
								<div className="mb-4">
									<Banner severity="info">{t("PORTFOLIO_UPLOAD_ALERTS.CALCULATING_UPLOAD")}</Banner>
								</div>
								<AutoSortHScrollTable
									columns={[
										{
											name: "name",
											header: "name",
											content: (row, cellProps) => {
												if (row.proxyOverwriteType !== "PORTFOLIO_MIXED") {
													return row.instrument ?? "-";
												}

												return (
													<TableDataCell {...cellProps}>
														<ActionText
															classList="inline-flex items-center gap-1"
															onClick={() =>
																window.open(
																	typedUrlForRoute("PortfolioDetails", {
																		portfolioUid: row.ticker ?? "",
																		tab: PortfolioDetailsTabs.COMPOSITION,
																	}),
																	"_blank",
																)
															}
														>
															<span className="font-[weight:500] truncate items-center">{row.instrument ?? "-"}</span>
															<svg
																width="12"
																height="12"
																viewBox="0 0 12 12"
																fill="none"
																xmlns="http://www.w3.org/2000/svg"
																className="shrink-0"
															>
																<path
																	d="M8 1.5H10.5V4"
																	stroke="currentColor"
																	strokeWidth="1.5"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
																<path
																	d="M7 5L10.5 1.5"
																	stroke="currentColor"
																	strokeWidth="1.5"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
																<path
																	d="M8.5 7.4375V9.625C8.5 10.1084 8.10844 10.5 7.625 10.5H2.375C1.89156 10.5 1.5 10.1084 1.5 9.625V4.375C1.5 3.89156 1.89156 3.5 2.375 3.5H4.5625"
																	stroke="currentColor"
																	strokeWidth="1.5"
																	strokeLinecap="round"
																	strokeLinejoin="round"
																/>
															</svg>
														</ActionText>
													</TableDataCell>
												);
											},
										},
										{ name: "identifier", header: "Identifier", content: (row) => row.identifier ?? "-" },
										{ name: "assetClass", header: "Asset Class", content: (row) => row.assetClass ?? "-" },
										{
											name: "microAssetClass",
											header: "Micro Asset Class",
											content: (row) => row.microAssetClass ?? "-",
										},
									]}
									rows={composition ?? []}
									classList="max-h-[410px]"
								/>
							</Card>
						)}
					</ReactQueryWrapper>
				)}

				{status !== "RETRIEVING_DATA" && status !== "REVIEW" && (
					<WidgetsMapper style={{ minHeight: "120px" }} widgetName="ReadonlyUniverseComposition" />
				)}
			</UniverseContext.Provider>
		</>
	);
}

export default UniverseDetails;
