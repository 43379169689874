import { FormFields } from "$root/ui-lib/form/FormFields";
import { zodResolver } from "@hookform/resolvers/zod";
import type { DialogProps } from "@mdotm/mdotui/components";
import { Button, Dialog, DialogFooter, Icon, SubmitButton } from "@mdotm/mdotui/components";
import type { SpawnResult } from "@mdotm/mdotui/react-extensions";
import { adaptAnimatedNodeProvider, spawn } from "@mdotm/mdotui/react-extensions";
import type { Set } from "immutable";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import type { InstrumentEditorEntity, MinimunDialogProps } from "../../const";

export type AddMissingInstrumentDialogProps = {
	instrumentInComposition: Set<string>;
	onSubmit(data: { identifier: string; weight: number }): void;
	onAnimationStateChange?: DialogProps["onAnimationStateChange"];
	entity: InstrumentEditorEntity;
} & MinimunDialogProps;

const defaultInstrumentValue = { identifier: "", weight: 0.01 };
function AddMissingInstrumentDialog(props: AddMissingInstrumentDialogProps) {
	const { t } = useTranslation();

	const { formState, control, handleSubmit } = useForm({
		defaultValues: defaultInstrumentValue,
		resolver: zodResolver(
			z.object(
				props.entity === "UNIVERSE"
					? {
							identifier: z
								.string()
								.regex(/^((([A-Z]){2}){1}((\d)|(\w)){10}|([a-zA-Z0-9]{9}))+$/, { message: "Insert a valid identifier" })
								.refine((identifier) => !props.instrumentInComposition.has(identifier), {
									message: "identifier is duplicated",
								}),
					  }
					: {
							identifier: z
								.string()
								.regex(/^((([A-Z]){2}){1}((\d)|(\w)){10}|([a-zA-Z0-9]{9}))+$/, { message: "Insert a valid identifier" })
								.refine((identifier) => !props.instrumentInComposition.has(identifier), {
									message: "identifier is duplicated",
								}),
							weight: z.number().min(0.01).max(100),
					  },
			),
		),
	});

	return (
		<Dialog
			size="medium"
			show={props.show}
			onClose={props.onClose}
			onAnimationStateChange={props.onAnimationStateChange}
			onSubmitAsync={() => handleSubmit(props.onSubmit)()}
			header="Add instrument"
			footer={
				<DialogFooter
					primaryAction={<SubmitButton>{t("BUTTON.ADD")}</SubmitButton>}
					neutralAction={
						<Button palette="tertiary" onClick={props.onClose}>
							{t("BUTTON.CANCEL")}
						</Button>
					}
				/>
			}
		>
			<FormFields.Text control={control} formState={formState} label="Identifier" name="identifier" />
			{props.entity !== "UNIVERSE" && (
				<>
					<br />
					<FormFields.NullableNumber
						control={control}
						formState={formState}
						label="Weight"
						name="weight"
						rightContent={<Icon icon="Percentile" />}
					/>
				</>
			)}
		</Dialog>
	);
}

type SpawnAddMissingInstrumentDialogProps = Omit<AddMissingInstrumentDialogProps, "show" | "onClose">;
export function spawnInstrumentDialog(props: SpawnAddMissingInstrumentDialogProps): SpawnResult<void> {
	return spawn<void>(
		adaptAnimatedNodeProvider(({ show, resolve, onHidden }) => (
			<AddMissingInstrumentDialog
				{...props}
				show={show}
				onAnimationStateChange={(state) => state === "hidden" && onHidden()}
				onClose={() => resolve()}
				onSubmit={(instruments) => {
					props.onSubmit(instruments);
					resolve();
				}}
			/>
		)),
	);
}
